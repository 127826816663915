import React from "react";
import { useSelector } from "react-redux";

import Tooltip from "../componentsGeneral/section/Tooltip";
import { monthNames } from "../constants/app";
import formatNumber from "../utils/formatNumber";

const Tooltipunitgraph = ({ payload, currentKey, className }) => {
  const { activeSection, year } = useSelector((state) => ({
    activeSection: state.sections.activeSection,
    year: state.sections.year,
  }));

  const getTitle = () => {
    if (!payload[0].payload) return null;

    return (
      <div className="mb-1">
        <span className="font-bold">
          {
            monthNames[
              parseInt(
                activeSection === "IT_Hr_TOTAL_ATTRITION"
                  ? payload[0].payload.period || payload[0].payload.month
                  : payload[0].payload.period || payload[0].payload.fiscMonth
              ) - 1
            ]
          }
        </span>{" "}
        FY
        {(activeSection === "IT_Hr_TOTAL_ATTRITION"
          ? payload[0].payload.year || year
          : payload[0].payload.fiscYear || year) - 2000}
      </div>
    );
  };

  const formatStr = ({ prependStr, number, appendStr }) =>
    `${prependStr}${number}${appendStr}`;

  const getItems = () => {
    if (!(currentKey in payload[0].payload)) return [];

    return [
      <div className="whitespace-nowrap" key={0}>
        {(activeSection === "IT_Hr_TOTAL_ATTRITION"
          ? "Attrition"
          : activeSection === "IT_Hr_TEAM_MEMBERS"
          ? "Team Member"
          : activeSection === "IT_Hr_Vacancies"
          ? "Vacancies"
          : activeSection === "IT_Hr_AvgDaysHire"
          ? "AvgDaysHire"
          : activeSection === "IT_Hr_Positions"
          ? "Positions"
          : "") +
          ": " +
          "" +
          formatStr(
            formatNumber({
              number: Math.round(payload[0].payload[currentKey]),
            })
          )}
      </div>,
    ];
  };

  return (
    <>
      {payload && payload.length ? (
        <Tooltip className={className}>
          <div className="text-xs text-black">
            {getTitle()}
            {getItems()}
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};

export default Tooltipunitgraph;
