import moment from 'moment';

export default () => {
    var newDate = new Date();
    // add 3 months
    newDate.setMonth(newDate.getMonth() + 3);
    const fiscMonth = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const quartal = Math.ceil(fiscMonth/3);
    let currentWeek = moment().format('w');
    currentWeek = parseInt(currentWeek);
    if(currentWeek > 39) {
        currentWeek -= 39;
    }
    else {
        currentWeek += 13;
    }
    return { year, quartal, month: parseInt(fiscMonth), week: parseInt(currentWeek) }
}
