import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Osha from '../fullAppUser/Osha';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const OshaPrepared = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
      setBuType('location')
      setActiveBu(['Prepared Foods'])
    }, [])
    
    return (
        <Osha
        onlyPrepared
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(OshaPrepared)
