import React from 'react'
import './styles.css'
import TutorialImg from '../../images/gif/tutorial.gif'
import TutorialImgDesk from '../../images/gif/desk_tutorial.gif'
// import TutorialVideo from '../../images/gif/tutorial.mp4'
// import TutorialVideoDesk from '../../images/gif/desk_tutorial.mp4'
import { DESKTOP_VIEW_BREAKPOINT } from '../../constants/app'

const Tutorial = props => {
  return (
    <div className='tutorial'>
      <img
        src={
          props.width < DESKTOP_VIEW_BREAKPOINT ? TutorialImg : TutorialImgDesk
        }
        style={{
          width: '100%',
          objectFit: 'none'
        }}
      />
      {/* <video controls autostart autoPlay src={props.width < DESKTOP_VIEW_BREAKPOINT ? TutorialVideo : TutorialVideoDesk} type="video/mp4" /> */}
    </div>
  )
}
export default Tutorial
