import * as React from "react"

function Circle({ size, color='#4cd964' }) {
  if(!size){
    size = 8;
  }

  return (
    <svg width={size} height={size} viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <circle cx={4} cy={4} r={4} fill={color} />
    </svg>
  )
}

export default Circle
