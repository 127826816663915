import React, { useEffect, useState, useRef } from 'react'
import { useOktaAuth } from "@okta/okta-react"
import {
  publicSynchronizerevenue
} from '../../services/dataService'
import { setData, setTrendData, onError, resetApp } from '../../actions/valuesActions'
import { setQuarter, setYear, setActiveBu, setActiveTab, setIsCore, setActiveSection } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';
import {setRevenueDataRequested} from '../../actions/app'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import {DESKTOP_VIEW_BREAKPOINT, sectionsFinance } from '../../constants/app'
import ScreenBlocker from '../../components/ScreenBlocker/ScreenBlocker'
import { setShowModal } from '../../actions/app'
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import ReactGA from "react-ga4";
import useWindowDimensions from '../../hooks/useWindowDimensions'
import LayoutApp from '../../componentsGeneral/AppLayout'
import HeaderDesktop from '../../componentsGeneral/section/HeaderDesktop'
import SectionSwitcherDesktop from '../../componentsGeneral/section/SectionSwticherDesktop'
import ContentContainerDesktop from '../../componentsGeneral/section/ContentContainerDesktop'
import ContentLeftDesktop from '../../componentsGeneral/section/ContentLeftDesktop'
import ContentRightDesktop from '../../componentsGeneral/section/ContentRightDesktop'
import CurrentScreenValuesFinance from '../../componentsFinance/CurrentScreenValuesFinance'
import ContentLeftDesktopHeader from '../../componentsGeneral/section/ContentLeftDesktopHeader'
import Domain from '../../images/Domain'
import BigGraphFinance from '../../componentsFinance/BigGraphFinance'
import GraphDataProviderFinance from '../../componentsFinance/GraphDataProviderFinance'
import UnitBackButton from '../../componentsGeneral/section/UnitsBackButton';
import Checkbox from '../../componentsGeneral/section/Checkbox';
import { Tooltip } from 'recharts';
import TooltipFinance from '../../componentsFinance/TooltipFinance';
import MobileControls from '../../componentsGeneral/section/MobileControls';

const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;


const Main = ({showModal, revenueDataRequested, ...props}) => {
  const { height, width } = useWindowDimensions()

  const [currentKey, setCurrentKey] = useState('OI')
  const [sortUnits, setSortUnits] = useState('alph')
  const [mounted, setMounted] = useState(false)
  const [trend2year, setTrend2year] = useState(false)
  const [units, setUnits] = useState([])

  const { authState, oktaAuth } = useOktaAuth()
  const {accessToken: { accessToken }} = authState

  const initRevenueData = async accessToken => {
    const { isCore } = props
     console.log('initRevenueData accessToken:',accessToken)
    try {
      const rdata = await publicSynchronizerevenue(accessToken, isCore)
      props.setTrendData(rdata)
    } catch (err) {
      console.log('publicSynchronizerevenue err', err)
    }
  }

  console.log(oktaAuth, authState, ' @$@$@$@$@$')

  useEffect(() => {
    const { setActiveSection, activeSection } = props;
    if(!activeSection || !sectionsFinance.includes(activeSection)) {
      setActiveSection('OI');
    }
  }, [])

  useEffect(() => {
    console.log("accessToken changed:", accessToken);
    if (accessToken && !revenueDataRequested) {

      oktaAuth.getUser().then(userInfo => {
        // console.log('userInfo', userInfo);
        //initGA(userInfo.AD_sAMAccountName);
        try {
          // initGA(userInfo.sub);
          ReactGA.set({ userId: userInfo.preferred_username })
          ReactGA.event({ action: "Login", category: "UserID" })
        } catch (e) {
          console.error(e)
        }
      });

      initRevenueData(accessToken);
      props.setRevenueDataRequested(true);
    }
    return () => console.log("###\nMAIN COMPONENT UNMOUNTED\n###");
  }, [accessToken]);

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })


  let refresher;
  useEffect(() => {
    oktaAuth.getUser().then(info => {
      console.log(info);
        refresher = setInterval(() => {
          try {
            oktaAuth.tokenManager.renew("idToken");
          } catch (e) {
            window.location.href = '/logout';
          }

        }, 1000 * 600);
    });

    return () => {
      clearInterval(refresher);
    }
  }, [authState]);

  oktaAuth.tokenManager.on("renewed", function(key, newToken, oldToken) {
    console.log("renewed: ", { key, newToken, oldToken });
  });

  const getQueryInfo = (includeGraph = 0) => {
    let { isSingle, year, quarter, activeBu, activeTab, isCore } = props;
    return {
      year: year ?? 2020,
      quartal: quarter ?? 1,
      month: quarter ? false : isSingle,
      week: quarter ? isSingle : false,
      segment: activeTab ? activeTab.toUpperCase() : activeTab,
      unit: activeBu,
      type: includeGraph,
      isCore
    }
  }

  const prevQYState =
    usePrevious({
      activeQYSwitcher: props.activeQYSwitcher,
      isSingle: props.isSingle
    }) || {}

  const requestOnlyHeaderData = () => {
    const { setData, isSingle, activeQYSwitcher, isGraphLoading } = props
    if (isGraphLoading) return

    if (
      (!isSingle || isSingle != prevQYState.isSingle) &&
      (!prevQYState || activeQYSwitcher == prevQYState.activeQYSwitcher)
    )
    {
      setData({ ...getQueryInfo(0) }, 0, accessToken)
    }
  }

  useEffect(() => {
    if (accessToken) {
      requestOnlyHeaderData();
    }
  }, [props.year, props.quarter, props.isSingle])

  const requestHeaderAndGraphData = () => {
    if (accessToken) {
      const { setData } = props
      setData({ ...getQueryInfo(1) }, 1, accessToken)
    }
  }

  useEffect(() => {
    requestHeaderAndGraphData();
  }, [
    props.activeTab,
    props.activeBu,
    props.activeQYSwitcher,
    props.isCore
  ]);

  useEffect(() => {
    initRevenueData(accessToken);
  }, [])

  useEffect(() => {
    initRevenueData(accessToken);
  }, [
    props.isCore
  ])

  useEffect(() => {
    const {
      setYear,
      setQuarter,
      currentDate
    } = props

    if (!currentDate.year) return

    setYear(currentDate.year)
    setQuarter(currentDate.quarter)
    setMounted(true)
  }, [props.currentDate]);

  useEffect(() => {
    if (mounted) requestHeaderAndGraphData()
  }, [mounted])

  useEffect(() => {
    const { activeSection } = props

    const keys = {
      'OI': 'ebit',
      'NET_SALES': 'netSales',
      'AOI': 'aoi',
      'VOLUME': 'volume'
    }

    setCurrentKey(keys[activeSection])
  }, [props.activeSection])

  const goBackOneLevel = () => {
    const { setActiveTab, setActiveBu, setNavigationStage, navigationStage } = props
    if (navigationStage === 2) {
      setNavigationStage(1)
      setActiveBu(false)
    }
    else if (navigationStage === 1) {
      setNavigationStage(0)
      setActiveTab(false)
    }
  }

  const getLeftHeaderTitle = () => {
    const { navigationStage, activeTabTitle, onlyChicken, onlyPrepared, 
      onlyFreshMeats, onlyMcDonalds, onlyOperations, onlyChicken_Operations, 
      onlyPrepared_Operations, onlyFreshMeats_Operations, onlyMcDonalds_Operations } = props
    if (onlyMcDonalds) return  <></>
    else if (navigationStage === 2) return <UnitBackButton onClick={goBackOneLevel}>
      { activeTabTitle.includes('Poultry') ? activeTabTitle.replace('Poultry', 'Chicken') : activeTabTitle}
    </UnitBackButton>
    else if (navigationStage === 1 && !onlyChicken && !onlyPrepared && !onlyFreshMeats && !onlyChicken_Operations && !onlyPrepared_Operations && !onlyFreshMeats_Operations && !onlyMcDonalds_Operations) return <UnitBackButton onClick={goBackOneLevel}>
      { 'Tyson Overall' }
    </UnitBackButton>
    else return <span>
      <Domain />
      <span className='text-gray-600 font-bold ml-2 leading-none'>
      {(onlyChicken || onlyPrepared || onlyFreshMeats || onlyMcDonalds || onlyChicken_Operations || 
      onlyPrepared_Operations || onlyFreshMeats_Operations || onlyMcDonalds_Operations) ? `Business Families` :  `Business Segments`}
      </span>
    </span>
  }

  const getGraphTitle = () => {
    const { navigationStage, activeTabTitle, activeBu, activeSection } = props
    // if (navigationStage === 2) return `${activeBu} Operating Income 2-YR Trend`
    if (navigationStage === 1) return `${activeTabTitle.includes('Poultry') ? activeTabTitle.replace('Poultry', 'Chicken') : activeTabTitle} ${activeSection === 'OI' ? 'Operating Income' : activeSection === 'AOI' ? 'AOI%' : activeSection === 'VOLUME' ? 'Volume' : activeSection === 'NET_SALES' ? 'Net Sales' : '' } 2-YR Trend`
    if (navigationStage === 2) return `${activeBu } ${activeSection === 'OI' ? 'Operating Income' : activeSection === 'AOI' ? 'AOI%' : activeSection === 'VOLUME' ? 'Volume' : activeSection === 'NET_SALES' ? 'Net Sales' : '' } 2-YR Trend`
  }

  const getGraphTrend = ({ trend }) => {
    if (!Array.isArray(trend) || !trend[0]) return []

    const { currentDate } = props;
    const newTrend = [];
    const lastMonth = parseInt( trend[trend.length - 1].groupByPeriod )
    let j = lastMonth;
    for(let i = lastMonth; i <= 24 + lastMonth; i++) {
      const yearIterate = (currentDate.year - 2) + Math.floor((i - 1)/12)
      const value = trend.find(({ fiscYear, groupByPeriod }) => parseInt(fiscYear) === yearIterate && parseInt(groupByPeriod) === j  );
      if(value) newTrend.push({
        ...value,
        'aoiPlanOriginal': value.aoiPlan,
        'aoiProjOriginal': value.aoiProj,
        'ebitPlanOriginal': value.ebitPlan,
        'ebitProjOriginal': value.ebitProj,
        'netSalesPlanOriginal': value.netSalesPlan,
        'netSalesProjOriginal': value.netSalesProj,
        'volumePlanOriginal': value.volumePlan,
        'volumeProjOriginal': value.volumeProj,
      });
      else newTrend.push({
          "fiscYear": yearIterate,
          "groupByPeriod": j,
          "ebit": 0,
          "netSales": 0,
          "aoi": 0,
          "volume": 0,
      });
      if(j === 12) j = 0;
      j++;
    }
    return newTrend.map(el => ({ ...el, yearMonth: `${el.fiscYear} ${el.groupByPeriod}` }));
  }

  const get2yearTrend = () => {
    const { trends, activeTab, activeBu, navigationStage } = props
    if (navigationStage === 2) {
      const trendData = trends.bsFamily.year[activeTab]?.filter(({ groupByEntity }) => groupByEntity == activeBu)
      return getGraphTrend({ trend: trendData })
    }
    else if (navigationStage === 1) {
      const trendData = trends.bsTrend.year[activeTab]
      return getGraphTrend({ trend: trendData })
    }
    else {
      return null
    }
  }

  const clickCheckbox = () => {
    const { setIsCore, isCore } = props

    setIsCore(!isCore)
  }

  const getHeaderadditionalContent = () => {
    const { activeSection, isCore } = props

    if (activeSection === 'VOLUME') {
      return <Checkbox
        checked={isCore}
        setChecked={clickCheckbox}
        title='Show Core'
      />
    }

    return null
  }

  const getAdditionalUnitValues = (unit) => {
    const v = []
    const plan = unit[currentKey] - unit[currentKey + 'Plan'];
    const proj = unit[currentKey] - unit[currentKey + 'Proj'];
    v.push({
      value: isNaN(plan) ? 0 : plan,
      text: ''
    })

    if (currentKey + 'Proj' in unit) {
      v[0].text = 'AOP'
      v.push({
        value: isNaN(proj) ? 0 : proj,
        text: 'Proj.'
      })
    }

    return v
  }

  const getAdditionalValuesVolume = (unit) => {
    const v = []
    v.push({
      value: isNaN(unit[currentKey] - unit[currentKey + 'Previous'] / (unit[currentKey + 'Previous'])) ? 0 : ((unit[currentKey] - unit[currentKey + 'Previous']) / (unit[currentKey + 'Previous'])),
      text: ''
    })

    return v
  }

  const clickBuSingle = (bu) => {
    props.setActiveBu(bu)
    props.setNavigationStage(2)
  }

  const clickBuDouble = (tab) => {
    props.setActiveTab(tab)
    props.setNavigationStage(1)
  }

  const sortUnitsFunction = (unit1, unit2, nameKey) => {
    if (sortUnits === 'alph') return unit1[nameKey]?.toLowerCase() < unit2[nameKey]?.toLowerCase() ? -1 : 1
    else return unit2[currentKey] - unit1[currentKey]
  }

  const getTrendInnerUnits = (trends, name) => {
    const { activeTab } = props
    return trends[activeTab]?.filter(({ groupByEntity }) => groupByEntity == name)
  }

  const getUnits = () => {
    const { navigationStage, trends, activeQYSwitcher, year, quarter, units, activeBu, onlyMcDonalds, onlyMcDonalds_Operations } = props
    const periodKey = activeQYSwitcher === 'Q' ? 'month' : 'year'
    if (navigationStage === 1 || navigationStage === 2) {
      return units.filter((el) => (onlyMcDonalds || onlyMcDonalds_Operations) ? '' : true)
          .sort((a, b) => sortUnitsFunction(a, b, 'entity'))
          .map(unit => <GraphDataProviderFinance
            isActive={activeBu === unit.entity}
            type='single'
            key={unit.entity}
            name={unit.entity}
            value={unit[currentKey]}
            trend={getTrendInnerUnits(trends?.bsFamily[periodKey], unit?.entity)}
            financePercents={currentKey === 'aoi'}
            currentKey={currentKey}
            isLast={navigationStage === 2}
            quarter={quarter}
            year={year}
            activeQYSwitcher={activeQYSwitcher}
            onClick={() => clickBuSingle(unit.entity)}
          />)
    }

    else {
      const names = {
        'BEEF': 'Beef',
        'CHICKEN': 'Chicken',
        'INTERNATIONAL': 'International',
        'PORK': 'Pork',
        'PREPARED FOODS': 'Prepared Foods',
        'INTERSEGMENT': 'Intersegment',
      }

      return units.map(unit => ({ ...unit, alias: names[unit.entity] }))
      .sort((a, b) => sortUnitsFunction(a, b, 'alias'))
      .map(unit => <GraphDataProviderFinance
        type='double'
        key={unit.entity}
        name={unit.alias}
        value={unit[currentKey]}
        additionalValues={currentKey === 'volume' ? getAdditionalValuesVolume(unit) : getAdditionalUnitValues(unit)}
        trend={trends.bsTrend[periodKey][unit.entity.toLowerCase()]}
        currentKey={currentKey}
        quarter={quarter}
        year={year}
        activeQYSwitcher={activeQYSwitcher}
        onClick={() => clickBuDouble(unit.entity.toLowerCase())}
      />)
    }
  }

  const getWeekMonth = (type) => {
    const { isSingle, activeQYSwitcher } = props
    if (!isSingle) return false;

    if (activeQYSwitcher == 'Y' && type == 'month') return isSingle.split('/')[1]
    if (activeQYSwitcher == 'Q' && type == 'week') return isSingle.split('/')[1]
  }

  const getAvailableSections = () => {
    const { onlyPoultry, onlyPrepared, onlyFreshMeats, onlyMcDonalds, 
      onlyOperations, onlyChicken_Operations, onlyPrepared_Operations, onlyFreshMeats_Operations, onlyMcDonalds_Operations } = props

    let sections = [
      {to: 'OI', title: 'Operating Income'},
      {to: 'AOI', title: 'AOI%'},
      {to: 'NET_SALES', title: 'Net Sales'},
      {to: 'VOLUME', title: 'Volume & Growth'}
    ]

    if (onlyOperations || onlyChicken_Operations || onlyPrepared_Operations || onlyFreshMeats_Operations || onlyMcDonalds_Operations) sections = [ {to: 'VOLUME', title: 'Volume & Growth'} ]

    return sections
  }

  useEffect(() => {
    if (!props.isGraphLoading) setTrend2year( get2yearTrend() )
  }, [
    props.isGraphLoading,
    props.year,
    props.activeBu,
    sortUnits,
    props.units,
    props.trends,
    currentKey,
    props.quarter,
    props.bsTrend,
    props.isCore
  ])

  useEffect(() => {
    if (!props.isGraphLoading) setUnits( getUnits() )
  }, [
    props.isGraphLoading,
    props.year,
    props.activeBu,
    sortUnits,
    props.units,
    props.trends,
    currentKey,
    props.quarter,
    props.isCore
  ])

  return (
    <ScreenWrapper doSetYearFinance doSetQuarterFinance>
      <ScreenBlocker
        text='Please, rotate your device.'
        active={
            isMobile &&
            ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
            (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
        }
      />
      <LayoutApp>
        <HeaderDesktop
          week={getWeekMonth('week')}
          month={getWeekMonth('month')}
        >
          { getHeaderadditionalContent() }
        </HeaderDesktop>
         <SectionSwitcherDesktop 
          buttons={getAvailableSections()}
        />
        <MobileControls>
          { getHeaderadditionalContent() }
        </MobileControls>
        <ContentContainerDesktop>
          <ContentLeftDesktop
            graphData={trend2year}
            graphTitle={getGraphTitle()}
            dataKey={currentKey}
            tooltip={!isMobile && <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{y: true, x:true}}
              offset={-3}
              content={<TooltipFinance
                currentKey={currentKey}
                groupKey={'yearMonth'}
                activeQYSwitcher={'Y'}
                splitWithValue=' '
                display={true}
                isNumberlessthan1000={true}
              />}
            >
            </Tooltip>}
          >
            {(props.onlyMcDonalds || props.onlyMcDonalds_Operations) ? '' : <ContentLeftDesktopHeader
              includeSort
              percents={props.activeSection === 'AOI'}
              number={props.activeSection === 'VOLUME'}
              activeSort={sortUnits}
              clickSortAlph={() => setSortUnits('alph')}
              clickSortVal={() => setSortUnits('val')}
            >
              { getLeftHeaderTitle() }
            </ContentLeftDesktopHeader>
            }
            <div className='overflow-y-scroll units-container'>
              {
                units
              }
            </div>

          </ContentLeftDesktop>
          <ContentRightDesktop>
            <CurrentScreenValuesFinance
              currentKey={currentKey}
              week={getWeekMonth('week')}
              month={getWeekMonth('month')}
              navigationStage={props.navigationStage}
              activeQYSwitcher={props.activeQYSwitcher}
            />
            {mounted && <BigGraphFinance
              currentKey={currentKey}
            />}
          </ContentRightDesktop>
        </ContentContainerDesktop>
      </LayoutApp>
    </ScreenWrapper>
  )
}



function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default connect(
  state => ({
    color: state.sections.color,
    scale: state.values.scale,
    sign: state.values.sign,
    navigationStage: state.navigationState.stage,
    units: state.values.units,
    year: state.sections.year,
    quarter: state.sections.quarter,
    activeTab: state.sections.activeTab,
    activeBu: state.sections.activeBu,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    activeSection: state.sections.activeSection,
    header: state.values.header,
    isGraphLoading: state.values.isGraphLoading,
    bars: state.values.bars,
    isHeaderLoading: state.values.isHeaderLoading,
    scaleNumber: state.values.scaleNumber,
    activeTabTitle: state.sections.activeTabTitle,
    trends: state.values.trends,
    planScaleNumber: state.values.planScaleNumber,
    projScaleNubmer: state.values.projScaleNubmer,
    planScale: state.values.planScale,
    projScale: state.values.projScale,
    projDelta: state.values.projDelta,
    planDelta: state.values.planDelta,
    stateResetApp: state.values.resetApp,
    mainValue: state.values.mainValue,
    isGraphReady: state.sections.isGraphReady,
    isTrendReady: state.values.isTrendReady,
    err: state.values.err,
    revenueDataRequested: state.app.revenueDataRequested,
    appValues: state.app,
    showModalValue: state.app.showModal,
    currentDate: state.sections.financeFiscDate,
    isFirstDataFetched: state.values.isFirstDataFetched,
    scrollPosition: state.sections.scrollPosition,
    isCore: state.sections.isCore
  }),
  { setData, setIsCore, setTrendData, resetApp, setActiveBu,showModal: setShowModal, setNavigationStage,onError, setRevenueDataRequested, setQuarter, setYear, setActiveTab, setActiveSection }
)(Main)

///code latest
