import React from 'react'
import { useSelector } from 'react-redux'
import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import formatNumber from '../../utils/formatNumber'
import toClassName from '../../utils/toClassName'
import useMobileHeader from '../../hooks/useMobileHeader'
import { sectionsFinance, fillRateTarget, sectionsFillRate } from '../../constants/app'
import MobileFixedHeader from './MobileFixedHeader'

const CurrentScreenValues = ({isItDash, isPosDot, week, month, value, dotValue, percents, financePercents, title, children, mobileHeaderChildren, needScale, needSign, employeePercents, includeDot, prependClasses, defaultTitle='Tyson', navigationStage, isNumberlessthan1000}) => {
  const {
    headerVisible,
    setBreakpointEl
  } = useMobileHeader()
  const {
    prependStr,
    appendStr,
    number
  } = formatNumber({ number: isNaN(value) ? 0 : value, percents: percents, needScale, needSign, financePercents, employeePercents, isNumberlessthan1000 })

  const getDot = (value) => {
    if (value >= 0) return <Circle size={16} />
    else return <Diamond size={16} />
  }

  const getItDashDot = (value) => {
    if (isPosDot) return <Circle size={16} />
    else return <Diamond size={16} />
  }

  const getTitle = () => {
    return title ? 
    (sectionsFinance.includes(activeSection) && title.includes('Poultry') && navigationStage == 1 ) ? 
    title.replace('Poultry', 'CHICKEN') : title.toString().toUpperCase() : defaultTitle.toUpperCase()
  }

  const getDotOshaRates = (value) => {
    if (value <= 0) return <Circle size={16} />
    else return <Diamond size={16} />
  }

  const getDotBasedOnValue = (dotValue) => {
    if (dotValue >= fillRateTarget) return <Circle />
    else return <Diamond />
  }

  const {
    activeSection
  } = useSelector(state => ({
    activeSection: state.sections.activeSection
  }))

  const getValueNode = (fontSizeClass, setRef=()=>{}) => <div className='mt-1 leading-none flex items-baseline text-black' ref={el => setRef(el)}>
    <span className={toClassName(['font-bold text-xl', prependClasses])}>
      { activeSection === 'VOLUME' ? prependStr.replace('$', '') : prependStr }
    </span>
    <span className={toClassName(['font-bold', fontSizeClass])}>
      { number }
    </span>
    <span className={toClassName(['font-bold text-xl', includeDot ? 'mr-1' : ''])}>
      { activeSection === 'VOLUME' ? appendStr + ' ' + 'LBS' : appendStr }
    </span>
    {
      includeDot && (activeSection === 'OSHA_RATES' ? getDotOshaRates(value) 
      : includeDot && isItDash ? getItDashDot(value) 
      : sectionsFillRate.includes(activeSection) ? getDotBasedOnValue(dotValue) 
      : getDotBasedOnValue(dotValue || value))
    }
  </div>

  return (
    // BLS
    <div className='flex flex-col items-center justify-start lg:mb-1 relative lg:static' 
    style={{ height: activeSection === 'OSHA_RATES' ? 180 : 175 }}>
      <div>
        <span className='font-medium text-base2 text-black'>
          { getTitle() }
        </span>
      </div>
      { getValueNode('text-xxxxl', setBreakpointEl) }

      { children }

      <MobileFixedHeader
        week={week}
        month={month}
        value={getValueNode('text-xxxl')}
        visible={headerVisible}
      >
        <span className='text-sm text-black font-semibold'>
          { getTitle() }
        </span>
        { mobileHeaderChildren }
      </MobileFixedHeader>
    </div>
  )
}

export default CurrentScreenValues
