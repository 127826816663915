import React from "react";
import { useSelector } from "react-redux";

import Tooltip from "../componentsGeneral/section/Tooltip";
import { monthNames } from "../constants/app";
import formatNumber from "../utils/formatNumber";

const TooltipTm = ({ payload, currentObj, display, dataKey, year2 }) => {
  const {
    activeQYSwitcher,
    year,
    activeSection,
    fiscDateEmployee,
    fiscDateEmployeeAbsenteesim,
    activetab,
  } = useSelector((state) => ({
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year,
    activeSection: state.sections.activeSection,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    activetab: state.sections.activeTab,
  }));

  const getTitle = () => {
    const data = payload[0].payload;
    if (activeQYSwitcher === "Y")
      return (
        <div className="mb-1">
          <span className="font-bold">
            {monthNames[parseInt(data.period) - 1]}
          </span>{" "}
          {activeSection === "IT_Hr_TEAM_MEMBERS" ? (
            <>FY{year - 2000}</>
          ) : (
            <>FY{year2 - 2000}</>
          )}
          {/* FY{year2 - 2000} */}
        </div>
      );
    else
      return (
        <div className="mb-1">
          <span className="font-bold">
            FW
            {parseInt(
              activeSection === "IT_Hr_TEAM_MEMBERS" ? data.period : data.period
            )}
          </span>{" "}
          FY
          {-2000}
        </div>
      );
  };

  const formatStr = ({ prependStr, number, appendStr }) =>
    `${prependStr}${number}${appendStr}`;

  const getValue = (obj, key) => {
    if (obj[key + "Future"]) return obj[key + "Future"];
    if (obj[key] === null || obj[key] === undefined) return 0;

    return obj[key];
  };

  const getItems = () => {
    if (!payload[0].payload) return [];
    const data = payload[0].payload;
    const r = [];
    if (activeSection === "IT_Hr_TEAM_MEMBERS") {
      r.push(
        Object.keys(currentObj)
          .sort((a, b) => currentObj[a].index - currentObj[b].index)
          .map((key) => (
            <div key={key} style={{ color: currentObj[key].color }}>
              {getValue(data, dataKey + key) != 0 && currentObj[key].title}
              {getValue(data, dataKey + key) != 0 && `: `}
              {getValue(data, dataKey + key) != 0 &&
                formatStr(
                  formatNumber({
                    number: getValue(data, dataKey + key),
                    employeePercents: activeSection === "TURNOVER_RATE",
                  })
                )}
            </div>
          ))
      );
    } else if (activeSection === "IT_Hr_TOTAL_ATTRITION") {
      r.unshift(
        <div key={0}>
          Attrition:{" "}
          {formatStr(
            formatNumber({
              number: data["attrition"],
            })
          )}
          <br></br>
          Total Attrition :{" "}
          {formatStr(
            formatNumber({
              number: data["attritionLine"],
            })
          )}
          <br></br>
          Turnover Rate :{" "}
          {formatStr(
            formatNumber({
              number: data["turnoverRateundefined"],
            })
          )}
        </div>
      );
    } else if (activeSection === "IT_Hr_Vacancies") {
      r.unshift(
        activetab === "Active" && (
          <div key={0}>
            Vacancies:
            {formatStr(
              formatNumber({
                number: data["vacancies"],
              })
            )}
            <br></br>
            Open Positions without requisitions greater than 14 days :
            {formatStr(
              formatNumber({
                number: data["openPositionWoReqGt14Days"],
              })
            )}
          </div>
        ),
        activetab === "Frozen" && (
          <div key={0}>
            Frozen Positions:
            {formatStr(
              formatNumber({
                number: data["frozenPositions"],
              })
            )}
          </div>
        ),
        activetab === "All" && (
          <div key={0}>
            Vacancies:
            {formatStr(
              formatNumber({
                number: data["vacancies"],
              })
            )}
            <br></br>
            Open Positions without requisitions greater than 14 days :
            {formatStr(
              formatNumber({
                number: data["openPositionWoReqGt14Days"],
              })
            )}
            <br></br>
            Frozen Positions:
            {formatStr(
              formatNumber({
                number: data["frozenPositions"],
              })
            )}
          </div>
        )
      );
    } else if (activeSection === "IT_Hr_Positions") {
      r.unshift(
        <div key={0}>
          Positions in offer status :
          {formatStr(
            formatNumber({
              number: data[`${dataKey}`],
            })
          )}
        </div>
      );
    } else if (activeSection === "IT_Hr_AvgDaysHire") {
      r.unshift(
        <div key={0}>
          Avg Days to Hire :
          {formatStr(
            formatNumber({
              number: data[`${dataKey}`],
            })
          )}
        </div>
      );
    }
    return r;
  };

  return (
    <>
      {payload && payload.length && payload[0].payload ? (
        <Tooltip className={display ? "" : "opacity-0"}>
          <div className="text-xs text-black">
            {getTitle()}
            {getItems()}
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};

export default TooltipTm;
