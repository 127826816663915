import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipFillRate = ({ payload, display }) => {
  const {
    activeQYSwitcher,
    year
  } = useSelector(state => ({
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year
  }))

  const getTitle = () => {
    const data = payload[0].payload

    if (activeQYSwitcher === 'Y') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period) - 1]}</span> FY{year - 2000}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(data.period)}</span> FY{year - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getItems = () => {
    if (!payload[0].payload) return []
    const data = payload[0].payload

    return <div>Fill Rate: {formatStr( formatNumber({ number: data.fillRate, employeePercents: true }) )}</div>;
  }

  return (
    <>
      {
        payload && payload.length && payload[0].payload ?
        <Tooltip
          className={display ? '' : 'opacity-0'}
        >
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }

    </>

  )
}

export default TooltipFillRate
