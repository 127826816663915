export const SET_IT_FINACE_DATA = "SET_IT_FINACE_DATA";
export const SET_IT_FINANCE_CLOUD_DATE = "SET_IT_FINACE_SALARY";
export const SET_IT_FINANCE_DRILLDOWN_DATA = "SET_IT_FINANCE_DRILLDOWN_DATA";
export const SET_IT_FISC_DATE = "SET_IT_FISC_DATE";
export const SET_IT_HR_DRILLDOWN_DATA = "SET_IT_HR_DRILLDOWN_DATA";

export const setItFinaceLatestMetrics = (itFinaceLatestMetrics) => ({
  type: SET_IT_FINACE_DATA,
  payload: {
    itFinaceLatestMetrics,
  },
});

export const setItFinanceCloudDate = (itFinanceCloudDate) => ({
  type: SET_IT_FINANCE_CLOUD_DATE,
  payload: {
    itFinanceCloudDate,
  },
});

export const setItFiscCloudDate = (itFiscCloudDate) => ({
  type: SET_IT_FISC_DATE,
  payload: {
    itFiscCloudDate,
  },
});

export const setItFinanceDrilldownData = (itFinanceDrilldownData) => ({
  type: SET_IT_FINANCE_DRILLDOWN_DATA,
  payload: {
    itFinanceDrilldownData,
  },
});

export const setItHRDrilldownData = (itHrDrilldownData) => ({
  type: SET_IT_HR_DRILLDOWN_DATA,
  payload: {
    itHrDrilldownData,
  },
});
