import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import toClassName from '../../utils/toClassName'

const Note = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const maxSymbols = 40

  const getText = () => {
    if (text.length < maxSymbols) return text

    return isExpanded ? text : text.slice(0, maxSymbols) + '...'
  }

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <div className={toClassName(["note bottom-2 left-0 lg:left-auto lg:bottom-auto lg:right-0 lg:top-0 w-full text-left text-black absolute px-5 lg:px-0 lg:text-right", isExpanded && text ? 'shadow' : ''])}>
      <span 
        className="text-xs inline-block p-2"
      >
        { !isMobile &&  getText() }

        { !isMobile && text.length > maxSymbols && <span className={toClassName(["cursor-pointer text-blue-400 whitespace-nowrap", isExpanded ? 'block' : ''])} onClick={toggleExpanded}> ({ isExpanded ? 'view less' : 'view more' })</span>}
      </span>
    </div>
  )
}

export default Note