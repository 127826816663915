import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipFinance = ({ payload, currentKey, groupKey, activeQYSwitcher, display, bu, splitWithValue='/', isNumberlessthan1000 }) => {
  const {
    activeSection
  } = useSelector(state => ({
    activeSection: state.sections.activeSection
  }))

  const getTitle = () => {
    const spl = (payload[0] && payload[0].payload[groupKey]) ? payload[0].payload[groupKey].split(splitWithValue) : false
    if (!spl) return null

    if (activeQYSwitcher === 'Y') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(spl[1]) - 1]}</span> FY{spl[0] - 2000}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(spl[1])}</span> FY{spl[0] - 2000}</div>
  }

  const getTitleLastYear = () => {
    const spl = (payload[0] && payload[0].payload[groupKey]) ? payload[0].payload[groupKey].split(splitWithValue) : false
    if (!spl) return null

    if (activeQYSwitcher === 'Y') return <div><span>{ monthNames[parseInt(spl[1]) - 1]}</span> FY{spl[0] - 2000}</div>
    else return <div><span>FW{parseInt(spl[1])}</span> FY{spl[0] - 2000 - 1}</div>
  }

  const getTitleBuYear = () => {
    console.log({payload})
    if (!(payload[0] && payload[0].payload)) return null

    const item = payload[0].payload
    console.log(item)

    return <div><span>{ monthNames[parseInt(item.groupByPeriod) - 1]}</span> FY{item.fiscYear - 2000 }</div>
  }

  const getTitleBuLastYear = () => {
    console.log({payload})
    if (!(payload[0] && payload[0].payload)) return null

    const item = payload[0].payload
    console.log(item)

    return <div><span>{ monthNames[parseInt(item.groupByPeriod) - 1]}</span> FY{item.fiscYear - 2000 - 1}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getItems = () => {
    // if (!payload[0].payload[currentKey]) return []
    if (!(currentKey in payload[0].payload)) return []
     
    const actual = payload[0].payload[currentKey];
    const plan = payload[0].payload[currentKey + 'PlanOriginal'];
    const proj = payload[0].payload[currentKey + 'ProjOriginal'];
    const lastYear = payload[0].payload[currentKey + 'LastYearOriginal'];


    if (activeSection === 'AOI') {
      return [
        <div className='whitespace-nowrap' key={0}>{ 'AOI%: ' + '' + formatStr(formatNumber({ number: payload[0].payload[currentKey], financePercents: true }))  }</div>,
        <div className='whitespace-nowrap' key={1}>{ 'AOP Target: ' + '' + formatStr(formatNumber({ number: payload[0].payload[currentKey] - (payload[0].payload["ebit" + 'PlanOriginal']/payload[0].payload["netSales" + 'PlanOriginal']), financePercents: true }))  }</div>
      ]
    }
    if (activeSection === 'VOLUME') {
      return [
        <div className='flex' key={0}>{ 'Volume: ' + '' + formatStr(formatNumber({ number: actual, needScale: true, remove$:true, isNumberlessthan1000:true })) + ' ' + 'LBS' }</div>,
        payload[0].payload[currentKey+ 'LastYearOriginal'] === undefined ? '' 
        : <div className='whitespace-nowrap flex flex-row' key={1}>
          { 'Growth: ' + '' + formatStr(formatNumber({ number:  (actual - lastYear)/lastYear, financePercents : true })) + ' vs'}
          {<span className='opacity-0'>${}</span>  }
          { (bu && activeQYSwitcher === 'Y') ? getTitleBuLastYear() : getTitleLastYear()}
          </div>,
        payload[0].payload[currentKey+ 'LastYearOriginal'] === undefined ? '' : <div className='whitespace-nowrap' key={2}>{ formatStr(formatNumber({ number: (actual - lastYear), needScale: true, remove$:true, isNumberlessthan1000:true  })) + ' ' + 'LBS' } vs Last Year</div>,
        <div className='whitespace-nowrap' key={3}>{ formatStr(formatNumber({ number:  (actual - plan), needScale: true, remove$:true, isNumberlessthan1000:true  })) + ' ' + 'LBS'} vs AOP</div>,
        <div className='whitespace-nowrap' key={4}>{ formatStr(formatNumber({ number:  (actual - proj), needScale: true, remove$:true, isNumberlessthan1000:true })) + ' ' + 'LBS'} vs Proj.</div>
      ]
    }
    else {
      return [
        <div className='whitespace-nowrap' key={0}>{ 'Actual: ' + '' + formatStr(formatNumber({ number:  actual, needScale: true, isNumberlessthan1000:true }))  }</div>,
        <div className='whitespace-nowrap' key={1}>{ formatStr(formatNumber({ number: (actual - plan), needScale: true, isNumberlessthan1000:true }))  } vs AOP</div>,
        <div className='whitespace-nowrap' key={2}>{ formatStr(formatNumber({ number: (actual - proj), needScale: true, isNumberlessthan1000:true }))  } vs Proj.</div>,
        payload[0].payload[currentKey+ 'LastYearOriginal'] === undefined ? '' : <div className='whitespace-nowrap' key={3}>{ formatStr(formatNumber({ number: (actual - lastYear), needScale: true, isNumberlessthan1000:true })) } vs last year</div>
      ]
    }
  }

  console.log({payload, currentKey}, ' !!!!!!')
  return (
    <>
      {
        // payload && payload.length && payload[0].payload[currentKey] ?
        payload && payload.length ?
        <Tooltip
          className={display ? '' : 'opacity-0'}
        >
          <div className='text-xs text-black'>
            { (bu && activeQYSwitcher === 'Y') ? getTitleBuYear() : getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }
      
    </>
    
  )
}

export default TooltipFinance