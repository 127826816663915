import React from "react";
import Circle from "../images/Circle";
import Diamond from "../images/Diamond";

function getDot(isPosDot) {
  if (isPosDot === undefined) {
    return;
  } else if (isPosDot) {
    return <Circle size={12} />;
  } else {
    return <Diamond size={12} />;
  }
}

const BusinessUnitInfo = (props) => {
  return (
    <div>
      {props.section === "IT_Hr_TOTAL_ATTRITION" && props.tabs == 1 ? (
        <>
          <div className="units-container justify-items-end">
            <div className="h-px bg-gray-400 my-2"></div>
            <div className="flex">
              <div className="w-3/5 pr-5 text-sm font-bold">
                {props.firstNum}
              </div>
              <div className="w-1/4 text-sm font-bold">{props.secondNum}</div>
              <div className="w-1/4">
                <div className="flex flex-row-reverse  text-sm">
                  <span className="mx-1 text-sm">{getDot(props.isPosDot)}</span>

                  {props.thirdNum === "Infinity%" ? "N/A" : props.thirdNum}
                  {/* <FeatherIcon className='-rotate-90 transform text-blue-400' icon={'chevron-down'} /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="units-container justify-items-end">
            <div className="h-px bg-gray-400 my-2"></div>
            <div className="flex">
              <div className="w-3/5 pr-5 text-sm">{props.firstNum}</div>
              <div className="w-1/4 text-sm">${props.secondNum}</div>
              <div className="w-1/4">
                <div className="flex flex-row-reverse  text-sm">
                  <span className="mx-1 text-sm">
                    {" "}
                    {getDot(props.isPosDot)}
                  </span>

                  {props.thirdNum === "Infinity%" ? "N/A" : props.thirdNum}
                  {/* <FeatherIcon className='-rotate-90 transform text-blue-400' icon={'chevron-down'} /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessUnitInfo;
