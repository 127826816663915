import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar, Cell, Line, Tooltip, YAxis } from 'recharts'
import { setQuarter, setSingle, setYear } from '../actions/sectionsActions'
import BigGraph from '../componentsGeneral/section/BigGraph'
import GraphTicks from '../componentsGeneral/section/GraphTicks'
import { ageColors, ethnicityColors, exitReasonColors, genderColors, oshaColors, tenureColors } from '../constants/colors'
import formatNumber from '../utils/formatNumber'
import FeatherIcon from 'feather-icons-react';
import ScrollButton from '../componentsGeneral/section/ScrollButton'
import GraphInfo from '../componentsGeneral/section/GraphInfo'
import { getProperty } from '../utils/dataMappingTurnover'
import CustomDot from '../componentsGeneral/section/CustomDot'
import GraphXTitles from '../componentsGeneral/section/GraphXTitles'
import BarShapeTm from '../componentsTeamMembers/BarShapeTm'
import TooltipOsha from './TooltipOsha'
import useTooltipControl from '../hooks/useTooltipControl'
import { isMobile } from 'react-device-detect'


const BigGraphOsha = ({ dataKey, currentDate }) => {
  const dispatch = useDispatch()
  const {
    dataGraphAdditional,
    activeTab,
    activeSection,
    isSingle,
    activeQYSwitcher,
    year,
    quarter,
    bars,
    barsPY
  } = useSelector(state => ({
    dataGraphAdditional: state.values.dataGraphAdditional,
    activeSection: state.sections.activeSection,
    activeTab: state.sections.activeTab,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year,
    quarter: state.sections.quarter,
    bars: state.values.bars,
    barsPY: state.values.barsPY
  }))

  const graphHeight = 310
  const [graphRef, setGraphRef] = useState(null)
  const [data, setData] = useState([])
  const [buttonsState, setButtonsState] = useState({
    showLeft: true,
    showRight: false
  })

  const {
    onMouseOverBar,
    onMouseLeaveBar,
    isTooltipActive
  } = useTooltipControl()

  const fillBars = (data, startWeekOfPeriod, barsPY, isFutureDateCallback, totalIteratios) => {
    for(let i = 0; i < totalIteratios; i++) {
      const currentPeriod = startWeekOfPeriod + i;
      let currentPeriodObj = data.find(({ period }) => parseInt(period) === currentPeriod );
      const prevPeriodObj = data[i - 1];
      const isFutureDate = isFutureDateCallback(currentPeriod);
      if(!currentPeriodObj) {
        const countRecordedCummulative = isFutureDate ? null : prevPeriodObj ? prevPeriodObj.countRecordedCummulative : 0;
        currentPeriodObj = {countRecorded: 0, countRecordedCummulative, period: currentPeriod };
        data.splice(i, 0, currentPeriodObj)
      }
      else {
        data[i] = { ...currentPeriodObj };
        currentPeriodObj = data[i];
        const countRecordedCummulative = isFutureDate ? null : prevPeriodObj ? prevPeriodObj.countRecordedCummulative + currentPeriodObj.countRecorded : currentPeriodObj.countRecorded;
        currentPeriodObj.countRecordedCummulative = countRecordedCummulative;
      }
      let lastYP = barsPY.find( ({ period }) => period === currentPeriod );
      if(!lastYP) lastYP = { period: lastYP, countRecorded: 0 };
      currentPeriodObj.countRecordedCummulativePY = prevPeriodObj ? prevPeriodObj.countRecordedCummulativePY + lastYP.countRecorded : lastYP.countRecorded;
      currentPeriodObj.countRecordedCummulativePY = isFutureDate ? null : currentPeriodObj.countRecordedCummulativePY
    }
  }  

  const getDataRecords = () => {
    let data = bars.slice(0);
    if(activeQYSwitcher === 'Y') {
      const startWeekOfPeriod = 1;
      const c = (currentPeriod) => currentDate.year == year && currentPeriod > currentDate.month;
      fillBars(data, startWeekOfPeriod, barsPY, c, 12);
    }
    else {
      const firstPeriod = data[0] ? data[0].period : (quarter - 1) * 13 + 1;
      const startWeekOfPeriod = Math.floor((firstPeriod === 53 ? 39 : firstPeriod - 1)/13)*13 + 1;
      const lastPeriod = data[data.length - 1] ? data[data.length - 1].period : 0;
      const weeksCount = lastPeriod === 53 ? 14 : 13;
      const lastWeekPeriod = data[data.length - 1] ? data[data.length - 1].period : 0;
      const c = (currentPeriod) => currentDate.year == year && currentDate.quarter == quarter && currentPeriod > lastWeekPeriod;
      fillBars(data, startWeekOfPeriod, barsPY, c, weeksCount);
    }

    return data
  }

  const getDataRates = () => {
  let data = bars.slice(0);
    const startWeekOfPeriod = 1;
    for(let i = 0; i < 12; i++) {
      if(!data.some(({ period }) => parseInt(period) === startWeekOfPeriod + i )) {
        data.splice(i, 0, { period: startWeekOfPeriod + i, [dataKey]: null });
      }

      if (year == currentDate.year && i + 1 > currentDate.month) {
        data[i][dataKey] = null
        data[i][dataKey + 'PY'] = null
      }
    }

    return data
  }

  const clickChart = (e) => {
    if(isSingle) dispatch( setSingle(false) );
  }

  const handleClickBar = (data) => {
    if (data.period === isSingle) dispatch(setSingle(false))

    if ( activeSection === 'OSHA_RATES' && currentDate.year == year && currentDate.month < data.period) dispatch( setSingle(false) )
    else dispatch( setSingle(parseInt(data.period)) )
  }


  const formatTickValue = (value) => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, needScale: false, employeePercents: activeSection === 'OSHA_RATES', remove$: true })

    return `${number === '0' || number === 0 ? '' : prependStr}${number === '0' || number === 0 ? '' : number}${number === '0' || number === 0  ? '' : appendStr}`
  }


  const renderTicks = (side) => {
    if (!graphRef || !graphRef.state.yAxisMap[side] || ((activeSection === 'OSHA_RATES' || isSingle) &&  graphRef.state.yAxisMap['right'])) return null
    
    return graphRef.state.yAxisMap[side].niceTicks.map((tickValue, i) => <span className='text-sm text-black leading-0' key={i}>
      { isFinite(tickValue) ? formatTickValue(tickValue) : '' }
    </span>)
  }
  
  const getBarRecords = (bars) => {
    for(let key in oshaColors) {
      bars.push(
        <Bar
            dataKey={`${key}`}
            stackId="a"
            onClick={handleClickBar}
            key={key}
            yAxisId='left'
            barSize={20}
            shape={<BarShapeTm />}  
            onMouseOver={onMouseOverBar}
            onMouseLeave={onMouseLeaveBar}
        >
          {
            data.map((entry, index) => <Cell
                  cursor="pointer"
                  fill={oshaColors[key].color}
                  opacity={((isSingle && isSingle === parseInt(entry.period)) || !isSingle) ? 1 : 0.4}
                  period={entry.period}
                  key={`cell-${index}`}
                  isHalfClosed={activeQYSwitcher == 'Y' && year == currentDate.year && entry.period >= currentDate.month || (activeQYSwitcher == 'Q' && year == currentDate.year && entry.period == currentDate.week)}
                />
            )
          }
        </Bar>
      )
    }

    bars.push(
      !isSingle && <Line
        type="monotone"
        dataKey={`countRecordedCummulative`}
        key={5}
        dot={<CustomDot isSingle={isSingle} fill={'#002544'} />}
        cursor={false}
        stroke={activeTab === 'SIF' ? getProperty('sifCnt', 'color') : '#002544'}
        strokeWidth={4}
        opacity={!isSingle ? 1 : 0.4}
        yAxisId='right'
      />,
      !isSingle && <Line
        type="monotone"
        dataKey={`countRecordedCummulativePY`}
        key={6}
        dot={<CustomDot isSingle={isSingle} fill={'#002544'} />}
        cursor={false}
        stroke={activeTab === 'SIF' ? getProperty('sifCnt', 'color') : '#002544'}
        strokeWidth={4}
        opacity={!isSingle ? 1 : 0.4}
        strokeDasharray={[2, 2]}
        yAxisId='right'
      />,
      <YAxis key={7} yAxisId='right' hide />,
      <YAxis key={8} yAxisId='left' hide />
    )
  }

  const getBarRates = (bars) => {
    bars.push(
      <Line
        type="monotone"
        dataKey={`rate`}
        key={1}
        dot={<CustomDot isSingle={isSingle} fill={'#002544'} />}
        cursor={false}
        stroke={'#002544'}
        strokeWidth={5}
        opacity={!isSingle ? 1 : 0.4}
        yAxisId='left'
      />,
      <Line
        type="monotone"
        dataKey={`ratePY`}
        key={2}
        dot={<CustomDot isSingle={isSingle} fill={'#002544'} />}
        cursor={false}
        stroke={'#002544'}
        strokeWidth={4}
        opacity={!isSingle ? 1 : 0.4}
        strokeDasharray={[2, 2]}
        yAxisId='left'
      />,
      // BLS
      // <Line
      //   type="monotone"
      //   dataKey={`bls`}
      //   key={3}
      //   dot={<CustomDot isSingle={isSingle} fill={'##4C97F4'} />}
      //   cursor={false}
      //   stroke={'#4C97F4'}
      //   strokeWidth={5}
      //   opacity={!isSingle ? 1 : 0.4}
      //   //strokeDasharray={[2, 2]}
      //   yAxisId='left'
      // />,
      <Bar
        onMouseOver={onMouseOverBar}
        onMouseLeave={onMouseLeaveBar}
        minPointSize={graphHeight}
        maxBarSize={graphHeight}
        stackId="a"
        onClick={handleClickBar}
        key={4}
        fill='transparent'
        yAxisId='left'
        dot={false}
      ></Bar>
    )
  }

  const getGraphInnerContent = (content) => {
    if (activeSection === 'OSHA_RATES') getBarRates(content)
    else getBarRecords(content)
  }

  const getGraphContent = () => {
    const content = []

    getGraphInnerContent(content)

    return content
  }

  const manageButtonsState = () => {
    if (currentDate.year == year) {
      if (activeQYSwitcher == 'Y') {
        setButtonsState({
          showLeft: true,
          showRight: false
        })
      }
      else {
        if (currentDate.quarter == quarter) {
          setButtonsState({
            showLeft: true,
            showRight: false
          })
        }
        else {
          setButtonsState({
            showLeft: true,
            showRight: true
          })
        }
      }
    }
    else if (currentDate.year - 2 == year) {
      if (activeQYSwitcher == 'Y') {
        setButtonsState({
          showLeft: false,
          showRight: true
        })
      }
      else {
        if (1 == quarter) {
          setButtonsState({
            showLeft: false,
            showRight: true
          })
        }
        else {
          setButtonsState({
            showLeft: true,
            showRight: true
          })
        }
      }
    }
    else setButtonsState({
      showLeft: true,
      showRight: true
    })
  }

  const clickLeftScroll = () => {
    if (activeQYSwitcher === 'Y') {
      dispatch( setYear(year - 1) )
    }
    else {
      if (quarter == 1) {
        dispatch( setQuarter(4) )
        dispatch( setYear(year - 1) )
      }
      else {
        dispatch( setQuarter(quarter - 1) )
      }
    }

    dispatch( setSingle(false) )
  }

  const clickRightScroll = () => {
    if (activeQYSwitcher === 'Y') {
      dispatch( setYear(year + 1) )
    }
    else {
      if (quarter == 4) {
        dispatch( setQuarter(1) )
        dispatch( setYear(year + 1) )
      }
      else {
        dispatch( setQuarter(quarter + 1) )
      }
    }

    dispatch( setSingle(false) )
  }

  const getGraphInfo = () => {
    const infoRates = [
      {
        title: 'Current',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #002544 solid' }}></span>
      },
      {
        title: 'Last Year',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #002544 dotted' }}></span>
      },
      // BLS
      // {
      //   title: `BLS FY20`,
      //   line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #4C97F4 solid' }}></span>
      // }
    ]

    const infoRecords = [
      {
        title: 'Total Current',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #002544 solid' }}></span>
      },
      {
        title: 'Total Last Year',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #002544 dotted' }}></span>
      }
    ]

    let workingArray = activeSection === 'OSHA_RATES' ? infoRates : infoRecords

    // if (activeSection === 'OSHA_RECORDABLES' && activeTab !== 'SIF' && Array.isArray(dataGraphAdditional)) workingArray = [...workingArray, dataGraphAdditional, ...dataGraphAdditional.map(i => ({
    //   title: i.title,
    //   line: <span className='w-3 h-3 mr-2' style={{ backgroundColor: i.color }}></span>
    // }))]

    return workingArray.map((v, i) => <div key={i} className='flex items-center'>{ v.line }<span className='text-xs text-black'>{ v.title }</span></div>)
  }

  useEffect(() => {
    manageButtonsState()
  }, [
    year, 
    quarter, 
    activeQYSwitcher, 
    currentDate
  ])

  useEffect(() => {
    setData( activeSection === 'OSHA_RATES' ? getDataRates() : getDataRecords() )
  }, [bars])

  return (
    <div className='flex relative lg:overflow-visible overflow-x-scroll pt-3 pb-12 no-scrollbar'>
      <GraphInfo>
        { getGraphInfo() }
      </GraphInfo>
      <div className='h-full w-16 flex-shrink-0'>
        <GraphTicks>
          { renderTicks('left') }
        </GraphTicks>
      </div>
      <div className='flex-1 relative pb-4 main-graph-container'>
        {buttonsState.showLeft && <ScrollButton
          className='left-0'
          onClick={clickLeftScroll}
        >
          <FeatherIcon className='text-gray-600' icon={'chevron-left'} />
        </ScrollButton>}
        <BigGraph
          data={data}
          height={graphHeight}
          setRef={setGraphRef}
          vertical={activeSection === 'OSHA_RATES'}
          onClick={clickChart}
          titles={<GraphXTitles 
            data={data}
            getMonth={el => el.period}
          />}
        >
          {!isMobile && <Tooltip
            isAnimationActive={false}
            content={<TooltipOsha 
              display={isTooltipActive}
            />}
          >
          </Tooltip>
          } 
          { getGraphContent() }
        </BigGraph>
        {buttonsState.showRight && <ScrollButton
          className='right-0'
          onClick={clickRightScroll}
        >
          <FeatherIcon className='text-gray-600' icon={'chevron-right'} />
        </ScrollButton>}
      </div>
      {<div className='h-full w-16 flex-shrink-0'>
        <GraphTicks>
          { renderTicks('right') }
        </GraphTicks>
      </div>}
    </div>
  )
}

export default BigGraphOsha
