import React, { useEffect } from 'react'
import authClient from '../services/authService'
import { useHistory } from 'react-router-dom'

const Login = () => {
  const history = useHistory()

  useEffect(async () => {
    console.log(await authClient.tokenManager.get('accessToken'), ' @$@$@$$@')
    console.log(authClient.getAccessToken())
    if (!authClient.getAccessToken()) {
      authClient.signInWithRedirect(window.location.origin);
    } else {
      history.replace('/')
    }
  })
  return (
    <div
      className='uk-flex uk-height-medium uk-margin uk-text-center'
      style={{ minHeight: '100vh'}}
    >
      <div className='uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s'>
        <p className='uk-text-center'>Loggin in...</p>
      </div>
    </div>
  )
}

export default Login
