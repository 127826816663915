import React, { useEffect, useState } from 'react';
import { setUserInfo } from '../../actions/userActions';
import { getLatestDataHeader, getLatestEmployeeMetrics, getOshaLatesMetrics, getLatestEmployeeAbsenteesimMetrics, getOshaRatesLatesMetrics, getLatestPlantOpsMetrics } from '../../services/dataService';
import { receiveDataHeader, setCurrentDate, resetApp } from '../../actions/valuesActions'
import { setQuarter, setYear, setScrollPosition, setActiveQYSwitcher, setActiveSection } from '../../actions/sectionsActions';
import { setLatestMetrcis, setLatestMetrcisAbsenteesim, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setOshaMetrics, setOshaRatesMetrics } from '../../actions/dataActionsTeamMembers';
import { setPlantOpsMetrics, setPlantOpsFiscDate } from '../../actions/dataActionsPlantOps';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setShowModal, setShowMenu } from '../../actions/app';
import roundFunction from '../../utils/roundFunction';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import { financeErrorData } from '../../constants/app'

import AppLayout from '../../componentsGeneral/AppLayout'
import ItemsContainer from '../../homeScreenComponents/general/ItemsContainer'
import ItemsContainerCollapsible from '../../homeScreenComponents/general/ItemsContainerCollapsible'
import ItemDouble from '../../homeScreenComponents/general/ItemDouble'
import Item from '../../homeScreenComponents/general/Item'
import Collapsible from '../../homeScreenComponents/general/Collapsible'
import Section from '../../homeScreenComponents/general/Section'
import SectionRow from '../../homeScreenComponents/general/SectionRow';

import formatFiscDate from '../../utils/formatFiscDate'
import formatFiscDateLastYear from '../../utils/formatFiscDateLastYear'
import formatNumber from '../../utils/formatNumber'

import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import toClassName from '../../utils/toClassName';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LoadingBlocker from '../../components/LoadingBlocker/LoadingBlocker';


const HomeOperationsMcDonalds = (props) => {
    const { height, width } = useWindowDimensions()

    const { authState, oktaAuth } = useOktaAuth()
    const { accessToken: { accessToken } } = authState
    const [isLoading, setIsLoading] = useState(false)

    const getDataFinance = async (initial) => {
        const { receiveDataHeader, setQuarter, setCurrentDate, setYear, setScrollPosition } = props;

        let { data, type } = await getLatestDataHeader('', accessToken);

        if (type === 'success') {
            const date = data.date[0];
            data = data.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
        else {
            // setTimeout(async () => {
            //     let { data, type } = await getLatestDataHeader('', accessToken);
            //     if (type === 'success') {
            //         const date = data.date[0];
            //         data = data.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     } else {
            //         const date = financeErrorData.date[0];
            //         data = financeErrorData.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     }
            // }, 1000)
            const date = financeErrorData.date[0];
            data = financeErrorData.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
    }


    const employeeSuccessHandler = ({ data }) => {
        const { setLatestMetrcis } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                exitEmployees: employeeInfo['McDonalds'].exitEmployees,
                avgActiveEmployees: roundFunction(employeeInfo['McDonalds'].avgActiveEmployees, 'B'),
                turnoverRate: employeeInfo['McDonalds'].turnoverRate,
            }
            setLatestMetrcis(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployee = async () => {
        const { setLatestMetrcis } = props;
        const { data, type } = await getLatestEmployeeMetrics({ segment: '?segment=McDonalds', accessToken });
        if (type === 'success') {
            employeeSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeMetrics({ segment: '?segment=McDonalds', accessToken });
            //     if (type === 'success') {
            //         employeeSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcis('Error');
            //     }
            // }, 1000)
            setLatestMetrcis('Error');
        }
    }

    const employeeAbsenteesimSuccessHandler = ({ data }) => {
        const { setLatestMetrcisAbsenteesim } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                absenteeism: employeeInfo['McDonalds'].absenteeism,
            }
            setLatestMetrcisAbsenteesim(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployeeAbsenteesim = async () => {
        const { setLatestMetrcisAbsenteesim } = props;
        const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '?segment=McDonalds', accessToken });
        if (type === 'success') {
            employeeAbsenteesimSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '?segment=McDonalds', accessToken });
            //     if (type === 'success') {
            //         employeeAbsenteesimSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcisAbsenteesim('Error');
            //     }
            // }, 1000)
            setLatestMetrcisAbsenteesim('Error');
        }
    }

    const oshaSuccessHandler = ({ data }) => {
        const { setOshaMetrics } = props;
        try {
            let metrics = {};

            const { poultryMcdCounts } = data;
            metrics = {
                "countRecorded": poultryMcdCounts[0].countRecorded ? poultryMcdCounts[0].countRecorded : 0,
            }
            setOshaMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Record. error');
        }
    }

    const getDataOsha = async () => {
        const { setOshaMetrics } = props;
        const { data, type } = await getOshaLatesMetrics({ segment: '?segment=McDonalds', accessToken });
        if (type === 'success') {
            oshaSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaLatesMetrics({ segment: '?segment=McDonalds', accessToken });
            //     if (type === 'success') {
            //         oshaSuccessHandler({ data });
            //     } else {
            //         setOshaMetrics('Error');
            //     }
            // }, 1000)
            setOshaMetrics('Error');
        }
    }

    const oshaRatesSuccessHandler = ({ data }) => {
        const { setOshaRatesMetrics } = props;
        try {
            let metrics = {};
            const { poultryMcdCounts } = data;
            metrics = {
                "rate": poultryMcdCounts[0].businessFunction === "McDonalds" ? (poultryMcdCounts[0].rate) : 0,
                "ratePY": poultryMcdCounts[0].businessFunction === "McDonalds" ? (poultryMcdCounts[0].ratePY) : 0,
            }

            setOshaRatesMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Rates error');
        }
    }

    const getDataOshaRates = async () => {
        const { setOshaRatesMetrics } = props;
        const { data, type } = await getOshaRatesLatesMetrics({ segment: '?segment=McDonalds', accessToken });
        if (type === 'success') {
            oshaRatesSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaRatesLatesMetrics({ segment: '?segment=McDonalds', accessToken });
            //     if (type === 'success') {
            //         oshaRatesSuccessHandler({ data });
            //     } else {
            //         setOshaRatesMetrics('Error');
            //     }
            // }, 1000)
            setOshaRatesMetrics('Error');
        }
    }

    const plantOpsSuccessHandler = ({ data }) => {
        const { setPlantOpsMetrics } = props;
        try {
            let metrics = {};
            const { McDonalds } = data;
            metrics = {
                "McdCUPro": McDonalds[0].CapacityType === "CapacityUtilisation Processing" ? (McDonalds[0].CapacityRecorded) : (McDonalds[1].CapacityRecorded),
                "McdEfficiencyPro": McDonalds[1].CapacityType === "Efficiency Processing" ? (McDonalds[1].CapacityRecorded) : (McDonalds[0].CapacityRecorded),
            }
            setPlantOpsMetrics(metrics);
        } catch (e) {
            console.error(e, 'Plant Ops error');
        }
    }

    const getDataPlantOps = async () => {
        const { setPlantOpsMetrics } = props;
        const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
        if (type === 'success') {
            plantOpsSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
            //     if (type === 'success') {
            //         plantOpsSuccessHandler({ data });
            //     } else {
            //         setPlantOpsMetrics('Error');
            //     }
            // }, 1000)
            setPlantOpsMetrics('Error');
        }
    }

    const requestData = async (initial) => {
        setIsLoading(true)
        Promise.all([
            getDataFinance(initial),
            getDataEmployee(),
            getDataEmployeeAbsenteesim(),
            getDataOsha(),
            getDataOshaRates(),
            getDataPlantOps()
        ]).then((values) => {
            setIsLoading(false)
        })
            .catch(e => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const { resetApp } = props;
        resetApp();
        requestData();
    }, []);

    useEffect(() => {
        const { setUserInfo } = props;
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null)
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info)
            })
        }
    },
        [
            authState,
            oktaAuth
        ]);

    const getDot = (value) => {
        if (value > 0) return <Circle />
        else if (value == 0) return null
        else return <Diamond />
    }

    const getDotForOshaRates = (value) => {
        if (value < 0) return <Circle />
        else return <Diamond />
    }

    const generateDot = (title, value, classes = []) => {
        return <span className={toClassName(['inline-flex items-center leading-0', ...classes])}>
            <span className='text-xs font-medium mr-1 leading-tight'>{title}</span>{getDot(value)}
        </span>
    }

    const {
        metrics,
        absenteesimMetrics,
        plantOpsMetrics,
        fiscDateEmployee,
        fiscDateEmployeeAbsenteesim,
        oshaRatesMetrics,
        oshaMetrics,
        oshaRatesFiscDate,
        oshaFiscDate,
        plantOpsFiscDate,
        quarter,
        year,
        header,
        userPrivilages
    } = props

    const getFinanceHeaderDataMcDonalds = () => {
        const { units } = props;
        if (!units || !Array.isArray(units)) return [];
        const header = units.find(({ entity }) => entity === 'MCDONALDS');
        return header || [];
    }

    const history = useHistory()
    const dispatch = useDispatch()

    const switchView = (view) => {
        const links = {
            'CHICKEN': '/poultry',
            'PREPARED FOODS': '/prepared',
            'FRESH MEATS': '/freshMeats',
            'MCDONALDS': '/McDonalds',
            'HROSHA': '/hrosha',
            'OPERATIONS': '/operations',
            'OPERATIONS_POULTRY': '/operations/poultry',
            'OPERATIONS_PREPARED': '/operations/prepared',
            'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
            'OPERATIONS_MCDONALDS': '/operations/McDonalds',
            'IT_USER': '/information-technology',
            'false': '/'
        }

        dispatch({
            type: 'SET_USER_PRIVILAGES',
            payload: {
                userPrivilages: {
                    ...userPrivilages,
                    view
                }
            }
        })

        history.push(links[view.toString()])
    }

    const navigateAction = ({ href, section, tab }) => {
        dispatch(setActiveSection(section, tab))

        history.push(href)
    }

    const formatNumberString = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    const formatNumberStringTeamMembers = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    return (
        <ScreenWrapper home landingPage>
            <LoadingBlocker loading={isLoading} />
            <AppLayout keepPaddings>
                <div className='flex gap-x-6 flex-wrap'>

                    <ItemsContainer title="MCDONALD'S FINANCE">
                        <ItemDouble>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/McDonalds/finance?segment=McDonalds', section: 'VOLUME', tab: 'McDonalds' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Volume'
                                value={getFinanceHeaderDataMcDonalds().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataMcDonalds().volume) ? 0 : formatNumberString({ number: getFinanceHeaderDataMcDonalds().volume, needScale: true, remove$: true, needLBS: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', getFinanceHeaderDataMcDonalds().volume - getFinanceHeaderDataMcDonalds().volumeProj),
                                        generateDot('AOP ', getFinanceHeaderDataMcDonalds().volume - getFinanceHeaderDataMcDonalds().volumePlan, ['ml-2'])
                                    ]
                                }
                            />
                            <div className='w-px bg-gray-400 h-20'></div>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/McDonalds/finance?segment=McDonalds', section: 'VOLUME', tab: 'McDonalds' })}
                                date={<span className='opacity-0'>0</span>}
                                title='Growth'
                                value={getFinanceHeaderDataMcDonalds().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataMcDonalds().volume) ? 0 : formatNumberString({ number: (getFinanceHeaderDataMcDonalds().volume - getFinanceHeaderDataMcDonalds().volumePrevious) / getFinanceHeaderDataMcDonalds().volumePrevious, financePercents: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (getFinanceHeaderDataMcDonalds().volume - getFinanceHeaderDataMcDonalds().volumePrevious) / getFinanceHeaderDataMcDonalds().volumePrevious)
                                    ]
                                }
                            />
                        </ItemDouble>

                    </ItemsContainer>

                    <ItemsContainer title="MCDONALD'S TEAM MEMBERS">
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/team-members?segment=McDonalds', section: 'TEAM_MEMBERS' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ month: fiscDateEmployee.month, year: fiscDateEmployee.year })}
                            title='Team Members'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.avgActiveEmployees) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.avgActiveEmployees) ? 0 : metrics.avgActiveEmployees })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/team-members?segment=McDonalds', section: 'TURNOVER_RATE' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ year: fiscDateEmployee.year })}
                            title='Turnover Rate'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.turnoverRate * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.turnoverRate * 100) ? 0 : metrics.turnoverRate * 100, employeePercents: true })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/team-members?segment=McDonalds', section: 'ABSENTEEISM' })}
                            date={!fiscDateEmployeeAbsenteesim.year ? 'Error' : formatFiscDate({ year: fiscDateEmployeeAbsenteesim.year })}
                            title='Absenteeism'
                            value={absenteesimMetrics === 'Error' ? 'Error' : isNaN(absenteesimMetrics.absenteeism * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(absenteesimMetrics.absenteeism * 100) ? 0 : absenteesimMetrics.absenteeism, employeePercents: true })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title="MCDONALD'S OSHA">
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/osha?segment=McDonalds', section: 'OSHA_RATES' })}
                            date={!oshaRatesFiscDate.year ? 'Error' : formatFiscDate({ year: oshaRatesFiscDate.year }) + ` YTD`}
                            title='Rates'
                            value={<>{oshaRatesMetrics === 'Error' ? 'Error' : isNaN(oshaRatesMetrics.rate) ? 0 : formatNumberString({ number: oshaRatesMetrics.rate })} {oshaRatesMetrics === 'Error' ? '' : getDotForOshaRates(oshaRatesMetrics.rate - oshaRatesMetrics.ratePY)}</>}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/osha?segment=McDonalds', section: 'OSHA_RECORDABLES' })}
                            date={!oshaFiscDate.year ? 'Error' : formatFiscDate({ quarter: oshaFiscDate.quarter, year: oshaFiscDate.year })}
                            title='Recordables'
                            value={oshaMetrics === 'Error' ? 'Error' : isNaN(oshaMetrics.countRecorded) ? 0 : formatNumberString({ number: oshaMetrics.countRecorded })}
                        />
                    </ItemsContainer>


                </div>
                {/* <Section title='OPERATIONS'>
                    <SectionRow>

                    <Collapsible
                            title='MCDONALDS'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/McDonalds/plant-ops', section: 'McDonalds', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.McdCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.McdCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/McDonalds/plant-ops', section: 'McDonalds', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.McdEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.McdEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />
                        </Collapsible>
                    </SectionRow>


                </Section> */}
            </AppLayout>
        </ScreenWrapper>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.userState.userInfo,
        activeQYSwitcher: state.sections.activeQYSwitcher,
        activeSection: state.sections.activeSection,
        currentDate: state.values.currentDate,
        isSingle: state.sections.isSingle,
        header: state.values.header,
        year: state.sections.year,
        quarter: state.sections.quarter,
        activeTab: state.sections.activeTab,
        activeBu: state.sections.activeBu,
        currentQuarter: state.sections.currentQuarter,
        isHeaderLoading: state.values.isHeaderLoading,
        showModal: state.app.showModal,

        userPrivilages: state.userState.userPrivilages,
        metrics: state.values.metrics,
        absenteesimMetrics: state.values.absenteesimMetrics,
        fiscDateEmployee: state.sections.fiscDateEmployee,
        fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
        oshaFiscDate: state.sections.oshaFiscDate,
        oshaMetrics: state.values.oshaMetrics,
        oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
        oshaRatesMetrics: state.values.oshaRatesMetrics,
        plantOpsFiscDate: state.sections.plantOpsFiscDate,
        plantOpsMetrics: state.values.plantOpsMetrics,
        units: state.values.units
    }
}
export default connect(
    mapStateToProps,
    {
        setLatestMetrcis, setLatestMetrcisAbsenteesim, resetApp, setFiscDateEmployeeAbsenteesim,
        setFiscDateEmployee, setOshaMetrics, setOshaRatesMetrics,
        setUserInfo, setShowModal, setShowMenu, setPlantOpsMetrics,
        receiveDataHeader, setCurrentDate, setQuarter, setYear, setScrollPosition, setActiveQYSwitcher
    }
)(HomeOperationsMcDonalds)
