import { combineReducers } from 'redux';
import { reducer as values } from './valuesReducer';
import { reducer as observing } from './observingReducer';
import { reducer as sections } from './sectionReducer';
import { reducer as auth} from './authReducer'
import { reducer as navigationState } from './navigationReducer';
import { reducer as dimesnsionValues } from './graphDimensionValuesReducer';
import { reducer as userState } from './userReducer';
import { INITIAL_STATE as valuesInitialState } from './valuesReducer';
import { INITIAL_STATE as sectionsInitialState } from './sectionReducer'
import {reducer as app} from './appReducer'
import { RESET_APP } from '../actions/valuesActions';

const allReducers = combineReducers({
    values,
    observing,
    sections,
    navigationState,
    dimesnsionValues,
    auth,
    userState,
    app
});

export default (state, action) => {
    if (action.type === RESET_APP) {
        let { auth, app, userState, values, sections } = state;
        values = { ...valuesInitialState , currentDate: values.currentDate, resetApp: !values.resetApp, isGraphLoading: values.isGraphLoading }
        sections = { ...sectionsInitialState,
            fiscDateEmployee: sections.fiscDateEmployee,
            fiscDateEmployeeAbsenteesim: sections.fiscDateEmployeeAbsenteesim,
            oshaFiscDate: sections.oshaFiscDate,
            oshaRatesFiscDate: sections.oshaRatesFiscDate,
            plantOpsFiscDate: sections.plantOpsFiscDate,
            fiscDateNesFavBrand: state.sections.fiscDateNesFavBrand,
            fiscDateFillRate: state.sections.fiscDateFillRate
        }
        state = { auth, app, userState, values, sections };
      }

    return allReducers(state, action);
}
