import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipPlantOps = ({ payload, display, dataKey }) => {
  const {
    activeQYSwitcher,
    year,
    activeSection,
    activeTab
  } = useSelector(state => ({
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year,
    activeSection: state.sections.activeSection,
    activeTab: state.sections.activeTab
  }))

  const getTitle = () => {
    const data = payload[0].payload

    if (activeQYSwitcher === 'Y') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period) - 1]}</span> FY{year - 2000}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(data.period)}</span> FY{year - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getItems = () => {
    if (!payload[0].payload) return []
    const data = payload[0].payload

    const items = [];

    if((dataKey === 'actual' || dataKey === 'actualHours') && data) {
      items.push(
        <div key={1}>Capacity Utilization Rate</div>,
        <div key={2}><b>{ formatStr(formatNumber({ number: data.CapacityRecorded, employeePercents: true })) }</b> to Available</div>,

        activeTab.includes('CapacityUtilisation Processing') && activeSection === 'PPI' ? 
        <div key={3}>Actual Processing LBS: { formatStr( formatNumber({ number: data.actual }) ) } LBS </div> 
        : activeTab.includes('CapacityUtilisation Processing') ? 
        <div key={4}>Actual Processing Hours: { formatStr( formatNumber({ number: data.actualHours }) ) } Hours </div> 
        : <div key={5}>Head Harvested Actual: { formatStr( formatNumber({ number: data.actual }) ) } Heads</div>,

        activeTab.includes('CapacityUtilisation Processing') && activeSection === 'PPI' ? 
        <div key={6}>Available Capacity LBS: { formatStr( formatNumber({ number: data.available }) ) }</div>
        : activeTab.includes('CapacityUtilisation Processing') ?
        <div key={7}>Available Capacity Hours: { formatStr( formatNumber({ number: data.capacityHours }) ) }</div>
        : <div key={8}>Head Harvested Capacity: { formatStr( formatNumber({ number: data.available }) ) }</div>,

        activeTab.includes('CapacityUtilisation Processing') && activeSection === 'PPI' ? 
        <div key={9}>Planned Capacity LBS:  {  formatStr( formatNumber({ number: data.planned }) )  }</div>
        : activeTab.includes('CapacityUtilisation Processing') ?
        <div key={10}>Planned Capacity Hours:  {  formatStr( formatNumber({ number: data.budgetedHours }) )  }</div>
        : <div key={11}>Head Harvested Projected:  {  formatStr( formatNumber({ number: data.planned }) )  }</div>
      )

    }
    else {
      items.push(
        <div key={12}>{ formatStr( formatNumber({number: data.CapacityRecorded, employeePercents: true}) ) } Efficiency</div>
      )
    }

    return items;
  }

  return (
    <>
      {
        payload && payload.length && payload[0].payload ?
        <Tooltip
          className={display ? '' : 'opacity-0'}
        >
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }
      
    </>
    
  )
}

export default TooltipPlantOps