import * as React from "react"

function SvgComponent({ fill='#2699FB' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 15.264 22"
      className='fill-current'
    >
      <path
        d="M15.264 7.635a7.632 7.632 0 10-12.913 5.5h-.007c4.877 5.353 5.271 8.194 5.287 8.86.02-.663.41-3.507 5.291-8.86h-.01a7.584 7.584 0 002.352-5.5zM7.632 3.589a4.044 4.044 0 11-4.043 4.046 4.046 4.046 0 014.043-4.046z"
      />
    </svg>
  )
}

export default SvgComponent
