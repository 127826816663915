import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import oktaAuth from './services/authService'
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react'
import './App.css'
import './index.css'
import Dimensions from 'react-dimensions'
import { initGA } from "./components/Tracking";

import Home from './screens/fullAppUser/Home'
import HomeChicken from './screens/poultry/HomeChicken'
import HomePrepared from './screens/prepared/HomePrepared'
import HomeFreshMeats from './screens/freshmeats/HomeFreshMeats'
import HomeMcDonalds from './screens/mcDonalds/HomeMcDonalds'
import HomeHROsha from './screens/hrUser/HomeHROsha'
import HomeOperations from './screens/operstionsUser/HomeOperations'
import HomeOperationsPoultry from './screens/operstionsUser/HomeOperationsPoultry'
import HomeOperationsPrepared from './screens/operstionsUser/HomeOperationsPrepared'
import HomeOperationsFreshMeats from './screens/operstionsUser/HomeOperationsFreshMeats'
import HomeOperationsMcDonalds from './screens/operstionsUser/HomeOperationsMcDonalds'


import Main from './screens/fullAppUser/Main'
import MainChicken from './screens/poultry/MainChicken'
import MainPrepared from './screens/prepared/MainPrepared'
import MainFreshMeatsBeef from './screens/freshmeats/MainFreshMeatsBeef'
import MainFreshMeatsPork from './screens/freshmeats/MainFreshMeatsPork'
import MainFreshMeatsIntersegment from './screens/freshmeats/MainFreshMeatsIntersegment'

import MainMcDonalds from './screens/mcDonalds/MainMcDonalds'
import MainOperations from './screens/operstionsUser/MainOperations'
import MainOperationsPoultry from './screens/operstionsUser/MainOperationsPoultry'
import MainOperationsPrepared from './screens/operstionsUser/MainOperationsPrepared'
import MainOperationsFreshMeatsBeef from './screens/operstionsUser/MainOperationsFreshMeatsBeef'
import MainOperationsFreshMeatsPork from './screens/operstionsUser/MainOperationsFreshMeatsPork'
import MainOperationsMcDonalds from './screens/operstionsUser/MainOperationsMcDonalds'


import TeamMembers from './screens/fullAppUser/TeamMembers'
import TeamMembersPoultry from './screens/poultry/TeamMembersPoultry'
import TeamMembersPrepared from './screens/prepared/TeamMembersPrepared'
import TeamMembersMcDonalds from './screens/mcDonalds/TeamMembersMcDonalds'
import TeamMembersFreshMeats from './screens/freshmeats/TeamMembersFreshMeats'

import Osha from './screens/fullAppUser/Osha'
import OshaPoultry from './screens/poultry/OshaPoultry'
import OshaMcDonalds from './screens/mcDonalds/OshaMcDonalds'
import OshaPrepared from './screens/prepared/OshaPrepared'
import OshaFreshMeats from './screens/freshmeats/OshaFreshMeats'

import PlantOps from './screens/fullAppUser/PlantOps'
import PlantOpsPrepared from './screens/prepared/PlantOpsPrepared'
import PlantOpsPoultry from './screens/poultry/PlantOpsPoultry'
import PlantOpsFreshMeats from './screens/freshmeats/PlantOpsFreshMeats'
import PlantOpsMcDonalds from './screens/mcDonalds/PlantOpsMcDonalds'

import NesFavBrand from './screens/fullAppUser/NesFavBrand'
import FillRate from './screens/fullAppUser/FillRate'
import FillRatePoultry from './screens/poultry/FillRatePoultry'
import FillRatePrepared from './screens/prepared/FillRatePrepared'
import FillRateFreshMeats from './screens/freshmeats/FillRateFreshMeats'

import ItDashboard from './screens/ItDashboard/ItDashboard'
import ItDashboardDrilldown from './screens/ItDashboard/ItDashboardDrilldown'

import Login from './screens/Login'
import Logout from './screens/Logout'
import Timeout from './screens/Timeout'
import OktaErrorComponent from './components/OktaErrorComponent'
import HealthCheck from './screens/HealthCheck/HealthCheck'

const customCallback = props => {
  return (
    <LoginCallback
      {...props}
      errorComponent={OktaErrorComponent}
    ></LoginCallback>
  )
}

initGA();

function App () {
  return (
    <Router>
      <Security oktaAuth={oktaAuth}>
       <Route path='/implicit/callback' component={customCallback} />

        <SecureRoute exact={true} path='/' component={Home} />
        <SecureRoute exact={true} path='/poultry' component={HomeChicken} />
        <SecureRoute exact={true} path='/prepared' component={HomePrepared} />
        <SecureRoute exact={true} path='/freshMeats' component={HomeFreshMeats} />
        <SecureRoute exact={true} path='/McDonalds' component={HomeMcDonalds} />
        <SecureRoute exact={true} path='/hrosha' component={HomeHROsha} />
        <SecureRoute exact={true} path='/operations' component={HomeOperations} />
        <SecureRoute exact={true} path='/operations/poultry' component={HomeOperationsPoultry} />
        <SecureRoute exact={true} path='/operations/prepared' component={HomeOperationsPrepared} />
        <SecureRoute exact={true} path='/operations/freshMeats' component={HomeOperationsFreshMeats} />
        <SecureRoute exact={true} path='/operations/McDonalds' component={HomeOperationsMcDonalds} />


        <SecureRoute exact={true} path='/r/finance' component={Main} />
        <SecureRoute exact={true} path='/r/poultry/finance' component={MainChicken} />
        <SecureRoute exact={true} path='/r/prepared/finance' component={MainPrepared} />
        <SecureRoute exact={true} path='/r/freshMeatsBeef/finance' component={MainFreshMeatsBeef} />
        <SecureRoute exact={true} path='/r/freshMeatsPork/finance' component={MainFreshMeatsPork} />
        <SecureRoute exact={true} path='/r/freshMeatsIntersegment/finance' component={MainFreshMeatsIntersegment} />

        <SecureRoute exact={true} path='/r/McDonalds/finance' component={MainMcDonalds} />
        <SecureRoute exact={true} path='/r/operations/finance' component={MainOperations} />
        <SecureRoute exact={true} path='/r/operations/poultry/finance' component={MainOperationsPoultry} />
        <SecureRoute exact={true} path='/r/operations/prepared/finance' component={MainOperationsPrepared} />
        <SecureRoute exact={true} path='/r/operations/freshMeatsBeef/finance' component={MainOperationsFreshMeatsBeef} />
        <SecureRoute exact={true} path='/r/operations/freshMeatsPork/finance' component={MainOperationsFreshMeatsPork} />
        <SecureRoute exact={true} path='/r/operations/McDonalds/finance' component={MainOperationsMcDonalds} />
        

        <SecureRoute exact={true} path='/r/team-members' component={TeamMembers} />
        <SecureRoute exact={true} path='/r/poultry/team-members' component={TeamMembersPoultry} />
        <SecureRoute exact={true} path='/r/McDonalds/team-members' component={TeamMembersMcDonalds} />
        <SecureRoute exact={true} path='/r/prepared/team-members' component={TeamMembersPrepared} />
        <SecureRoute exact={true} path='/r/freshMeats/team-members' component={TeamMembersFreshMeats} />

        <SecureRoute exact={true} path='/r/osha' component={Osha} />
        <SecureRoute exact={true} path='/r/poultry/osha' component={OshaPoultry} />
        <SecureRoute exact={true} path='/r/McDonalds/osha' component={OshaMcDonalds} />
        <SecureRoute exact={true} path='/r/prepared/osha' component={OshaPrepared} />
        <SecureRoute exact={true} path='/r/freshMeats/osha' component={OshaFreshMeats} />

        <SecureRoute exact={true} path='/information-technology' component={ItDashboard} />
        <SecureRoute exact={true} path='/r/it-dashboard/drilldown' component={ItDashboardDrilldown} />

        <SecureRoute exact={true} path='/r/plant-ops' component={PlantOps} />      
        <SecureRoute exact={true} path='/r/poultry/plant-ops' component={PlantOpsPoultry} />
        <SecureRoute exact={true} path='/r/prepared/plant-ops' component={PlantOpsPrepared} />
        <SecureRoute exact={true} path='/r/freshMeats/plant-ops' component={PlantOpsFreshMeats} />
        <SecureRoute exact={true} path='/r/McDonalds/plant-ops' component={PlantOpsMcDonalds} />

        <SecureRoute exact={true} path='/r/nes-fav-brand' component={NesFavBrand} />
        <SecureRoute exact={true} path='/r/fill-rate' component={FillRate} />
        <SecureRoute exact={true} path='/r/poultry-fill-rate' component={FillRatePoultry} />
        <SecureRoute exact={true} path='/r/prepared-fill-rate' component={FillRatePrepared} />
        <SecureRoute exact={true} path='/r/freshMeats-fill-rate' component={FillRateFreshMeats} />

        <Route exact={true} path='/login' component={Login} />
        <Route path='/timeout' component={Timeout} />
        <Route path='/logout' component={Logout} />
        <Route path='/r/healthcheck' component={HealthCheck}/>
      </Security>
    </Router>
  )
}
export default Dimensions()(App)
