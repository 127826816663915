import React, { useState, useRef, useEffect } from 'react'
import './styles.css'
import { useOktaAuth } from '@okta/okta-react'
import { setShowModal } from '../../actions/app'
import { connect } from 'react-redux'

const API_URL = process.env.REACT_APP_API_URL

const Contact = props => {
  const [incident, setIncident] = useState(null)
  const modalSuccess = useRef(null)
  const modalFailure = useRef(null)
  const [answer, setAnswer] = useState([])
  const [description, setdescription] = useState('')
  const { authState, oktaAuth } = useOktaAuth()
  const {accessToken:{accessToken}} = authState;
  const [loading, setloading] = useState(false)

  useEffect(() => {
  }, [])

  const ArrSuvery = [
    {
      question: 'Data seems Incorrect',
      value: 'Data seems Incorrect'
    },
    {
      question: 'Missing data',
      value: 'Missing data'
    },
    {
      question: 'App response is slow',
      value: 'App response is slow'
    },
    {
      question: 'Other issues',
      value: 'Other issuess'
    }
  ]
  const onSurvey = e => {
    const value = e.target.value
    if (!answer.includes(value)) {
      setAnswer([value])
    } else {
      setAnswer([])
    }
  }

  const submitForm = async e => {
    setIncident(null)
    setloading(true)
    const uploadEndpoint = API_URL + '/report'
    const { email } = await oktaAuth.getUser()

    try {
      const headers = {
        'Content-Type': 'application/json',
         Authorization: accessToken
      }
      const body = {
        answer,
        description,
        u_reported_by: email,
        caller_id: email
      }
      console.log('body', body)
      const res = await fetch(uploadEndpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
      console.log('res', res)
      if (res.ok) {
        const body = await res.json()
        const data = body.result ? body.result : body
        setIncident(data)
        window.UIkit.modal(document.getElementById('modalSuccess')).show()
      } else {
        window.UIkit.modal(document.getElementById('modalFailure')).show()
      }
      props.setShowModal({ active: false })
    } catch (err) {
      console.log('err', err)
      window.UIkit.modal(document.getElementById('modalFailure')).show()
    }
    setloading(false)
  }

  return (
    <div className='contact'>
      <h2 className='uk-text-lead uk-margin-bottom' style={{ padding: 0, fontSize: 16, color: "#65686B" }}>
        Tell us what is not working ...
      </h2>
      <form className='uk-form-controls'>
        <div className='uk-child-width-1-2 uk-margin-bottom' data-uk-grid style={{marginLeft: 0, fontSize: 16, color: "#65686B"}}>
          {ArrSuvery.map((item, index) => {
            return (
              <div key={index}>
                <label className={`uk-text-center ${answer.includes(item.question) ? 'checked' : ''}`}>
                  <input
                    className="uk-radio"
                    type='radio'
                    name='answer'
                    value={item.question}
                    onClick={onSurvey}
                    testID={item.question}
                  />
                  <span style={{marginLeft: 8}}>{`${item.question}`}</span>
                </label>
              </div>
            )
          })}
        </div>
      </form>
      <div className='uk-width-expand'>
        <h2 className='uk-text-lead uk-margin-bottom' style={{ padding: 0, fontSize: 16, color: "#65686B" }}>
          Description
        </h2>
        <textarea
          className='uk-textarea uk-form-large'
          name='description'
          rows='4'
          cols='50'
          onChange={e => setdescription(e.target.value)}
          testID="tech-support-description"
        />
      </div>
      <div className='uk-align-right'>
        <button
          className='uk-button uk-button-text'
          onClick={submitForm}
          disabled={loading}
          style={{color: "#65686B"}}
          testID="tech-support-submit-button"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
      <div
        id='modalSuccess'
        className='uk-flex-top'
        data-uk-modal
        ref={modalSuccess}
      >
        <div className='uk-modal-dialog uk-modal-body uk-margin-auto-vertical'>
          <p testID="tech-support-success-text">
            {`Thank you for alerting us of an issue.
            We will investigate and work to resolve it as soon as possible.`}
          </p>
          <br></br>
          <p testID="tech-support-incident-number">Incident Number: {incident ? incident.number : ''}</p>
          <p className='uk-text-right' style={{ marginTop: 16 }}>
            <button
              className='uk-button uk-button-text uk-modal-close'
              type='button'
              testID="tech-support-close-button"
            >
              Close
            </button>
          </p>
        </div>
      </div>
      <div
        id='modalFailure'
        className='uk-flex-top'
        data-uk-modal
        ref={modalFailure}
      >
        <div className='uk-modal-dialog uk-modal-body uk-margin-auto-vertical'>
          <p className='uk-text-danger' testID="tech-support-error-text">
            Sorry there is an issue please contact service desk.
          </p>
          <p className='uk-text-right' style={{ marginTop: 16 }}>
            <button
              className='uk-button uk-button-text uk-modal-close'
              type='button'
              testID="tech-support-error-close-button"
            >
              Close
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ app }) => ({
  app
})

function mapDispatchToProps (dispatch) {
  return {
    setShowModal: payload => dispatch(setShowModal(payload))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact)
