import React from 'react'
import GreyCircle from '../../images/GreyCircle';

const CustomDot = (props) => {
  const {
    payload, isSingle, cx, cy, fill
  } = props;

  if (isSingle === parseInt(payload.period) && cy) {
    return (
      <GreyCircle
        cx={cx}
        cy={cy}
        r={6}
        fill={fill}
      />
    );
  }


  return null;

}

export default CustomDot