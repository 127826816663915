import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import { getProperty } from '../utils/dataMappingTurnover';
import formatNumber from '../utils/formatNumber';


const TooltipOsha = ({ payload, display }) => {
  const {
    activeSection,
    year,
    activeQYSwitcher,
    activeTab
  } = useSelector(state => ({
    activeSection: state.sections.activeSection,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year,
    activeTab: state.sections.activeTab
  }))

  const getTitle = (py) => {
    const data = payload[0].payload
    if (!data) return null
    if (activeQYSwitcher === 'Y' && activeSection === 'OSHA_RATES') return <div className='mb-1'>YTD <span className='font-bold'>{monthNames[parseInt(data.period) - 1]}</span> FY{year - (py ? 2001 : 2000)}</div>
    else if (activeQYSwitcher === 'Y') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period) - 1]}</span> FY{year - (py ? 2001 : 2000)}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(data.period)}</span> FY{year - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getKey = (key, py) => py ? key + 'PY' : key

  const getItems = (py) => {
    if (!payload[0].payload) return []
    const data = payload[0].payload

    if (activeSection === 'OSHA_RECORDABLES') {
      if (activeTab == 'SIF') {
        return [
          <div  style={{ color: getProperty('sifCnt', 'color') }} className='whitespace-nowrap' key={0}>{ 'SIF: ' + formatStr(formatNumber({ number: data.sifCnt }))  }</div>,
        ]
      }
      return [
        <div style={{ color: getProperty('countRecorded', 'color') }} className='whitespace-nowrap' key={0}>{ 'Total ' + formatStr(formatNumber({ number: data.countRecorded })) + ' Recordables'  }</div>,
        <div style={{ color: getProperty('Injury', 'color') }} className='whitespace-nowrap' key={1}>{ 'Injury: ' + '' + formatStr(formatNumber({ number: data.Injury }))  }</div>,
        <div style={{ color: getProperty('All Other Illnesses', 'color') }} className='whitespace-nowrap' key={2}>{ 'Illnesses: ' + '' + formatStr(formatNumber({ number: data['All Other Illnesses'] }))  }</div>,
        <div style={{ color: getProperty('Hearing Loss', 'color') }} className='whitespace-nowrap' key={3}>{ 'Hearing: ' + '' + formatStr(formatNumber({ number: data['Hearing Loss'] }))  }</div>,
        <div style={{ color: getProperty('Respiratory Condition', 'color') }} className='whitespace-nowrap' key={4}>{ 'Respiratory: ' + '' + formatStr(formatNumber({ number: data['Respiratory Condition'] }))  }</div>,
        <div style={{ color: getProperty('Skin Disorders', 'color') }} className='whitespace-nowrap' key={5}>{ 'Skin: ' + '' + formatStr(formatNumber({ number: data['Skin Disorders'] }))  }</div>
      ]
    }
    else {
      return [
        <div className='whitespace-nowrap' key={0}>{ 'Rate ' + formatStr(formatNumber({ number: data[getKey('rate', py)] }))  }</div>,
        <div className='whitespace-nowrap' key={1}>{ 'Hours: ' + '' + formatStr(formatNumber({ number: data[getKey('hours', py)], needScale: true, remove$: true }))  }</div>,
        <div className='whitespace-nowrap' key={2}>{ 'Recordables: ' + '' + formatStr(formatNumber({ number: data[getKey('cases', py)] }))  }</div>,
        // <div className='whitespace-nowrap' key={3}>{ 'BLS: ' + '' + formatStr(formatNumber({ number: data[getKey('bls', py)] }))  }</div>,
      ]
    }
  }

  return (
    <>
      {
        payload && payload.length ?
        <Tooltip
          className={display ? '' : 'opacity-0'}
        >
          <div className='text-xs text-black'>
            <div>
              { getTitle() }
              { getItems() }
            </div>
            {activeSection === 'OSHA_RATES' && <div className='mt-2'>
              { getTitle(true) }
              { getItems(true) }
            </div>}
          </div>
        </Tooltip>
        :
        null
      }
      
    </>
    
  )
}

export default TooltipOsha