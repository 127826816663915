import React from "react";
import Link from "./Link";
import { useSelector } from "react-redux";

const Breadcrumb = ({ className }) => {
  const { userPrivilages, activeSection } = useSelector((state) => ({
    userPrivilages: state.userState.userPrivilages,
    activeSection: state.sections.activeSection,
  }));

    const getHomeLink = () => {
        const links = {
            'CHICKEN': '/poultry',
            'PREPARED FOODS': '/prepared',
            'FRESH MEATS': '/freshMeats',
            'MCDONALDS': '/McDonalds',
            'HROSHA': '/hrosha',
            'OPERATIONS': '/operations',
            'OPERATIONS_POULTRY': '/operations/poultry',
            'OPERATIONS_PREPARED': '/operations/prepared',
            'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
            'OPERATIONS_MCDONALDS': '/operations/McDonalds',
            'IT_USER': '/information-technology',
            'false': '/'
        }

        return links[userPrivilages.view];
    };

    return (
        <div className={className}>
            <Link href={(userPrivilages.view === 'OPERATIONS') && window.location.href.includes('poultry') ? '/operations/poultry'
                : (userPrivilages.view === 'OPERATIONS') && window.location.href.includes('prepared') ? '/operations/prepared'
                    : (userPrivilages.view === 'OPERATIONS') && window.location.href.includes('freshMeats') ? '/operations/freshMeats'
                        : (userPrivilages.view === 'OPERATIONS') && window.location.href.includes('McDonalds') ? '/operations/McDonalds'
                                                : getHomeLink()}>
                Home
            </Link>

            {`${activeSection === "OI"
                ? " > Operating Income"
                : activeSection === "AOI"
                    ? " > AOI%"
                    : activeSection === "NET_SALES"
                        ? " > Net Sales"
                        : activeSection === "VOLUME"
                            ? " > Volume & Growth"
                            : activeSection === "TEAM_MEMBERS"
                                ? " > Team Members"
                                : activeSection === "TURNOVER_RATE"
                                    ? " > Turnover Rate"
                                    : activeSection === "ABSENTEEISM"
                                        ? " > Absenteeism"
                                        : activeSection === "OSHA_RECORDABLES"
                                            ? " > OSHA Recordables"
                                            : activeSection === "OSHA_RATES"
                                                ? " > OSHA Rates"
                                                : activeSection === "Poultry"
                                                    ? " > Poultry"
                                                    : activeSection === "Beef"
                                                        ? " > Beef"
                                                        : activeSection === "Pork"
                                                            ? " > Pork"
                                                            : activeSection === "PPI"
                                                                ? " > PPI"
                                                                : activeSection === "Prepared"
                                                                    ? " > Prepared"
                                                                    : activeSection === "McDonalds"
                                                                        ? " > McDonalds"
                                                                        : activeSection === "NES_FAVORITE_BRAND"
                                                                            ? " > NES & Favorite Brand"
                                                                            : activeSection === "Overall Fill Rate" || activeSection === "Top 10 Customers" || activeSection === "Top 10 Shorted Customers" || activeSection === "Top 10 Shorted Categories"
                                                                                ? " > Fill Rate"
                                                                                : activeSection === "IT_Hr_TEAM_MEMBERS"
                                                                                    ? " > Team Members"
                                                                                    :  activeSection === "IT_Hr_TOTAL_ATTRITION"
                                                                                    ? " > Attrition"
                                                                                    : activeSection === "IT_Hr_Vacancies"
                                                                                    ? " > Vacancies"
                                                                                    : activeSection === "IT_Hr_Positions"
                                                                                    ? " > Positions in Offer Status"
                                                                                    : activeSection === "IT_Hr_AvgDaysHire"
                                                                                    ? " > Average Days to Hire" : ""
                }`}
        </div>
    )
}

export default Breadcrumb;
