import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import toClassName from '../../utils/toClassName';

const UpdatedText = ({ className }) => {
  const {
    lastChanged
  } = useSelector(state => ({
    lastChanged: state.values.lastChanged
  }))

  if(!lastChanged) return null;
  return (
    <span className={toClassName(['text-xxxs italic text-black leading-none' ,className])}>
      Updated {moment(lastChanged).format('hh:mm A  MM/DD/YYYY')}
    </span>
  )
}

export default UpdatedText