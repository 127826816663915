import React from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children, targetNode }) => {
  return ReactDOM.createPortal(
    children,
    targetNode
  );
}

export default Portal