import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TeamMembers from '../fullAppUser/TeamMembers';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const TeamMembersPoultry = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
        setActiveBu(['Poultry'])
        setBuType('location')
    }, [])
    
    return (
        <TeamMembers
          onlyPoultry
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(TeamMembersPoultry)
