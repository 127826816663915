import React from 'react'

const GraphInfoItem = ({ children, title }) => {
  return (
    <div className='flex items-center'>
      { children }
      <span className='text-xxs lg:text-xs text-black'>{ title }</span>
    </div>
  )
}

export default GraphInfoItem