import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setQuarter, setSingle, setYear } from "../actions/sectionsActions";
import ScrollButton from "../componentsGeneral/section/ScrollButton";

import FeatherIcon from "feather-icons-react";

const BigGraphFinanceSlider = ({
  sections,
  sliderRef,
  currentDate,
  activeQYSwitcher,
  scrollPosition,
  setScrollPosition,
  getCurrentScreenData,
  activeTab,
  year,
  graphData,
}) => {
  const dispatch = useDispatch();
  const sliderScrollableElement = useRef(null);
  const [buttonsState, setButtonsState] = useState({
    showLeft: true,
    showRight: false,
  });
  const [graphYear, setGraphYear] = useState(
    graphData !== undefined ? graphData.itFinanceCloudDate.fiscYear : ""
  );
  const [graphQtr, setGraphQtr] = useState(
    graphData !== undefined ? graphData.itFinanceCloudDate.fiscQtr : ""
  );
  const [graphDate, setGraphDate] = useState(
    graphData !== undefined ? graphData.itFinanceCloudDate.date : ""
  );

  const setYearAndQuarter = () => {
    if (!currentDate) {
      return;
    }
    if (activeQYSwitcher === "Q" && activeTab === "YTD") {
      const quarter = (scrollPosition % 4) + 1;
      const year = Math.floor(scrollPosition / 4) + (currentDate.year - 2);
      dispatch(setQuarter(quarter));
      dispatch(setYear(year));
    } else if (activeQYSwitcher === "Q" && activeTab === "LE") {
      const quarter = (scrollPosition % 4) + 1;
      const year = Math.floor(scrollPosition / 4) + (currentDate.year - 2);
      dispatch(setQuarter(quarter));
      dispatch(setYear(year));
    } else {
      const year = scrollPosition + (currentDate.year - 2);
      dispatch(setYear(year));
    }
  };

  const manageButtonsState = () => {
    console.log(
      "G1 manageButtonsStatessssssssss",
      scrollPosition,
      sections.length - 1,
      graphQtr,
      graphData
    );
    if (scrollPosition == 0)
      setButtonsState({
        showLeft: false,
        showRight: true,
      });
    else if (scrollPosition == sections.length - 1)
      setButtonsState({
        showLeft: true,
        showRight: false,
      });
    else
      setButtonsState({
        showLeft: true,
        showRight: true,
      });
    if (
      graphData !== undefined &&
      ((activeQYSwitcher === "Y" && Number(graphYear).toString() === "2021") ||
        (activeQYSwitcher === "Q" && graphQtr === "FY21 Q1"))
    ) {
      setButtonsState({
        showLeft: false,
        showRight: true,
      });
    }
  };

  const getTranslateXAbs = () => {
    const element = sliderScrollableElement.current;
    return Math.abs(
      parseInt(
        element.style.transform.replace("translateX(", "").replace(")", "")
      )
    );
  };

  function scrollTo(to, duration = 200, callback) {
    const element = sliderScrollableElement.current;

    const currentScroll = getTranslateXAbs();

    var start = currentScroll,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.style.transform = `translateX(-${val}px)`;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();

    setTimeout(() => callback(), duration + 10);
  }

  useEffect(() => {
    if (graphData === undefined) {
      setYearAndQuarter();
    }
    manageButtonsState();
    dispatch(setSingle(false));
    console.log("G1 DATE", scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    console.log("G1 activeQYSwitcher", activeQYSwitcher, graphData);
    if (graphData !== undefined) {
      setGraphYear(graphData.itFinanceCloudDate.fiscYear);
      setGraphQtr(graphData.itFinanceCloudDate.fiscQtr);
      setGraphDate(graphData.itFinanceCloudDate.date);
    }
  }, [activeQYSwitcher, activeTab]);

  const getYearQuarter = (type) => {
    let currentQtr = graphQtr.split("Q")[1];
    console.log("G1 qtr", currentQtr);
    if (type === "sub" && currentQtr > 1) {
      return { qtr: Number(currentQtr) - 1, year: graphYear };
    } else if (type === "sub" && currentQtr == 1) {
      let obj = { qtr: Number(4), year: `${Number(graphYear) - 1}` };
      setGraphYear(`${Number(graphYear) - 1}`);
      return obj;
    }
    if (type === "add" && currentQtr < 4) {
      return { qtr: Number(currentQtr) + 1, year: graphYear };
    } else if (type === "add" && currentQtr == 4) {
      let obj = { qtr: 1, year: `${Number(graphYear) + 1}` };
      setGraphYear(`${Number(graphYear) + 1}`);
      return obj;
    }
  };

  const getYear = (type) => {
    console.log("G1 qtr", graphYear);
    if (type === "sub" && Number(graphYear) > 2021) {
      return `${Number(graphYear) - 1}`;
    } else if (type === "sub" && Number(graphYear) === 2021) {
      return "2021";
    }
    if (type === "add" && Number(graphYear) >= 2021) {
      return `${Number(graphYear) + 1}`;
    } else if (
      type === "add" &&
      Number(graphYear) == graphData.itFinanceCloudDate.fiscYear
    ) {
      return graphData.itFinanceCloudDate.fiscYear;
    }
  };

  return (
    <React.Fragment>
      {buttonsState.showLeft && (
        <ScrollButton
          className="left-0"
          onClick={() => {
            setScrollPosition(scrollPosition - 1);
            if (activeQYSwitcher === "Y") {
              if (graphData !== undefined) {
                graphData.getCurrentScreenData(
                  (Number(graphYear) - 1).toString()
                );
                setGraphYear((Number(graphYear) - 1).toString());
              }
            } else if (activeQYSwitcher === "Q") {
              if (graphData !== undefined) {
                // const quarter = ((scrollPosition - 2) % 4) + 1;
                // const year =
                //   Math.floor((scrollPosition - 2) / 4) + (graphYear - 2);
                const quarter = getYearQuarter("sub").qtr;
                const year = getYearQuarter("sub").year;
                console.log(
                  "G1 DATE",
                  year,
                  "FY" + (year - 2000) + " Q" + quarter,
                  quarter
                );
                graphData.getCurrentScreenData(
                  year,
                  "FY" + (year - 2000) + " Q" + quarter
                );
                setGraphQtr("FY" + (year - 2000) + " Q" + quarter);
              }
            } else if (activeQYSwitcher === "W") {
              if (graphData !== undefined) {
                const date = new Date(graphDate);
                date.setDate(date.getDate() - 7);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const day = date.getDate().toString().padStart(2, "0");
                //console.log("G1 DATE", year + "-" + month + "-" + day);
                graphData.getCurrentScreenData(
                  year,
                  null,
                  year + "-" + month + "-" + day
                );
                setGraphDate(year + "-" + month + "-" + day);
              }
            }
          }}
        >
          <FeatherIcon className="text-gray-600" icon={"chevron-left"} />
        </ScrollButton>
      )}
      <div
        ref={sliderScrollableElement}
        className="flex"
        style={{
          width: "100%",
        }}
      >
        <div className="flex flex-1">{sections[scrollPosition]}</div>
      </div>
      {buttonsState.showRight && (
        <ScrollButton
          className="right-0"
          onClick={() => {
            setScrollPosition(scrollPosition + 1);
            if (activeQYSwitcher === "Y") {
              if (graphData !== undefined) {
                graphData.getCurrentScreenData(
                  (Number(graphYear) + 1).toString()
                );
              }
              setGraphYear((Number(graphYear) + 1).toString());
            } else if (activeQYSwitcher === "Q") {
              // const quarter = (scrollPosition % 4) + 1;
              // const year = Math.floor(scrollPosition / 4) + (graphYear - 2);
              const quarter = getYearQuarter("add").qtr;
              const year = getYearQuarter("add").year;
              if (graphData !== undefined) {
                graphData.getCurrentScreenData(
                  year,
                  "FY" + (year - 2000) + " Q" + quarter
                );
              }
              setGraphQtr("FY" + (year - 2000) + " Q" + quarter);
            } else if (activeQYSwitcher === "W") {
              const date = new Date(graphDate);
              date.setDate(date.getDate() + 7);
              const year = date.getFullYear();
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const day = date.getDate().toString().padStart(2, "0");
              // console.log("G1 DATE", year + "-" + month + "-" + day);
              if (graphData !== undefined) {
                graphData.getCurrentScreenData(
                  year,
                  null,
                  year + "-" + month + "-" + day
                );
              }
              setGraphDate(year + "-" + month + "-" + day);
            }
          }}
        >
          <FeatherIcon className="text-gray-600" icon={"chevron-right"} />
        </ScrollButton>
      )}
    </React.Fragment>
  );
};

export default BigGraphFinanceSlider;
