import axios from 'axios'
import oktaAuth from './authService'

const axiosInstance = axios.create();

let isFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  }, 
  (error) => {
  const { config, response: { status } } = error
  const originalRequest = config

  if (status === 401) {
    if (!isFetchingAccessToken) {
      isFetchingAccessToken = true
      oktaAuth.tokenManager.renew("idToken")
      .then((res) => {
        isFetchingAccessToken = false
        onAccessTokenFetched(res.value)
      })
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(access_token => {
        originalRequest.headers.Authorization = 'Bearer ' + access_token
        resolve(axios(originalRequest))
      })
    })
    return retryOriginalRequest
  }
  return Promise.reject(error)
})

export default axiosInstance