import React from 'react';
import { useSelector } from 'react-redux';

import { monthNames } from '../../constants/app';

const GraphXTitles = ({ data, getMonth=()=>{} }) => {
  const {
    activeQYSwitcher,
    quarter
  } = useSelector(state => ({
    activeQYSwitcher: state.sections.activeQYSwitcher,
    quarter: state.sections.quarter
  }))

  const getQTitles = () => {
    if (activeQYSwitcher === 'Q') {
      return monthNames.slice((quarter - 1) * 3, quarter * 3)
    }
    else {
      return monthNames.reduce((previousValue, currentValue, currentIndex) => { 
        if (currentIndex % 2 == 0) return [...previousValue, currentValue]
        else return previousValue
      }, [])
    }
  }

  const getFlexValues = (data) => {
    const flexArray = []
    let flexValue = 0
    let prevMonth = 0

    data.forEach((element, i) => {
      const month = getMonth(element, prevMonth)
      if (!prevMonth) prevMonth = month
      else if (month != prevMonth || i == data.length - 1) {
        flexArray.push(flexValue)
        flexValue = 0
        prevMonth = 0
        return 
      }
      flexValue++
    });

    return flexArray
  }

  const getTitles = () => {
      if(activeQYSwitcher === 'Q') {
          const flexArray = getFlexValues(data);
          const titles = getQTitles()
          
          const result = titles.map((title, index) => {
              return <span key={title} className='text-xs text-black' style={{
                  flex: flexArray[index]
              }}>{title}</span>
          })
          return result;
      }
      else if(activeQYSwitcher === 'Y') {
        const flexArray = [2, 2, 2, 2, 2, 2];
        const titles = getQTitles()
        
        const result = titles.map((title, index) => {
            return <span key={title} className='text-xs text-black lg:pl-4 xl:pl-12 mt-0.5' style={{
                flex: flexArray[index]
            }}>{title}</span>
        })
        return result;
      }
  }

  return (
      <div className="absolute bottom-0 w-full flex h-3 -bottom-3">
          {
              getTitles()
          }
      </div>
  )
}

export default GraphXTitles