import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar, Cell, YAxis, Line, Area, Tooltip } from 'recharts'
import { setSingle, setYear, setQuarter } from '../actions/sectionsActions'
import BigGraph from '../componentsGeneral/section/BigGraph'
import GraphTicks from '../componentsGeneral/section/GraphTicks'
import formatNumber from '../utils/formatNumber'
import FeatherIcon from 'feather-icons-react';
import ScrollButton from '../componentsGeneral/section/ScrollButton'
import GraphInfo from '../componentsGeneral/section/GraphInfo'
import GraphXTitles from '../componentsGeneral/section/GraphXTitles'
import TooltipPlantOps from './TooltipPlantOps'
import BarShapeTm from '../componentsTeamMembers/BarShapeTm'
import useTooltipControl from '../hooks/useTooltipControl'
import { isMobile } from 'react-device-detect'


const BigGraphPlantOps = ({ dataKey }) => {
  const dispatch = useDispatch()
  const {
    activeSection,
    isSingle,
    activeQYSwitcher,
    year,
    quarter,
    bars,
    activeTab,
    currentDate
  } = useSelector(state => ({
    dataGraphAdditional: state.values.dataGraphAdditional,
    activeSection: state.sections.activeSection,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year,
    quarter: state.sections.quarter,
    activeTab: state.sections.activeTab,
    currentDate: state.sections.plantOpsFiscDate,
    bars: state.values.bars,
    barsPY: state.values.barsPY
  }))

  const graphHeight = 310
  const [graphRef, setGraphRef] = useState(null)
  const [data, setData] = useState([])
  const [buttonsState, setButtonsState] = useState({
    showLeft: true,
    showRight: false
  })

  const {
    onMouseOverBar,
    onMouseLeaveBar,
    isTooltipActive
  } = useTooltipControl()

  const clickChart = (e) => {
    if(isSingle) dispatch( setSingle(false) );
  }

  const handleClickBar = (data) => {
    if (data.period === isSingle) dispatch(setSingle(false))
    dispatch( setSingle(parseInt(data.period)) )
  }


  const formatTickValue = (value) => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, needScale: true, employeePercents: (activeTab && activeTab.includes('Efficiency')), remove$: true })

    return `${number === '0' || number === 0 ? '' : prependStr}${number === '0' || number === 0 ? '' : number}${number === '0' || number === 0  ? '' : appendStr}`
  }


  const renderTicks = () => {
    if (!graphRef || !graphRef.state.yAxisMap.left) return null
    
    return graphRef.state.yAxisMap.left.niceTicks.map((tickValue, i) => <span className='text-sm text-black leading-0' key={i}>
      { isFinite(tickValue) ? formatTickValue(tickValue) : '' }
    </span>)
  }

  const manageButtonsState = () => {
    if (currentDate.year == year) {
      if (activeQYSwitcher == 'Y') {
        setButtonsState({
          showLeft: true,
          showRight: false
        })
      }
      else {
        if (currentDate.quarter == quarter) {
          setButtonsState({
            showLeft: true,
            showRight: false
          })
        }
        else {
          setButtonsState({
            showLeft: true,
            showRight: true
          })
        }
      }
    }
    else if (currentDate.year - 2 == year) {
      if (activeQYSwitcher == 'Y') {
        setButtonsState({
          showLeft: false,
          showRight: true
        })
      }
      else {
        if (1 == quarter) {
          setButtonsState({
            showLeft: false,
            showRight: true
          })
        }
        else {
          setButtonsState({
            showLeft: true,
            showRight: true
          })
        }
      }
    }
    else setButtonsState({
      showLeft: true,
      showRight: true
    })
  }

  const clickLeftScroll = () => {
    if (activeQYSwitcher === 'Y') {
      dispatch( setYear(year - 1) )
    }
    else {
      if (quarter == 1) {
        dispatch( setQuarter(4) )
        dispatch( setYear(year - 1) )
      }
      else {
        dispatch( setQuarter(quarter - 1) )
      }
    }

    dispatch( setSingle(false) )
  }

  const clickRightScroll = () => {
    if (activeQYSwitcher === 'Y') {
      dispatch( setYear(year + 1) )
    }
    else {
      if (quarter == 4) {
        dispatch( setQuarter(1) )
        dispatch( setYear(year + 1) )
      }
      else {
        dispatch( setQuarter(quarter + 1) )
      }
    }

    dispatch( setSingle(false) )
  }

  const getGraphInfo = () => {
    if (!activeTab) return []

    const infoCapacityCUHar = [
      {
        title: 'Head Harvested Actual',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ backgroundColor: '#002544' }}></span>
      },
      {
        title: 'Head Harvested Capacity',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ background: 'linear-gradient(#a0a0a0, transparent)' }}></span>
      },
      {
        title: 'Head Harvested Projected',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #707070 dotted' }}></span>
      }
    ]

    const infoCapacityCUProHours = [
      {
        title: 'Actual Processing Hours',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ backgroundColor: '#002544' }}></span>
      },
      {
        title: 'Avaiable Capacity Hours',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ background: 'linear-gradient(#a0a0a0, transparent)' }}></span>
      },
      {
        title: 'Planned Capacity Hours',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #707070 dotted' }}></span>
      }
    ]

    const infoCapacityCUProLbs = [
      {
        title: 'Actual Processing LBS',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ backgroundColor: '#002544' }}></span>
      },
      {
        title: 'Avaiable Capacity LBS',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ background: 'linear-gradient(#a0a0a0, transparent)' }}></span>
      },
      {
        title: 'Planned Capacity LBS',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #707070 dotted' }}></span>
      }
    ]

    const infoEfficiency = [
      {
        title: 'Efficiency',
        line: <span className='inline-block mr-2 h-4 w-4' style={{ backgroundColor: 'rgb(245, 159, 75)' }}></span>
      }
    ]

    let workingArray = activeTab.includes('Efficiency') ? infoEfficiency 
    : activeTab.includes('CapacityUtilisation Processing') && activeSection === 'PPI' ? infoCapacityCUProLbs 
    : activeTab.includes('CapacityUtilisation Processing') ? infoCapacityCUProHours 
    : infoCapacityCUHar

    return workingArray.map(v => <div key={v.title} className='flex items-center'>{ v.line }<span className='text-xs text-black'>{ v.title }</span></div>)
  }

  const fillBars = (data, startWeekOfPeriod, totalIteratios) => {
    for(let i = 0; i < totalIteratios; i++) {
      const currentPeriod = startWeekOfPeriod + i;
      let currentPeriodObj = data.find(({ period }) => parseInt(period) === currentPeriod );
      if(!currentPeriodObj) {
        currentPeriodObj = {CapacityRecorded: 0, period: currentPeriod };
        data.splice(i, 0, currentPeriodObj)
      }
      else {
        data[i] = { ...currentPeriodObj };
      }

      if (currentDate.year == year && data[i].period >= currentDate.month && activeQYSwitcher == 'Y') data[i].isHalfClosed = true
    }
  }

  const getData = () => {
    let data = []

    if(activeQYSwitcher === 'Y') {
      data = bars.slice(0);
      const startWeekOfPeriod = 1;
      fillBars(data, startWeekOfPeriod, 12);
    }
    else {
        data = bars.slice(0);
        const firstPeriod = data[0] ? data[0].period : (quarter - 1) * 13 + 1;
        const startWeekOfPeriod = Math.floor((firstPeriod === 53 ? 39 : firstPeriod - 1)/13)*13 + 1;
        const lastPeriod = data[data.length - 1] ? data[data.length - 1].period : 0;
        const weeksCount = lastPeriod === 53 ? 14 : 13;
        fillBars(data, startWeekOfPeriod, weeksCount);
    }

    return data
  }

  const getGraphContent = () => {
    if (!activeTab) return []

    if (activeTab.includes('Efficiency')) {
      return (
        <Bar 
          key={1}
          dataKey={dataKey}
          yAxisId='left'
          onClick={handleClickBar}
          shape={<BarShapeTm />}
          barSize={20}
          //minPointSize={25}
          onMouseOver={onMouseOverBar}
          onMouseLeave={onMouseLeaveBar}
        >
          {
            data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill='rgb(245, 159, 75)'
                opacity={(isSingle && isSingle !== entry.period) ? 0.5 : 1}
                // isHalfClosed={year == currentDate.year && entry.period >= currentDate.month}
                />
            ))
          }
        </Bar>
      )
    }
    else if(dataKey === 'actual')  {
      return [
        <Area 
          key={3}
          type="monotone"
          hide={!!isSingle} 
          yAxisId='left'
          dataKey="available" 
          stroke="transparent" 
          fillOpacity={1} 
          fill="url(#colorAvailable)"
          isAnimationActive={true} 
        />,
        <Bar 
          key={1}
          dataKey={dataKey}
          yAxisId='left'
          onClick={handleClickBar}
          shape={<BarShapeTm />}
          //minPointSize={25}
          barSize={20}
          onMouseOver={onMouseOverBar}
          onMouseLeave={onMouseLeaveBar}
          isAnimationActive={true}
        >
          {
            data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill='#002544'
                opacity={(isSingle && isSingle !== entry.period) ? 0.5 : 1}
              />
            ))
          }
        </Bar>,
        <Line
          key={2}
          dot={false}
          yAxisId='left'
          hide={!!isSingle} 
          type='linear'
          dataKey={'planned'}
          strokeDasharray="2 2"
          stroke={'#65686B'}
          strokeWidth={2}
          connectNulls={true}
          isAnimationActive={true}
        />
      ]
    }
    else if(dataKey === 'actualHours')  {
      return [
        <Area 
          key={3}
          type="monotone"
          hide={!!isSingle}  
          yAxisId='left'
          dataKey="capacityHours" 
          stroke="transparent" 
          fillOpacity={1} 
          fill="url(#colorAvailable)" 
          isAnimationActive={true}
        />,
        <Bar 
          key={1}
          dataKey={dataKey}
          yAxisId='left'
          onClick={handleClickBar}
          shape={<BarShapeTm />}
          barSize={20}
          //minPointSize={25}
          onMouseOver={onMouseOverBar}
          onMouseLeave={onMouseLeaveBar}
          isAnimationActive={true}
        >
          {
            data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill='#002544'
                opacity={(isSingle && isSingle !== entry.period) ? 0.5 : 1}
              />
            ))
          }
        </Bar>,
        <Line
          key={2}
          dot={false}
          yAxisId='left'
          hide={!!isSingle} 
          type='linear'
          dataKey={'budgetedHours'}
          strokeDasharray="2 2"
          stroke={'#65686B'}
          strokeWidth={2}
          connectNulls={true}
          isAnimationActive={true}
        />
      ]
    }
  }

  useEffect(() => {
    manageButtonsState()
  }, [year, quarter])

  useEffect(() => {
    const d = getData()
    console.log({ d }, ' !!!!#@')
    setData( getData() )
  }, [bars])

  useEffect(() => {
    setData( getData() )
  }, [])

  return (
    <div className='flex relative pb-16 justify-center'>
      <GraphInfo>
        { getGraphInfo() }
      </GraphInfo>
      <div className='h-full w-16'>
        <GraphTicks>
          { renderTicks() }
        </GraphTicks>
      </div>
      <div className='overflow-hidden flex-1 relative pb-4 main-graph-container'>
        {buttonsState.showLeft && <ScrollButton
          className='left-0'
          onClick={clickLeftScroll}
        >
          <FeatherIcon className='text-gray-600' icon={'chevron-left'} />
        </ScrollButton>}
        <BigGraph
          data={data}
          height={graphHeight * 0.85}
          setRef={setGraphRef}
          onClick={clickChart}
          titles={<GraphXTitles 
            data={data}
            getMonth={el => el.period}
          />}
        >
          <defs>
            <linearGradient id="colorAvailable" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#a0a0a0" stopOpacity={0.6}/>
              <stop offset="100%" stopColor="#a0a0a0" stopOpacity={0.1}/>
            </linearGradient>
          </defs>
          {!isMobile && <Tooltip 
            content={<TooltipPlantOps 
              dataKey={dataKey} 
              display={isTooltipActive}
            />}
          />
            }
          { getGraphContent() }
          <YAxis 
            yAxisId='left'
            hide
          />
        </BigGraph>
        {buttonsState.showRight && <ScrollButton
          className='right-0'
          onClick={clickRightScroll}
        >
          <FeatherIcon className='text-gray-600' icon={'chevron-right'} />
        </ScrollButton>}
      </div>
    </div>
  )
}

export default BigGraphPlantOps
