/**
 * Simple tracking utility for google analytics, just need to add pageview with the page name parameter
 * to track pages, call Event method to track events(clicking on poultry icon, meat icon etc...). Track
 * exceptions using Exception method
 */

import ReactGA from "react-ga4";
const REACT_APP_GA = process.env.REACT_APP_GA;

export const initGA = () => {
  ReactGA.initialize(REACT_APP_GA);
};

/**
 * PageView - To track page views
 * @param {string} page
 */

export const PageView = (page) => {
  if (page === undefined) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  } else {
    ReactGA.pageview(page);
  }
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */

/*
 ex:
 <button
   onClick={()=>
       Event("PRODUCT", "Product added to cart", "PRODUCT_PAGE")
   } >Add to Cart</button>
 */

export const Event = (category, action, label) => {
  ReactGA.event({
    category: ''+category,
    action: ''+action,
    label: ''+label,
  });
};

/**
 * TrackException - Exceptions can be tracked here
 * @param {string} TrackException
 */
export const TrackException = (description, isFatal = false) => {
  ReactGA.exception({
    description: description,
    fatal: isFatal,
  });
};
