import * as React from "react"

function SvgComponent({ fill='#2699FB' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 23.27 22"
      className='fill-current'
    >
      <path
        d="M7.111 0h9.542v9.541H7.111zM0 12.459h9.542V22H0zM13.728 12.459h9.542V22h-9.542z"
      />
    </svg>
  )
}

export default SvgComponent
