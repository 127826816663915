import * as React from "react"

const SvgComponent = ({ size=36, color='#2699FB', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props}>
    <path data-name="Rectangle 19701" fill="none" d="M0 0h36v36H0z" />
    <path
      data-name="icon"
      d="M35.837 14.031c-.164 0-.164 0-.327.164a5.536 5.536 0 0 0-1.308-.491c-.164 0-.164-.164-.327-.327a6.752 6.752 0 0 1-1.145-1.8c0-.327 0-.327-.164-.491h1.15a1.375 1.375 0 0 0 .981-.654.571.571 0 0 1 .164-.327c.327-.327.327-.491.491-.491 0-.164 0-.164-.164-.164h-1.8l-1.312-.324h-.491l-1.308.327a7.372 7.372 0 0 0-1.8-.491 60.473 60.473 0 0 1-8.176-.818A29.137 29.137 0 0 0 11.472 7c-2.78 0-4.252 0-8.34 2.289-.164 0-.327.164-.491.327-.489-.327-1.634-.327-2.615-.816a2.135 2.135 0 0 0 1.145 1.961h.164a4.866 4.866 0 0 0-.819 2.453 10.7 10.7 0 0 0 1.472 5.56c.164.491.818 1.635.981 2.126a4.416 4.416 0 0 0 0 1.472c.164.327.491.164.654.491a7.971 7.971 0 0 0 .654 1.8 4.945 4.945 0 0 1 .327 1.637v.818c0 .327.164.654.327.654h.164v-.654l.327 1.308c.818.164 1.8.164 1.635-.327-.327-.491-.491-4.252-.491-4.579 0 0 .327-.164.654-1.8.327-1.472.327-2.126.818-2.78a13.62 13.62 0 0 0 3.6.491c2.453 0 4.742-.491 7.195 0a.6.6 0 0 1 .491.164 3.081 3.081 0 0 1 .327 1.308c.164.654.654 2.289 1.145 3.925a4.945 4.945 0 0 1 .327 1.635v.818c0 .327.164.654.327.654h.164v-.654l.327 1.308c.818.164 1.8.164 1.635-.327-.164-1.145-.981-5.56-.327-7.685.654-.327 4.579-2.616 5.233-2.943a1.236 1.236 0 0 1 .654-.164h.818a1.528 1.528 0 0 0 1.472 0 3.032 3.032 0 0 1 1.145-.327c.491 0 .491-.164.818-.327a1.266 1.266 0 0 1 .491-.164.709.709 0 0 0 .654-.327l.327-.327.164.164c.164 0 .164 0 .164-.164v-.658l.818-.981c0-.164 0-.164-.164-.327"
      className="fill-current"
    />
  </svg>
)

export default SvgComponent
