import React from "react";
import { useSelector } from "react-redux";

import formatFiscDate from "../../utils/formatFiscDate";
import { monthNames } from "../../constants/app";

const CurrentScreenTitle = ({
  className,
  week,
  month,
  qrtr,
  yr,
  activeQYSwitcher,
}) => {
  const { sectionTitle, quarter, year, activeSection, activeQYSwitcher1 } = useSelector(
    (state) => ({
      sectionTitle: state.sections.sectionTitle,
      quarter: state.sections.quarter,
      year: state.sections.year,
      activeSection: state.sections.activeSection,
      activeQYSwitcher1: state.sections.activeQYSwitcher
    })
  );
  const getITDashboard = () => {
   if (activeSection.toString().includes("IT_Hr")){
      if (activeQYSwitcher === "Y") {
        return `FY${yr.toString().slice(-2)} (US Only)`;
      } else if (activeQYSwitcher === "Q") {
        return qrtr;
      } else {
        return qrtr;
      }
    }
    else if (activeSection.toString().includes("IT_")) {
      if (activeQYSwitcher === "Y") {
        return `FY${yr.toString().slice(-2)}`;
      } else if (activeQYSwitcher === "Q") {
        return qrtr;
      } else {
        return qrtr;
      }
    }
    else {
      return formatFiscDate({ quarter, year, week, month });
    }
  };
  return (
    <span className={className}>
      {sectionTitle} -{" "}
      {activeSection === "OSHA_RATES" ? "YTD " + formatFiscDate({ quarter, year, week, month })
      : activeSection === 'ABSENTEEISM' ? `${month ? `${monthNames[month-1]} ` : week ? `FW${week} ` : (quarter && activeQYSwitcher1 == 'Q') ? `Q${quarter} ` : ''}FY${Number.parseInt(year) - 2000}`
      : getITDashboard()}
    </span>
  );
};

export default CurrentScreenTitle;
