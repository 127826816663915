export default ({number, percents=false, needScale=false, financePercents=false, needPercentsSign=true, employeePercents=false, needSign, remove$, needLBS, isNumberlessthan1000}) => {
  number = Number.parseFloat((isNaN(number) || number === null) ? 0 : number)
  let maximumFractionDigits = 2
  let divideBy = 1
  let appendStr = ''
  let prependStr = ''

  if(percents) {
    divideBy = 100
    appendStr = '%'
    maximumFractionDigits = 1
  }
  else if(financePercents) {
    divideBy = 0.01
    appendStr = '%'
    maximumFractionDigits = 1
  }
  else if(employeePercents) {
    divideBy = 1
    appendStr = '%'
    maximumFractionDigits = 1
  }
  else if(needScale && Math.abs(number) >= 1000000000) {
    divideBy = 1000000000;
    appendStr = 'B'
    prependStr = '$'
    maximumFractionDigits = 2
  }
  else if(needScale && Math.abs(number) >= 1000000 && Math.abs(number) < 1000000000) {
    divideBy = 1000000;
    appendStr = 'M'
    prependStr = '$'
    maximumFractionDigits = 1
  }
  else if(needScale && Math.abs(number) >= 1000 && Math.abs(number) < 1000000) {
    divideBy = 1000;
    appendStr = 'K'
    prependStr = '$'
    maximumFractionDigits = 0
  }
  else if(needScale && Math.abs(number) >= -999 && Math.abs(number) <= 999 && isNumberlessthan1000) {
    divideBy = 1/0;
    appendStr = 'K'
    prependStr = '$'
    maximumFractionDigits = 0
  }
  else if(needScale && Math.abs(number) < 1000) {
    divideBy = 1;
    appendStr = ''
    prependStr = '$'
    maximumFractionDigits = 0
  }

  if (needSign) {
    prependStr = number < 0 ? '' : '+'
  }

  if (number < 0) {
    number = Math.abs(number)
    prependStr = '-' + prependStr
  }

  if (remove$) {
    prependStr = prependStr.replace('$', '')
  }

  if (needLBS) {
    appendStr = appendStr + ' LBS'
  }

  if (!needPercentsSign) {
    appendStr = appendStr.replace('%', '')
  }

  return {
    prependStr,
    number: `${Intl.NumberFormat(
      'en-US',
      {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        style: 'decimal'
      }
    ).format(number/divideBy)}`,
    appendStr
  }
}

// roundFn(number/divideBy, prependStr ? prependStr : appendStr) 