export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_PRIVILAGES = 'SET_USER_PRIVILAGES';


export const setUserPrivilages = (userPrivilages) => ({
    type: SET_USER_PRIVILAGES,
    payload: {
        userPrivilages
    }
})
export const setUserInfo = (userInfo) => ({
    type: SET_USER_INFO,
    payload: {
        userInfo
    }
})
