import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import CurrentScreenValues from '../componentsGeneral/section/CurrentScreenValues'
import ValueWithDot from '../componentsGeneral/section/ValueWithDot'
import formatFiscDateLastYear from '../utils/formatFiscDateLastYear'
import Note from '../componentsGeneral/section/Note'


const CurrentScreenValuesFinance = ({ currentKey, week, month, navigationStage }) => {
  const {
    header,
    activeTabTitle,
    activeBu,
    activeSection,
    quarter,
    year,
    financeFiscDate,
    activeQYSwitcher
  } = useSelector(state => ({
    header: state.values.header,
    activeTabTitle: state.sections.activeTabTitle,
    activeBu: state.sections.activeBu,
    activeSection: state.sections.activeSection,
    quarter: state.sections.quarter,
    year: state.sections.year,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    financeFiscDate: state.sections.financeFiscDate,
  }))


  const getMainValue = () => {
    return header ? isNaN(header[currentKey]) ? 0 :   header[currentKey] : ''
    // return header ? isNaN(header[currentKey]) ? 0 : header[currentKey] : ''
  }

  const getAdditionalValues = () => {
    const v = []
    v.push({
      value: header ? isNaN(header[currentKey] - header[currentKey + 'Plan']) ? 0 
      : header[currentKey] - header[currentKey + 'Plan']
      : '',
      text: ' vs AOP '
    })

    if (header && currentKey + 'Proj' in header) v.push({
      value: header ? isNaN(header[currentKey] - header[currentKey + 'Proj']) ? 0 
      : header[currentKey] - header[currentKey + 'Proj']
      : '',
      text: ' vs Proj. '
    })

    return v.map(v => <ValueWithDot 
      className='text-black text-sm font-extralight'
      value={v.value}
      text={v.text}
      key={v.text}
      useDot={true}
      financePercents={currentKey === 'aoi' || currentKey === 'volume'}
      isNumberlessthan1000={true}
    />)
  }

  const getAdditionalValuesVolume = () => {
    const v = []
    v.push({
      value: header ? (isNaN(header[currentKey] - header[currentKey + 'Previous'] / (header[currentKey + 'Previous'])) ? 0 
      : ((header[currentKey] - header[currentKey + 'Previous']) / (header[currentKey + 'Previous'])))
      : '',
      text: `vs ${formatFiscDateLastYear({ quarter, year, week, month })} `
    })

    return v.map(v => <ValueWithDot 
      className='text-black text-sm font-extralight'
      value={v.value}  
      text={v.text}
      key={v.text}
      useDot={true}
      preTextVolume={true}
      financePercents={currentKey === 'aoi' || currentKey === 'volume'}
      isNumberlessthan1000={true}
    />)
  }

  return (
    <CurrentScreenValues 
      value={getMainValue()}
      needScale={currentKey !== 'aoi' || currentKey !== 'volume'}
      financePercents={currentKey === 'aoi'}
      isNumberlessthan1000={true}
      defaultTitle='TYSON OVERALL'
      title={activeBu ? activeBu : activeTabTitle}
      navigationStage={navigationStage}
      week={week}
      month={month}
    >
      <div className='mt-2 flex flex-col items-center justify-start'>
        { activeSection === 'VOLUME' ? year !== financeFiscDate.year - 2  ?  getAdditionalValuesVolume() : '' : getAdditionalValues() }
      </div>
      <Note 
        text={year === 2020 && activeQYSwitcher != 'Q' ? (`Note: 53 weeks in FY${year - 2000}`)
        : year === 2020 && quarter === 4 ? (`Note: 14 weeks in Qtr`)
        : year === 2021 && quarter === 4 ? (`Note: A material ledger issue overstated OI in FWs 41-42 and understated OI in FW43 requiring unfavorable adj to Poultry ($17M), Prepared ($30M) and B&P ($1M). Adj are not reflected until the ME processes run.`)
        : ''}
      />
    </CurrentScreenValues>
  )
}

export default CurrentScreenValuesFinance