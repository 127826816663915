import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import GraphSmall from '../componentsGeneral/section/GraphSmall'
import ValueWithDot from '../componentsGeneral/section/ValueWithDot'
import BusinessUnitGraphSection from '../componentsGeneral/section/BusinessUnitGraphSection'
import formatNumber from '../utils/formatNumber'

import FeatherIcon from 'feather-icons-react';
import { Tooltip } from 'recharts';
import TooltipFinance from './TooltipFinance'

const BusinessUnitDouble = ({ 
  graphData,
  name,
  value,
  currentKey,
  additionalValues,
  activeQYSwitcher,
  onClick,
  isNumberlessthan1000
}) => {
  const getValue = () => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, financePercents: currentKey === 'aoi', needScale: currentKey !== 'aoi', needLBS: currentKey === 'volume', isNumberlessthan1000, remove$: currentKey === 'volume' })

    return `${prependStr}${number}${appendStr}`
  }
  const {
    quarter,
    year,
    financeFiscDate,
  } = useSelector(state => ({
    quarter: state.sections.quarter,
    year: state.sections.year,
    financeFiscDate: state.sections.financeFiscDate,
  }))

  return (
    <div 
      className='first:border-t border-b border-gray-200 flex py-2 text-black items-end cursor-pointer'
      onClick={onClick}
    >
      <div className='flex flex-col lg:pl-2 flex-1'>
        <span className='whitespace-nowrap text-sm'>
          {
            name
          }
        </span>
        <span className='text-base2 font-semibold'>
          {
            getValue()
          }
        </span>
      </div>
      <div className='bu-double-indecators mr-2 items-end'>
        {
          additionalValues.map(({ value, text }, i) => <React.Fragment key={i}>
            <span className='text-right text-xxxs italic min-w-0'>
              <span className='pr-2'>{ text }</span>
            </span>
            {currentKey === 'volume' && year === financeFiscDate.year - 2 ? '' : <ValueWithDot 
              className='text-sm leading-none whitespace-nowrap text-right min-w-0'
              value={value}
              financePercents={currentKey === 'aoi' || currentKey === 'volume'}
              useDot={true}
              isNumberlessthan1000={true}
            />
        }
          </React.Fragment>)
        }
      </div>
      <BusinessUnitGraphSection
        useGraphBorder
        height={40}
      >
        <GraphSmall 
          data={graphData}
          dataKey={currentKey}
          needAnimation={true}
          height={40}
        >
          {!isMobile && <Tooltip 
            allowEscapeViewBox={{y: true, x:true}}
            offset={-3}
            isAnimationActive={false}
            content={
              <TooltipFinance 
                // className="-translate-x-full -translate-y-full transform"
                currentKey={currentKey} 
                groupKey={'groupByPeriod'}
                activeQYSwitcher={activeQYSwitcher}
                display={true}
                isNumberlessthan1000={true}
                bu
              />
            }
            cursor={false}
          ></Tooltip>
           }
        </GraphSmall>
      </BusinessUnitGraphSection>
      <div className='self-center'>
        <FeatherIcon className='-rotate-90 transform text-blue-400' icon={'chevron-down'} />
      </div>
    </div>
  )
}

export default BusinessUnitDouble