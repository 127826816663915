import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import { updateToken } from "../actions/authAction"


const scopes = ['openid', 'profile', 'email', 'WorkdayOktaSAMAccount', 'AD_sAMAccountName', 'MeatballAppUserRole']

const config = {
    /* tokenManager: {
    storage: 'localStorage',
    secure: true
    }, */
    pkce: true,
    issuer: process.env.REACT_APP_OKTAISSUER,
    clientId: process.env.REACT_APP_OKTACLIENTID,
    redirectUri: window.location.origin + '/implicit/callback',
    additionalParameters: {},
    response_mode: 'query',
    prompt: 'none',
    scopes,
    tokenManager: {
        storage: 'localStorage'
    }

}

const authClient = new OktaAuth(config);

export default authClient