import React, { useState } from 'react'

import FeatherIcon from 'feather-icons-react';

import toClassName from '../../utils/toClassName'



const ItemDouble = ({ children, title, itemsCollapsible }) => {
  const [extended, setExtended] = useState(true)

  const toggleExtended = () => {
    setExtended(!extended)
  }


  return (
    <div className={'bg-gray-400 px-1 lg:px-3 pt-2 pb-3.5 mt-2 w-full lg:w-max'}>
      <h3 onClick={toggleExtended} className='text-base2 font-bold text-left tracking-widest text-black cursor-pointer flex justify-between'>
        <span>{ title }</span>
        <FeatherIcon className={toClassName([extended ? 'rotate-180' : '', 'transform transition-all text-blue-400'])} icon={'chevron-down'} />
      </h3>
      <div className='flex justify-around mt-2 -mx-1 lg:mx-0'>
        {
          children
        }
      </div>
      {extended && <div className='grid justify-around mt-2 space-y-1 -mx-1 lg:mx-0 home-mobile-collapsible-content'>
        {
          itemsCollapsible
        }
      </div>}
    </div>
  )
}

export default ItemDouble