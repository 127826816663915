import React, { useState, useRef } from 'react'
import { getProperty } from '../utils/dataMappingTurnover';
import TooltipTmUnitGraph from './TooltipTmUnitGraph';

const TmSmallGraph = ({ width, height, data, dataKey, isSingle, currentDate, activeQYSwitcher, year}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipCoords, setTooltipCoords] = useState({});
  const tooltipRef = useRef(null);
  const timerRef = useRef(null);
  const wrapperRef = useRef(null)

  if(!data) return <></>;

  const offset = -13
  const getCurrentBarWidth = (el, totalValue, barX) => {
      const value = el[dataKey];
      const onePercent = totalValue/100;
      const barWidth = value/onePercent/100*width;
      const newBarX = barX + barWidth;
      return {barWidth, newBarX};
  }

  const getBars = () => {
      const key = dataKey === 'absCount' ? 'viewBy' : 'period'
      const totalVal = data.reduce((acc, currentVal) => {
          if(currentVal[dataKey]) return acc + currentVal[dataKey];
          else return acc;

      }, 0);
      let barX = 0;
      const organizedBars = data.sort((fEl, sEl) => {
          const index1 = getProperty(fEl[key], 'index');
          const index2 = getProperty(sEl[key], 'index');
          return index1 - index2;
      }).map((el, i) => {
          const { newBarX, barWidth } = getCurrentBarWidth(el, totalVal, barX);
          const currentBarX = barX;
          barX = newBarX;
          return <rect key={i + 'R'} x={currentBarX} width={barWidth} height={height} fill={getProperty(el[key], 'color')} />
      });
      if(currentDate && isSingle && ((currentDate.month == isSingle && activeQYSwitcher === 'Y' && currentDate.year == year))) {
          for(let i = 0; i<width; i+=10) {
              organizedBars.push(
                  <line key={i + 'l'} x1={i} y1={height} x2={i + 7.5} y2={-height} stroke-width={4.5} stroke="#fff" />
              );
          }
      }
      return organizedBars;
  }


  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
        setIsTooltipVisible(true);
    }, 300);
	}

	const onMouseLeave = () => {
			setIsTooltipVisible(false);
			clearTimeout(timerRef.current);
	}

	const onMouseMove = (e) => {
		const offset = 10
		const parent = wrapperRef.current;
		let bounds = parent.getBoundingClientRect();
		let x = e.clientX - bounds.left;
		let y = e.clientY - bounds.top;
		setTooltipCoords({ x: x + offset, y: y + offset })
	}

  return (
      <div
				ref={wrapperRef}
				className='relative'
				onMouseMove={onMouseMove}
				onMouseLeave={onMouseLeave}
				onMouseEnter={onMouseEnter}
			>
          <svg width={width} height={height}>
              { getBars() }
          </svg>
          <div
						style={{
								visibility: isTooltipVisible ? 'visible' : 'hidden',
								position: 'absolute',
								top: 0,
								left: 0,
								zIndex: 9999999999999,
								transform: `translate(${tooltipCoords.x + offset}px, ${tooltipCoords.y + offset}px)`
						}}
						ref={tooltipRef}
					>
							{isTooltipVisible && <TooltipTmUnitGraph
								isSingle={isSingle}
								data={data}
                                dataKey={dataKey}
							/>}
					</div>
      </div>
  )
}

export default TmSmallGraph
