export const oiColor = '#007AFF';
export const aoiColor = '#F3AF00';
export const salesColor = '#809E9B';
export const deepBlue = '#3949AB'
export const darkBlue = '#002554'

export const genderColors = {
  "Unknown": { color: '', title: 'Unknown', index: 2 },
  "Male": { color: '#002554', title: 'Male', index: 1 },
  "Female": { color: '#F59F4B', title: 'Female', index: 0 },
}

export const ethnicityColors = {
  "Unknown": { color: '', title: 'Unknown', index: 8 },
  "Two or More Races": { color: '#007AFF', title: '2+ Races', index: 7 },
  "White": { color: '#809E9B', title: 'White', index: 6 },
  "Native Hawaiian or Other Pacific Islander": { color: '#6A0D85', title: 'Pacific Islander', index: 5 },
  "I do not wish to answer.": { color: '#BD1B95', title: 'Non-Disclosed', index: 4 },
  "Hispanic or Latino": { color: '#65686B', title: 'Hispanic', index: 3 },
  'Black or African American': { color: '#002554', title: 'Black', index: 2 },
  "Asian": { color: '#14C232', title: 'Asian', index: 1 },
  "American Indian or Alaska Native": { color: '#F59F4B', title: 'American Indian', index: 0 }
}

export const ageColors = {
  "Unknown": { color: '', title: 'Unknown', index: 7 },
  "65 and over": { color: '#007AFF', title: '≥65', index: 5 },
  "55 - 64": { color: '#809E9B', title: '55-64', index: 4 },
  "45 - 54": { color: '#BD1B95', title: '45-54', index: 3 },
  "35 - 44": { color: '#002554', title: '35-44', index: 2 },
  "25 - 34": { color: '#14C232', title: '25-34', index: 1 },
  "24 and under": { color: '#F59F4B', title: '≤24', index: 0 }
}

export const tenureColors = {
  "Unknown": { color: '', title: 'unknown', index: 6 },
  "10+ Years": { color: '#007AFF', title: '10+ Years', index: 4 },
  "3 Years – 10 Years": { color: "#BD1B95", title: '3-10 Years', index: 3 },
  "1 Year – 3 Years": { color: '#002554', title: '1-3 Years', index: 2 },
  "Probationary – 1 Year": { color: '#809E9B', title: '90 Days - 1 Year', index: 1 },
  "Probationary (0-90 days)": { color: '#F59F4B', title: '≤90 Days', index: 0 }
}

export const absenteeismColors = {
  "Planned": { title: "Planned", color: '#506B67', index: 2 },
  "Unplanned": { title: "Unplanned", color: '#F3AF00', index: 1 },
  "Leave of Absence": { title: "Leave Of Absence", color: '#916600', index: 0 },
  "TotalCurrent": { title: 'Total', color: '#000', ignoreBar: true },
  "TotalLy": { title: 'Last Year', color: '#000', ignoreBar: true },
}

export const exitReasonColors = {
  "Unknown": { color: '', title: 'unknown', index: 3 },
  "Voluntary": { color: '#F59F4B', title: 'Voluntary', index: 0 },
  "Involuntary": { color: '#002554', title: 'Involuntary', index: 1 },
}

export const oshaColors = {
  'Skin Disorders': { color: '#B9007590', title: 'Skin', index: 4 },
  'Respiratory Condition': { color: '#809E9B90', title: 'Respiratory', index: 3 },
  'Hearing Loss': { color: '#00255490', title: 'Hearing', index: 2 },
  'All Other Illnesses': { color: '#F59F4B90', title: 'Illnesses', index: 1 },
  'Injury': { color: '#007AFF90', title: 'Injury', index: 0 },
  'sifCnt': { color: '#740000', title: 'SIF', index: 5 },
}

export const attritioncolors = {
  attrition: { color: "#6FB4FF", title: "attrition", index: 2 },
  Voluntary: { color: "#002554", title: "Male", index: 1, ignoreBar: true },
  Involuntary: { color: "#F59F4B", title: "Female", index: 0, ignoreBar: true },
  TotalCurrent: { title: "Total", color: "#000", ignoreBar: true },
  TotalLy: { title: "Last Year", color: "#000", ignoreBar: true },
};

export const vacanciescolors = {
  vacancies: { color: "#002544", title: "vacancies", index: 0,ignoreBar:true },
  offersextended: { color: "#F3AF00", title: "offersextended", index: 1, ignoreBar: true },
};

export const avgDaysHire = {
  avgDays: { color: "#6FB4FF", title: "attrition", index: 0 },
  TotalCurrent: { title: "Total", color: "#000", ignoreBar: true },
  TotalLy: { title: "Last Year", color: "#000", ignoreBar: true },
};
