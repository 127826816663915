import { getFillRateData } from '../services/dataService'

import { REQUEST_DATA, receiveDataGraph, onError, setTrend, receiveDataHeader, receiveDataGraphAdditional } from './valuesActions';

export const RECEIVE_UNITS = 'RECEIVE_UNITS';

export const SET_FILL_RATE_METRICS = 'SET_FILL_RATE_METRICS';
export const SET_FILL_RATE_FISC_DATE = 'SET_FILL_RATE_FISC_DATE';


export const setFillRateMetrics = (fillRateMetrics) => ({
  type: SET_FILL_RATE_METRICS,
  payload: {
    fillRateMetrics
  }
})

export const setFiscDateFillRate = (date) => ({
    type: SET_FILL_RATE_FISC_DATE,
    payload: {
        date
    }
})


const setUnits = (units) => ({
  type: RECEIVE_UNITS,
  payload: {
    units
  }
})

export const requestData = (type, qi) => ({
  type: REQUEST_DATA,
  payload: { type, qi }
})

export function setDataFillRate (params, accessToken = '') {
  return async function (dispatch) {
    dispatch(requestData(1))
    const { type, data } = await getFillRateData(params, accessToken);

    if (type === 'error') {
      dispatch(onError(data))
    } else {
      dispatch(receiveDataHeader({ header: data.headerRecord }));
      dispatch( setTrend({bsTrend: data.twoYearTrendRecord}) );
      dispatch( receiveDataGraphAdditional( data.graphSummary ) )
      dispatch(setUnits([data.segData, data.channelData]))
      dispatch( receiveDataGraph({ graphBarRecords: data.graphBarRecords }) );
    }
    return data
  }
}
