import React from 'react'

import Dropdown from './Dropdown'

const DropdownMenu = ({ anchorEl, listItems, open }) => {
  return (
    <Dropdown
      placement='bottom-end'
      anchorEl={anchorEl}
      offset={[0, 0]}
      show={open}
    >
      <div className='pt-4'>
        <div className='shadow bg-gray-100'>
          <ul className='pt-2 pb-2'>
            { 
              listItems.map(({ title, href, onClick=()=>{} }) => 
                <li key={title} className='text-right'>
                  <a 
                    onClick={onClick}
                    className='text-blue-400 text-sm font-medium p-4 inline-block hover:no-underline hover:bg-blue-400 w-full hover:bg-opacity-5'
                    href={href}
                  >
                    { title }
                  </a>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </Dropdown>
  )
}

export default DropdownMenu