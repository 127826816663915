import React from 'react'
import { Tooltip } from 'recharts'
import TooltipFinance from './TooltipFinance';
import BusinessUnitWithGraph from '../componentsGeneral/section/BusinessUnitWithGraph';
import BusinessUnitDouble from './BusinessUnitDouble';
import { isMobile } from 'react-device-detect'

const GraphDataProviderFinance = ({ 
  quarter, 
  year, 
  activeQYSwitcher,
  trend,
  type,
  ...props
}) => {

  const getGraphData = () => {
    let amountOfDots = 0;
    let filteredTrend = [];
    let trendLastYear = [];
    let startPeriod = 0;
    const lastElement = trend ? trend[trend?.length - 1] : 0;
    let isCurrentTrend;
    if(!lastElement) isCurrentTrend = false;
    else isCurrentTrend = activeQYSwitcher == 'Y' ? +lastElement.fiscYear == year : (+lastElement.fiscYear == year && +lastElement.fiscQtr == quarter);

    if(!quarter) {
        filteredTrend = trend?.filter(({fiscYear}) => parseInt(fiscYear) == year);
        trendLastYear = trend?.filter(({fiscYear}) => parseInt(fiscYear) == year-1);
        amountOfDots = 12;
        startPeriod = 1;
    }
    else {
        filteredTrend = trend?.filter(
            ({fiscYear, fiscQtr}) => (parseInt(fiscYear) == year
            && parseInt(fiscQtr) == quarter)
        )
        trendLastYear = trend?.filter(
            ({fiscYear, fiscQtr}) => (parseInt(fiscYear) == year-1
            && parseInt(fiscQtr) == quarter)
        )
        amountOfDots = Math.max(13, filteredTrend?.length);
        if(filteredTrend?.length >= 13) {
            startPeriod = filteredTrend[0]?.groupByPeriod.split('/')[1];
        }
        else {
            startPeriod = (quarter-1) * 13 + 1;
        }
    }
    let currentPeriod = startPeriod;
    const lastYearBars = [];
    let j = 0;
    for(let i = 0; i < amountOfDots; i++) {
        let iteratePeriod = 99;
        try {
            iteratePeriod = activeQYSwitcher == 'Y' ? parseInt( trendLastYear[j].groupByPeriod ) : parseInt( trendLastYear[j].groupByPeriod.split('/')[1] );
        }
        catch(err) {}
        if( (iteratePeriod != currentPeriod) ) lastYearBars.push({
            "groupByEntity": 'n',
            "fiscYear": year,
            "fiscQtr": quarter,
            "groupByPeriod": activeQYSwitcher == 'Y' ? currentPeriod : `${year}/${currentPeriod}`,
            "ebit": undefined,
            "netSales": undefined,
            "aoi": undefined,
            "volume": undefined,
            "volumePrevious": undefined,
            "isClosedWeekFlag": 1,
            "ebitPlan": 0,
            "ebitProj": 0,
            "netSalesPlan": 0,
            "netSalesProj": 0,
            "volumePlan": 0,
            "volumeProj": 0,
        });
        else {
            lastYearBars.push( trendLastYear[j] );
            j++;
        }
        currentPeriod++;
    }
    currentPeriod = startPeriod;
    j = 0;
    const result = [];
    for(let i = 0; i < amountOfDots; i++) {
        let iteratePeriod = 99;
        try {
            iteratePeriod = activeQYSwitcher == 'Y' ? parseInt( filteredTrend[j].groupByPeriod ) : parseInt( filteredTrend[j].groupByPeriod.split('/')[1] );
        }
        catch(err) {}
        if ( !filteredTrend[j] && isCurrentTrend ) result.push({});
        else if( (iteratePeriod != currentPeriod) ) result.push({
            "groupByEntity": 'n',
            "fiscYear": year,
            "fiscQtr": quarter,
            "groupByPeriod": activeQYSwitcher == 'Y' ? currentPeriod : `${year}/${currentPeriod}`,
            "ebit": 0,
            "netSales": 0,
            "aoi": 0,
            "volume": 0,
            'aoiLastYearOriginal': lastYearBars[i].aoi,
            "isClosedWeekFlag": 1,
            "ebitPlan": 0,
            "ebitProj": 0,
            'ebitLastYearOriginal': lastYearBars[i].ebit,
            "netSalesPlan": 0,
            "netSalesProj": 0,
            'netSalesLastYearOriginal': lastYearBars[i].netSales,
            "volumePlan": 0,
            "volumeProj": 0,
            'volumeLastYearOriginal': lastYearBars[i].volume
        });
        else {
            result.push( {
                ...filteredTrend[j],
                'aoiPlanOriginal': filteredTrend[j].aoiPlan,
                'aoiProjOriginal': filteredTrend[j].aoiProj,
                'ebitPlanOriginal': filteredTrend[j].ebitPlan,
                'ebitProjOriginal': filteredTrend[j].ebitProj,
                'netSalesPlanOriginal': filteredTrend[j].netSalesPlan,
                'netSalesProjOriginal': filteredTrend[j].netSalesProj,
                'volumePlanOriginal': filteredTrend[j].volumePlan,
                'volumeProjOriginal': filteredTrend[j].volumeProj,
                'aoiLastYearOriginal': lastYearBars[i].aoi,
                'ebitLastYearOriginal': lastYearBars[i].ebit,
                'netSalesLastYearOriginal': lastYearBars[i].netSales,
                'volumeLastYearOriginal': lastYearBars[i].volume
            } );
            j++;
        }
        currentPeriod++;
    }

    return result;
  }

  return (
    <>
        {type === 'double' ? <BusinessUnitDouble 
            graphData={getGraphData()}
            quarter={quarter}
            year={year}
            activeQYSwitcher={activeQYSwitcher}
            isNumberlessthan1000={true}
            {...props}
        /> : <BusinessUnitWithGraph 
            graphData={getGraphData()}
            quarter={quarter}
            year={year}
            activeQYSwitcher={activeQYSwitcher}
            needScale={true}
            isNumberlessthan1000={true}
            graphChildren={!isMobile && <Tooltip 
                allowEscapeViewBox={{y: true, x:true}}
                offset={-3}
                isAnimationActive={false}
                content={
                    <TooltipFinance 
                        currentKey={props.currentKey} 
                        groupKey={'groupByPeriod'}
                        activeQYSwitcher={activeQYSwitcher}
                        display={true}
                        isNumberlessthan1000={true}
                        bu
                    />
                }
                cursor={false}
            ></Tooltip>}
            {...props}
        />}
    </>
  )
}

export default GraphDataProviderFinance