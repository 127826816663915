import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sectionsFinance } from '../../constants/app';

import GraphSmall from './GraphSmall'
import ExpandButton from "./ExpandButton";
import {setLeftBlockExpanded} from "../../actions/app";


const ContentLeftDesktop = ({ children, graphData, graphTitle, dataKey, dataKey2, tooltip }) => {
  const dispatch = useDispatch()
  const {
    activeSection,
    leftBlockExpanded
  } = useSelector(state => ({
    activeSection: state.sections.activeSection,
    leftBlockExpanded: state.app.leftBlockExpanded
  }))

  return (
    <div
      className={'px-5 pt-5 lg:pt-0 pb-8 lg:pb-0 lg:px-0 border-r border-gray-200 overflow-visible content-units relative ' + (leftBlockExpanded ? 'expanded ' : '')}
    >
      <div className={(leftBlockExpanded ? '' : 'overflow-hidden')}>
        <div className="absolute -right-3 top-1/1">
          <ExpandButton
            cb={() => dispatch(setLeftBlockExpanded(!leftBlockExpanded))}
            isActive={leftBlockExpanded}
          />
        </div>
        {
          graphData &&
            <div>
              <h4 className='text-black text-xs font-medium mb-4'>
                { graphTitle }
              </h4>
              <GraphSmall
                data={graphData}
                dataKey={dataKey}
                dataKey2={dataKey2}
                xAxisPeriod='year'
                needAnimation={sectionsFinance.includes(activeSection)}
                className='mb-8 pr-4 pb-2 border-r-2 border-l-2 border-gray-500'
              >
                { tooltip }
              </GraphSmall>
            </div>
        }
        <div className='pr-4'>
          { children }
        </div>
      </div>
    </div>
  )
}

export default ContentLeftDesktop
