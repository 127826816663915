import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import formatNumber from '../../utils/formatNumber'
import {sectionsFillRate, fillRateTarget} from '../../constants/app'


const ValueWithDot = ({ size, value, text='', className, percents, needPercentsSign, financePercents, employeePercents, useDot, needScale=true, needSign=true, preTextVolume=false, isNumberlessthan1000 }) => {
  const {
    prependStr,
    appendStr,
    number
  } = formatNumber({ number: isNaN(value) ? 0 : value, percents: percents, needPercentsSign, financePercents, employeePercents, needScale, needSign, isNumberlessthan1000 })

  const {
    year,
    activeSection,
    activeQYSwitcher,
    fiscDateEmployee,
    fiscDateEmployeeAbsenteesim,
    oshaFiscDate,
    oshaRatesFiscDate,
    plantOpsFiscDate,
    financeFiscDate
  } = useSelector(state => ({
    sectionTitle: state.sections.sectionTitle,
    quarter: state.sections.quarter,
    year: state.sections.year,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    oshaFiscDate: state.sections.oshaFiscDate,
    oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
    plantOpsFiscDate: state.sections.plantOpsFiscDate,
    financeFiscDate: state.sections.financeFiscDate,
    activeSection: state.sections.activeSection,
    activeQYSwitcher: state.sections.activeQYSwitcher
  }))

  if(!size){
    size = 8;
  }

  const getDot = (value) => {
    if (value >= 0) return <Circle size={size}/>
     else return <Diamond size={size}/>
  }

  const getDotRates = (value) => {
    if (value <= 0) return <Circle size={size}/>
    else return <Diamond size={size}/>
  }

  const getDotBasedOnValue = (dotValue) => {
     if (dotValue*100 >= fillRateTarget) return <Circle />
    else return <Diamond />
  }

  return (
    <span className={className}>
      <span>
       { activeSection === 'VOLUME' && preTextVolume ? `Volume Growth: ` + prependStr : prependStr}{activeSection === 'OI' || activeSection === 'NET_SALES' ? '$' : ''}{number + appendStr} { text }
      </span>
      <span>
        { useDot ? 
        (activeSection === 'OSHA_RATES' ? getDotRates(value) 
        : sectionsFillRate.includes(activeSection) ? getDotBasedOnValue(value) 
        : getDot(value)) 
        : null }
      </span>
    </span>
  )
}

export default ValueWithDot
