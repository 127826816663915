import React, { useEffect, useState } from 'react';
import { setUserInfo } from '../../actions/userActions';
import { getLatestDataHeader, getLatestEmployeeMetrics, getLatestEmployeeAbsenteesimMetrics, getOshaLatesMetrics, getOshaRatesLatesMetrics, getLatestPlantOpsMetrics, getLatestNesFavBrandMetrics, getLatestFillRateMetrics } from '../../services/dataService';
import { receiveDataHeader, setCurrentDate, resetApp } from '../../actions/valuesActions'
import { setQuarter, setYear, setScrollPosition, setActiveQYSwitcher, setActiveSection } from '../../actions/sectionsActions';
import { setLatestMetrcis, setLatestMetrcisAbsenteesim, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setOshaMetrics, setOshaRatesMetrics } from '../../actions/dataActionsTeamMembers';
import { setPlantOpsMetrics, setPlantOpsFiscDate } from '../../actions/dataActionsPlantOps';
import { setNesFavBrandMetrics, setNesFavBrandFiscDate } from '../../actions/dataActionsNesFavBrand';
import { setFillRateMetrics, setFiscDateFillRate } from '../../actions/dataActionsFillRate';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setShowModal, setShowMenu } from '../../actions/app';
import roundFunction from '../../utils/roundFunction';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import { financeErrorData, fillRateTarget } from '../../constants/app'

import AppLayout from '../../componentsGeneral/AppLayout'
import ItemsContainer from '../../homeScreenComponents/general/ItemsContainer'
import ItemsContainerCollapsible from '../../homeScreenComponents/general/ItemsContainerCollapsible'
import ItemDouble from '../../homeScreenComponents/general/ItemDouble'
import Item from '../../homeScreenComponents/general/Item'
import Collapsible from '../../homeScreenComponents/general/Collapsible'
import Section from '../../homeScreenComponents/general/Section'
import SectionRow from '../../homeScreenComponents/general/SectionRow';

import formatFiscDate from '../../utils/formatFiscDate'
import formatFiscDateLastYear from '../../utils/formatFiscDateLastYear'
import formatNumber from '../../utils/formatNumber'

import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import toClassName from '../../utils/toClassName';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LoadingBlocker from '../../components/LoadingBlocker/LoadingBlocker';

const HomeOperations = (props) => {
    const { height, width } = useWindowDimensions()

    const { authState } = useOktaAuth()
    const { accessToken: { accessToken } } = authState
    const [isLoading, setIsLoading] = useState(false)

    const getDataFinance = async (initial) => {
        const { receiveDataHeader, setQuarter, setCurrentDate, setYear, setScrollPosition } = props;

        let { data, type } = await getLatestDataHeader('', accessToken);

        if (type === 'success') {
            const date = data.date[0];
            data = data.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
        else {
            // setTimeout(async () => {
            //     let { data, type } = await getLatestDataHeader('', accessToken);
            //     if (type === 'success') {
            //         const date = data.date[0];
            //         data = data.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     } else {
            //         const date = financeErrorData.date[0];
            //         data = financeErrorData.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     }
            // }, 1000)
            const date = financeErrorData.date[0];
            data = financeErrorData.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
    }


    const employeeSuccessHandler = ({ data }) => {
        const { setLatestMetrcis } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                exitEmployees: employeeInfo.exitEmployees,
                avgActiveEmployees: roundFunction(employeeInfo.avgActiveEmployees, 'B'),
                turnoverRate: employeeInfo.turnoverRate,
            }
            setLatestMetrcis(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployee = async () => {
        const { setLatestMetrcis } = props;
        const { data, type } = await getLatestEmployeeMetrics({ segment: '', accessToken });
        if (type === 'success') {
            employeeSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         employeeSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcis('Error');
            //     }
            // }, 1000)
            setLatestMetrcis('Error');
        }
    }

    const nesFavBrandSuccessHandler = ({ data }) => {
        const { setNesFavBrandMetrics } = props;
        try {
            const { consumerInfo } = data;
            let metrcis = {};
            metrcis = {
                netExperienceScore: consumerInfo.netExperienceScore,
                favoriteBrand: consumerInfo.favoriteBrand,
            }
            setNesFavBrandMetrics(metrcis);
        } catch (e) {
            console.error(e, 'NES & Fav Brand error');
        }
    }

    const getDataNesFavBrand = async () => {
        const { setNesFavBrandMetrics } = props;
        const { data, type } = await getLatestNesFavBrandMetrics({ segment: '', accessToken });
        if (type === 'success') {
            nesFavBrandSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestNesFavBrandMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         nesFavBrandSuccessHandler({ data });
            //     } else {
            //         setNesFavBrandMetrics('Error');
            //     }
            // }, 1000)
            setNesFavBrandMetrics('Error');
        }
    }

    const fillRateSuccessHandler = ({ data }) => {
        const { setFillRateMetrics } = props;
        try {
            const { consumerFillRateInfo } = data;
            let metrcis = {};
            metrcis = {
                fillRate: consumerFillRateInfo.overallFillRate,
            }
            setFillRateMetrics(metrcis);
        } catch (e) {
            console.error(e, 'NES & Fav Brand error');
        }
    }

    const getDataFillRate = async () => {
        const { setFillRateMetrics } = props;
        const { data, type } = await getLatestFillRateMetrics({ segment: '', accessToken });
        if (type === 'success') {
            fillRateSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestFillRateMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         fillRateSuccessHandler({ data });
            //     } else {
            //         setFillRateMetrics('Error');
            //     }
            // }, 1000)
            setFillRateMetrics('Error');
        }
    }

    const employeeAbsenteesimSuccessHandler = ({ data }) => {
        const { setLatestMetrcisAbsenteesim } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                absenteeism: employeeInfo.absenteeism,
            }
            setLatestMetrcisAbsenteesim(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployeeAbsenteesim = async () => {
        const { setLatestMetrcisAbsenteesim } = props;
        const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '', accessToken });
        if (type === 'success') {
            employeeAbsenteesimSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         employeeAbsenteesimSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcisAbsenteesim('Error');
            //     }
            // }, 1000)
            setLatestMetrcisAbsenteesim('Error');
        }
    }

    const oshaSuccessHandler = ({ data }) => {
        const { setOshaMetrics } = props;
        try {
            let metrics = {};
            const { employeeInfo } = data;
            metrics = {
                "countRecorded": employeeInfo.countRecorded,
            }
            setOshaMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Record. error');
        }
    }

    const getDataOsha = async () => {
        const { setOshaMetrics } = props;
        const { data, type } = await getOshaLatesMetrics({ segment: '', accessToken });
        if (type === 'success') {
            oshaSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaLatesMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         oshaSuccessHandler({ data });
            //     } else {
            //         setOshaMetrics('Error');
            //     }
            // }, 1000)
            setOshaMetrics('Error');
        }
    }

    const oshaRatesSuccessHandler = ({ data }) => {
        const { setOshaRatesMetrics } = props;
        try {
            let metrics = {};
            const { employeeInfo } = data;
            metrics = {
                "rate": (employeeInfo.rate) ? (employeeInfo.rate) : 0,
                "ratePY": (employeeInfo.rate) ? (employeeInfo.ratePY) : 0
            }
            setOshaRatesMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Rates error');
        }
    }

    const getDataOshaRates = async () => {
        const { setOshaRatesMetrics } = props;
        const { data, type } = await getOshaRatesLatesMetrics({ segment: '', accessToken });
        if (type === 'success') {
            oshaRatesSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaRatesLatesMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         oshaRatesSuccessHandler({ data });
            //     } else {
            //         setOshaRatesMetrics('Error');
            //     }
            // }, 1000)
            setOshaRatesMetrics('Error');
        }
    }

    const plantOpsSuccessHandler = ({ data }) => {
        const { setPlantOpsMetrics } = props;
        try {
            let metrics = {};
            const { Poultry, Beef, Pork, PPI, Prepared, McDonalds } = data;
            const poultry0 = "CapacityUtilisation Harvesting";
            const poultry1 = "Efficiency Harvesting";
            const poultry2 = "CapacityUtilisation Processing";
            const poultry3 = "Efficiency Processing";
            const CapacityRecorded0 = Poultry[0].CapacityRecorded;
            const CapacityRecorded1 = Poultry[1].CapacityRecorded;
            const CapacityRecorded2 = Poultry[2].CapacityRecorded;
            const CapacityRecorded3 = Poultry[3].CapacityRecorded;
            const CapacityType0 = Poultry[0].CapacityType;
            const CapacityType1 = Poultry[1].CapacityType;
            const CapacityType2 = Poultry[2].CapacityType;
            const CapacityType3 = Poultry[3].CapacityType;

            const pork0 = "CapacityUtilisation Harvesting";
            const pork1 = "Efficiency Harvesting";
            const pork2 = "CapacityUtilisation Processing";
            const pork3 = "Efficiency Processing";
            const CapacityRecordedPork0 = Pork[0].CapacityRecorded;
            const CapacityRecordedPork1 = Pork[1].CapacityRecorded;
            const CapacityRecordedPork2 = Pork[2].CapacityRecorded;
            const CapacityRecordedPork3 = Pork[3].CapacityRecorded;
            const CapacityTypePork0 = Pork[0].CapacityType;
            const CapacityTypePork1 = Pork[1].CapacityType;
            const CapacityTypePork2 = Pork[2].CapacityType;
            const CapacityTypePork3 = Pork[3].CapacityType;

            metrics = {
                "PoultryCUHar": CapacityType0 == poultry0 ? CapacityRecorded0
                    : CapacityType1 == poultry0 ? CapacityRecorded1
                        : CapacityType2 == poultry0 ? CapacityRecorded2
                            : CapacityType3 == poultry0 ? CapacityRecorded3 : 0,
                "PoultryEfficiencyHar": CapacityType0 === poultry1 ? CapacityRecorded0
                    : CapacityType1 === poultry1 ? CapacityRecorded1
                        : CapacityType2 === poultry1 ? CapacityRecorded2
                            : CapacityType3 === poultry1 ? CapacityRecorded3 : 0,
                "PoultryCUPro": CapacityType0 === poultry2 ? CapacityRecorded0
                    : CapacityType1 === poultry2 ? CapacityRecorded1
                        : CapacityType2 === poultry2 ? CapacityRecorded2
                            : CapacityType3 === poultry2 ? CapacityRecorded3 : 0,
                "PoultryEfficiencyPro": CapacityType0 === poultry3 ? CapacityRecorded0
                    : CapacityType1 === poultry3 ? CapacityRecorded1
                        : CapacityType2 === poultry3 ? CapacityRecorded2
                            : CapacityType3 === poultry3 ? CapacityRecorded3 : 0,

                "BeefCUHar": Beef[0].CapacityType === "CapacityUtilisation Harvesting" ? (Beef[0].CapacityRecorded) : (Beef[1].CapacityRecorded),
                "BeefEfficiencyHar": Beef[1].CapacityType === "Efficiency Harvesting" ? (Beef[1].CapacityRecorded) : (Beef[0].CapacityRecorded),

                "PorkCUHar": CapacityTypePork0 == pork0 ? CapacityRecordedPork0
                    : CapacityTypePork1 == pork0 ? CapacityRecordedPork1
                        : CapacityTypePork2 == pork0 ? CapacityRecordedPork2
                            : CapacityTypePork3 == pork0 ? CapacityRecordedPork3 : 0,
                "PorkEfficiencyHar": CapacityTypePork0 === pork1 ? CapacityRecordedPork0
                    : CapacityTypePork1 === pork1 ? CapacityRecordedPork1
                        : CapacityTypePork2 === pork1 ? CapacityRecordedPork2
                            : CapacityTypePork3 === pork1 ? CapacityRecordedPork3 : 0,
                "PorkCUPro": CapacityTypePork0 === pork2 ? CapacityRecordedPork0
                    : CapacityTypePork1 === pork2 ? CapacityRecordedPork1
                        : CapacityTypePork2 === pork2 ? CapacityRecordedPork2
                            : CapacityTypePork3 === pork2 ? CapacityRecordedPork3 : 0,
                "PorkEfficiencyPro": CapacityTypePork0 === pork3 ? CapacityRecordedPork0
                    : CapacityTypePork1 === pork3 ? CapacityRecordedPork1
                        : CapacityTypePork2 === pork3 ? CapacityRecordedPork2
                            : CapacityTypePork3 === pork3 ? CapacityRecordedPork3 : 0,

                "PPICUPro": PPI[0].CapacityType === "CapacityUtilisation Processing" ? (PPI[0].CapacityRecorded) : (PPI[1].CapacityRecorded),
                "PPIEfficiencyPro": PPI[1].CapacityType === "Efficiency Processing" ? (PPI[1].CapacityRecorded) : (PPI[0].CapacityRecorded),

                "PreparedCUPro": Prepared[0].CapacityType === "CapacityUtilisation Processing" ? (Prepared[0].CapacityRecorded) : (Prepared[1].CapacityRecorded),
                "PreparedEfficiencyPro": Prepared[1].CapacityType === "Efficiency Processing" ? (Prepared[1].CapacityRecorded) : (Prepared[0].CapacityRecorded),

                "McdCUPro": McDonalds[0].CapacityType === "CapacityUtilisation Processing" ? (McDonalds[0].CapacityRecorded) : (McDonalds[1].CapacityRecorded),
                "McdEfficiencyPro": McDonalds[1].CapacityType === "Efficiency Processing" ? (McDonalds[1].CapacityRecorded) : (McDonalds[0].CapacityRecorded),
            }
            setPlantOpsMetrics(metrics);
        } catch (e) {
            console.error(e, 'Plant Ops error');
        }
    }

    const getDataPlantOps = async () => {
        const { setPlantOpsMetrics } = props;
        const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
        if (type === 'success') {
            plantOpsSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
            //     if (type === 'success') {
            //         plantOpsSuccessHandler({ data });
            //     } else {
            //         setPlantOpsMetrics('Error');
            //     }
            // }, 1000)
            setPlantOpsMetrics('Error');
        }
    }

    const requestData = async (initial) => {
        setIsLoading(true)
        Promise.all([
            getDataFinance(initial),
            getDataEmployee(),
            getDataEmployeeAbsenteesim(),
            getDataOsha(),
            getDataOshaRates(),
            getDataPlantOps(),
            getDataNesFavBrand(),
            getDataFillRate(),
        ]).then((values) => {
            setIsLoading(false)
        })
            .catch(e => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const { resetApp } = props;
        resetApp();
        requestData();
    }, []);

    const getDot = (value) => {
        if (value > 0) return <Circle />
        else if (value == 0) return null
        else return <Diamond />
    }

    const getDotForOshaRates = (value) => {
        if (value < 0) return <Circle />
        else return <Diamond />
    }

    const getDotBasedOnValue = (value) => {
        if (value >= fillRateTarget) return <Circle />
        else return <Diamond />
    }

    const generateDot = (title, value, classes = []) => {
        return <span className={toClassName(['inline-flex items-center leading-0', ...classes])}>
            <span className='text-xs font-medium mr-1 leading-tight'>{title}</span>{getDot(value)}
        </span>
    }

    const {
        metrics,
        absenteesimMetrics,
        plantOpsMetrics,
        nesFavBrandMetrics,
        fillRateMetrics,
        fiscDateEmployee,
        fiscDateEmployeeAbsenteesim,
        oshaRatesMetrics,
        oshaMetrics,
        oshaRatesFiscDate,
        oshaFiscDate,
        plantOpsFiscDate,
        fiscDateNesFavBrand,
        fiscDateFillRate,
        quarter,
        year,
        header,
        userPrivilages
    } = props

    const history = useHistory()
    const dispatch = useDispatch()

    const switchView = (view) => {
        const links = {
            'CHICKEN': '/poultry',
            'PREPARED FOODS': '/prepared',
            'FRESH MEATS': '/freshMeats',
            'MCDONALDS': '/McDonalds',
            'HROSHA': '/hrosha',
            'OPERATIONS': '/operations',
            'OPERATIONS_POULTRY': '/operations/poultry',
            'OPERATIONS_PREPARED': '/operations/prepared',
            'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
            'OPERATIONS_MCDONALDS': '/operations/McDonalds',
            'IT_USER': '/information-technology',
            'false': '/'
        }

        dispatch({
            type: 'SET_USER_PRIVILAGES',
            payload: {
                userPrivilages: {
                    ...userPrivilages,
                    view
                }
            }
        })

        history.push(links[view.toString()])
    }

    const navigateAction = ({ href, section, tab }) => {
        console.log({ href, section, tab }, ' @R@R@RR')
        dispatch(setActiveSection(section, tab))

        history.push(href)
    }

    const formatNumberString = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    const formatNumberStringTeamMembers = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    return (
        <ScreenWrapper home landingPage>
            <LoadingBlocker loading={isLoading} />
            <AppLayout keepPaddings>
                <div className='flex gap-x-6 flex-wrap'>

                    <ItemsContainer title='FINANCE'>
                        <ItemDouble>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/finance', section: 'VOLUME' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Volume'
                                value={header.volume === 1112223334445556 ? 'Error' : isNaN(header.volume) ? 0 : formatNumberString({ number: header.volume, needScale: true, remove$: true, needLBS: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', header.volume - header.volumeProj),
                                        generateDot('AOP ', header.volume - header.volumePlan, ['ml-2'])
                                    ]
                                }
                            />
                            <div className='w-px bg-gray-400 h-20'></div>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/finance', section: 'VOLUME' })}
                                date={<span className='opacity-0'>0</span>}
                                title='Growth'
                                value={header.volume === 1112223334445556 ? 'Error' : isNaN(header.volume) ? 0 : formatNumberString({ number: (header.volume - header.volumePrevious) / header.volumePrevious, financePercents: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (header.volume - header.volumePrevious) / header.volumePrevious)
                                    ]
                                }
                            />
                        </ItemDouble>
                    </ItemsContainer>

                    {width > 900 ?
                        <ItemsContainer title='CUSTOMER/CONSUMER'>
                            <Item
                                variant='vertical-lg'
                                onClick={() => navigateAction({ href: '/r/fill-rate', section: 'OVERALL_FILL_RATE' })}
                                date={!fiscDateFillRate.year ? 'Error' : formatFiscDate({ quarter: fiscDateFillRate.quarter, year: fiscDateFillRate.year })}
                                title='Poultry & Prepared Fill Rate'
                                value={<>{fillRateMetrics === 'Error' ? 'Error' : isNaN(fillRateMetrics.fillRate * 100) ? 0+'%' : formatNumberStringTeamMembers({ number: fillRateMetrics.fillRate, employeePercents: true })} {fillRateMetrics === 'Error' ? '' : getDotBasedOnValue(fillRateMetrics.fillRate)}</>}
                            />
                            <ItemDouble >
                                <Item
                                    variant='double'
                                    onClick={() => navigateAction({ href: '/r/nes-fav-brand', section: 'NES_FAVORITE_BRAND' })}
                                    date={!fiscDateNesFavBrand.year ? 'Error' : formatFiscDate({ quarter: fiscDateNesFavBrand.quarter, year: fiscDateNesFavBrand.year })}
                                    title='Net Experience Score'
                                    value={nesFavBrandMetrics === 'Error' ? 'Error' : isNaN(nesFavBrandMetrics.netExperienceScore * 100) ? 0 : formatNumberStringTeamMembers({ number: nesFavBrandMetrics.netExperienceScore })}
                                />
                                <div className='w-px bg-gray-400 h-20'></div>
                                <Item
                                    variant='double'
                                    onClick={() => navigateAction({ href: '/r/nes-fav-brand', section: 'NES_FAVORITE_BRAND' })}
                                    date={!fiscDateNesFavBrand.year ? 'Error' : formatFiscDate({ year: fiscDateNesFavBrand.year }) + ` YTD`}
                                    title='Favorite Brand'
                                    value={nesFavBrandMetrics === 'Error' ? 'Error' : isNaN(nesFavBrandMetrics.favoriteBrand * 100) ? 0 : formatNumberStringTeamMembers({ number: nesFavBrandMetrics.favoriteBrand })}
                                />
                            </ItemDouble>
                        </ItemsContainer>
                        :

                        <ItemsContainerCollapsible
                            title='CUSTOMER/CONSUMER'
                            itemsCollapsible={[
                                <Item
                                    onClick={() => navigateAction({ href: '/r/fill-rate', section: 'OVERALL_FILL_RATE' })}
                                    date={!fiscDateFillRate.year ? 'Error' : formatFiscDate({ quarter: fiscDateFillRate.quarter, year: fiscDateFillRate.year })}
                                    title='Poultry & Prepared Fill Rate'
                                value={<>{fillRateMetrics === 'Error' ? 'Error' : isNaN(fillRateMetrics.fillRate * 100) ? 0+'%' : formatNumberStringTeamMembers({ number: fillRateMetrics.fillRate, employeePercents: true })} {fillRateMetrics === 'Error' ? '' : getDotBasedOnValue(fillRateMetrics.fillRate)}</>}
                                    />,
                                <ItemDouble >
                                    <Item
                                        variant='double'
                                        onClick={() => navigateAction({ href: '/r/nes-fav-brand', section: 'NES_FAVORITE_BRAND' })}
                                        date={!fiscDateNesFavBrand.year ? 'Error' : formatFiscDate({ quarter: fiscDateNesFavBrand.quarter, year: fiscDateNesFavBrand.year })}
                                        title='Net Experience Score'
                                        value={nesFavBrandMetrics === 'Error' ? 'Error' : isNaN(nesFavBrandMetrics.netExperienceScore * 100) ? 0 : formatNumberStringTeamMembers({ number: nesFavBrandMetrics.netExperienceScore })}
                                    />
                                    <div className='w-px bg-gray-400 h-20'></div>
                                    <Item
                                        variant='double'
                                        onClick={() => navigateAction({ href: '/r/nes-fav-brand', section: 'NES_FAVORITE_BRAND' })}
                                        date={!fiscDateNesFavBrand.year ? 'Error' : formatFiscDate({ year: fiscDateNesFavBrand.year }) + ` YTD`}
                                        title='Favorite Brand'
                                        value={nesFavBrandMetrics === 'Error' ? 'Error' : isNaN(nesFavBrandMetrics.favoriteBrand * 100) ? 0 : formatNumberStringTeamMembers({ number: nesFavBrandMetrics.favoriteBrand })}
                                    />
                                </ItemDouble>
                            ]}
                        >
                        </ItemsContainerCollapsible>
                    }

                    {/* <ItemsContainer title='CUSTOMER/CONSUMER'>
                        <ItemDouble>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/nes-fav-brand', section: 'NES_FAVORITE_BRAND' })}
                                date={!fiscDateNesFavBrand.year ? 'Error' : formatFiscDate({ quarter: fiscDateNesFavBrand.quarter, year: fiscDateNesFavBrand.year })}
                                title='Net Experience Score'
                                value={nesFavBrandMetrics === 'Error' ? 'Error' : isNaN(nesFavBrandMetrics.netExperienceScore * 100) ? 0 : formatNumberStringTeamMembers({ number: nesFavBrandMetrics.netExperienceScore })}
                            />
                            <div className='w-px bg-gray-400 h-20'></div>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/nes-fav-brand', section: 'NES_FAVORITE_BRAND' })}
                                date={!fiscDateNesFavBrand.year ? 'Error' : formatFiscDate({ year: fiscDateNesFavBrand.year }) + ` YTD`}
                                title='Favorite Brand'
                                value={nesFavBrandMetrics === 'Error' ? 'Error' : isNaN(nesFavBrandMetrics.favoriteBrand * 100) ? 0 : formatNumberStringTeamMembers({ number: nesFavBrandMetrics.favoriteBrand })}
                            />
                        </ItemDouble>
                    </ItemsContainer> */}

                    <ItemsContainer title='TEAM MEMBERS'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/team-members', section: 'TEAM_MEMBERS' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ month: fiscDateEmployee.month, year: fiscDateEmployee.year })}
                            title='Team Members'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.avgActiveEmployees) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.avgActiveEmployees) ? 0 : metrics.avgActiveEmployees })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/team-members', section: 'TURNOVER_RATE' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ year: fiscDateEmployee.year })}
                            title='Turnover Rate'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.turnoverRate * 100) ? 0 : formatNumberStringTeamMembers({ number: metrics.turnoverRate * 100, employeePercents: true })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/team-members', section: 'ABSENTEEISM' })}
                            date={!fiscDateEmployeeAbsenteesim.year ? 'Error' : formatFiscDate({ year: fiscDateEmployeeAbsenteesim.year })}
                            title='Absenteeism'
                            value={absenteesimMetrics === 'Error' ? 'Error' : isNaN(absenteesimMetrics.absenteeism * 100) ? 0 : formatNumberStringTeamMembers({ number: absenteesimMetrics.absenteeism, employeePercents: true })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title='OSHA'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/osha', section: 'OSHA_RATES' })}
                            date={!oshaRatesFiscDate.year ? 'Error' : formatFiscDate({ year: oshaRatesFiscDate.year }) + ` YTD`}
                            title='Rates'
                            value={<>{oshaRatesMetrics === 'Error' ? 'Error' : isNaN(oshaRatesMetrics.rate) ? 0 : formatNumberString({ number: oshaRatesMetrics.rate })} {oshaRatesMetrics === 'Error' ? '' : getDotForOshaRates(oshaRatesMetrics.rate - oshaRatesMetrics.ratePY)}</>}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/osha', section: 'OSHA_RECORDABLES' })}
                            date={!oshaFiscDate.year ? 'Error' : formatFiscDate({ quarter: oshaFiscDate.quarter, year: oshaFiscDate.year })}
                            title='Recordables'
                            value={oshaMetrics === 'Error' ? 'Error' : isNaN(oshaMetrics.countRecorded) ? 0 : formatNumberString({ number: oshaMetrics.countRecorded })}
                        />
                    </ItemsContainer>
                </div>
                <Section title='OPERATIONS'>
                    <SectionRow>
                        <Collapsible
                            title='POULTRY'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                            dashboardClickHandler={() => switchView('OPERATIONS_POULTRY')}
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Poultry', tab: 'CapacityUtilisation Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryCUHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryCUHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Poultry', tab: 'Efficiency Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryEfficiencyHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryEfficiencyHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            {/* <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Poultry', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Poultry', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            /> */}
                        </Collapsible>


                        <Collapsible
                            title='PREPARED'
                            //subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                            dashboardClickHandler={() => switchView('OPERATIONS_PREPARED')}
                        >
                            {/* <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Prepared', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PreparedCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PreparedCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Prepared', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PreparedEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PreparedEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            /> */}
                        </Collapsible>

                        <Collapsible
                            title="MCDONALD'S"
                            //subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                            dashboardClickHandler={() => switchView('OPERATIONS_MCDONALDS')}
                        >
                            {/* <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'McDonalds', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.McdCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.McdCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'McDonalds', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.McdEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.McdEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            /> */}
                        </Collapsible>
                    </SectionRow>

                    <SectionRow>
                        <Collapsible
                            title='PORK'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                            dashboardClickHandler={() => switchView('OPERATIONS_FRESH_MEATS')}
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Pork', tab: 'CapacityUtilisation Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkCUHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkCUHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Pork', tab: 'Efficiency Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkEfficiencyHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkEfficiencyHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            {/* <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Pork', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Pork', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            /> */}
                        </Collapsible>

                        <Collapsible
                            title='BEEF'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                            dashboardClickHandler={() => switchView('OPERATIONS_FRESH_MEATS')}
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Beef', tab: 'CapacityUtilisation Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.BeefCUHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.BeefCUHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'Beef', tab: 'Efficiency Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.BeefEfficiencyHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.BeefEfficiencyHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />
                        </Collapsible>


                        {/* <Collapsible
                            title='PPI'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                            dashboardClickHandler={() => switchView('FRESH MEATS')}
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'PPI', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PPICUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PPICUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/plant-ops', section: 'PPI', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PPIEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PPIEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />
                        </Collapsible> */}
                    </SectionRow>
                </Section>
            </AppLayout>
        </ScreenWrapper>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.userState.userInfo,
        activeQYSwitcher: state.sections.activeQYSwitcher,
        activeSection: state.sections.activeSection,
        currentDate: state.values.currentDate,
        isSingle: state.sections.isSingle,
        header: state.values.header,
        year: state.sections.year,
        quarter: state.sections.quarter,
        activeTab: state.sections.activeTab,
        activeBu: state.sections.activeBu,
        currentQuarter: state.sections.currentQuarter,
        isHeaderLoading: state.values.isHeaderLoading,
        showModal: state.app.showModal,
        userPrivilages: state.userState.userPrivilages,
        metrics: state.values.metrics,
        absenteesimMetrics: state.values.absenteesimMetrics,
        fiscDateEmployee: state.sections.fiscDateEmployee,
        fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
        oshaFiscDate: state.sections.oshaFiscDate,
        oshaMetrics: state.values.oshaMetrics,
        oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
        oshaRatesMetrics: state.values.oshaRatesMetrics,
        plantOpsFiscDate: state.sections.plantOpsFiscDate,
        plantOpsMetrics: state.values.plantOpsMetrics,
        nesFavBrandMetrics: state.values.nesFavBrandMetrics,
        fiscDateNesFavBrand: state.sections.fiscDateNesFavBrand,
        fillRateMetrics: state.values.fillRateMetrics,
        fiscDateFillRate: state.sections.fiscDateFillRate,
        units: state.values.units
    }
}
export default connect(
    mapStateToProps,
    {
        setLatestMetrcis, setLatestMetrcisAbsenteesim, resetApp, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim,
        setOshaMetrics, setOshaRatesMetrics, setNesFavBrandMetrics, setFillRateMetrics,
        setUserInfo, setShowModal, setShowMenu, setPlantOpsMetrics,
        receiveDataHeader, setCurrentDate, setQuarter, setYear, setScrollPosition, setActiveQYSwitcher
    }
)(HomeOperations)
