import React from 'react'
import { useSelector } from 'react-redux'

import CurrentScreenValues from '../componentsGeneral/section/CurrentScreenValues'
import { oshaColors } from '../constants/colors'
import formatNumber from '../utils/formatNumber'
import toClassName from '../utils/toClassName'


const CurrentScreenValuesPlantOps = ({ currentKey }) => {
  const {
    header,
    activeTabTitle,
    dataGraphAdditional,
    activeBu,
    activeTab,
    activeSection
  } = useSelector(state => ({
    header: state.values.header,
    activeTabTitle: state.sections.activeTabTitle,
    dataGraphAdditional: state.values.dataGraphAdditional,
    activeBu: state.sections.activeBu,
    activeTab: state.sections.activeTab,
    activeSection: state.sections.activeSection
  }))


  const getMainValue = () => {
    if (!Array.isArray(header)) return 0
  
    return isNaN(dataGraphAdditional.CapacityRecorded) || dataGraphAdditional.CapacityRecorded === null ? 0 : dataGraphAdditional.CapacityRecorded
  }

  const formatValue = ({ appendStr, prependStr, number }) => {
    return `${prependStr}${number}${appendStr}`
  }
  
  const getBottomItem = (title, value, appendStr) => {
    return (<div key={title} className='text-sm text-black font-medium'>
      <span>{ title }: </span>
      <span>
        { value } { appendStr }
      </span>
    </div>)
  }

  console.log("dataGraphAdditional.CapacityRecorded", dataGraphAdditional.CapacityRecorded)

  const getBottomValues = () => {
    if (activeTab && activeTab.includes('CapacityUtilisation')) return [
      getBottomItem(activeTab.includes('CapacityUtilisation Processing') ? 'Actual Processing: ' : 'Actual Harvested: ', formatValue(formatNumber({ number: dataGraphAdditional.actual, remove$: true, needScale: false })), (activeTab.includes('Processing') && activeSection === 'PPI') ? 'LBS' :  activeTab.includes('Harvesting') ? ` Heads` : ` Hours` ),
      getBottomItem('Utilization Rate: ', formatValue(formatNumber({ number: dataGraphAdditional.CapacityRecorded, employeePercents: true })), 
      activeTab.includes('CapacityUtilisation Processing') && activeSection === 'PPI' ? 
      `(to ${(isNaN(dataGraphAdditional.available) ? 0 : formatValue(formatNumber({ number: isNaN(dataGraphAdditional.available) || dataGraphAdditional.available === null ? 0 : dataGraphAdditional.available, remove$: true, needScale: false }) ))} Available Capacity LBS)` 
      :
      activeTab.includes('CapacityUtilisation Processing') ? 
      `(to ${(isNaN(dataGraphAdditional.available) ? 0 : formatValue(formatNumber({ number: isNaN(dataGraphAdditional.available) || dataGraphAdditional.available === null ? 0 : dataGraphAdditional.available, remove$: true, needScale: false })))} Available Capacity Hours)` 
      :
      `(to ${(isNaN(dataGraphAdditional.available) ? 0 : formatValue(formatNumber({ number: isNaN(dataGraphAdditional.available) || dataGraphAdditional.available === null ? 0 : dataGraphAdditional.available, remove$: true, needScale: false })))} Head Harvested Capacity)`)
    ]
    
    return null
  }

  return (
    <CurrentScreenValues 
      value={getMainValue()}
      employeePercents={true}
      needSign={false}
      defaultTitle={activeSection.toUpperCase()}
      title={activeBu.length ? activeBu[activeBu.length - 1] : activeTabTitle}
      includeDot={false}
      prependClasses='self-start'
    >
      <div className='mt-2 flex flex-col flex-wrap items-start justify-start px-4'>
        { getBottomValues() }
      </div>
    </CurrentScreenValues>
  )
}

export default CurrentScreenValuesPlantOps