import React, { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import classes from './styles.module.css'

const HANDLED_ERRORS = [
  'AuthSdkError',
  'AuthApiError'
]
const OktaErrorComponent = props => {
  const { oktaAuth, authState } = useOktaAuth()
  const { error } = props
  const history = useHistory()

  useEffect(() => {
    //console.log('OktaErrorComponent props', props)
    if (HANDLED_ERRORS.indexOf(error.name) !== -1) {
      history.replace('/')
    }
  })

  const onBackClick = e => {
    history.replace('/')
  }

  return (
    <div className={classes.rowContainerCenter} style={{ minHeight: '100vh' }}>
      <div className={classes.colContainerCenter}>
        <p style={{ marginBottom: 16 }}>Error: {error.toString()}</p>
        <button onClick={onBackClick} className={classes.btn}>
          Back
        </button>
      </div>
    </div>
  )
}
export default OktaErrorComponent
