export const SET_MODAL_SHOW = "SET_MODAL_SHOW";
export const SET_SHOW_MENU = "SET_SHOW_MENU";
export const SET_REVENUE_DATA_REQUESTED = "SET_REVENUE_DATA_REQUESTED";
export const SET_APP_DATA = "SET_APP_DATA";
export const SET_LEFT_BLOCK_EXPANDED = "SET_LEFT_BLOCK_EXPANDED";
export const DESKTOP_VIEW_BREAKPOINT = 900;
export const week53thYear = 2020;
export const week53thQuarter = 4;
export const week53thMonth = 12;

export const addNoteQuarter = 4;
export const addNoteYear = 2021;

export const fillRateTarget = 98.5;

export const OSHA_SECTION = "OSHA_RECORDABLES";
export const OSHA_RATES_SECTION = "OSHA_RATES";

export const TURNOVER_RATE_SECTION = "TURNOVER_RATE";
export const TEAM_MEMBERS_SECTION = "TEAM_MEMBERS";
export const ABSENTEEISM_SECTION = "ABSENTEEISM";

export const PLANT_OPS_SECTION = "PLANT_OPS";

export const IT_FINANCE_DASHBOARD = "IT_DASHBOARD";
export const IT_FINANCE_ACTUALS = "IT_FINANCE_ACTUALS";
export const IT_FINANCE_COMPENSATION = "IT_FINANCE_COMPENSATION";
export const IT_FINANCE_CONTROLLABLES = "IT_FINANCE_CONTROLLABLES";
export const IT_FINANCE_CLOUD = "IT_FINANCE_CLOUD";
export const IT_FINANCE_NonControllables = "IT_FINANCE_NonControllables";

export const IT_Hr_TEAM_MEMBERS = "IT_Hr_TEAM_MEMBERS";
export const IT_Hr_TOTAL_ATTRITION = "IT_Hr_TOTAL_ATTRITION";
export const IT_Hr_Vacancies = "IT_Hr_Vacancies"
export const IT_Hr_Positions = "IT_Hr_Positions"
export const IT_Hr_AvgDaysHire = "IT_Hr_AvgDaysHire"

export const sectionsFinance = ['AOI', 'OI', 'NET_SALES', 'VOLUME'];
export const sectionsEmployees = [TEAM_MEMBERS_SECTION, TURNOVER_RATE_SECTION, ABSENTEEISM_SECTION];
export const sectionsOsha = ['OSHA_RATES', 'OSHA_RECORDABLES']
export const sectionsPlantOps = ['Poultry','Beef','Pork','PPI', 'Prepared', "McDonalds"]
export const sectionsFillRate = ['Overall Fill Rate', 'Top 10 Customers', 'Top 10 Shorted Customers', 'Top 10 Shorted Categories'];

export const disableQuarter = [
  TEAM_MEMBERS_SECTION,
  TURNOVER_RATE_SECTION,
  OSHA_RATES_SECTION,
  IT_Hr_TEAM_MEMBERS,
  IT_Hr_TOTAL_ATTRITION,
  IT_Hr_Vacancies,
  IT_Hr_Positions,
  IT_Hr_AvgDaysHire
];

export const tabsFinance = [
  "chicken",
  "prepared foods",
  "beef",
  "pork",
  "intersegment",
  "international",
];

export const tabsTeamMembers = [
  "Gender",
  "Ethnicity",
  "AgeGroup",
  "Tenure",
  "ExitReason",
];
export const mutualTabsTeamMembers = [
  "Gender",
  "Ethnicity",
  "AgeGroup",
  "Tenure",
];

export const PoultryTabsPlantOps = [
  "CapacityUtilisation Harvesting",
  "Efficiency Harvesting",
  "CapacityUtilisation Processing",
  "Efficiency Processing",
];

export const BeefTabsPlantOps = [
  "CapacityUtilisation Harvesting",
  "Efficiency Harvesting",
];

export const PorkTabsPlantOps = [
  "CapacityUtilisation Harvesting",
  "Efficiency Harvesting",
];

export const PPITabsPlantOps = [
  "CapacityUtilisation Processing",
  "Efficiency Processing",
];

export const PreparedTabsPlantOps = [
  "CapacityUtilisation Processing",
  "Efficiency Processing",
];

export const McdTabsPlantOps = [
  "CapacityUtilisation Processing",
  "Efficiency Processing",
];

export const oshaTabs = [
  "All Other Illnesses",
  "Injury",
  "All",
  "SIF",
  "Skin Disorders",
  "Respiratory Condition",
  "Hearing Loss",
];
export const monthNames = [
  "Oct",
  "Nov",
  "Dec",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
];
export const financeErrorData = {
  date: [
    {
      year: "0",
      qtr: "0",
    },
  ],
  data: {
    header: {
      ebit: 1112223334445556,
      ebitPlan: 1112223334445556,
      ebitProj: 1112223334445556,
      netSales: 1112223334445556,
      netSalesPlan: 1112223334445556,
      netSalesProj: 1112223334445556,
      aoi: 1112223334445556,
      aoiPlan: 1112223334445556,
      volume: 1112223334445556,
      volumePlan: 1112223334445556,
      volumeProj: 1112223334445556,
      volumePrevious: 1112223334445556,
    },
    entitySummary: [
      {
        entity: "BEEF",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
      {
        entity: "CHICKEN",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
      {
        entity: "INTERSEGMENT",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
      {
        entity: "INTERNATIONAL",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
      {
        entity: "PORK",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
      {
        entity: "PREPARED FOODS",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
      {
        entity: "MCDONALDS",
        ebit: 1112223334445556,
        ebitPlan: 1112223334445556,
        ebitProj: 1112223334445556,
        netSales: 1112223334445556,
        netSalesPlan: 1112223334445556,
        netSalesProj: 1112223334445556,
        aoi: 1112223334445556,
        aoiPlan: 1112223334445556,
        volume: 1112223334445556,
        volumePlan: 1112223334445556,
        volumeProj: 1112223334445556,
        volumePrevious: 1112223334445556,
      },
    ],
  },
};
