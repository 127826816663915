import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Bar, Cell, Line, Tooltip, YAxis } from 'recharts'
import {setQuarter, setSingle, setYear} from '../actions/sectionsActions'
import BigGraph from '../componentsGeneral/section/BigGraph'
import GraphTicks from '../componentsGeneral/section/GraphTicks'
import { ageColors, ethnicityColors, exitReasonColors, genderColors, tenureColors, absenteeismColors } from '../constants/colors'
import formatNumber from '../utils/formatNumber'
import FeatherIcon from 'feather-icons-react';
import BarShapeTm from './BarShapeTm'
import ScrollButton from '../componentsGeneral/section/ScrollButton'
import GraphInfo from '../componentsGeneral/section/GraphInfo'
import { getProperty } from '../utils/dataMappingTurnover'
import CustomDot from '../componentsGeneral/section/CustomDot'
import TooltipTm from './TooltipTm'
import GraphXTitles from '../componentsGeneral/section/GraphXTitles'
import useTooltipControl from '../hooks/useTooltipControl'
import { isMobile } from 'react-device-detect'


const BigGraphTeamMembers = ({ dataKey, trend2year }) => {
  const dispatch = useDispatch()
  const {
    activeSection,
    isSingle,
    activeTab,
    fiscDateEmployee,
    activeQYSwitcher,
    fiscDateEmployeeAbsenteesim,
    year,
    quarter,
    bars
  } = useSelector(state => ({
    activeSection: state.sections.activeSection,
    isSingle: state.sections.isSingle,
    activeTab: state.sections.activeTab,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    year: state.sections.year,
    quarter: state.sections.quarter,
    bars: state.values.bars
  }))

  const graphHeight = 310
  const [graphRef, setGraphRef] = useState(null)
  const [data, setData] = useState([])
  const [buttonsState, setButtonsState] = useState({
    showLeft: true,
    showRight: false
  })

  const {
    onMouseOverBar,
    onMouseLeaveBar,
    isTooltipActive
  } = useTooltipControl()

  const getDefObject = () => {
    if(activeTab === 'Gender') return genderColors;
    else if(activeTab === 'Ethnicity') return ethnicityColors;
    else if(activeTab === 'AgeGroup') return ageColors;
    else if (activeTab === 'Tenure') return tenureColors;
    else if(activeTab === 'ExitReason') return exitReasonColors;
    if(activeSection === 'ABSENTEEISM') return  absenteeismColors;
  }

  const fillBars = (data, startWeekOfPeriod, totalIteratios, callback=()=>{}) => {
    console.log(data, startWeekOfPeriod, totalIteratios)
    for(let i = 0; i < totalIteratios; i++) {
      const currentPeriod = startWeekOfPeriod + i;
      let currentPeriodObj = data.find(({ period }) => parseInt(period) === currentPeriod );
      if(!currentPeriodObj) {
        currentPeriodObj = {[dataKey]: 0, period: currentPeriod };
        data.splice(i, 0, currentPeriodObj)
      }
      else {
        data[i] = { ...currentPeriodObj };
      }

      callback(data, i)
    }
  }

  const getData = () => {
    let data = bars.slice(0);
    if(activeQYSwitcher === 'Y') {
      const startWeekOfPeriod = 1;
      const cb = (data, i) => {
        if (activeSection == 'TURNOVER_RATE') {
          const overallData = trend2year.find(({ fiscYear, fiscMonth }) => year == fiscYear && fiscMonth == startWeekOfPeriod + i);
          const overallTurnover = overallData ? overallData.turnoverRate*100 : null;
          data[i]['Overall Turnover Rate'] = overallTurnover;
        }
      }
      fillBars(data, startWeekOfPeriod, 12, cb);
    }
    else {
      const firstPeriod = data[0] ? data[0].period : (quarter - 1) * 13 + 1;
      const startWeekOfPeriod = Math.floor((firstPeriod === 53 ? 39 : firstPeriod - 1)/13)*13 + 1;
      console.log({ startWeekOfPeriod, data })
      const lastPeriod = data[data.length - 1] ? data[data.length - 1].period : 0;
      const weeksCount = lastPeriod === 53 ? 14 : 13;
      fillBars(data, startWeekOfPeriod, weeksCount);
    }

    let dataCopy
    if(dataKey === 'turnoverRate') {
      dataCopy = data.map((el) => Object.assign({}, el));
      const o = getDefObject();
      dataCopy.forEach((el, i, array) => {
        if(el.period == fiscDateEmployee.month && year === fiscDateEmployee.year) {
          for(let k in o) {
            el[`${dataKey}${k}Future`] = el[`${dataKey}${k}`]
            el[`${dataKey}${k}`] = null;
            if(array[i - 1]) {
              array[i - 1][`${dataKey}${k}Future`] = array[i - 1][`${dataKey}${k}`];
            }
          }

          el[`Overall Turnover RateFuture`] = el[`Overall Turnover Rate`]
          el[`Overall Turnover Rate`] = null;

          if(array[i - 1]) {
            array[i - 1][`Overall Turnover RateFuture`] = array[i - 1][`Overall Turnover Rate`]
          }
        }
      })
    }

    return dataCopy ? dataCopy : data
  }

  const handleClickBar = (data) => {
    if (!Object.keys(data).includes('viewBy')) {
      dispatch(setSingle(false))
      return
    }

    if (data.period == isSingle) {
      dispatch(setSingle(false))
      return
    }

    dispatch( setSingle(parseInt(data.period)) )
  }


  const formatTickValue = (value) => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, needScale: activeSection !== 'TURNOVER_RATE', employeePercents: activeSection === 'TURNOVER_RATE', remove$: true })

    return `${number === '0' || number == 0 ? '' : prependStr}${number === '0' || number == 0 ? '' : number}${number === '0' || number == 0  ? '' : appendStr}`
  }

  const getGraphInfo = () => {
    const infoAbsenteesim = [
      {
        title: 'Total Current',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #002544 solid' }}></span>
      },
      {
        title: 'Total Last Year',
        line: <span className='inline-block mr-2 h-0 w-6' style={{ border: '2px #002544 dotted' }}></span>
      }
    ]

    return infoAbsenteesim.map((v, i) => <div key={i} className='flex items-center'>{ v.line }<span className='text-xs text-black'>{ v.title }</span></div>)
  }


  const renderTicks = () => {
    if (!graphRef) return null

    return graphRef.state.yAxisMap.left.niceTicks.map((tickValue, i) => <span className='text-sm text-black leading-0' key={i}>
      { isFinite(tickValue) ? formatTickValue(tickValue) : '' }
    </span>)
  }

  const getBarTm = (obj, bars) => {
    for(let key in obj) {
      bars.push(
        <Bar
            dataKey={`${dataKey}${key}`}
            stackId="a"
            onClick={handleClickBar}
            key={key}
            yAxisId='left'
            shape={<BarShapeTm />}
            barSize={20}
            onMouseOver={onMouseOverBar}
            onMouseLeave={onMouseLeaveBar}
        >
          {
            data.map((entry, index) => <Cell
                  cursor="pointer"
                  fill={obj[key].color}
                  opacity={((isSingle && isSingle === parseInt(entry.period)) || !isSingle) ? 1 : 0.2}
                  period={entry.period}
                  key={`cell-${index}`}
                  isHalfClosed={year == fiscDateEmployee.year && entry.period >= fiscDateEmployee.month}
                />
            )
          }
        </Bar>
      )
    }
  }

  const getBarAbs = (obj, bars) => {
    for(let key in obj) {
      if (obj[key].ignoreBar) continue
      bars.push(
          <Bar
              dataKey={`${dataKey}${key}`}
              stackId="a"
              onClick={handleClickBar}
              key={key}
              yAxisId='left'
              shape={<BarShapeTm />}
              barSize={20}
              onMouseOver={onMouseOverBar}
              onMouseLeave={onMouseLeaveBar}
          >
            {
              data.map((entry, index) => <Cell
                      cursor="pointer"
                      fill={obj[key].color}
                      opacity={((isSingle && isSingle === parseInt(entry.period)) || !isSingle) ? 1 : 0.2}
                      period={entry.period}
                      key={`cell-${index}`}
                      isHalfClosed={year == fiscDateEmployeeAbsenteesim.year && ((entry.period >= fiscDateEmployeeAbsenteesim.month && activeQYSwitcher === 'Y'))}
                  />
              )
            }
          </Bar>
      )
    }
    !isSingle && bars.push(
        <Line
            type="monotone"
            dataKey={'absCountTotalCurrent'}
            key={1}
            dot={false}
            cursor={false}
            stroke={'#002544'}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId='left'
            connectNulls={true}
            isAnimationActive={true}
        />,
        <Line
            type="monotone"
            dataKey={'absCountTotalLy'}
            key={2}
            dot={false}
            cursor={false}
            stroke={'#002544'}
            strokeWidth={4}
            opacity={!isSingle ? 1 : 0.4}
            yAxisId='left'
            strokeDasharray={[4, 4]}
            connectNulls={true}
            isAnimationActive={true}
        />
    )
  }

  const getBarTr = (obj, bars) => {
    for(let key in obj) {
      bars.push(
        <Line
          type="monotone"
          dataKey={`${dataKey}${key}`}
          key={key}
          dot={<CustomDot isSingle={isSingle} fill={obj[key].color} />}
          cursor={false}
          stroke={obj[key].color}
          strokeWidth={4}
          opacity={!isSingle ? 1 : 0.4}
          yAxisId='left'
        />,
        <Line
          type="monotone"
          dataKey={`${dataKey}${key}Future`}
          key={key + 'dashed'}
          dot={<CustomDot isSingle={isSingle} fill={obj[key].color} />}
          cursor={false}
          stroke={obj[key].color}
          strokeWidth={4}
          strokeDasharray={[4, 4]}
          opacity={!isSingle ? 1 : 0.4}
          yAxisId='left'
        />,
        <Bar
            minPointSize={graphHeight}
            maxBarSize={graphHeight}
            stackId="a"
            onClick={handleClickBar}
            key={key+'bar'}
            fill='transparent'
            yAxisId='left'
            dot={false}
            onMouseOver={onMouseOverBar}
            onMouseLeave={onMouseLeaveBar}
        ></Bar>
      )
    }

    bars.push(<Line
        type="monotone"
        dataKey={'Overall Turnover Rate'}
        key={9822}
        dot={<CustomDot isSingle={isSingle} fill={'#65686B'} />}
        cursor={false}
        stroke={'#65686B'}
        strokeWidth={4}
        opacity={!isSingle ? 1 : 0.4}
        yAxisId='left'
      />,
      <Line
        type="monotone"
        dataKey={'Overall Turnover RateFuture'}
        key={9823}
        dot={<CustomDot isSingle={isSingle} fill={'#65686B'} />}
        cursor={false}
        stroke={'#65686B'}
        strokeWidth={4}
        strokeDasharray={[4, 4]}
        opacity={!isSingle ? 1 : 0.4}
        yAxisId='left'
      />
    )
  }

  const getGraphInnerContent = (colors, content) => {
    if (activeSection === 'ABSENTEEISM') getBarAbs(colors, content)
    else if (activeSection === 'TEAM_MEMBERS') getBarTm(colors, content)
    else getBarTr(colors, content)
  }

  const getGraphContent = () => {
    const content = []

    if(activeTab === 'Gender') getGraphInnerContent(genderColors, content);
    else if(activeTab === 'Ethnicity') getGraphInnerContent(ethnicityColors, content);
    else if(activeTab === 'AgeGroup') getGraphInnerContent(ageColors, content);
    else if (activeTab === 'Tenure') getGraphInnerContent(tenureColors, content);
    else if(activeTab === 'ExitReason') getGraphInnerContent(exitReasonColors, content);

    if(activeSection === 'ABSENTEEISM') getGraphInnerContent(absenteeismColors, content);

    return content
  }

  const manageButtonsState = () => {
    const currentDate = activeSection === 'ABSENTEEISM' ? fiscDateEmployeeAbsenteesim : fiscDateEmployee
    if (currentDate.year == year) {
      if (activeQYSwitcher == 'Y') {
        setButtonsState({
          showLeft: true,
          showRight: false
        })
      }
      else {
        if (currentDate.quarter == quarter) {
          setButtonsState({
            showLeft: true,
            showRight: false
          })
        }
        else {
          setButtonsState({
            showLeft: true,
            showRight: true
          })
        }
      }
    }
    else if (currentDate.year - 2 == year) {
      if (activeQYSwitcher == 'Y') {
        setButtonsState({
          showLeft: false,
          showRight: true
        })
      }
      else {
        if (1 == quarter) {
          setButtonsState({
            showLeft: false,
            showRight: true
          })
        }
        else {
          setButtonsState({
            showLeft: true,
            showRight: true
          })
        }
      }
    }
    else setButtonsState({
        showLeft: true,
        showRight: true
    })
  }

  const clickLeftScroll = () => {
    if (activeSection === 'TEAM_MEMBERS') {
      dispatch( setSingle(12) )
    }
    else {
      dispatch( setSingle(false) )
    }

    if (activeQYSwitcher === 'Y') {
      dispatch( setYear(year - 1) )
    }
    else {
      if (quarter == 1) {
        dispatch( setQuarter(4) )
        dispatch( setYear(year - 1) )
      }
      else {
        dispatch( setQuarter(quarter - 1) )
      }
    }
  }

  const clickRightScroll = () => {
    if (activeSection === 'TEAM_MEMBERS') {
      if (year + 1 == fiscDateEmployee.year) dispatch( setSingle(fiscDateEmployee.month) )
      else dispatch( setSingle(12) )
    }
    else {
      dispatch( setSingle(false) )
    }

    if (activeQYSwitcher === 'Y') {
      dispatch( setYear(year + 1) )
    }
    else {
      if (quarter == 4) {
        dispatch( setQuarter(1) )
        dispatch( setYear(year + 1) )
      }
      else {
        dispatch( setQuarter(quarter + 1) )
      }
    }
  }

  useEffect(() => {
    manageButtonsState()
  }, [year, quarter])

  useEffect(() => {
    setData( getData() )
  }, [
    bars,
    dataKey,
    activeQYSwitcher,
    trend2year
  ])

  return (
    <div className='flex relative lg:overflow-visible overflow-x-scroll pt-3 pb-12 no-scrollbar'>
      {activeSection === 'ABSENTEEISM' ? <GraphInfo>
        { getGraphInfo() }
      </GraphInfo> : ''}
      <div className='w-16 relative flex-shrink-0'>
        <GraphTicks>
        { renderTicks() }
        </GraphTicks>
      </div>
      <div className='flex-1 relative pb-4 main-graph-container'>
        {buttonsState.showLeft && <ScrollButton
          className='left-0'
          onClick={clickLeftScroll}
        >
          <FeatherIcon className='text-gray-600' icon={'chevron-left'} />
        </ScrollButton>}
        <BigGraph
          data={data}
          height={graphHeight}
          setRef={setGraphRef}
          titles={<GraphXTitles
            data={data}
            getMonth={el => el.period}
          />}
        >
           {!isMobile && <Tooltip
            content={<TooltipTm
              dataKey={dataKey}
              currentObj={getDefObject()}
              display={isTooltipActive}
            ></TooltipTm>}
          >
          </Tooltip>
          }
          { getGraphContent() }
        </BigGraph>
        {buttonsState.showRight && <ScrollButton
          className='right-0'
          onClick={clickRightScroll}
        >
          <FeatherIcon className='text-gray-600' icon={'chevron-right'} />
        </ScrollButton>}
      </div>
    </div>
  )
}

export default BigGraphTeamMembers
