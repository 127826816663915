import React from 'react'

const AboutSection = () => {
  return (
    <div className='body'>
      <b>This is highly sensitive, confidential, and proprietary information of Tyson Foods.</b>
      Do not share this information with anyone, including other Tyson employees.
      This information may also constitute material non-public information. Pursuant to the Securities Trading Policy,
      you may not purchase or sell shares of Tyson stock while in possession of material non-public information.
      Should you have any questions about access to or use of this information, please contact the Tyson Law Department.
    </div>
  )
}
export default AboutSection
