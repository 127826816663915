import * as React from "react"

function SvgComponent({ size=20 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className='fill-current'>
      <path d="M16 12h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2V8h-2V6h8M8 4H6V2h2m0 6H6V6h2m0 6H6v-2h2m0 6H6v-2h2M4 4H2V2h2m0 6H2V6h2m0 6H2v-2h2m0 6H2v-2h2m6-10V0H0v18h20V4z" />
    </svg>
  )
}

export default SvgComponent
