import React, { useRef, useState, useEffect } from 'react'

import { 
  LineChart, 
  ResponsiveContainer, 
  Line,
  CartesianGrid,
  XAxis
} from 'recharts'
import toClassName from '../../utils/toClassName'

const GraphSmall = ({ data, dataKey='value', dataKey2, height=60, xAxisPeriod, className, children, needAnimation=false }) => {
  const [chartRef, setChartRef] = useState(null)

  const getTitles = () => {
    if (!chartRef) return []

    if (xAxisPeriod === 'year') {
      const result = []
      chartRef.state.tooltipTicks?.forEach(v => {
        if (typeof v.value != 'string') return

        const [year, month] = v.value.split(' ')
        if (month == 1) result.push(<span key={year} className='absolute -bottom-2 italic text-xxxs' style={{ left: (v.coordinate - 13) + 'px' }}>FY{ year - 2000 }</span>)
      })

      return result
    }
    return []
  }

  const getLines = () => {
    if (!chartRef) return []

    if (xAxisPeriod === 'year') {
      const result = []
      chartRef.state.tooltipTicks?.forEach(v => {
        if (typeof v.value != 'string') return

        const [year, month] = v.value.split(' ')
        if (month == 1) result.push(v.coordinate)
      })

      return result
    }
    return chartRef.state.tooltipTicks?.map(v => v.coordinate) ?? []
  }

  return (
    <div className={toClassName(['relative', className])}>
      <ResponsiveContainer width={'100%'} height={height}>
        <LineChart 
          ref={(chart) => {
            setChartRef(chart)
          }}
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}  
          tickCount={1}
        >
          <CartesianGrid 
            horizontal={false} 
            verticalPoints={getLines()}
          />
          <Line
            activeDot={true}
            dot={false}
            type="monotone"
            dataKey={dataKey}
            stroke={'#002544'}
            isAnimationActive={!needAnimation ? false : true}
            strokeWidth={1}
            connectNulls
          />
          {dataKey2
            ?<Line
              activeDot={true}
              dot={false}
              type="monotone"
              dataKey={dataKey2}
              stroke={'#002544'}
              isAnimationActive={!needAnimation ? false : true}
              strokeDasharray="5 5"
              connectNulls
            /> :
          <div></div>}
          { children }
          <XAxis 
            hide
            dataKey="yearMonth"
          />
        </LineChart>
      </ResponsiveContainer>
      { getTitles() }
    </div>
  )
}

export default GraphSmall