import React from 'react'
import toClassName from '../../utils/toClassName'
import FeatherIcon from 'feather-icons-react';

import GraphSmall from './GraphSmall'
import ValueWithDot from './ValueWithDot'
import BusinessUnitGraphSection from './BusinessUnitGraphSection'

const BusinessUnitWithGraph = ({ 
  value,
  financePercents,
  employeePercents,
  name,
  needScale,
  needSign,
  graphData,
  useGraphBorder=true,
  currentKey,
  isActive,
  useDot=true,
  graph,
  children,
  isLast=true,
  graphChildren=null,
  onClick 
}) => {
  return (
    <div 
      className={toClassName(['first:border-t border-b flex py-2 text-black items-center cursor-pointer', isActive ? 'bg-gray-200' : ''])}
      onClick={onClick}
    >
      <div className='flex flex-col lg:pl-2 flex-1'>
        <span className='text-sm'>
          {
            name
          }
        </span>
      </div>
      <div className='mr-2 items-start'>
        {
          children
        }
        <ValueWithDot
          className='text-sm leading-none whitespace-nowrap text-right min-w-0'
          value={value}
          financePercents={financePercents}
          needScale={needScale}
          needSign={needSign}
          useDot={useDot}
          employeePercents={employeePercents}
          isNumberlessthan1000={true}
        />
      </div>
      <BusinessUnitGraphSection
        useGraphBorder={useGraphBorder}
        height={25}
      >
        {
          graph ? graph : <GraphSmall
            data={graphData}
            dataKey={currentKey}
            needAnimation={true}
            height={20}
          >{ graphChildren }</GraphSmall>
        }
      </BusinessUnitGraphSection>
      { !isLast &&
       <div className='self-center'>
        <FeatherIcon className='-rotate-90 transform text-blue-400' icon={'chevron-down'} />
      </div>
      }
    </div>
  )
}

export default BusinessUnitWithGraph