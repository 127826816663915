import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { 
  ComposedChart, 
  ResponsiveContainer, 
  CartesianGrid
} from 'recharts'


const BigGraph = ({ data, height=310, width, children, setRef, titles, vertical=false,onClick }) => {

  const {
    activeSection
  } = useSelector(state => ({
    activeSection: state.sections.activeSection
  }))

  return (
    // Change height * 0.60 to 1.0
    <div className='block relative big-graph-container'>
      <ResponsiveContainer width={width ? width : '100%'} height={height}>
        <ComposedChart 
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          ref={el => setRef(el)}
          onClick={onClick}
        >
          <CartesianGrid 
            horizontal={true}
            vertical={(activeSection === 'OSHA_RATES' || activeSection === 'TURNOVER_RATE') ? true : false} 
          />
          { children }
        </ComposedChart>
      </ResponsiveContainer>
      { titles }
    </div>
  )
}

export default BigGraph