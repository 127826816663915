import * as React from "react"

function Diamond({ size, color='#FF2D55' }) {
  if(!size){
    size = 8;
  }  
  return (
    <svg width={size} height={size} viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
      <rect
        width={6}
        height={6}
        rx={1}
        ry={1}
        transform="rotate(45 2.302 4.703)"
        fill={color}
      />
    </svg>
  )
}

export default Diamond
