import { publicSynchronize } from '../services/dataService'
import linkBuilder from '../utils/linkBuilder'

const fillHeaderEmptyKeys = (data) => {
  return {
    aoi: data.aoi ? data.aoi : 0,
    aoiPlan: data.aoiPlan ? data.aoiPlan : 0,
    ebit: data.ebit ? data.ebit : 0,
    ebitPlan: data.ebitPlan ? data.ebitPlan : 0,
    ebitProj: data.ebitProj ? data.ebitProj : 0,
    netSales: data.netSales ? data.netSales : 0,
    netSalesPlan: data.netSalesPlan ? data.netSalesPlan : 0,
    netSalesProj: data.netSalesProj ? data.netSalesProj : 0,
    volume: data.volume ? data.volume : 0,
    volumePlan: data.volumePlan ? data.volumePlan : 0,
    volumeProj: data.volumeProj ? data.volumeProj : 0,
    volumePrevious: data.volumePrevious ? data.volumePrevious : 0,
  }
}

const has53thWeek = (data, y) => {
    return data.some( ({groupByEntity}) =>
        groupByEntity === `${y}/53`
    )
}

const fillWeek = ({data, dataIndex, indentifier, i, fillWith, isFillClosed, result}) => {
  if (data[dataIndex] && data[dataIndex].groupByEntity === indentifier) {
    result.push({
      ...data[dataIndex],
      index: i,
      ebitPlanClosed : data[dataIndex].ebitPlan,
      netSalesPlanClosed : data[dataIndex].netSalesPlan,
      volumePlanClosed : data[dataIndex].volumePlan,
      ebitProjClosed: data[dataIndex].ebitProj,
      netSalesProjClosed : data[dataIndex].netSalesProj,
      volumeProjClosed: data[dataIndex].volumeProj,
    })
    dataIndex++
    if (
      (data[dataIndex] && data[dataIndex].isClosedWeekFlag === 0) || !data[dataIndex]
    )
      isFillClosed = 1
    if (data[dataIndex] && data[dataIndex].groupByEntity === indentifier)
      dataIndex++
  } else {
    const fill = {
      ...fillWith,
      groupByEntity: indentifier,
      isClosedWeekFlag: isFillClosed ? 0 : 1,
      index: i,
      ebitPlanClosed : 0,
      netSalesPlanClosed : 0,
      volumePlanClosed : 0,
      ebitProjClosed: 0,
      netSalesProjClosed : 0,
      volumeProjClosed : 0,
    }
    result.push(fill)
  }
  return dataIndex;
}

function getLastYear (data)  {
  for(let i = data.length - 1; i > 0; i--) {
    if(data[i].isClosedWeekFlag) {
      return {indexLast: i, year:parseInt(data[i].fiscYear), quartal: parseInt(data[i].fiscQtr), month: data[i].fiscMonth.split('/')[1]};
    }
  }
  return {indexLast: 0, year:parseInt(data[0]?.fiscYear), quartal: parseInt(data[0]?.fiscQtr), month: data[0]?.fiscMonth.split('/')[1]};
}

function getStartindex (data, year, wm) {
  for(let i = 0; i < data.length; i++) {
    const dataArr = data[i].groupByEntity.split('/');
    if(parseInt(dataArr[0]) >= parseInt(year) && parseInt(dataArr[1]) >= parseInt(wm) ) {
      return i;
    }
  }
  return 0;
}

async function fillMissedBars (data, type) {
  console.log('fqawqf12')
  const {year, quartal, month, indexLast} = getLastYear(data);
  let fillWith = {
    //"groupByEntity": "2018/01",
    isClosedWeekFlag: 1,
    ebit: 0,
    ebitPlan: 0,
    ebitProj: 0,
    netSales: 0,
    netSalesPlan: 0,
    netSalesProj: 0,
    aoi: 0,
    aoiPlan: 0,
    volume: 0,
    volumePlan: 0,
    volumeProj: 0,
    volumePrevious: 0,
  }
  let startYear = year - 2;
  let isFillClosed = false
  let result = [];
  let startIndex = 0;
  if (!type) {
    // type == year/quarter
    let dataIndex = 0
    for (let i = 0; i <= (year - startYear + 1) * 12; i++) {
      const addYear = Math.floor(i / 12)
      const m = (i % 12) + 1 < 10 ? `0${(i % 12) + 1}` : (i % 12) + 1
      const indentifier = `${startYear + addYear}/${m}`;
      if(i === 0) {
        dataIndex = getStartindex(data, startYear + addYear, m);
        startIndex = dataIndex;
      }
      if (data[dataIndex] && data[dataIndex].groupByEntity === indentifier) {
        const newEl = {
          ...data[dataIndex],
          index: i,
          ebitPlanClosed : data[dataIndex].ebitPlan,
          ebitProjClosed: data[dataIndex].ebitProj,
          netSalesPlanClosed : data[dataIndex].netSalesPlan,
          netSalesProjClosed : data[dataIndex].netSalesProj,
          volumePlanClosed : data[dataIndex].volumePlan,
          volumeProjClosed: data[dataIndex].volumeProj,
        }
        //if(newEl.aoi || newEl.ebit || newEl.netSales || newEl.volume) newEl.isClosedWeekFlag = 1;
        result.push(newEl)
        dataIndex++
        if (
          (data[dataIndex] && data[dataIndex].isClosedWeekFlag === 0) || !data[dataIndex]
        )
          isFillClosed = 1
        if (data[dataIndex] && data[dataIndex].groupByEntity === indentifier) {
          newEl.aoiPlan = data[dataIndex].aoiPlan
          newEl.ebitPlan += data[dataIndex].ebitPlan
          newEl.ebitProj += data[dataIndex].ebitProj
          newEl.volumePlan += data[dataIndex].volumePlan
          newEl.volumeProj += data[dataIndex].volumeProj
          newEl.netSalesPlan += data[dataIndex].netSalesPlan
          newEl.netSalesPlanClosed = data[dataIndex].netSalesPlan;
          newEl.netSalesProj += data[dataIndex].netSalesProj
          newEl.ebit += data[dataIndex].ebit
          newEl.ebitPlanClosed = data[dataIndex].ebitPlan
          newEl.ebitProjClosed = data[dataIndex].ebitProj
          newEl.volume += data[dataIndex].volume
          newEl.volumePlanClosed = data[dataIndex].volumePlan
          newEl.volumeProjClosed = data[dataIndex].volumeProj
          newEl.netSalesProjClosed = data[dataIndex].netSalesProj
          newEl.netSales += data[dataIndex].netSales
          newEl.aoi += data[dataIndex].aoi
          newEl.isClosedWeekFlag = 1
          newEl.isHalfClosed = 1
          dataIndex++
        }
      } else {
        const fill = {
          ...fillWith,
          groupByEntity: indentifier,
          isClosedWeekFlag: isFillClosed ? 0 : 1,
          fiscYear: startYear+addYear,
          fiscQtr: Math.ceil(m/3),
          index: i,
          ebitPlanClosed : 0,
          volumePlanClosed : 0,
          netSalesPlanClosed : 0,
          ebitProjClosed: 0,
          volumeProjClosed: 0,
          netSalesProjClosed : 0
        }
        result.push(fill)
      }
    }
  } else {
    let dataIndex = 0;

    for (let i = 0; i <= (year - startYear + 1) * 4 * 13; i++) {
      if(dataIndex > indexLast) {
        isFillClosed = true;
      }
      const totalWeeks = 52;
      const currentWeek = i % totalWeeks;
      const addYear = Math.floor(i / totalWeeks);
      const w = (i % 52) + 1 < 10 ? `0${(i % 52) + 1}` : (i % 52) + 1;
      const currentYear = (year - 2) + addYear;
      const fiscQtr = Math.ceil( (currentWeek+1)/13 );
      const fiscYear = currentYear;
      const fiscMonth = Math.ceil((currentWeek + 1)/4.3);
      fillWith = {  ...fillWith, fiscQtr, fiscYear, fiscMonth };
      const indentifier = `${startYear + addYear}/${w}`;

      if(i === 0) {
        dataIndex = getStartindex(data, startYear + addYear, w);
        startIndex = dataIndex;
      }
      dataIndex = fillWeek({data, dataIndex, indentifier, i, fillWith, isFillClosed, result});
      if( w === 52 && has53thWeek( data, currentYear ) ) {
        dataIndex = fillWeek({data, dataIndex, indentifier:`${currentYear}/53`, i:i+1, fillWith, isFillClosed, result});
      }
    }
  }
  result = data.slice(0 ,startIndex).concat(result);
  return {filledData: result, dataYear: year, dataMonth: month, dataQuartal: quartal};
}

export const REQUEST_DATA = 'REQUEST_DATA'
export const RECEIVE_DATA_GRAPH = 'RECEIVE_DATA_GRAPH'
export const RECEIVE_DATA_HEADER = 'RECEIVE_DATA_HEADER'
export const SET_SCALE = 'SET_SCALE'
export const SET_BOTTOM_SCALES = 'SET_BOTTOM_SCALES'
export const RECEIVE_DATA_TREND_UNITS = 'RECEIVE_DATA_TREND_UNITS'
export const RESET_APP = 'RESET_APP'
export const SET_MAIN_VALUE = 'SET_MAIN_VALUE'
export const SET_BOTTOM_VALUES = 'SET_BOTTOM_VALUES'
export const RECEIVE_UNFILLED_DATA = 'RECEIVE_UNFILLED_DATA'
export const GOT_ERROR = 'GOT_ERROR'
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const RECEIVE_DATA_GRAPH_ADDITIONAL = 'RECEIVE_DATA_GRAPH_ADDITIONAL';

export const onError = err => ({
  type: GOT_ERROR,
  payload: {
    err
  }
})

export const setBottomValues = (planDelta, projDelta) => ({
  type: SET_BOTTOM_VALUES,
  payload: {
    planDelta,
    projDelta
  }
})

export const setMainValue = mainValue => ({
  type: SET_MAIN_VALUE,
  payload: {
    mainValue
  }
})

export const setBottomScales = (planScale, projScale) => ({
  type: SET_BOTTOM_SCALES,
  payload: {
    planScale,
    projScale
  }
})

export const resetApp = () => ({
  type: RESET_APP
})

export const setTrend = data => ({
  type: RECEIVE_DATA_TREND_UNITS,
  payload: {
    ...data
  }
})

export function setTrendData (rdata) {
  return async function (dispatch) {
    const { type, data } = rdata
    if (type === 'error') {
      dispatch(onError(data))
    } else {
      dispatch(setTrend(data))
    }
  }
}

export const setScale = scale => ({
  type: SET_SCALE,
  payload: {
    scale
  }
})

export const requestData = (type, qi) => ({
  type: REQUEST_DATA,
  payload: { type, qi }
})
export const receiveDataGraph = dataGraph => ({
  type: RECEIVE_DATA_GRAPH,
  payload: { dataGraph }
})
export const receiveDataHeader = dataHeader => ({
  type: RECEIVE_DATA_HEADER,
  payload: { dataHeader }
})
const receiveUnfilledDataGraph = data => ({
  type: RECEIVE_UNFILLED_DATA,
  payload: { unfilledData: data }
})
export const setCurrentDate = date => ({
  type: SET_CURRENT_DATE,
  payload: {
    date
  }
})
export const receiveDataGraphAdditional = (dataGraphAdditional) => ({
  type: RECEIVE_DATA_GRAPH_ADDITIONAL,
  payload: { dataGraphAdditional }
})

export function setData (queryInfo, requestType, accessToken) {
  return async function (dispatch) {
    dispatch(requestData(requestType, queryInfo))
    const link = linkBuilder(queryInfo)
    const { type, data } = await publicSynchronize(link, accessToken)
    if (type === 'error') {
      dispatch(onError(data))
    } else {
      data.header = fillHeaderEmptyKeys(data.header)
      dispatch(receiveDataHeader(data))
      if (requestType) {
        dispatch(receiveUnfilledDataGraph({ ...data }))
        const {filledData, dataYear, dataQuartal} = await fillMissedBars(
          data.graphBarRecords,
          queryInfo.quartal
        )
        dispatch(setCurrentDate({ year: dataYear, qtr: -dataQuartal }));
        data.graphBarRecords = filledData;
        dispatch(receiveDataGraph(data));
      }
    }
    return data
  }
}
