import { useState, useRef } from 'react';

export default function useTooltipControl() {
  const timerRef = useRef(null)
  const [isTooltipActive ,setIsTooltipActive] = useState(false)

  const onMouseOverBar = () => {
    if (!timerRef.current) timerRef.current = setTimeout(() => {
      setIsTooltipActive(true);
    }, 300)
  }

  const onMouseLeaveBar = () => {
    clearTimeout(timerRef.current);
    timerRef.current = null;
    setIsTooltipActive(false);
  }

  return {
    onMouseOverBar,
    onMouseLeaveBar,
    isTooltipActive
  };
}