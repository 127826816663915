import React from 'react'

const Collapsible = ({ children }) => {

  return (
    <div className='section_row'>
      { children }
    </div>
  )
}

export default Collapsible