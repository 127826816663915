import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PlantOps from '../fullAppUser/PlantOps';
import { setActiveSection } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';


const PlantOpsChicken = ({ activeSection, setActiveSection, setNavigationStage, userPrivilages }) => {
    // useEffect(() => {
    //     setActiveSection('Poultry');
    // }, [])

    // if (activeSection !== 'Poultry') return <></>
    
    return (
        <PlantOps
            onlyPoultry
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveSection, setNavigationStage }
  )(PlantOpsChicken)
