
import { SET_STAGE } from '../actions/navigationActions';

const INITIAL_STATE = {
    stage: 0
}

export const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_STAGE:
            const { stage } = action.payload;
            return {
                ...state,
                stage: stage
            }
        default:
            return state;
    }
}
