import React from 'react'
import styles from '../AboutSection/styles.css'
import InfoImg from '../../images/icons/infoicon.png';
import {connect} from 'react-redux';

const AboutSection = ({ content, name }) => {
  return (
    <div className='body'>
      {/* <img src={InfoImg}></img><br/> */}
      <b style={{display: 'block', margin: '0.3rem', marginLeft: 0}}>{name}</b>{ content }
    </div>
  )
}
export default connect(
  state => ({
    content: state.app.showModal.content,
    name: state.app.showModal.name
  })
)(AboutSection)
