import React from 'react'
import GraphNesFav from "./GraphNesFav";

const SectionNesFav = ({ title, bars, hasLine, children }) => {
  return (
    <div className='flex flex-col justify-between'>
      <div>
        <div className='pb-2 border-b border-gray-900'>
          <h3 className='text-xxl font-semibold text-black'>
            { title }
          </h3>
        </div>
        <div className='mt-8'>
          { children }
        </div>
      </div>
      <div className='mt-20'>
        <GraphNesFav
          hasLine={hasLine}
          bars={bars}
        />
      </div>
    </div>
  )
}

export default SectionNesFav
