import React from 'react'

const BarShapeRegular = (props) => {
  let {height, width, y, x, fill, opacity} = props;

  const yCalculated = height < 0 ? y + height : y
  // add rect to register clicks at any place
  return (
    <>
    <rect 
      opacity={opacity}
      width={width}
      height={Math.abs(height)}
      x={x}
      y={yCalculated}
      fill={fill}
      cursor="pointer"
    >
    </rect>
    </> 
  )
}

export default BarShapeRegular