import React from 'react'

const ItemDouble = ({ children }) => {
  return (
    <div className={'relative rounded border-05 border-gray-500 flex items-center flex-1 bg-white box-border home-item-double mx-2'}>
      {
        children
      }
    </div>
  )
}

export default ItemDouble