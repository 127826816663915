import React from 'react'
import toClassName from '../../utils/toClassName'

const Button = ({ onClick, setRef=()=>{}, disabled, type='primary', isActive, children, className='', size='md', ...other }) => {
  const classes = {
    'primary': {
      default: toClassName (['font-semibold text-center text-blue-400', className]),
      md: 'px-3 py-1 text-base2',
      'md-': 'px-2 py-1 text-base2',
      'md--': 'px-2 py-1 text-base2',
      sm: 'px-2 py-1 text-xs',
      disabled: 'bg-wgite text-gray-400 cursor-default',
      active: 'font-semibold text-center text-white bg-gray-600 rounded'
    },
    'secondary': {
      default: toClassName(['transition text-base2 font-semibold text-center py-1 px-3 lg:px-2 lg:py-3 text-blue-400 border border-blue-400 lg:border-transparent lg:border-t-0 lg:border-l-0 lg:border-r-0 lg:border-b-4 rounded-xl lg:rounded-none border-transparent bg-gray-800 lg:bg-transparent hover:bg-blue-400 hover:text-white', className]),
      active: toClassName(['transition text-base2 font-semibold text-center py-1 px-3 lg:px-2 lg:py-3 text-white lg:text-black rounded-xl lg:rounded-sm lg:border-b-4 lg:border-gray-600 hover:border-transparent bg-blue-400 lg:bg-transparent hover:bg-blue-400 hover:text-white', className])
    }
  }

  const currentClass = classes[type]


  return (
    <button
      ref={el => setRef(el)} className={toClassName([disabled ? currentClass.disabled : isActive ? currentClass.active : currentClass.default, currentClass[size]])}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      <span className='whitespace-nowrap'>
        { children }
      </span>
    </button>
  )
}

export default Button
