import React from 'react'
import UpdatedText from "../componentsGeneral/section/UpdatedText";
import Breadcrumb from "../componentsGeneral/section/Breadcrumb";

const HeaderNesFav = (props) => {
  return (
    <div className='justify-between items-end pt-4 pb-3 px-5 lg:px-0 hidden lg:flex'>
      <Breadcrumb />
      <UpdatedText />
    </div>
  )
}

export default HeaderNesFav
