import React, { useEffect, useState, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useIdleTimer } from "react-idle-timer";


const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;


const Timeout = () => {
const { oktaAuth } = useOktaAuth();

  const IDLE_TIMEOUT_MINUTES = 1200;
  const lowercase = (val = "") =>
    val.toLowerCase()
      .trim();
  const onIdle = async event => {
    const user = await oktaAuth.getUser();

    const shouldIgnoreIdle =
      lowercase(user.email) === lowercase(USER_EMAIL_IGNORING_IDLE);

    if (shouldIgnoreIdle) {
      console.log('useridle',shouldIgnoreIdle );
      // do nothing
      return;
    } else {
      oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + "/timeout"
      });
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * IDLE_TIMEOUT_MINUTES,
    onIdle,
    debounce: 500
  })

return null

};

export default Timeout;
