import { getTeamMembersData, getOshaData } from '../services/dataService'
import {
    getHeaderData,
    getGraphData,
    getGraphAdditionalData,
    getOshaHeaderData,
    getGraphDataOsha,
    getOshaRatesHeaderData,
    getGraphDataOshaRates,
    getGraphDataSIF
} from '../utils/dataMappingTurnover';
import { REQUEST_DATA, receiveDataGraph, onError, setTrend,receiveDataHeader, receiveDataGraphAdditional } from './valuesActions';

export const RECEIVE_LEDAER_UNITS = 'RECEIVE_LEDAER_UNITS'
export const RECEIVE_LOCATION_UNITS = 'RECEIVE_LOCATION_UNITS';
export const SET_LATEST_METRICS = 'SET_LATEST_METRICS';
export const SET_LATEST_METRICS_ABSENTEESIM = 'SET_LATEST_METRICS_ABSENTEESIM';
export const SET_FISC_DATE_EMPLOYEE = 'SET_FISC_DATE_EMPLOYEE';
export const SET_FISC_DATE_EMPLOYEE_ABSENTEEISM = 'SET_FISC_DATE_EMPLOYEE_ABSENTEEISM';
export const SET_OSHA_FISC_DATE = 'SET_OSHA_FISC_DATE';
export const SET_OSHA_METRICS = 'SET_OSHA_METRICS';
export const SET_OSHA_RATES_FISC_DATE = 'SET_RATES_OSHA_FISC_DATE';
export const SET_OSHA_RATES_METRICS = 'SET_RATES_OSHA_METRICS';

export const setOshaMetrics = (oshaMetrics) => ({
  type: SET_OSHA_METRICS,
  payload: {
    oshaMetrics
  }
})

export const setOshaFiscDate = (oshaFiscDate) => ({
    type: SET_OSHA_FISC_DATE,
    payload: {
        oshaFiscDate
    }
})

export const setOshaRatesMetrics = (oshaRatesMetrics) => ({
  type: SET_OSHA_RATES_METRICS,
  payload: {
    oshaRatesMetrics
  }
})

export const setOshaRatesFiscDate = (oshaRatesFiscDate) => ({
    type: SET_OSHA_RATES_FISC_DATE,
    payload: {
        oshaRatesFiscDate
    }
})

const setLeaderUnits = (units) => ({
  type: RECEIVE_LEDAER_UNITS,
  payload: {
    units
  }
})

const setLocationUnits = (locationUnits) => ({
  type: RECEIVE_LOCATION_UNITS,
  payload: {
    locationUnits
  }
})

export const setLatestMetrcis = (metrics) => ({
  type: SET_LATEST_METRICS,
  payload: {
    metrics
  }
})

export const setLatestMetrcisAbsenteesim = (absenteesimMetrics) => ({
  type: SET_LATEST_METRICS_ABSENTEESIM,
  payload: {
    absenteesimMetrics
  }
})

export const setFiscDateEmployee = (date) => ({
  type: SET_FISC_DATE_EMPLOYEE,
  payload: {
    date
  }
})

export const setFiscDateEmployeeAbsenteesim = (date) => ({
  type: SET_FISC_DATE_EMPLOYEE_ABSENTEEISM,
  payload: {
    date
  }
})

export const requestData = (type, qi) => ({
  type: REQUEST_DATA,
  payload: { type, qi }
})

export function setDataTeamMembers (params, accessToken = '', {buArray, buType}) {
  console.log({params}, '!E@')
  return async function (dispatch) {
    dispatch(requestData(1))
    const isOsha = params.section === 'osha';
    const isRates = params.section === 'rates';
    const { type, data } = (isOsha || isRates) ? await getOshaData(params, accessToken, isRates ? '/rates' : '') : await getTeamMembersData(params, accessToken)

    if (type === 'error') {
      dispatch(onError(data))
    } else {
        const dGraph = (isOsha || isRates) ? params.viewBy === 'SIF' ?  await getGraphDataSIF(data.graphBarRecords ? data : [])
        : await getGraphDataOsha(data.graphBarRecords ? data : [], isRates)
        :
        await getGraphData(data)
        const graphData = {graphBarRecords:  dGraph, graphBarRecordsPY: data.graphBarRecordsPY};

        let locationUnits = data.locationData;
        let leaderUnits = data.orgData;
        console.log({data, locationUnits, buArray}, '124124124dwq')
        dispatch(receiveDataHeader({ header: isOsha ? getOshaHeaderData(data.headerRecord) : getHeaderData(data.headerRecord) }));
        dispatch(setLeaderUnits( leaderUnits ));
        dispatch(setLocationUnits(  locationUnits ));
        dispatch(receiveDataGraphAdditional( isRates ? data.graphSummary : getGraphAdditionalData(data, params.section, params.viewBy) ));
        dispatch( setTrend({bsTrend: data.twoYearTrendRecord}) );
        dispatch( receiveDataGraph( graphData ) );
    }
    return data
  }
}
