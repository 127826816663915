import React from 'react'


const ContentContainerDesktop = ({ children }) => {
  return (
    <div className='py-6 flex flex-col-reverse lg:flex-row justify-center items-stretch relative'>
      { children }
    </div>
  )
}

export default ContentContainerDesktop