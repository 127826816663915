import React from 'react'

const AboutSection = () => {
  return (
    <div className='body' style={{ color: "#65686B"}}>
      <h1 style={{ color: "#65686B"}}>
        {' '}
        <b>Insights@Tyson</b> is an automated dashboard providing executive leadership visibility to Tyson’s most critical metrics.
      </h1>
      <ul className='li' style={{ color: "#65686B"}}>

      </ul>

      <h1 style={{ color: "#65686B"}}>
      <span>Version  <b>{process.env.REACT_APP_VERSION}</b></span>

      </h1>
      
      <br />
      <h1 style={{ color: "#65686B"}}>
      About Release 2.0
      </h1>

      <br></br>

      <h1 style={{ color: "#65686B"}}>
          <b>Finance</b> data includes Operating Income, AOI %, and Net Sales for poultry, prepared foods, beef, pork and international business segments.
        </h1>
      <ul className='li' style={{ color: "#65686B"}}>

        <li style={{ color: "#65686B"}}>
            Data is updated weekly after the financial week closes
        </li>
        <li style={{ color: "#65686B"}}>
          Month-end financial adjustments will be made to the final week of the fiscal month
        </li>
        <li style={{ color: "#65686B"}}>
          The last fiscal week of the month closes on Wednesday, after which there will be a one hour window before month-end adjustments begin posting
        </li>
        <li style={{ color: "#65686B"}}>
          Data includes two years of history plus current year
        </li>
        <li style={{ color: "#65686B"}}>
          QTD targets are based on quarterly projections; YTD targets are based on AOP
        </li>
        <li style={{ color: "#65686B"}}>
          Future projections are based on the latest LE (latest estimate)
        </li>

      </ul>
      <br />
      <br />
      <h1 style={{ color: "#65686B"}}>
        <b>Team Members</b> data includes Team Members and Turnover Rate, with segments by organizations and locations.
      </h1>
      <ul className='li' style={{ color: "#65686B"}}>
        <li style={{ color: "#65686B"}}>
        Team Members and Turnover information is refreshed weekly        </li>
        <li style={{ color: "#65686B"}}>
        Team Members counts include Tyson domestic team members        </li>
        <li style={{ color: "#65686B"}}>
        Turnover information is scoped to full-time team members         </li>
        <li style={{ color: "#65686B"}}>Turnover is quoted as FYTD Annualized, monthly selections reflect the selected month annualized</li>
        <li style={{ color: "#65686B"}}>Turnover excludes retirement, permanent layoff, severance, termination as sale (divestiture), death, and transfer to Offsite/Cobb </li>
      </ul>
      <br />
      <br />
      <h1 style={{ color: "#65686B"}}>
        <b>OSHA Recordables</b>
      </h1>
      <ul className='li' style={{ color: "#65686B"}}>
        <li style={{ color: "#65686B"}}>
        Health & Safety data is updated weekly        </li>
        <li style={{ color: "#65686B"}}>
        Health & Safety data includes the current fiscal year and two prior fiscal years        </li>
        <li style={{ color: "#65686B"}}>
        Year-end Health & Safety data is as of each fiscal year end (e.g. FY 2020 as of 10/3/2020)         </li>
        <li style={{ color: "#65686B"}}>Only ELT approved OSHA recordable targets are displayed</li>
        <li style={{ color: "#65686B"}}>DART = Days Away, Restricted, and Transfer, LT = Lost Time, and SIF = Serious Injury or Fatality </li>
      </ul>
    </div>
  )
}
export default AboutSection
