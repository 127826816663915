import * as React from "react"

const SvgComponent = ({ size=36, color='#2699FB', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props}>
    <path data-name="Rectangle 19700" fill="none" d="M0 0h36v36H0z" />
    <path
      data-name="icon"
      d="M31.124 7.837a1.414 1.414 0 0 1-.7 1.4c-.1.1-.2.3-.3.4a.591.591 0 0 1-.9.3l-.6-.3a.636.636 0 0 0-.6 0 1.165 1.165 0 0 1-1 0 3.8 3.8 0 0 1-1-.3 9.15 9.15 0 0 1-2.294-.9 5.374 5.374 0 0 0-2.095-.8c-1-.1-2.095-.2-3.092-.3H16.46c-2.195 0-4.289-.1-6.484-.1h-.4a24.522 24.522 0 0 1-2.793-.2 2.486 2.486 0 0 0-1.7.3.842.842 0 0 1-.7.2c-.5.1-1 .2-1.6.3a2.932 2.932 0 0 0-2.095 1.8A17.191 17.191 0 0 0 0 11.725v1.6a17.916 17.916 0 0 0 .2 2.195 2.011 2.011 0 0 0 .3 1.1 1.993 1.993 0 0 1 .2 1.5 10.436 10.436 0 0 1-.4 1.7c-.2.7 0 .9.7 1.1.1 0 .1.1.2.1a1.146 1.146 0 0 0 .6-.9v-1.5c0-.6-.1-1.2-.1-1.9a4.048 4.048 0 0 0-.4-1.9 4.577 4.577 0 0 1-.3-2.596 25.979 25.979 0 0 1 1 2.494 13.471 13.471 0 0 1 .6 3.491 3.786 3.786 0 0 1-.6 2.295 1.265 1.265 0 0 0-.1.6 1.418 1.418 0 0 0 .2.6 7.594 7.594 0 0 1 .3 2.095c0 .4.1.9.1 1.3a2.4 2.4 0 0 1-.4 1.6c.1.2.3.3.4.5 0 .2-.1.4.1.6a1.07 1.07 0 0 1 .2.6.66.66 0 0 0 .6.7 4.136 4.136 0 0 0 1.9-.1c.1 0 .2-.1.3-.2-.3-.4-.5-.9-.8-1.3a4.338 4.338 0 0 1-.4-.7 7.384 7.384 0 0 1-.2-3.89 4.407 4.407 0 0 1 .6-1.6A16.61 16.61 0 0 1 6 19.015l.3-.3c.2.3.1.7.5.9.1-.1.2-.3.3-.4.1.2.1.5.5.4a4.864 4.864 0 0 0 .3-.7.348.348 0 0 1 .2-.1 1.519 1.519 0 0 1 1.8-.2c.1.1.3.1.5.2.5.3 1.1.5 1.6.8a1.527 1.527 0 0 0 1.3.2c.5-.1 1.1-.3 1.6-.4a2.762 2.762 0 0 1 .9-.1c.8-.1 1.5-.1 2.294-.2a1.442 1.442 0 0 1 .7.2 22.122 22.122 0 0 0 .6 4.489v2.693c0 .5.4.9.4 1.5 0 .1.1.1.2.2a8.063 8.063 0 0 0 2.195.7 1.482 1.482 0 0 0 .9-.2.563.563 0 0 0-.2-.6 2.133 2.133 0 0 1-.9-1.8c0-.1 0-.2-.1-.2a4.154 4.154 0 0 1 .2-2.893.366.366 0 0 1 .1-.3c.1-.7.3-1.4.4-2.095a4.864 4.864 0 0 0 .7-.3 2.987 2.987 0 0 0 .8-.4 13 13 0 0 0 1.5-1c.6-.4 1.2-.7 1.4-1.4.1-.4.4-.6.7-.9a5.281 5.281 0 0 1 2.893-1.3 14.733 14.733 0 0 1 2.095.1 3.394 3.394 0 0 0 1 .1.366.366 0 0 1 .3.1 1.055 1.055 0 0 0 1.6-.3c.6-.7.7-1.3 0-1.9-.4-.4-.7-.9-1.1-1.3a.756.756 0 0 1-.2-.4 1.905 1.905 0 0 0-.7-1.4 2.07 2.07 0 0 1-.6-1c.7-.9.7-1.2.2-1.6-.2.6-.2 1.1-.6 1.3l-.9-.3c-.154-.372-.154-.772-.553-1.072Z"
      className="fill-current"
    />
  </svg>
)

export default SvgComponent
