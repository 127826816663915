import * as React from "react"

const SvgComponent = ({ size=36, color='#2699FB', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props}>
    <path data-name="Rectangle 19699" fill="none" d="M.49 0h36v36h-36z" />
    <path
      data-name="Union 8"
      d="M.58 31.4a.58.58 0 0 1-.58-.58v-2a.582.582 0 0 1 .58-.58h34.842a.582.582 0 0 1 .58.58v2a.58.58 0 0 1-.58.58Zm.212-4.96a.8.8 0 0 1-.779-.833c.388-9.218 7.046-16.725 15.6-17.921a.71.71 0 0 1-.013-.163 2.4 2.4 0 1 1 4.8 0 .71.71 0 0 1-.013.163c8.553 1.2 15.215 8.7 15.6 17.921a.8.8 0 0 1-.779.833ZM19.203 7.572v-.049a1.2 1.2 0 1 0-2.4 0v.049c.4-.037.792-.049 1.2-.049s.804.012 1.2.049Z"
      className="fill-current"
    />
  </svg>
)

export default SvgComponent
