import React, { useEffect, useState, useRef } from 'react'
import Dimensions from 'react-dimensions'
import { useOktaAuth } from "@okta/okta-react"
import { setActiveSection } from '../../actions/sectionsActions';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { DESKTOP_VIEW_BREAKPOINT, sectionsPlantOps } from '../../constants/app'
import ScreenBlocker from '../../components/ScreenBlocker/ScreenBlocker'

import useWindowDimensions from '../../hooks/useWindowDimensions'
import LayoutApp from '../../componentsGeneral/AppLayout'

import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import {setDataNesFavBrand} from "../../actions/dataActionsNesFavBrand";
import HeaderNesFav from "../../componentsNesFav/HeaderNesFav";
import SectionNesFav from "../../componentsNesFav/SectionNesFav";
import formatFiscDate from "../../utils/formatFiscDate";
import ParagraphNesFav from "../../componentsNesFav/ParagraphNesFav";

const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;

const monthNames = [
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep'
]

const NesFavBrand = ({showModal, revenueDataRequested, ...props}) => {
  const { height, width } = useWindowDimensions()
  const { authState } = useOktaAuth()
  const {accessToken: { accessToken }} = authState

  const [scoreRecords, setScoreRecords] = useState([])
  const [brandRecords, setBrandRecords] = useState([])
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);

  useEffect(() => {
    const { setActiveSection, activeSection} = props;

    if(!activeSection || (activeSection != 'NES_FAVORITE_BRAND')) setActiveSection('NES_FAVORITE_BRAND');
  }, [])

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const getAverageLine = (bars) => {
    const barsCopy = bars.slice(0)
    barsCopy.forEach((bar, index, array) => {
      if (index === 0) {
        bar.total = bar.consumerScr
        bar.avgLine =  bar.consumerScr
      }
      else {
        bar.total = array[index - 1].total + bar.consumerScr
        //bar.avgLine =  bar.consumerScr
        bar.avgLine =  bar.total / (index + 1)
      }
    })

    return barsCopy
  }

  const getData = () => {
    const { fiscDateNesFavBrand, setDataNesFavBrand} = props;
    let filters = [
      { "fiscYear": fiscDateNesFavBrand.year }
    ];
    setDataNesFavBrand({
      filters
    }, accessToken)
    setInitialLoadingCompleted(true)
  }

  useEffect(() => {
    const { bars } = props;

    if (bars.graphBarRecordsNes) setScoreRecords(bars.graphBarRecordsNes)
    if (bars.graphBarRecordsFavBrand) setBrandRecords(getAverageLine(bars.graphBarRecordsFavBrand))
  }, [
    props.bars
  ])

  useEffect(() => {
    const { activeSection, fiscDateNesFavBrand } = props;
    let timer;
    if(activeSection && fiscDateNesFavBrand && fiscDateNesFavBrand.year) {
      timer = setTimeout(() => {
        getData();
      }, 100)

    }
    return () => {
      clearTimeout(timer);
    }
  }, [
    props.activeSection,
    props.fiscDateNesFavBrand.year
  ])

  return (
    <ScreenWrapper nesFavBrand doSetQuarter doSetYear initialLoadingCompleted={initialLoadingCompleted}>
      <ScreenBlocker
        text='Please, rotate your device.'
        active={
            isMobile &&
            ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
            (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
        }
      />
      <LayoutApp>
        <HeaderNesFav>
        </HeaderNesFav>

        <div className='grid grid-rows-2 lg:grid-cols-2 gap-y-20 gap-x-20 px-5 pt-12 lg:pt-0 lg:px-0'>
          <SectionNesFav
            title={`Net Experience Score - ${formatFiscDate({ year: props.fiscDateNesFavBrand.year, quarter: props.quarter })}`}
            bars={scoreRecords}
          >
            <ParagraphNesFav
              text='Third Party Online Survey, Key Foodservice & Retail Customers'
              title='Source: '
            />
            <ParagraphNesFav
              text='Scale question evaluating overall experience with Tyson Foods. Single metric highlights percentage of positive ratings relative to negative ratings'
              title='Interpretation: '
            />
          </SectionNesFav>
          <SectionNesFav
            title={`Favorite Brand - ${formatFiscDate({ year: props.fiscDateNesFavBrand.year })} YTD`}
            bars={brandRecords}
            hasLine={true}
          >
            <ParagraphNesFav
              text='Source: Third Party Online Survey, Brand Health Tracking, General Population Consumers'
              title='Source: '
            />
            <ParagraphNesFav
              text='Average year-over-year gap to top branded competitor in key categories where our flagship brands play'
              title='Interpretation: '
            />
          </SectionNesFav>
        </div>
      </LayoutApp>
    </ScreenWrapper>
  )
}

export default Dimensions()(connect(
  state => ({
    quarter: state.sections.quarter,
    activeSection: state.sections.activeSection,
    isGraphLoading: state.values.isGraphLoading,
    nesFavBrandMetrics: state.values.nesFavBrandMetrics,
    bars: state.values.bars,
    fiscDateNesFavBrand: state.sections.fiscDateNesFavBrand
  }),
  { setActiveSection, setDataNesFavBrand }
)(NesFavBrand))

///code latest
