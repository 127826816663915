import React, { useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LinkInsightsTyson from './section/LinkInsights@Tyson'

import ConfidentialitySection from '../components/ConfidentialitySection/ConfidentialitySection';
import ModalContent from '../components/Modal/Modal'
import AboutSection from '../components/AboutSection/AboutSection';
import Tutorial from '../../src/components/Tutorial/Tutorial'
import Contact from '../../src/components/Contact/Contact'
import InfoModal from '../../src/components/InfoModal/InfoModal'
import getGreetingTime from '../utils/getGreetingTime';

import DropdownMenu from './DropdownMenus';
import { useOktaAuth } from '@okta/okta-react'


import GearIcon from '../images/Gear'
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import toClassName from '../utils/toClassName';

const LayoutApp = ({ children, keepPaddings }) => {
  const history = useHistory()
  const { authState, oktaAuth } = useOktaAuth();
  const {accessToken: { accessToken, claims }} = authState;

  const [menuRef, setMenuRef] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [settingsRef, setSettingsRef] = useState(null)
  const [settingsOpen, setSettingsOpen] = useState(false)

  const openDropdown = (stateControl) => {
    stateControl(true)
  }

  const closeDropdown = (stateControl) => {
    stateControl(false)
  }

  const toggleDropdown = (stateControl, value) => {
    stateControl(!value)
  }


  const {
    userName,
    showModal,
    activeSection,
    userPrivilages
  } = useSelector(state => ({
    userName: state.userState.userInfo,
    showModal: state.app.showModal,
    activeSection: state.sections.activeSection,
    userPrivilages: state.userState.userPrivilages
  }))

  const dispatch = useDispatch()

  const openModal = (e, type) => {
    e.preventDefault()
    dispatch({
      type: 'SET_MODAL_SHOW',
      payload: {
        active: true,
        type
      }
    })
  }

  const closeModal = () => {
    dispatch({
      type: 'SET_MODAL_SHOW',
      payload: {
        active: false
      }
    })
  }

  const FINANCE_ROUTE = '/r/finance';
  const POULTRY_FINANCE_ROUTE = '/r/poultry/finance';
  const PREPARED_FINANCE_ROUTE = '/r/prepared/finance';
  const FRESHMEATS_FINANCE_ROUTE = '/r/freshMeatsBeef/finance';
  const MCDONALDS_FINANCE_ROUTE = '/r/McDonalds/finance?segment=McDonalds';
  const POULTRY_OPERATIONS_ROUTE = '/r/operations/finance';
  const POULTRY_FINANCE_OPERATIONS_ROUTE = '/r/operations/poultry/finance';
  const PREPARED_FINANCE_OPERATIONS_ROUTE = '/r/operations/prepared/finance';
  const FRESHMEATS_FINANCE_OPERATIONS_ROUTE = '/r/operations/freshMeatsBeef/finance';
  const MCDONALDS_FINANCE_OPERATIONS_ROUTE = '/r/operations/McDonalds/finance';


  const financeRoutes = {
    'false': FINANCE_ROUTE,
    'FRESH MEATS': FRESHMEATS_FINANCE_ROUTE,
    'PREPARED FOODS': PREPARED_FINANCE_ROUTE,
    'MCDONALDS': MCDONALDS_FINANCE_ROUTE,
    'CHICKEN': POULTRY_FINANCE_ROUTE,
    'OPERATIONS': POULTRY_OPERATIONS_ROUTE,
    'OPERATIONS_POULTRY': POULTRY_FINANCE_OPERATIONS_ROUTE,
    'OPERATIONS_PREPARED': PREPARED_FINANCE_OPERATIONS_ROUTE,
    'OPERATIONS_FRESH_MEATS': FRESHMEATS_FINANCE_OPERATIONS_ROUTE,
    'OPERATIONS_MCDONALDS': MCDONALDS_FINANCE_OPERATIONS_ROUTE,
  }

  const TM_ROUTE = '/r/team-members';
  const PREPARED_TM_ROUTE = '/r/prepared/team-members';

  const tmRoutes = {
    'false': TM_ROUTE,
    'PREPARED FOODS': PREPARED_TM_ROUTE,
    "HROSHA": TM_ROUTE,
  }

  const OSHA_ROUTE = '/r/osha';
  const PREPARED_OSHA_ROUTE = '/r/prepared/osha';

  const oshaRoutes = {
    'false': OSHA_ROUTE,
    'PREPARED FOODS': PREPARED_OSHA_ROUTE,
    "HROSHA": OSHA_ROUTE,
  }

  const IT_DASHBOARD = '/information-technology';

  const PLANT_OPS_ROUTE = '/r/plant-ops';
  const PLANT_OPS_POULTRY_ROUTE = '/r/poultry/plant-ops';
  const PLANT_OPS_PREPARED_ROUTE = '/r/prepared/plant-ops';
  const PLANT_OPS_FRESHMEATS_ROUTE = '/r/freshMeats/plant-ops';
  const PLANT_OPS_MCDONALDS_ROUTE = '/r/McDonalds/plant-ops';

  const NES_FAV_BRAND_ROUTE = '/r/nes-fav-brand';

  const plantOpsRoutes = {
    'false': PLANT_OPS_ROUTE,
  }


  const switchView = (e, view) => {
    e.preventDefault()


    const links = {
      'CHICKEN': '/poultry',
      'PREPARED FOODS': '/prepared',
      'FRESH MEATS': '/freshMeats',
      'MCDONALDS': '/McDonalds',
      'HROSHA': '/hrosha',
      'OPERATIONS': '/operations',
      'OPERATIONS_POULTRY': '/operations/poultry',
      'OPERATIONS_PREPARED': '/operations/prepared',
      'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
      'OPERATIONS_MCDONALDS': '/operations/McDonalds',
      'IT_USER': '/information-technology',
      'false': '/'
    }

    dispatch({
      type: 'SET_USER_PRIVILAGES',
      payload: {
        userPrivilages: {
          ...userPrivilages,
          view
        }
      }
    })

    history.push(links[view.toString()])
  }

  const signout = async () => {
    await oktaAuth.signOut();
  };

  const getViewLinks = () => {
    const v = (userPrivilages.view === 'CHICKEN') ?
      [{ title: 'FINANCE', href: financeRoutes[userPrivilages.view + ''] },
      { title: 'TEAM MEMBERS', href: '/r/poultry/team-members?segment=Poultry' },
      { title: 'OSHA', href: '/r/poultry/osha?segment=Poultry' },
      { title: 'OPS PERFORMANCE', href: PLANT_OPS_POULTRY_ROUTE },
      { title: 'FILL RATE', href: '/r/poultry-fill-rate' },
      (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
    ]
      :
      (userPrivilages.view === 'PREPARED FOODS') ?
        [{ title: 'FINANCE', href: financeRoutes[userPrivilages.view + ''] },
        { title: 'TEAM MEMBERS', href: '/r/prepared/team-members?segment=Prepared+Foods' },
        { title: 'OSHA', href: '/r/prepared/osha?segment=Prepared+Foods' },
          // { title: 'OPS PERFORMANCE', href: PLANT_OPS_PREPARED_ROUTE },
          { title: 'FILL RATE', href: '/r/prepared-fill-rate' },
          (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
        ]
        :
        (userPrivilages.view === 'FRESH MEATS') ?
          [{ title: 'FINANCE', href: financeRoutes[userPrivilages.view + ''] },
          { title: 'TEAM MEMBERS', href: '/r/freshMeats/team-members?segment=Fresh+Meats' },
          { title: 'OSHA', href: '/r/freshMeats/osha?segment=Fresh+Meats' },
          { title: 'OPS PERFORMANCE', href: PLANT_OPS_FRESHMEATS_ROUTE },
          // { title: 'FILL RATE', href: '/r/freshMeats-fill-rate' },
          (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
        ]
          :
          (userPrivilages.view === 'MCDONALDS') ?
            [{ title: 'FINANCE', href: financeRoutes[userPrivilages.view + ''] },
            { title: 'TEAM MEMBERS', href: '/r/McDonalds/team-members?segment=McDonalds' },
            { title: 'OSHA', href: '/r/McDonalds/osha?segment=McDonalds' },
              // { title: 'OPS PERFORMANCE', href: PLANT_OPS_MCDONALDS_ROUTE },
              (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
            ]
            :
            (userPrivilages.view === 'HROSHA') ?
              [{ title: 'TEAM MEMBERS', href: tmRoutes[userPrivilages.view + ''] },
              { title: 'OSHA', href: oshaRoutes[userPrivilages.view + ''] },
              (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
            ]
              :
              ((userPrivilages.view === 'OPERATIONS') && window.location.href.includes('poultry')) ?
                [{ title: 'FINANCE', href: POULTRY_FINANCE_OPERATIONS_ROUTE },
                { title: 'TEAM MEMBERS', href: '/r/poultry/team-members?segment=Poultry' },
                { title: 'OSHA', href: '/r/poultry/osha?segment=Poultry' },
                { title: 'OPS PERFORMANCE', href: PLANT_OPS_POULTRY_ROUTE },
                { title: 'FILL RATE', href: '/r/poultry-fill-rate' },
                (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
                ]
                :
                ((userPrivilages.view === 'OPERATIONS') && window.location.href.includes('prepared')) ?
                  [{ title: 'FINANCE', href: PREPARED_FINANCE_OPERATIONS_ROUTE },
                  { title: 'TEAM MEMBERS', href: '/r/prepared/team-members?segment=Prepared+Foods' },
                  { title: 'OSHA', href: '/r/prepared/osha?segment=Prepared+Foods' },
                    // { title: 'OPS PERFORMANCE', href: PLANT_OPS_PREPARED_ROUTE },
                    { title: 'FILL RATE', href: '/r/prepared-fill-rate' },
                    (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
                  ]
                  :
                  ((userPrivilages.view === 'OPERATIONS') && window.location.href.includes('freshMeats')) ?
                    [{ title: 'FINANCE', href: FRESHMEATS_FINANCE_OPERATIONS_ROUTE },
                    { title: 'TEAM MEMBERS', href: '/r/freshMeats/team-members?segment=Fresh+Meats' },
                    { title: 'OSHA', href: '/r/freshMeats/osha?segment=Fresh+Meats' },
                    { title: 'OPS PERFORMANCE', href: PLANT_OPS_FRESHMEATS_ROUTE },
                    // { title: 'FILL RATE', href: '/r/freshMeats-fill-rate' },
                    (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
                    ]
                    :
                    ((userPrivilages.view === 'OPERATIONS') && window.location.href.includes('McDonalds')) ?
                      [{ title: 'FINANCE', href: MCDONALDS_FINANCE_OPERATIONS_ROUTE },
                      { title: 'TEAM MEMBERS', href: '/r/McDonalds/team-members?segment=McDonalds' },
                      { title: 'OSHA', href: '/r/McDonalds/osha?segment=McDonalds' },
                        // { title: 'OPS PERFORMANCE', href: PLANT_OPS_MCDONALDS_ROUTE },
                        (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
                      ]
                      :
                      (userPrivilages.view === 'OPERATIONS') ?
                        [{ title: 'FINANCE', href: financeRoutes[userPrivilages.view + ''] },
                        { title: 'TEAM MEMBERS', href: '/r/team-members' },
                        { title: 'OSHA', href: '/r/osha' },
                        { title: 'OPS PERFORMANCE', href: PLANT_OPS_ROUTE },
                        { title: 'NES & FAVORITE BRAND', href: NES_FAV_BRAND_ROUTE },
                        { title: 'FILL RATE', href: '/r/fill-rate' },
                        (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ?
                        [{ title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') },
                        { title: 'POULTRY BU VIEW', href: '/poultry', onClick: (e) => switchView(e, 'CHICKEN') },
                        { title: 'PREPARED BU VIEW', href: '/prepared', onClick: (e) => switchView(e, 'PREPARED FOODS') },
                        { title: 'FRESH MEATS BU VIEW', href: '/freshMeats', onClick: (e) => switchView(e, 'FRESH MEATS') },
                        { title: "MCDONALD'S BU VIEW", href: '/McDonalds', onClick: (e) => switchView(e, 'MCDONALDS') }]
                        :
                        { title: 'POULTRY BU VIEW', href: '/poultry', onClick: (e) => switchView(e, 'CHICKEN') },
                        { title: 'PREPARED BU VIEW', href: '/prepared', onClick: (e) => switchView(e, 'PREPARED FOODS') },
                        { title: 'FRESH MEATS BU VIEW', href: '/freshMeats', onClick: (e) => switchView(e, 'FRESH MEATS') },
                        { title: "MCDONALD'S BU VIEW", href: '/McDonalds', onClick: (e) => switchView(e, 'MCDONALDS') },
                        ]

                          :

                          (userPrivilages.view === 'IT_USER')
                          ?
                          [
                            {},
                          ]
                          :

                          [
                            { title: 'FINANCE', href: financeRoutes[userPrivilages.view + ''] },
                            { title: 'TEAM MEMBERS', href: tmRoutes[userPrivilages.view + ''] },
                            { title: 'OSHA', href: oshaRoutes[userPrivilages.view + ''] },
                            { title: 'OPS PERFORMANCE', href: PLANT_OPS_ROUTE },
                            { title: 'NES & FAVORITE BRAND', href: NES_FAV_BRAND_ROUTE },
                            { title: 'FILL RATE', href: '/r/fill-rate' },
                            // { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') },
                            (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User')) ? { title: 'TECHNOLOGY VIEW', href: IT_DASHBOARD, onClick: (e) => switchView(e, 'IT_USER') } : {},
                            // { title: 'Op VIEW', href: '/r/operations/finance', onClick: (e) => switchView(e, 'OPERATIONS')  },
                            { title: 'POULTRY BU VIEW', href: '/poultry', onClick: (e) => switchView(e, 'CHICKEN') },
                            { title: 'PREPARED BU VIEW', href: '/prepared', onClick: (e) => switchView(e, 'PREPARED FOODS') },
                            { title: 'FRESH MEATS BU VIEW', href: '/freshMeats', onClick: (e) => switchView(e, 'FRESH MEATS') },
                            { title: "MCDONALD'S BU VIEW", href: '/McDonalds', onClick: (e) => switchView(e, 'MCDONALDS') },
                          ]

    if (userPrivilages.isSuperUser && userPrivilages.view) v.push({ title: 'ENTERPRISE VIEW', href: '/', onClick: (e) => switchView(e, false) })

    return v
  }

  const titleName = () => {
    return (userPrivilages.view === "CHICKEN" || userPrivilages.view === "PREPARED FOODS" || userPrivilages.view === "FRESH MEATS"
    || userPrivilages.view === "MCDONALDS" || userPrivilages.view === "HROSHA" || userPrivilages.view === "OPERATIONS")
    && (window.location.href.includes('information-technology') || window.location.href.includes('it-dashboard')) ? "Insights@Tyson - Technology"
    : userPrivilages.view === "CHICKEN" ? "Insights@Tyson - Poultry"
      : userPrivilages.view === "PREPARED FOODS" ? "Insights@Tyson - Prepared"
        : userPrivilages.view === "FRESH MEATS" ? "Insights@Tyson - Fresh Meats"
          : userPrivilages.view === "MCDONALDS" ? "Insights@Tyson - McDonald's"
            : userPrivilages.view === "HROSHA" ? "Insights@Tyson - People Metrics"
              : userPrivilages.view === "OPERATIONS" && window.location.href.includes('poultry') ? "Insights@Tyson - Poultry"
                : userPrivilages.view === "OPERATIONS" && window.location.href.includes('prepared') ? "Insights@Tyson - Prepared"
                  : userPrivilages.view === "OPERATIONS" && window.location.href.includes('freshMeats') ? "Insights@Tyson - Fresh Meats"
                    : userPrivilages.view === "OPERATIONS" && window.location.href.includes('McDonalds') ? "Insights@Tyson - McDonald's"
                      : userPrivilages.view === "OPERATIONS" ? "Insights@Tyson"
                        : userPrivilages.view === "IT_USER" ? "Insights@Tyson - Technology"
                            : "Insights@Tyson";
  };

  return (
    <div className={'flex flex-col min-h-screen box-border overflow-hidden ' + activeSection}>
      <header className='box-border px-5 lg:px-20 flex flex-row justify-between items-center pt-4 lg:pt-2.5 pb-3 lg:pb-1.5 bg-gray-50 shadow-xxs border-b-05 border-gray-200'>
        <LinkInsightsTyson href='/'>
          {titleName()}
        </LinkInsightsTyson>


        <div className='flex justify-end'>
          <div className='hidden lg:block text-right'>
            <div>
              <span className='font-base text-blue-600'>
                Today {moment().format('L')}
              </span>
            </div>
            <div>
              <span className='font-base text-blue-600 font-bold'>
                <p><b>{getGreetingTime(moment())}, {userName ? userName.given_name + `!` : 'Anonymous'}</b></p>
              </span>
            </div>
          </div>

          <div className='w-24 flex items-center justify-end'>
            <div
              ref={setMenuRef}
              className='mr-3 lg:mr-5'
              onMouseEnter={() => openDropdown(setMenuOpen)}
              onMouseLeave={() => closeDropdown(setMenuOpen)}
            >
              <FeatherIcon
                className='cursor-pointer'
                size={20}
                icon='menu'
                stroke='#2699FB' />
              <DropdownMenu
                anchorEl={menuRef}
                open={menuOpen}
                listItems={getViewLinks()}
              />
            </div>

            <div
              ref={setSettingsRef}
              onMouseEnter={() => openDropdown(setSettingsOpen)}
              onMouseLeave={() => closeDropdown(setSettingsOpen)}
            >
              <GearIcon
                className='cursor-pointer'
              />
              <DropdownMenu
                anchorEl={settingsRef}
                open={settingsOpen}
                listItems={[
                  { title: 'ABOUT', href: '/', onClick: (e) => openModal(e, 'AboutSection') },
                  { title: 'APP TUTORIAL', href: '/', onClick: (e) => openModal(e, 'tutorial') },
                  { title: 'TECH SUPPORT', href: '/', onClick: (e) => openModal(e, 'contact') },
                  { title: 'SIGN OUT', onClick: (e) => signout() }
                ]}
              />
            </div>
          </div>
        </div>
      </header>

      <div className={toClassName(['lg:px-20 flex-1', keepPaddings ? 'px-5' : 'px-0'])}>
        {children}
      </div>

      <footer className='px-5 lg:px-20 relative text-center lg:text-right py-3 box-border bg-gray-400 lg:bg-none'>
        {/* TODO: insert logo */}
        <a onClick={(e) => openModal(e, 'Confidentiality')} className='hover:no-underline text-red-400 font-semibold font-base1 cursor-pointer'>
          HIGHLY CONFIDENTIAL - Not to be copied/distributed. <span className='underline'>Detail</span>
        </a>
      </footer>

      <ModalContent
        modalIsOpen={showModal.active}
        setShowModal={closeModal}
      >
        {showModal.type === 'contact' && <Contact />}
        {showModal.type === 'tutorial' && (<Tutorial width={window.innerWidth} />)}
        {showModal.type === 'Confidentiality' && <ConfidentialitySection />}
        {showModal.type === 'AboutSection' && <AboutSection />}
        {showModal.type === 'imodal' && <InfoModal />}
      </ModalContent>
    </div>
  )
}

export default LayoutApp
