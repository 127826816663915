import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipTrUnitGraph = ({ payload, currentKey, className }) => {
  const {
    activeSection,
    year
  } = useSelector(state => ({
    activeSection: state.sections.activeSection,
    year: state.sections.year
  }))

  const getTitle = () => {
    if (!payload[0].payload) return null

    return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(payload[0].payload.period || payload[0].payload.fiscMonth) - 1]}</span> FY{(payload[0].payload.fiscYear || year)- 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getItems = () => {
    if (!(currentKey in payload[0].payload)) return []

    return [
      <div className='whitespace-nowrap' key={0}>{ (activeSection === 'TURNOVER_RATE' ? 'Turnover Rate' : activeSection === 'ABSENTEEISM' ? 'Absenteeism' : 'Team Members') + ': ' + '' + formatStr(formatNumber({ number: payload[0].payload[currentKey], financePercents: activeSection === 'TURNOVER_RATE', employeePercents: activeSection === 'ABSENTEEISM' }))  }</div>
    ]
  }

  return (
    <>
      {
        payload && payload.length ?
        <Tooltip
          className={className}
        >
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }

    </>

  )
}

export default TooltipTrUnitGraph
