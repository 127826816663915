import { getPlantOpsData } from '../services/dataService'
import {
    getHeaderData,
    getGraphData,
} from '../utils/dataMappingPlantOps';
import { REQUEST_DATA, receiveDataGraph, onError, setTrend, receiveDataHeader, receiveDataGraphAdditional } from './valuesActions';

export const RECEIVE_LOCATION_UNITS = 'RECEIVE_LOCATION_UNITS';

export const SET_PLANT_OPS_METRICS = 'SET_PLANT_OPS_METRICS';
export const SET_PLANT_OPS_FISC_DATE = 'SET_PLANT_OPS_FISC_DATE';


export const setPlantOpsMetrics = (plantOpsMetrics) => ({
  type: SET_PLANT_OPS_METRICS,
  payload: {
    plantOpsMetrics
  }
})

export const setPlantOpsFiscDate = (plantOpsFiscDate) => ({
    type: SET_PLANT_OPS_FISC_DATE,
    payload: {
      plantOpsFiscDate
    }
})


const setLocationUnits = (locationUnits) => ({
  type: RECEIVE_LOCATION_UNITS,
  payload: {
    locationUnits
  }
})

export const requestData = (type, qi) => ({
  type: REQUEST_DATA,
  payload: { type, qi }
})

export function setDataPlantOps (params, accessToken = '', {buArray, buType}) {
  console.log({params}, '!E@')
  return async function (dispatch) {
    dispatch(requestData(1))
    const { type, data } = await getPlantOpsData(params, accessToken);

    if (type === 'error') {
      dispatch(onError(data))
    } else {
        const dGraph = data.graphBarRecords;
        const graphData = {graphBarRecords:  dGraph, graphBarRecordsPY: data.graphBarRecordsPY};

        let locationUnits = data.locationData;
        console.log({data, locationUnits, buArray}, '124124124dwq')
        dispatch(receiveDataHeader({ header: data.headerRecord }));
        dispatch(setLocationUnits(  locationUnits ));
        dispatch( setTrend({bsTrend: data.twoYearTrendRecord}) );
        dispatch( receiveDataGraphAdditional( data.graphSummary ) )
        dispatch( receiveDataGraph( graphData ) );
    }
    return data
  }
}
