import React from 'react'
import { useSelector } from 'react-redux'

import CurrentScreenValues from '../componentsGeneral/section/CurrentScreenValues'
import formatNumber from "../utils/formatNumber";


const CurrentScreenValuesFillRate = ({title}) => {
  const {
    header,
    activeTabTitle,
    activeBu,
    activeSection
  } = useSelector(state => ({
    header: state.values.header,
    activeTabTitle: state.sections.activeTabTitle,
    activeBu: state.sections.activeBu,
    activeSection: state.sections.activeSection
  }))


  const getMainValue = () => {
    return header ? header.fillRate : 0
  }

  const formatValue = ({ appendStr, prependStr, number }) => {
    return `${prependStr}${number}${appendStr}`
  }

  const getBottomItem = (value, appendStr) => {
    return (<div key={appendStr} className='text-sm text-black font-medium'>
      <span>
        { formatValue(value) } { appendStr }
      </span>
    </div>)
  }

  const getBottomValues = () => {
    if (activeBu.length < 1) return null

    return [
      getBottomItem(formatNumber({number: header?.totalOrder, employeePercents: true}), ' of Total Tyson Orders'),
      getBottomItem(formatNumber({number: header?.variance, employeePercents: true}), ` Variance of ${activeBu.length == 1 ? 'Tyson' : activeBu[0] } Fill Rate`)
    ]
  }

  return (
    <CurrentScreenValues
      value={getMainValue()}
      employeePercents={true}
      needSign={false}
      defaultTitle={title}
      includeDot={true}
      dotValue={getMainValue()}
      prependClasses='self-start'
    >
      <div className='mt-2 flex flex-col flex-wrap items-start justify-start px-4'>
        { getBottomValues() }
      </div>
    </CurrentScreenValues>
  )
}

export default CurrentScreenValuesFillRate
