import { getNesFavBrandData } from '../services/dataService'

import { REQUEST_DATA, receiveDataGraph, onError, setTrend, receiveDataHeader, receiveDataGraphAdditional } from './valuesActions';

export const RECEIVE_LOCATION_UNITS = 'RECEIVE_LOCATION_UNITS';

export const SET_NES_FAV_BRAND_METRICS = 'SET_NES_FAV_BRAND_METRICS';
export const SET_NES_FAV_BRAND_FISC_DATE = 'SET_NES_FAV_BRAND_FISC_DATE';


export const setNesFavBrandMetrics = (nesFavBrandMetrics) => ({
  type: SET_NES_FAV_BRAND_METRICS,
  payload: {
    nesFavBrandMetrics
  }
})

export const setFiscDateNesFavBrand = (date) => ({
    type: SET_NES_FAV_BRAND_FISC_DATE,
    payload: {
        date
    }
})


const setLocationUnits = (locationUnits) => ({
  type: RECEIVE_LOCATION_UNITS,
  payload: {
    locationUnits
  }
})

export const requestData = (type, qi) => ({
  type: REQUEST_DATA,
  payload: { type, qi }
})

export function setDataNesFavBrand (params, accessToken = '') {
  return async function (dispatch) {
    dispatch(requestData(1))
    const { type, data } = await getNesFavBrandData(params, accessToken);

    if (type === 'error') {
      dispatch(onError(data))
    } else {
      const {
        graphBarRecordsNes,
        graphBarRecordsFavBrand,
        graphBarRecordsFavBrandAvg
      } = data
      const graphData = { graphBarRecords: {
          graphBarRecordsNes,
          graphBarRecordsFavBrand,
          graphBarRecordsFavBrandAvg
      }};

      dispatch( receiveDataGraph( graphData ) );
    }
    return data
  }
}
