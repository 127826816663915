import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../actions/sectionsActions'
import FeatherIcon from 'feather-icons-react';

import toClassName from '../utils/toClassName';
import Button from '../componentsGeneral/section/Button';
import Dropdown from '../componentsGeneral/Dropdown';

const FilterSelectTm = ({ }) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const dispatch = useDispatch()
  const {
    filters,
    activeSection
  } = useSelector(state => ({
    filters: state.sections.filters,
    activeSection: state.sections.activeSection,
  }))

  const manageFilter = (filter, type) => {
    dispatch(setFilter({ filter, type }));
  }

  const optionsTop = [
    { title: 'Corp', onClick: () => { manageFilter('Corporate', filters.corpOps.includes('Corporate') ? 'corpOpsRemove' : 'corpOps') }, isActive: filters.corpOps.includes('Corporate') },
    { title: 'Ops', onClick: () => { manageFilter('Operations', filters.corpOps.includes('Operations') ? 'corpOpsRemove' : 'corpOps') }, isActive: filters.corpOps.includes('Operations') },
    { title: 'Support', onClick: () => { manageFilter('Support', filters.corpOps.includes('Support') ? 'corpOpsRemove' : 'corpOps') }, isActive: filters.corpOps.includes('Support') },

  ]

  const optionsBottom = [
    { title: 'Hourly', onClick: () => { manageFilter('Mgmt', 'hourlySalary') }, isActive: filters.hourlySalary !== 'Mgmt' },
    { title: 'Mgmt/Mgmt Support', onClick: () => { manageFilter('Labor', 'hourlySalary') }, isActive: filters.hourlySalary !== 'Labor' }
  ]

  const optionsAbsenteesimTop = [
    { title: 'Yes', onClick: () => { manageFilter('Y', 'absenteeismBaseLine') }, isActive: filters.absenteeismBaseLine.includes('Y') },
    { title: 'No', onClick: () => { manageFilter('N', 'absenteeismBaseLine') }, isActive: filters.absenteeismBaseLine.includes('N') },
  ]

  const optionsAbsenteesim = [
    { title: 'Planned', onClick: () => { manageFilter('Planned', 'absenteeism') }, isActive: filters.absenteeism.includes('Planned') },
    { title: 'Unplanned', onClick: () => { manageFilter('Unplanned', 'absenteeism') }, isActive: filters.absenteeism.includes('Unplanned') },
    { title: 'Leave of Absence', onClick: () => { manageFilter('Leave of Absence', 'absenteeism') }, isActive: filters.absenteeism.includes('Leave of Absence') },
  ]

  const getAppliedFilters = () => {
    return activeSection === 'ABSENTEEISM' ? filters.absenteeism.length + filters.absenteeismBaseLine.length : filters.corpOps.length + (!filters.hourlySalary ? 2 : 1)
  }

  return (
    <div
      className="flex justify-center items-stretch"
    >
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(!open)}
      >
        <Button
          isActive={true}
          size="md"
          setRef={setAnchorEl}
        >
          <span> {getAppliedFilters()} filters applied </span>
          <FeatherIcon className={toClassName([open ? 'rotate-180' : '', 'ml-2 transform transition-all text-white'])} icon={'chevron-down'} />
        </Button>
        <Dropdown
          anchorEl={anchorEl}
          offset={[0, 0]}
          show={open}
          style={{ width: 183 }}
          placement='bottom-start'
        >
          {(activeSection === 'TEAM_MEMBERS' || activeSection === 'TURNOVER_RATE')
            ?
            <div className='shadow bg-white border border-gray-700 rounded px-2 pt-2'>
              <span className='text-sm text-black'>
                Select at least one option from each group
              </span>
              <ul className='py-2 px-1'>
                {
                  optionsTop.map(({ isActive, title, onClick }) =>
                    <li
                      onClick={onClick}
                      key={title}
                      className='flex items-start space-x-2 mb-4 last:mb-0 cursor-pointer'
                    >
                      <span className={toClassName(["flex items-center justify-center rounded border border-gray-600 w-6 h-6", isActive ? 'bg-gray-600' : ''])}>
                        {isActive && <FeatherIcon icon='check' className="text-white" />}
                      </span>
                      <span
                        className='text-black text-base2 w-full'
                      >
                        {title}
                      </span>
                    </li>
                  )
                }
              </ul>
              <ul className='py-2 px-1 border-t border-gray-700'>
                {
                  optionsBottom.map(({ isActive, title, onClick }) =>
                    <li
                      onClick={onClick}
                      key={title}
                      className='flex items-start space-x-2 mb-4 cursor-pointer'
                    >
                      <span className={toClassName(["flex items-center justify-center rounded border border-gray-600 w-6 h-6", isActive ? 'bg-gray-600' : ''])}>
                        {isActive && <FeatherIcon icon='check' className="text-white" />}
                      </span>
                      <span
                        className='text-black text-base2 w-full'
                      >
                        {title}
                      </span>
                    </li>
                  )
                }
              </ul>
            </div>
            :
            <div className='shadow bg-white border border-gray-700 rounded px-2 pt-2'>
              <span className='text-sm text-black'>
              <div>Select at least one option from each group</div>
              </span>
              <span className='text-sm font-bold text-black'>
              <div>Baseline filter</div>
              </span>
              <ul className='py-2 px-1'>
                {
                  optionsAbsenteesimTop.map(({ isActive, title, onClick }) =>
                    <li
                      onClick={onClick}
                      key={title}
                      className='flex items-start space-x-2 mb-4 last:mb-0 cursor-pointer'
                    >
                      <span className={toClassName(["flex items-center justify-center rounded border border-gray-600 w-6 h-6", isActive ? 'bg-gray-600' : ''])}>
                        {isActive && <FeatherIcon icon='check' className="text-white" />}
                      </span>
                      <span
                        className='text-black text-base2 w-full'
                      >
                        {title}
                      </span>
                    </li>
                  )
                }
              </ul>
              <ul className='py-2 px-1 border-t border-gray-700'>
              <span className='text-sm font-bold text-black'>
              <div>Classification filter</div>

              </span>
                {
                  optionsAbsenteesim.map(({ isActive, title, onClick }) =>
                    <li
                      onClick={onClick}
                      key={title}
                      className='flex items-start space-x-2 mb-4 cursor-pointer'
                    >
                      <span className={toClassName(["flex items-center justify-center rounded border border-gray-600 w-6 h-6", isActive ? 'bg-gray-600' : ''])}>
                        {isActive && <FeatherIcon icon='check' className="text-white" />}
                      </span>
                      <span
                        className='text-black text-base2 w-full'
                      >
                        {title}
                      </span>
                    </li>
                  )
                }
              </ul>
            </div>
          }
        </Dropdown>
      </div>
    </div>

  )
}

export default FilterSelectTm
