import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipUnitsFillRate = ({ payload, activeQYSwitcher }) => {
  const {
    year,
    activeTab
  } = useSelector(state => ({
    year: state.sections.year,
    activeTab: state.sections.activeTab
  }))

  const getTitle = () => {
    const data = payload[0].payload

    return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period || data.fiscMonth) - 1]}</span> FY{(data.fiscYear || year) - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getItems = () => {
    if (!payload[0].payload) return []
    const data = payload[0].payload

    return <div>Fill Rate: {formatStr( formatNumber({ number: data.fillRate, employeePercents: true }) )}</div>;
  }

  return (
    <>
      {
        payload && payload.length && payload[0].payload ?
        <Tooltip>
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }

    </>

  )
}

export default TooltipUnitsFillRate
