import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipUnitsPlantOps = ({ payload, activeQYSwitcher }) => {
  const {
    year,
    activeTab
  } = useSelector(state => ({
    year: state.sections.year,
    activeTab: state.sections.activeTab
  }))

  const getTitle = () => {
    const data = payload[0].payload

    if (activeQYSwitcher === 'Y') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period || data.fiscMonth) - 1]}</span> FY{(data.fiscYear || year) - 2000}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(data.period || data.fiscMonth)}</span> FY{year - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getName = () => {
    return {
      'CapacityUtilisation Harvesting': 'CU - Harvesting',
      'CapacityUtilisation Processing': 'CU - Processing',
      "Efficiency Harvesting": 'Efficiency - Harvesting',
      "Efficiency Processing": 'Efficiency - Processing'
    }[activeTab]
  }

  const getItems = () => {
    if (!payload[0].payload) return []
    const data = payload[0].payload

    const items = [];

    items.push(
      <div>{ getName() }: { formatStr( formatNumber({number: data.CapacityRecorded, employeePercents: true}) ) }</div>
    )

    return items;
  }

  return (
    <>
      {
        payload && payload.length && payload[0].payload ?
        <Tooltip>
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }
      
    </>
    
  )
}

export default TooltipUnitsPlantOps