import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSection } from '../../actions/sectionsActions'

import Button from './Button'
import UpdatedText from './UpdatedText'

const SectionSwitcherDesktop = ({ buttons }) => {
  const dispatch = useDispatch()
  const {
    activeSection
  } = useSelector(state => ({
    activeSection: state.sections.activeSection
  }))

  const clickButton = (to) => {
    if (to !== activeSection) dispatch( setActiveSection(to) );
  }

  return (
    <div className="flex justify-center lg:justify-start">
      <div className='flex justify-start lg:flex-1 lg:justify-center lg:justify-between overflow-x-scroll lg:border-b items-end no-scrollbar'>
        <div className="flex lg:relative lg:top-0.5 space-x-4 lg:space-x-0">
          {
            buttons.map(({ title, icon=null, to }) => <Button 
              key={title}
              type='secondary' 
              className='switcher-button'
              onClick={() => clickButton(to)}  
              isActive={activeSection === to}
            >
              <span className='inline-flex flex-col justify-center items-center'>
                <span className="hidden lg:inline">{ icon }</span>
                { title }
              </span>
            </Button>)
          }
        </div>
        <UpdatedText
          className='hidden lg:block'
        />
      </div>
    </div>
  )
}

export default SectionSwitcherDesktop