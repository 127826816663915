import React from 'react'
import Button from '../componentsGeneral/section/Button'

const TabsTm = ({ options, isMobile }) => {
  return (
    <div className='rounded border border-gray-600 p-px whitespace-nowrap'>
      {
        options.map(({ title, active, onClick, disabled }) => <Button
        key={title}
        onClick={onClick}
        isActive={active}
        size={isMobile ? 'md--' : 'md'}
        disabled={disabled}
      >
        { title }
      </Button>)
      }
    </div>
  )
}

export default TabsTm