import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProperty } from "../utils/dataMappingTurnover";
import CurrentScreenValues from "../componentsGeneral/section/CurrentScreenValues";
import formatNumber from "../utils/formatNumber";

const CurrentScreenValueItDashboard = ({
  includeDot,
  data,
  title,
  currentKey,
  week,
  dataKey,
  month,
  navigationStage,
  activeSection,
}) => {
  function calculateDiff(current, compareWith) {
    // const difference = (compareWith - current) / ((compareWith + current) / 2);
    const difference = (current - compareWith) / compareWith;
    return (difference * 100).toFixed(2);
  }

  const [attr, setAttr] = useState();

  const { dataGraphAdditional, activeTab } = useSelector((state) => ({
    activeTab: state.sections.activeTab,
    dataGraphAdditional: state.values.dataGraphAdditional,
  }));

  function numberFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const getAdditionalValues = () => {
    if (data) {
      const compareWith =
        activeSection !== "IT_FINANCE_CLOUD" ? data[0].aop : data[0].forecast;
      const percent = calculateDiff(data[0].currentTotal, compareWith);
      return activeSection !== "IT_FINANCE_CLOUD"
        ? `${percent}% vs. Planned ($${numberFormatter(compareWith)})`
        : `${percent}% vs. Forecast ($${numberFormatter(compareWith)})`;
    }
  };

  const formatBottomValue = (value) => {
    const { prependStr, appendStr, number } = formatNumber({
      number: isNaN(value) ? 0 : value,
      employeePercents: true,
    });

    return `${prependStr}${number}${appendStr}`;
  };

  const getBottomAttrData = () => {
    if (data) {
      return (
        <div className="text-sm text-black font-medium">
          {activeSection === "IT_Hr_Vacancies" && activeTab === "Active" ? (
            <>
              Open Positions without requisitions greater than 14 days :{" "}
              {data.openPositionWoReqGt14Days}
            </>
          ) : activeSection === "IT_Hr_Positions" ? (
            <> Positions In Offer Status </>
          ) : activeSection === "IT_Hr_AvgDaysHire" ? (
            <>Average Days to Hire</>
          ) : activeSection === "IT_Hr_Vacancies" && activeTab === "Frozen" ? (
            <>Frozen Positions</>
          ) : activeSection === "IT_Hr_Vacancies" && activeTab === "All" ? (
            <>
              Vacancies: {data.vacancies} &nbsp;&nbsp; Frozen Positions :{" "}
              {data.frozenPositions}
            </>
          ) : (
            activeSection !== "IT_Hr_Vacancies" && (
              <>
                Turnover Rate:{" "}
                {data.turnoverRate === "Infinity"
                  ? "NA"
                  : `${Math.round(data.turnoverRate * 100)}%`}{" "}
                {includeDot}
              </>
            )
          )}
        </div>
      );
    }
  };

  const getBottomValues = () => {
    return dataGraphAdditional.map((item) => {
      return (
        <div key={item.viewBy} className="text-sm text-black font-medium">
          <span style={{ color: item.color }}>
            {getProperty(item.viewBy, "title")}:{" "}
          </span>
          <span>{formatBottomValue(item["avgActiveEmployees"])}</span>
        </div>
      );
    });
  };

  const getAttritionHeader = () => {
    let attrdata = "";
    // if (data.totalAttrition)
    //   Object.keys(data).map(() =>
    //     data["totalAttrition"].map((item) => (attrdata = item.total_attrition))
    //   );
    return attrdata;
  };

  function calculateDot() {
    if (data) {
      const compareWith =
        activeSection !== "IT_FINANCE_CLOUD" ? data[0].aop : data[0].forecast;
      const toPercent =
        ((data[0].currentTotal - compareWith) /
          ((compareWith - data[0].currentTotal) / 2)) *
        100;
      return toPercent > 1 || toPercent < -1 ? false : true;
    }
  }

  return (
    <>
      {activeSection.startsWith("IT_Hr") ? (
        <CurrentScreenValues
          value={
            activeSection === "IT_Hr_TOTAL_ATTRITION"
              ? data
                ? data.totalAttrition
                : ""
              : activeSection === "IT_Hr_TEAM_MEMBERS"
              ? data
                ? data.avgActiveEmployees
                : ""
              : activeSection === "IT_Hr_AvgDaysHire"
              ? data
                ? Math.round(data.avgDaysToHire)
                : ""
              : activeSection === "IT_Hr_Positions"
              ? data
                ? data.PositionsInOffer
                : ""
              : data
              ? activeTab === "All" 
                ? data.vacancies + data.frozenPositions
                : activeTab === "Frozen" ? data.frozenPositions : data.vacancies
              : ""
          }
          needScale={false}
          defaultTitle={
            activeSection !== "IT_Hr_TOTAL_ATTRITION"
              ? `IT OVERALL ${title}`
              : `IT Total ${title}`
          }
        >
          <div className="mt-2 flex flex-wrap items-center justify-center space-x-2 px-4">
            {activeSection !== "IT_Hr_TEAM_MEMBERS"
              ? getBottomAttrData()
              : getBottomValues()}
          </div>
        </CurrentScreenValues>
      ) : (
        <CurrentScreenValues
          value={data ? data[0].currentTotal : ""}
          needScale={currentKey !== "aoi" || currentKey !== "volume"}
          financePercents={currentKey === "aoi"}
          isNumberlessthan1000={true}
          defaultTitle={`IT OVERALL ${title}`}
          navigationStage={navigationStage}
          week={week}
          month={month}
          includeDot={includeDot}
          isItDash={true}
          isPosDot={calculateDot()}
        >
          <div className="mt-2 flex flex-col items-center justify-start">
            {getAdditionalValues()}
          </div>
        </CurrentScreenValues>
      )}
    </>
  );
};

export default CurrentScreenValueItDashboard;
