import React, { useState } from "react";

import PeriodSwitcher from "./PeriodSwitcher";

import CurrentScreenTitle from "./CurrentScreenTitle";
import Breadcrumb from "./Breadcrumb";
import TabsTm from "../../componentsTeamMembers/TabsTm";

const HeaderDesktop = ({
  week,
  month,
  children,
  quarter,
  year,
  activeQYSwitcher,
  activeSections,
}) => {
  const sectionIT = [
    "IT_FINANCE_ACTUALS",
    "IT_FINANCE_CONTROLLABLES",
    "IT_FINANCE_COMPENSATION",
    "IT_FINANCE_CLOUD",
    "IT_FINANCE_NonControllables",
    "IT_Hr_TEAM_MEMBERS",
    "IT_Hr_Vacancies",
  ];
  return (
    <header className="pt-4 lg:pt-6 pb-5 lg:pb-8 flex justify-center lg:justify-between items-center">
      <div className="flex flex-col">
        <Breadcrumb
          className={
            sectionIT.includes(`${activeSections}`)
              ? "lg:block"
              : "hidden lg:block"
          }
        />

        <CurrentScreenTitle
          className="text-black font-medium lg:font-semibold text-xl lg:text-xxl text-center"
          week={week}
          month={month}
          qrtr={quarter}
          yr={year}
          activeQYSwitcher={activeQYSwitcher}
        />
      </div>
      <div className="hidden lg:flex space-x-4">
        {sectionIT.includes(`${activeSections}`) ? (
          <>
            {children}
            <PeriodSwitcher />
          </>
        ) : (
          <>
            <PeriodSwitcher />
            {children}
          </>
        )}
      </div>
    </header>
  );
};

export default HeaderDesktop;
