import React from 'react';
import classes from './styles.module.css';
const HealthCheck = ()=>{
    return(
        <div className={classes.healthcheck} style={{minHeight: '100vh'}}>
            This page is to monitor availability of this application
        </div>
    )
}
export default HealthCheck;
