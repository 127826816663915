import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipTm = ({ payload, currentObj, display, dataKey }) => {
  const {
    activeQYSwitcher,
    year,
    activeSection,
    fiscDateEmployee,
    fiscDateEmployeeAbsenteesim,
  } = useSelector(state => ({
    activeQYSwitcher: state.sections.activeQYSwitcher,
    year: state.sections.year,
    activeSection: state.sections.activeSection,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
  }))

  const getTitle = () => {
    const data = payload[0].payload

    if (activeQYSwitcher === 'Y') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period) - 1]}</span> FY{year - 2000}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(data.period)}</span> FY{year - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getValue = (obj, key) => {
    if (obj[key + "Future"]) return obj[key + "Future"]
    if (obj[key] === null || obj[key] === undefined) return 0

    return obj[key]
  }

  const getItems = () => {
    if (!payload[0].payload) return []
    const data = payload[0].payload

    const r = Object.keys(currentObj)
    .sort((a, b) => currentObj[a].index - currentObj[b].index)
    .map(key => <div key={key} style={{ color: currentObj[key].color }}>
      {getValue(data, dataKey + key) != 0 && currentObj[key].title}
      {getValue(data, dataKey + key) != 0 && `: `}
      { getValue(data, dataKey + key) != 0 && formatStr( formatNumber({ number: getValue(data, dataKey + key), employeePercents: activeSection === 'TURNOVER_RATE' }) )  }
      </div>)

    if (activeSection === 'TURNOVER_RATE') {
      r.unshift(<div key={0}>Overall Turnover Rate: {
        formatStr(formatNumber({ number: data['Overall Turnover Rate'] || data['Overall Turnover RateFuture'], employeePercents: true }))
      }</div>)
    }

    return r
  }

  return (
    <>
      {
        payload && payload.length && payload[0].payload ?
        <Tooltip
          className={display ? '' : 'opacity-0'}
        >
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }

    </>

  )
}

export default TooltipTm
