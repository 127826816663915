import React from 'react'

const TextTooltip = ({ content, children }) => {
    return (
        <div className="tooltip contents">
            { children }
            <div className='tooltiptext bg-blue-600 text-white px-4 py-2 rounded'>{ content }</div>
        </div>
    )
}

export default TextTooltip