import React from 'react'

import DoubleButton from './DoubleButton'

const ContentLeftDesktopHeader = ({ 
  children, 
  includeSort, 
  clickSortAlph, 
  clickSortVal, 
  clickThird,
  percents, 
  number,
  activeSort
}) => {


  return (
    <div
      className='flex justify-between pb-2 truncate ...'
    >
      <div className='flex items-center'>
        { children }
      </div>
      {
        includeSort && 
        <div className='mr-1 flex items-center'>
          <span className='text-xs text-black font-semibold mr-2'>
            Sort by
          </span>
          <DoubleButton 
            firstText='A'
            firstOnClick={clickSortAlph}
            firstActive={activeSort === 'alph'}
            secondText={percents ? '%' : number ? '#' : '$'}
            secondOnClick={clickSortVal}
            secondActive={activeSort === 'val'}
            thirdText={clickThird ? '%' : false}
            thirdOnClick={clickThird}
            thirdActive={activeSort === 'third'}
            size='sm'
          />
        </div>
      }
    </div>
  )
}

export default ContentLeftDesktopHeader