import * as React from "react"

const SvgComponent = ({ size=36, color='#2699FB', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props}>
    <path data-name="Rectangle 19699" fill="none" d="M.001 0h36v36h-36z" />
    <path
      data-name="Path 12109"
      d="M20.213 32.207h-4.465c-.046-.05-.026-.11-.026-.164 0-1.381-.026-2.762-.07-4.143q-.083-2.574-.282-5.142a87.819 87.819 0 0 0-.462-4.55 53.032 53.032 0 0 0-1.547-7.978 15.782 15.782 0 0 0-1.306-3.323 4.354 4.354 0 0 0-.993-1.279 1.5 1.5 0 0 0-.895-.385 1.433 1.433 0 0 0-1.085.486 5.428 5.428 0 0 0-1.083 1.65 21.386 21.386 0 0 0-1.312 3.948c-.41 1.661-.713 3.343-.969 5.034-.219 1.446-.4 2.9-.545 4.352-.117 1.147-.212 2.3-.295 3.446a137.123 137.123 0 0 0-.3 6.182 175.36 175.36 0 0 0-.058 3.972.182.182 0 0 1-.023.127H.028a.229.229 0 0 1-.023-.156q-.007-2.305.11-4.607.126-2.517.389-5.025a69.746 69.746 0 0 1 1.5-8.95 37.577 37.577 0 0 1 2.393-7.156 14.608 14.608 0 0 1 2.44-3.829 5.741 5.741 0 0 1 1.805-1.369 3.637 3.637 0 0 1 3.592.287A7.668 7.668 0 0 1 14.376 5.7a19.564 19.564 0 0 1 2.367 4.431 36.352 36.352 0 0 1 1.182 3.49.388.388 0 0 0 .079.158c.068-.229.134-.458.2-.687a29.052 29.052 0 0 1 2.3-5.675 12.129 12.129 0 0 1 2.164-2.935 5.409 5.409 0 0 1 1.922-1.251 3.62 3.62 0 0 1 3.355.441 8.014 8.014 0 0 1 2.144 2.2 20.878 20.878 0 0 1 2.326 4.592 45.581 45.581 0 0 1 1.929 6.739c.4 1.875.71 3.766.955 5.667.157 1.22.283 2.443.385 3.67q.279 3.341.315 6.693v1.217h-4.494c-.034-.05-.017-.108-.017-.163 0-1.469-.027-2.938-.069-4.407q-.074-2.574-.249-5.144c-.1-1.439-.218-2.876-.371-4.311-.237-2.225-.534-4.442-.96-6.64a31.658 31.658 0 0 0-1.545-5.687 7.744 7.744 0 0 0-1.149-2.1 1.888 1.888 0 0 0-1-.72 1.425 1.425 0 0 0-1.342.433 5.436 5.436 0 0 0-1.167 1.7 20.4 20.4 0 0 0-1.331 3.859 52.751 52.751 0 0 0-1.034 5.273 85.762 85.762 0 0 0-.514 4.169 101.907 101.907 0 0 0-.261 3.168 124.935 124.935 0 0 0-.276 8.233c0 .03-.004.054-.007.094Z"
      className="fill-current"
    />
  </svg>
)

export default SvgComponent
