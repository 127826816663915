import roundFunction from "./roundFunction";
import {
  genderColors,
  oshaColors,
  ethnicityColors,
  ageColors,
  tenureColors,
  exitReasonColors,
  absenteeismColors,
} from "../constants/colors";

const formatData = (data) => {
  if (Array.isArray(data)) return data;

  const result = {
    ...data,
    turnoverRate: roundFunction(data.turnoverRate * 100),
  };
  return result;
};

export const getProperty = (key, prop) => {
  if (genderColors[key]) return genderColors[key][prop];
  if (absenteeismColors[key]) return absenteeismColors[key][prop];
  if (ethnicityColors[key]) return ethnicityColors[key][prop];
  if (ageColors[key]) return ageColors[key][prop];
  if (tenureColors[key]) return tenureColors[key][prop];
  if (exitReasonColors[key]) return exitReasonColors[key][prop];
  if (oshaColors[key]) return oshaColors[key][prop];
};

export const makeUniqueFields = (obj, appendKey) => {
  const resultObj = {...obj};
  console.log("fcafc", resultObj);
  for (let key in obj) {
    if (key === "viewBy" || key === "period") {
      continue;
    }
    resultObj[key + appendKey] = obj[key];
    const divideBy = 1;
    if (key === "turnoverRate") {
      resultObj[key + appendKey] = roundFunction(
        resultObj[key + appendKey] * 100
      );
    } else if (key === "avgActiveEmployees") {
      resultObj[key + appendKey] = roundFunction(
        resultObj[key + appendKey] / divideBy,
        "K"
      );
    } else {
      resultObj[key + appendKey] = resultObj[key + appendKey];
    }
  }
  
  return resultObj;
};

export const getAttrGraphData = async (data, lastyear) => {
  const data1 = data.graphBarRecords;
  const data2 = data.twoYearTrendRecord;
  const dataly = [];
  data2.map(
    (dte) =>
      dte.year < lastyear &&
      dataly.push({
        period: dte.month,
        year: dte.year,
        attritionLY: dte.attrition,
      })
  );
  const data3 = [...data1, ...dataly];
  console.log("dahgvdagj", data3);
  let res2 = [];
  if (data1 === undefined) {
    return;
  }
  //return data1;
  const map = data3.reduce((accumulator, currentVal) => {
    let {
      period,
      month,
      exitEmployees,
      totalEmployees,
      avgActiveEmployees,
      turnoverRate,
      viewBy,
      countRecorded,
      year,
    } = currentVal;
    period = period + "";
    month = month + "";

    let resultObj = makeUniqueFields(currentVal, viewBy);
    res2.push(resultObj);
    console.log("jtcgc", resultObj);
    let mapData = accumulator[period];
    console.log("resobj", mapData);

    if (mapData) {
      const summedObj = {
        ...mapData,
        ...resultObj,
        exitEmployees:
          parseInt(exitEmployees) + parseInt(mapData.exitEmployees),
        totalEmployees:
          parseInt(totalEmployees) + parseInt(mapData.totalEmployees),
        avgActiveEmployees:
          parseInt(avgActiveEmployees) + parseInt(mapData.avgActiveEmployees),
        turnoverRate: roundFunction(
          (parseInt(turnoverRate) + parseInt(mapData.turnoverRate)) * 100
        ),
        countRecordedAll:
          viewBy === "SIF"
            ? mapData.countRecordedAll !== undefined
              ? mapData.countRecordedAll
              : mapData.countRecorded
            : countRecorded +
              (mapData.countRecordedAll ? mapData.countRecordedAll : 0),
      };
      accumulator[period] = summedObj;
    } else {
      accumulator[period] = resultObj;
    }
    return accumulator;
  }, {});
  console.log(map, " !!!s");

  let result = [];
  for (let key in map) result.push(map[key]);
  result = result.sort((a, b) => a.period - b.period);

  console.log("ressaaa", result);
  console.log("ghchagc", res2);

  return result;
};

export const getGraphData = async (data) => {
  const data1 = data.graphBarRecords;

  let res2 = [];
  if (data1 === undefined) {
    return;
  }
  //return data1;
  const map = data1.reduce((accumulator, currentVal) => {
    let {
      period,
      month,
      exitEmployees,
      totalEmployees,
      avgActiveEmployees,
      turnoverRate,
      viewBy,
      countRecorded,
      year,
    } = currentVal;
    period = period + "";
    month = month + "";

    let resultObj = makeUniqueFields(currentVal, viewBy);
    res2.push(resultObj);
    console.log("jtcgc", resultObj);
    let mapData = accumulator[period];
    console.log("resobj", mapData);

    if (mapData) {
      const summedObj = {
        ...mapData,
        ...resultObj,
        exitEmployees:
          parseInt(exitEmployees) + parseInt(mapData.exitEmployees),
        totalEmployees:
          parseInt(totalEmployees) + parseInt(mapData.totalEmployees),
        avgActiveEmployees:
          parseInt(avgActiveEmployees) + parseInt(mapData.avgActiveEmployees),
        turnoverRate: roundFunction(
          (parseInt(turnoverRate) + parseInt(mapData.turnoverRate)) * 100
        ),
        countRecordedAll:
          viewBy === "SIF"
            ? mapData.countRecordedAll !== undefined
              ? mapData.countRecordedAll
              : mapData.countRecorded
            : countRecorded +
              (mapData.countRecordedAll ? mapData.countRecordedAll : 0),
      };
      accumulator[period] = summedObj;
    } else {
      accumulator[period] = resultObj;
    }
    return accumulator;
  }, {});
  console.log(map, " !!!s");

  let result = [];
  for (let key in map) result.push(map[key]);
  result = result.sort((a, b) => a.period - b.period);

  console.log("ressaaa", result);
  console.log("ghchagc", res2);

  return result;
};

export const getSortFunction = (a, b, section, tab, dataKey) => {
  let objectSelected;
  switch (tab) {
    case "Tenure":
      objectSelected = tenureColors;
      break;
    case "Gender":
      objectSelected = genderColors;
      break;
    case "Ethnicity":
      objectSelected = ethnicityColors;
      break;
    case "AgeGroup":
      objectSelected = ageColors;
      break;
    case "ExitReason":
      objectSelected = exitReasonColors;
      break;
    default:
      objectSelected = oshaColors;
      break;
  }
  if (section === "teammember" || section === "osha")
    return objectSelected[a.viewBy].index - objectSelected[b.viewBy].index;
  else return b[dataKey] - a[dataKey];
};

export const getGraphAdditionalData = (data1, section, tab) => {
  const data = [];

  for (let key in data1.graphSummary) {
    //if(key === 'Unknown') continue;
    if (tab === "ExitReason" && key !== "Voluntary" && key !== "Involuntary") {
      continue;
    }
    data.push({
      viewBy: key,
      ...data1.graphSummary[key],
      turnoverRateVoluntary: 0,
      countRecorded: "osha" === section ? data1.graphSummary[key] : 0,
    });
  }
  const totalValueEmpl = data.reduce(
    (acc, {avgActiveEmployees}) => acc + avgActiveEmployees,
    0
  );
  return data
    .sort((a, b) => getSortFunction(a, b, section, tab, "turnoverRate"))
    .map((v) => {
      v.color = getProperty(v.viewBy, "color");
      v.title = getProperty(v.viewBy, "title");
      if (v.hasOwnProperty("turnoverRate"))
        v.turnoverRate = isNaN(roundFunction(v.turnoverRate * 100))
          ? 0
          : roundFunction(v.turnoverRate * 100);
      if (v.hasOwnProperty("avgActiveEmployees"))
        v.avgActiveEmployees = isNaN(
          roundFunction((v.avgActiveEmployees / totalValueEmpl) * 100)
        )
          ? 0
          : roundFunction((v.avgActiveEmployees / totalValueEmpl) * 100);
      return v;
    });
};
