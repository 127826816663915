import React,{useRef} from 'react'
//import Modal from 'react-modal'
import './styles.scss'
import Modal from '@kenetto/uikit-modal'

const ModalContent = ({
  modalIsOpen=false,
  title = '',
  children,
  setShowModal
}) => {
  const onClose = e => {
    setShowModal(false)
  }
  const toggleModal = (...args)=>{
    onClose()
  }

  return (
    <Modal
      className="uk-margin-auto-vertical"
      title={title}
      toggleModal={toggleModal}
      modalIsOpen={modalIsOpen}
      style={{zIndex: 99999999}}
    >
      <div className='uk-grid-small uk-child-width-expand' data-uk-grid>
        {children}
      </div>
    </Modal>
  )
}

export default ModalContent
