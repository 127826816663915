export default (num, scale) => {
    switch(scale) {
        case 1000000000:
            scale = 'B'
            break;
        case 1000000:
            scale = 'M'
            break;
        case 1000:
            scale = 'K';
            break;
    }
    switch(scale) {
        case 'B':
            return Math.round((num) * 100) / 100;
        case 'M':
            return Math.round((num) * 10) / 10;
        case '%':
            return Math.round((num) * 10) / 10;
        case 'K':
            return Math.round(num);
        default:
            return Math.round((num) * 100) / 100;
    }

}
