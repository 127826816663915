import React, { useState } from 'react'

import FeatherIcon from 'feather-icons-react';
import toClassName from '../../utils/toClassName';

const BusinessUnitCollapsible = ({ name, children, onCollapse, addBackButtonImage }) => {
  const [extended, setExtended] = useState(false)

  const toggle = () => {
    onCollapse(!extended)
    setExtended(!extended)
  }

  return (
    <div>
      <div className='first:border-t border-b py-1.5 flex justify-between  cursor-pointer' onClick={toggle}>
        <div className= ' flex flex-row' >
          {addBackButtonImage &&
            <div>
              <FeatherIcon className=' text-blue-400' icon={'chevron-left'} />
            </div>
          }
          {addBackButtonImage ? <span className='text-base text-black justify-evenly'>{name}</span> 
          : <span className='text-sm text-black justify-evenly'>{name}</span>}
          
        </div>
        <div>
          <FeatherIcon className={toClassName([extended ? 'rotate-180' : '', 'transform transition-all text-blue-400'])} icon={'chevron-down'} />
        </div>
      </div>
      <div className={toClassName([extended ? 'block' : 'hidden'])}>
        {children}
      </div>
    </div>
  )
}

export default BusinessUnitCollapsible