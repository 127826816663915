import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TeamMembers from '../fullAppUser/TeamMembers';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const TeamMembersPrepared = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
        setActiveBu(['Prepared Foods'])
        setBuType('location')
    }, [])
    
    return (
        <TeamMembers
          onlyPrepared
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(TeamMembersPrepared)
