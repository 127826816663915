import React from 'react';
import LoadingImage from '../../images/loading.gif'

const LoadingBlocker = ({loading})=>{

    return <div style={{
        display: loading ? 'flex':'none',
        position: 'fixed',
        zIndex:1000,
        top:0,
        flex:1,
        height:'100%',
        width: '100%',
        alignItems:"center",
        justifyContent:"center",
        minHeight: '100vh',
        backgroundColor: 'rgba(255,255,255,0.85)'
       // backgroundColor: 'rgba(255,255,255,1)'
      }}>
      <img src={LoadingImage}
          style={{
            width: '30vh',
            height: 'auto'
          }}
        ></img>
      </div>
}

export default LoadingBlocker;
