import React from 'react'
import toClassName from '../../utils/toClassName'
import CurrentScreenTitle from './CurrentScreenTitle'

const MobileFixedHeader = ({ week, month, children, value, visible }) => {
  const classes = visible ? 'opacity-100' : 'opacity-0 -translate-y-full'

  return (
    <div className={toClassName([classes, 'transform px-5 fixed border-b border-gray-700 shadow z-50 flex justify-between items-center lg:hidden w-full top-0 bg-white py-4 transition'])}>
      <div className='leading-7 flex flex-col'>
        <CurrentScreenTitle 
          week={week}
          month={month}
          className='text-base2 text-black font-semibold'
        />
        { children }
      </div>
      <div>
        { value }
      </div>
    </div>
  )
}

export default MobileFixedHeader