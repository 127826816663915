
import {
  REQUEST_DATA,
  RECEIVE_DATA_GRAPH,
  RECEIVE_DATA_HEADER,
  SET_SCALE,
  SET_BOTTOM_SCALES,
  RECEIVE_DATA_TREND_UNITS,
  RESET_APP,
  SET_MAIN_VALUE,
  SET_BOTTOM_VALUES,
  RECEIVE_UNFILLED_DATA,
  GOT_ERROR,
  RECEIVE_DATA_GRAPH_ADDITIONAL,
  SET_CURRENT_DATE
} from '../actions/valuesActions';
import {
  RECEIVE_LEDAER_UNITS,
  RECEIVE_LOCATION_UNITS,
  SET_LATEST_METRICS,
  SET_LATEST_METRICS_ABSENTEESIM,
  SET_OSHA_METRICS,
  SET_OSHA_RATES_METRICS
} from '../actions/dataActionsTeamMembers';
import {
  SET_PLANT_OPS_METRICS
} from '../actions/dataActionsPlantOps';
import {
  SET_NES_FAV_BRAND_METRICS
} from '../actions/dataActionsNesFavBrand';
import {
  SET_FILL_RATE_METRICS,
  RECEIVE_UNITS
} from '../actions/dataActionsFillRate';
import {
  SET_IT_FINACE_DATA,
  SET_IT_FINANCE_CLOUD_DATE,
  SET_IT_FINANCE_DRILLDOWN_DATA,
  SET_IT_FISC_DATE,
  SET_IT_HR_DRILLDOWN_DATA,
} from '../actions/itDashFinaceActions';


function getBottomScale(scale) {
  switch(scale) {
      case 'M':
          return 1000000;

      case 'B':
          return 1000000000;

      case 'K':
          return 1000;

      case '%':
          return 0.01;

      default:
          return 1;

  }
}


export const INITIAL_STATE = {
  header: {
      ebit: null,
      ebitPlan: null,
      ebitProj: null,
      netSales: null,
      netSalesPlan: null,
      netSalesProj: null,
      aoi: null,
      aoiPlan: null,
      volume: null,
      volumePlan: null,
      volumeProj: null,
      volumePrevious: null,
  },
  dataGraphAdditional: [],
  isHeaderLoading: true,
  isGraphLoading: false,
  sectionValues: {
      'chicken': 0,
      'beef': 0,
      'pork': 0,
      'prepared food': 0,
      'international': 0,
      'intersegment': 0,
  },
  scale: 'M',
  planScale: 'M',
  projScale: 'M',
  scaleNumber: 1,
  planScaleNumber: 1,
  projScaleNubmer: 1,
  resetApp: false,
  sign: '$',
  leaderUnits: [],
  units: [],
  allUnits: [],
  bars: [],
  barsPY: [],
  trends: {
      bsFamily: {
          month:{ chicken: [], beef: [], pork: [], 'prepared foods': [], intersegment: [], international: [] },
          year: { chicken: [], beef: [], pork: [], 'prepared foods': [], intersegment: [], international: [] }
      },
      bsTrend: {
          month:{ chicken: [], beef: [], pork: [], 'prepared foods': [], intersegment: [], international: [] },
          year: { chicken: [], beef: [], pork: [], 'prepared foods': [], intersegment: [], international: [] }
      }
  },
  mainValue: 0,
  planDelta: 0,
  projDelta: 0,
  unfilledData: [],
  isTrendReady: false,
  err: '',
  currentDate: 0,
  isFirstDataFetched: false,
  dataIdArr: [],
  locationUnits: [],
  metrics: {},
  absenteesimMetrics: {},
  oshaMetrics: {},
  oshaRatesMetrics: {},
  plantOpsMetrics : {},
  nesFavBrandMetrics : {},
  fillRateMetrics : {},
  isAdditionalGraphDataLoading: true,
  itFinaceLatestMetrics: {},
  itFinanceCloudDate: {},
  itFinanceDrilldownData:{}
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
      case SET_IT_FINACE_DATA:
          const { itFinaceLatestMetrics } = action.payload;
          return {
              ...state,
              itFinaceLatestMetrics
          }
      case SET_IT_FISC_DATE:
          const { itFiscCloudDate } = action.payload;
          return {
                  ...state,
                  itFiscCloudDate
              }
      case SET_IT_FINANCE_CLOUD_DATE:
          const { itFinanceCloudDate } = action.payload;
          return {
              ...state,
              itFinanceCloudDate
          }
      case SET_IT_FINANCE_DRILLDOWN_DATA:
          const { itFinanceDrilldownData } = action.payload;
          return {
              ...state,
              itFinanceDrilldownData
          }
      case SET_IT_HR_DRILLDOWN_DATA:
            const { itHrDrilldownData } = action.payload;
            return {
                ...state,
                itHrDrilldownData
            }
      case SET_LATEST_METRICS_ABSENTEESIM:
          const { absenteesimMetrics } = action.payload;
          return {
              ...state,
              absenteesimMetrics
          }
      case SET_PLANT_OPS_METRICS:
          const { plantOpsMetrics } = action.payload;
          return {
              ...state,
              plantOpsMetrics
          }
      case SET_NES_FAV_BRAND_METRICS:
          const { nesFavBrandMetrics } = action.payload;
          return {
              ...state,
              nesFavBrandMetrics
          }
      case SET_FILL_RATE_METRICS:
          const { fillRateMetrics } = action.payload;
          return {
              ...state,
              fillRateMetrics
          }
      case SET_OSHA_METRICS:
          const { oshaMetrics } = action.payload;
          return {
              ...state,
              oshaMetrics
          }
      case SET_OSHA_RATES_METRICS:
          const { oshaRatesMetrics } = action.payload;
          return {
              ...state,
              oshaRatesMetrics
          }
      case SET_LATEST_METRICS:
          const { metrics } = action.payload;
          return {
              ...state,
              metrics,
              isGraphLoading: false
          }
      case RECEIVE_LEDAER_UNITS:
          const { units } = action.payload;
          return {
              ...state,
              units
          }
      case RECEIVE_LOCATION_UNITS:
          const { locationUnits } = action.payload;
          return {
              ...state,
              locationUnits
          }
      case RECEIVE_UNITS:
          return  {
              ...state,
              allUnits: action.payload.units
          }
      case RECEIVE_DATA_GRAPH_ADDITIONAL:
          const { dataGraphAdditional } = action.payload;
          return {
              ...state,
              dataGraphAdditional,
              isAdditionalGraphDataLoading: false
          }
      case REQUEST_DATA:
          const { type } = action.payload;
          const isHeaderLoading = true;
          let isGraphLoading = false;
          if(type) isGraphLoading = true;
          return {
              ...state,
              isGraphLoading,
              isHeaderLoading,
              isAdditionalGraphDataLoading: true
          }
      case SET_BOTTOM_SCALES:
          const { planScale, projScale } = action.payload;
          let planScaleNumber = getBottomScale(planScale);
          let projScaleNubmer = getBottomScale(projScale);

          return {
              ...state,
              planScale,
              projScale,
              planScaleNumber,
              projScaleNubmer
          }
      case SET_SCALE:
          const { scale } = action.payload;
          let scaleNumber = 1;
          let sign = '$';
          switch(scale) {
              case 'M':
                  scaleNumber = 1000000;
                  break;
              case 'B':
                  scaleNumber = 1000000000;
                  break;
              case 'K':
                  scaleNumber = 1000;
                  break;
              case '%':
                  scaleNumber = 0.01;
                  sign = '%';
                  break;
              default:
                  scaleNumber = 1;
                  break;
          }
          return {
              ...state,
              scale,
              scaleNumber,
              sign
          }
      case RECEIVE_DATA_GRAPH:
          const { dataGraph } = action.payload;
          const {
              graphBarRecords,
              graphBarRecordsPY
          } = dataGraph;
          return {
              ...state,
              bars: graphBarRecords,
              barsPY: graphBarRecordsPY ? graphBarRecordsPY : [],
              isGraphLoading: false,
              isFirstDataFetched: true
          }
      case RECEIVE_DATA_HEADER:
          const { dataHeader } = action.payload;

          const { header, entitySummary, lastChanged } = dataHeader;
          return {
              ...state,
              header,
              units: entitySummary,
              isHeaderLoading: false,
              ...lastChanged ? {lastChanged}:{},
          }
      case RECEIVE_DATA_TREND_UNITS:
          const {
              bsFamily,
              bsTrend
          } = action.payload;
          return {
              ...state,
              trends: {
                  bsFamily,
                  bsTrend
              },
              isTrendReady: true
          }
      case RECEIVE_UNFILLED_DATA:
          const { unfilledData } = action.payload;
          return {
              ...state,
              unfilledData: unfilledData
          }
      case RESET_APP:
          return {
              ...state
          }
      case SET_MAIN_VALUE:
          const { mainValue } = action.payload;
          return {
              ...state,
              mainValue
          }
      case SET_BOTTOM_VALUES:
          const { planDelta, projDelta } = action.payload;
          return {
              ...state,
              planDelta,
              projDelta
          }
      case GOT_ERROR:
          const { err } = action.payload;
          return {
              ...state,
              err: err
          }
      case SET_CURRENT_DATE:
          const {
              date
          } = action.payload;
          return {
              ...state,
              currentDate: state.currentDate ? state.currentDate : {
                  year: Math.abs(parseInt(date.year)) ,
                  quartal: Math.abs(parseInt(date.qtr))
              },
          }
      default:
          return state;
  }
}
