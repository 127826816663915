import React from 'react'
import { useHistory } from 'react-router';
import toClassName from '../../utils/toClassName';


const Link = ({ children, href, className='' }) => {
  const history = useHistory()

  const onClick = (e) => {
    e.preventDefault()
    history.push(href)
  }

  return (
    <a className={toClassName(['text-base font-medium text-blue-400 hover:no-underline', className])} href={href} onClick={onClick} >
      { children }
    </a>
  )
}

export default Link