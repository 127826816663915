import React from 'react'

import toClassName from '../../utils/toClassName'

const Item = ({ variant='vertical', date, title, value, indicators, onClick=()=>{} }) => {
  const classes = {
    'horizontal': {
      container: ['home-item-horizontal flex-row lg:mx-2 lg:-mt-1', 'rounded	border-05 border-gray-500 shadow justify-between mb-3 flex-1'],
      valueContainer: ['text-right'],
      indicators: ['order-first leading-none'],
      date: ['text-xs leading-tight'],
      indicatorsContainer: ['flex flex-col justify-start']
    },
    'double': {
      container: ['home-item-vertical flex-col flex-1 lg:flex-auto', 'box-border rounded justify-start flex-1 justify-between'],
      valueContainer: ['text-right lg:text-center lg:-mt-10'],
      indicators: ['order-first lg:order-none text-left whitespace-nowrap'],
      date: ['text-xxs leading-tight'],
      indicatorsContainer: ['flex flex-col lg:block']
    },
    'vertical': {
      container: ['home-item-vertical flex-col flex-1 lg:flex-none', 'rounded	border-05 border-gray-500 shadow justify-start mx-2 flex-1 justify-between'],
      valueContainer: ['text-right lg:text-center lg:-mt-10'],
      indicators: ['order-first lg:order-none text-left whitespace-nowrap'],
      date: ['text-xxs leading-tight'],
      indicatorsContainer: ['flex flex-col lg:block']
    },
    'vertical-lg': {
      container: ['home-item-vertical-lg flex-col flex-1 lg:flex-none', 'rounded	border-05 border-gray-500 shadow justify-start mx-2 flex-1 justify-between'],
      valueContainer: ['text-right lg:text-center lg:-mt-10'],
      indicators: ['order-first lg:order-none text-left whitespace-nowrap'],
      date: ['text-xxs leading-tight'],
      indicatorsContainer: ['flex flex-col lg:block']
    }
  }

  return (
    <div
      className={toClassName(['p-1 flex bg-white text-black cursor-pointer', ...classes[variant].container])}
      onClick={onClick}
    >
      <div className='flex flex-col justify-start'>
          <span className={toClassName([...classes[variant].date])}>
            { date }
          </span>
          <span className='text-sm font-semibold leading-none'>
            { title }
          </span>
      </div>
      <div className={toClassName([...classes[variant].indicatorsContainer])}>
        <div className={toClassName([...classes[variant].valueContainer])}>
          <span className='text-lg font-semibold leading-6'>
            { value }
          </span>
        </div>
        {
          indicators &&
          <div className={toClassName(['leading-0', ...classes[variant].indicators])}>
            { indicators.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>) }
          </div>
        }
      </div>
    </div>
  )
  }

  export default Item
