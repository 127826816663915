import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PlantOps from '../fullAppUser/PlantOps';
import { setActiveSection } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';


const PlantOpsFreshMeats = ({ activeSection, setActiveSection }) => {
    useEffect(() => {
        setActiveSection('Beef');
    }, [])
    
    return (
        <PlantOps
          onlyFreshMeats
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveSection, setNavigationStage }
  )(PlantOpsFreshMeats)
