import React, { useEffect, useState } from "react";
import { setUserInfo } from "../../actions/userActions";
import {
   setItFinaceLatestMetrics,
   setItFinanceCloudDate,
   setItFiscCloudDate,
   setItHRDrilldownData,
} from "../../actions/itDashFinaceActions";
import qs from "qs";
import {
   getFinanceCloudDateInfo,
   getFinanceFiscDateInfo,
   getFinanceLatestMetrics,
   getHrAttritionData,
   getLatestDataHeader,
   getTeamMembersData,
   getJbLatestMetrics,
   getLatestEmployeeAbsenteesimDate,
} from "../../services/dataService";
import {
   receiveDataHeader,
   setCurrentDate,
   resetApp,
} from "../../actions/valuesActions";
import {
   setQuarter,
   setYear,
   setScrollPosition,
   setActiveSection,
} from "../../actions/sectionsActions";
import {
   setLatestMetrcis,
   setFiscDateEmployee,
   setFiscDateEmployeeAbsenteesim,
   setLatestMetrcisAbsenteesim,
   setOshaMetrics,
   setOshaRatesMetrics,
} from "../../actions/dataActionsTeamMembers";
import {
   setPlantOpsMetrics,
   setPlantOpsFiscDate,
} from "../../actions/dataActionsPlantOps";
import { connect } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { setShowModal, setShowMenu } from "../../actions/app";
import roundFunction from "../../utils/roundFunction";
import ScreenWrapper from "../../components/ScreenWrapper/ScreenWrapper";
import { financeErrorData } from "../../constants/app";

import AppLayout from "../../componentsGeneral/AppLayout";
import ItemsContainer from "../../homeScreenComponents/general/ItemsContainer";
import ItemsContainerCollapsible from "../../homeScreenComponents/general/ItemsContainerCollapsible";
import ItemDouble from "../../homeScreenComponents/general/ItemDouble";
import ItemTriple from "../../homeScreenComponents/general/ItemTriple";
import Item from "../../homeScreenComponents/general/Item_IT";
import SectionRow from "../../homeScreenComponents/general/SectionRow";

import formatFiscDate from "../../utils/formatFiscDate";
import formatNumber from "../../utils/formatNumber";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LoadingBlocker from "../../components/LoadingBlocker/LoadingBlocker";
import ReactGA from "react-ga4";
import { ConsoleView } from "react-device-detect";

const HomeScreenItDashboard = (props) => {
   const { height, width } = useWindowDimensions();

   const { authState, oktaAuth } = useOktaAuth();
   const {
      accessToken: { accessToken },
   } = authState;
   const [isLoading, setIsLoading] = useState(false);
   const [date, setDate] = useState();
   const [attr, setAttr] = useState(0);
   const [team, setTeam] = useState(0);
   const teamFeatureFlag = false;
   const projectFeatureFlag = false;

   const getDataFinance = async (initial) => {
      const {
         receiveDataHeader,
         setQuarter,
         setCurrentDate,
         setYear,
         setScrollPosition,
      } = props;

      let { data, type } = await setPlantOpsFiscDate({ accessToken });

      if (type === "success") {
         const date = data.date[0];
         data = data.data;
         setQuarter(parseInt(date.qtr));
         setYear(parseInt(date.year));
         setScrollPosition(undefined);
         receiveDataHeader(data);
         setCurrentDate(date);
      } else {
         setTimeout(async () => {
            let { data, type } = await getLatestDataHeader("", accessToken);
            if (type === "success") {
               const date = data.date[0];
               data = data.data;
               setQuarter(parseInt(date.qtr));
               setYear(parseInt(date.year));
               setScrollPosition(undefined);
               receiveDataHeader(data);
               setCurrentDate(date);
            } else {
               const date = financeErrorData.date[0];
               data = financeErrorData.data;
               setQuarter(parseInt(date.qtr));
               setYear(parseInt(date.year));
               setScrollPosition(undefined);
               receiveDataHeader(data);
               setCurrentDate(date);
            }
         }, 1000);
      }
   };

   // const employeeSuccessHandler = ({ data }) => {
   //   const { setFinanceLatestMetrcis } = props;
   //   try {
   //     const { employeeInfo } = data;
   //     let metrcis = {};
   //     metrcis = {
   //       exitEmployeesPoultry: employeeInfo["Poultry"].exitEmployees,
   //       avgActiveEmployeesPoultry: roundFunction(
   //         employeeInfo["Poultry"].avgActiveEmployees,
   //         "B"
   //       ),
   //       turnoverRatePoultry: employeeInfo["Poultry"].turnoverRate,
   //       exitEmployeesMcDonalds: employeeInfo["McDonalds"].exitEmployees,
   //       avgActiveEmployeesMcDonalds: roundFunction(
   //         employeeInfo["McDonalds"].avgActiveEmployees,
   //         "B"
   //       ),
   //       turnoverRateMcDonalds: employeeInfo["McDonalds"].turnoverRate,
   //     };
   //     setFinanceLatestMetrcis(metrcis);
   //     console.log(
   //       roundFunction(employeeInfo["Poultry"].avgActiveEmployees, "B"),
   //       "employeeInfo['McDonalds'].turnoverRate"
   //     );
   //   } catch (e) {
   //     console.error(e, "Team members error");
   //   }
   // };

   // const getDataEmployee = async () => {
   //   const { setLatestMetrcis } = props;
   //   const { data, type } = await getLatestEmployeeMetrics({
   //     segment: "?segment=Poultry,McDonalds",
   //     accessToken,
   //   });
   //   if (type === "success") {
   //     employeeSuccessHandler({ data });
   //   } else {
   //     setTimeout(async () => {
   //       const { data, type } = await getLatestEmployeeMetrics({
   //         segment: "?segment=Poultry,McDonalds",
   //         accessToken,
   //       });
   //       if (type === "success") {
   //         employeeSuccessHandler({ data });
   //       } else {
   //         setLatestMetrcis("Error");
   //       }
   //     }, 1000);
   //   }
   // };

   const getItLatestMetricsSucccessHandler = ({ data }) => {
      const { setItFinaceLatestMetrics } = props;
      try {
         let metrics = {
            actual: data.actuals[0].actual,
            aop: data.actuals[0].aop,
            actualForecast: data.actuals[0].actualForecast,
            controllable: data.actuals[0].controllable,
            controllableAOP: data.actuals[0].controllableAOP,
            controllableForecast: data.actuals[0].controllableForecast,
            Noncontrollable: data.actuals[0].nonControllable,
            NoncontrollableAOP: data.actuals[0].nonControllableAOP,
            NoncontrollableForecast: data.actuals[0].nonControllableForecast,
            salaryAndBenefits: data.actuals[0].salaryAndBenefits,
            salaryAndBenefitsAOP: data.actuals[0].salaryAndBenefitsAOP,
            salaryAndBenefitsForecast:
               data.actuals[0].salaryAndBenefitsForecast,
            cloudCost: data.cloud[0].cloudCost,
            cloudCostForecast: data.cloud[0].cloudCostForecast,
         };
         setItFinaceLatestMetrics(metrics);
      } catch (e) {
         console.error(e, "Latest Metrics. Error");
      }
   };

   const getItHrLatestMetricss = (data) => {
      const { setItHRDrilldownData } = props;
      try {
         let metrics = {
            daysToHire: data.daysToHire,
            positionCount: data.positionCount,
            avgDaysToHire: data.avgDaysToHire,
            vacancies: data.vacancies,
            openPositionWoReqGt14Days: data.openPositionWoReqGt14Days,
            PositionsInOffer: data.PositionsInOffer,
            frozenPositions: data.frozenPositions,
         };
         setItHRDrilldownData(metrics);
      } catch (e) {
         console.log(e, "Hr latest metrics error");
      }
   };

   const getItLatestMetrics = async () => {
      const { setItFinaceLatestMetrics } = props;
      const { data, type } = await getFinanceLatestMetrics({ accessToken });
      if (type === "success") {
         getItLatestMetricsSucccessHandler({ data });
      } else {
         setTimeout(async () => {
            const { data, type } = await getFinanceLatestMetrics({
               accessToken,
            });
            if (type === "success") {
               setItFinaceLatestMetrics({ data });
            } else {
               setItFinaceLatestMetrics("error");
            }
         }, 1000);
      }
   };

   const itCloudFiscDateSuccessHandler = ({ data }) => {
      const { setItFinanceCloudDate } = props;
      try {
         setItFinanceCloudDate(data[0]);
      } catch (err) {
         console.error(err, "Latest Metrics. Error");
      }
   };

   const CloudFiscDateSuccessHandler = ({ data }) => {
      const { setItFiscCloudDate } = props;
      try {
         setItFiscCloudDate(data);
      } catch (err) {
         console.error(err, "Latest Metrics. Error");
      }
   };

   const getCloudFiscDate = async () => {
      const { setItFinanceCloudDate, activeSection } = props;
      let data, type;
      if (activeSection === "IT_FINANCE_CLOUD") {
         const resp = await getFinanceCloudDateInfo({ accessToken });
         data = resp.data;
         type = resp.type;
      } else {
         const resp = await getFinanceFiscDateInfo({ accessToken });
         data = resp.data;
         type = resp.type;
      }
      if (type === "success") {
         itCloudFiscDateSuccessHandler({ data });
      } else {
         setTimeout(async () => {
            if (activeSection === "IT_FINANCE_CLOUD") {
               const resp = await getFinanceCloudDateInfo({ accessToken });
               data = resp.data;
               type = resp.type;
            } else {
               const resp = await getFinanceFiscDateInfo({ accessToken });
               data = resp.data;
               type = resp.type;
               getItHrLatestMetrics(data);
            }
            if (type === "success") {
               setItFinanceCloudDate({ data });
            } else {
               setItFinanceCloudDate("error");
            }
         }, 1000);
      }
   };

   const getCloudFiscDateinfo = async () => {
      const { setItFiscCloudDate, activeSection } = props;
      let data, type;
      if (activeSection === "IT_FINANCE_CLOUD") {
         const resp = await getFinanceCloudDateInfo({ accessToken });
         data = resp.data;
         type = resp.type;
         HrAttritionData(data);
      } else {
         const resp = await getFinanceCloudDateInfo({ accessToken });
         data = resp.data;
         type = resp.type;
         HrAttritionData(data);
         jobopeningsMetrics(data);
         getItHrLatestMetrics(data);
      }
      if (type === "success") {
         CloudFiscDateSuccessHandler({ data });
      } else {
         setTimeout(async () => {
            if (activeSection === "IT_FINANCE_CLOUD") {
               const resp = await getFinanceCloudDateInfo({ accessToken });
               data = resp.data;
               type = resp.type;
               HrAttritionData(data);
               jobopeningsMetrics(data);
            } else {
               const resp = await getFinanceCloudDateInfo({ accessToken });
               data = resp.data;
               type = resp.type;
               getItHrLatestMetrics(data);
            }
            if (type === "success") {
               setItFiscCloudDate({ data });
            } else {
               setItFiscCloudDate("error");
            }
         }, 1000);
      }
   };

   const getItHrLatestMetrics = async (date) => {
      const { setItHRDrilldownData } = props;
      let payla = {};
      let absentismDate = await getLatestEmployeeAbsenteesimDate({
         accessToken,
      });

      let filters = [
         { fiscYear: absentismDate.data.fiscYear },
         { fiscMonth: absentismDate.data.fiscMonth },
         {
            OrgLevel1: "IT",
         },
      ];
      const { data, type } = await getTeamMembersData(
         {
            section: "teammember",
            viewBy: "Gender",
            filters,
         },
         accessToken
      );
      if (type === "success") {
         setTeam(data.headerRecord.avgActiveEmployees);
      } else {
         setTimeout(async () => {
            const { data, type } = await getTeamMembersData(
               {
                  section: "teammember",
                  viewBy: "Gender",
                  filters,
               },
               accessToken
            );
            if (type === "success") {
               setTeam(data.headerRecord.avgActiveEmployees);
            } else {
               setTeam("error");
            }
         }, 1000);
      }
   };

   const HrAttritionData = async (date) => {
      const { setItHRDrilldownData } = props;
      let payla = {};

      date.map((dte) => (payla = dte));

      let filters = [
         { fiscYear: payla.fiscYear },
         {
            OrgLevel1: "IT",
         },
      ];
      let viewBy = "ExitReason";
      const { data, type } = await getHrAttritionData(
         {
            filters,
            viewBy,
         },
         accessToken
      );
      if (type === "success") {
         setAttr(data.headerRecord);
      } else {
         setTimeout(async () => {
            const { data, type } = await getHrAttritionData(
               {
                  filters,
                  viewBy,
               },
               accessToken
            );
            if (type === "success") {
               setAttr(data.headerRecord);
            } else {
               setAttr("error");
            }
         }, 1000);
      }
   };

   const jobopeningsMetrics = async (date) => {
      const { setItHRDrilldownData } = props;
      let payla = {};

      date.map((dte) => (payla = dte));

      let filters = qs.stringify({ fiscYear: payla.fiscYear });
      const { data, type } = await getJbLatestMetrics(filters, accessToken);
      if (type === "success") {
         getItHrLatestMetricss(data[0]);
      } else {
         setTimeout(async () => {
            const { data, type } = await getJbLatestMetrics(
               filters,
               accessToken
            );
            if (type === "success") {
               setItHRDrilldownData(data);
            } else {
               setItHRDrilldownData("error");
            }
         }, 1000);
      }
   };
   // const employeeAbsenteesimSuccessHandler = ({ data }) => {
   //   const { setLatestMetrcisAbsenteesim } = props;
   //   try {
   //     const { employeeInfo } = data;
   //     let metrcis = {};
   //     metrcis = {
   //       absenteeismPoultry: employeeInfo["Poultry"].absenteeism,
   //       absenteeismMcDonalds: employeeInfo["McDonalds"].absenteeism,
   //     };
   //     setLatestMetrcisAbsenteesim(metrcis);
   //   } catch (e) {
   //     console.error(e, "Team members error");
   //   }
   // };

   // const getDataEmployeeAbsenteesim = async () => {
   //   const { setLatestMetrcisAbsenteesim } = props;
   //   const { data, type } = await getLatestEmployeeAbsenteesimMetrics({
   //     segment: "?segment=Poultry,McDonalds",
   //     accessToken,
   //   });
   //   if (type === "success") {
   //     employeeSuccessHandler({ data });
   //   } else {
   //     setTimeout(async () => {
   //       const { data, type } = await getLatestEmployeeAbsenteesimMetrics({
   //         segment: "?segment=Poultry,McDonalds",
   //         accessToken,
   //       });
   //       if (type === "success") {
   //         employeeAbsenteesimSuccessHandler({ data });
   //       } else {
   //         setLatestMetrcisAbsenteesim("Error");
   //       }
   //     }, 1000);
   //   }
   // };

   // const oshaSuccessHandler = ({ data }) => {
   //   const { setOshaMetrics } = props;
   //   try {
   //     let metrics = {};

   //     const { poultryMcdCounts } = data;

   //     poultryMcdCounts.length === 1
   //       ? (metrics = {
   //           countRecorded: poultryMcdCounts[0].countRecorded
   //             ? poultryMcdCounts[0].countRecorded
   //             : 0,
   //         })
   //       : (metrics = {
   //           countRecorded:
   //             poultryMcdCounts[0].businessFunction === "Poultry"
   //               ? poultryMcdCounts[0].countRecorded
   //               : poultryMcdCounts[1].countRecorded,
   //           countRecordedMCD:
   //             poultryMcdCounts[1].businessFunction === "McDonalds"
   //               ? poultryMcdCounts[1].countRecorded
   //               : poultryMcdCounts[0].countRecorded,
   //         });

   //     setOshaMetrics(metrics);
   //   } catch (e) {
   //     console.error(e, "osha Record. error");
   //   }
   // };

   // const getDataOsha = async () => {
   //   const { setOshaMetrics } = props;
   //   const { data, type } = await getOshaLatesMetrics({
   //     segment: "?segment=Poultry,McDonalds",
   //     accessToken,
   //   });
   //   if (type === "success") {
   //     oshaSuccessHandler({ data });
   //   } else {
   //     setTimeout(async () => {
   //       const { data, type } = await getOshaLatesMetrics({
   //         segment: "?segment=Poultry,McDonalds",
   //         accessToken,
   //       });
   //       if (type === "success") {
   //         oshaSuccessHandler({ data });
   //       } else {
   //         setOshaMetrics("Error");
   //       }
   //     }, 1000);
   //   }
   // };

   // const oshaRatesSuccessHandler = ({ data }) => {
   //   const { setOshaRatesMetrics } = props;
   //   try {
   //     let metrics = {};
   //     const { poultryMcdCounts } = data;
   //     metrics = {
   //       rate:
   //         poultryMcdCounts[0].businessFunction === "Poultry"
   //           ? poultryMcdCounts[0].rate
   //           : poultryMcdCounts[1].rate,
   //       ratePY:
   //         poultryMcdCounts[0].businessFunction === "Poultry"
   //           ? poultryMcdCounts[0].ratePY
   //           : poultryMcdCounts[1].ratePY,
   //       rateMCD:
   //         poultryMcdCounts[1].businessFunction === "McDonalds"
   //           ? poultryMcdCounts[1].rate
   //           : poultryMcdCounts[0].rate,
   //       ratePYMCD:
   //         poultryMcdCounts[1].businessFunction === "McDonalds"
   //           ? poultryMcdCounts[1].ratePY
   //           : poultryMcdCounts[0].ratePY,
   //     };
   //     setOshaRatesMetrics(metrics);
   //   } catch (e) {
   //     console.error(e, "osha Rates error");
   //   }
   // };

   // const getDataOshaRates = async () => {
   //   const { setOshaRatesMetrics } = props;
   //   const { data, type } = await getOshaRatesLatesMetrics({
   //     segment: "?segment=Poultry,McDonalds",
   //     accessToken,
   //   });
   //   if (type === "success") {
   //     oshaRatesSuccessHandler({ data });
   //   } else {
   //     setTimeout(async () => {
   //       const { data, type } = await getOshaRatesLatesMetrics({
   //         segment: "?segment=Poultry,McDonalds",
   //         accessToken,
   //       });
   //       if (type === "success") {
   //         oshaRatesSuccessHandler({ data });
   //       } else {
   //         setOshaRatesMetrics("Error");
   //       }
   //     }, 1000);
   //   }
   // };

   // const plantOpsSuccessHandler = ({ data }) => {
   //   const { setPlantOpsMetrics } = props;
   //   try {
   //     let metrics = {};
   //     const { Poultry, McDonalds } = data;
   //     const poultry0 = "CapacityUtilisation Harvesting";
   //     const poultry1 = "Efficiency Harvesting";
   //     const poultry2 = "CapacityUtilisation Processing";
   //     const poultry3 = "Efficiency Processing";
   //     const CapacityRecorded0 = Poultry[0].CapacityRecorded;
   //     const CapacityRecorded1 = Poultry[1].CapacityRecorded;
   //     const CapacityRecorded2 = Poultry[2].CapacityRecorded;
   //     const CapacityRecorded3 = Poultry[3].CapacityRecorded;
   //     const CapacityType0 = Poultry[0].CapacityType;
   //     const CapacityType1 = Poultry[1].CapacityType;
   //     const CapacityType2 = Poultry[2].CapacityType;
   //     const CapacityType3 = Poultry[3].CapacityType;

   //     metrics = {
   //       PoultryCUHar:
   //         CapacityType0 == poultry0
   //           ? CapacityRecorded0
   //           : CapacityType1 == poultry0
   //           ? CapacityRecorded1
   //           : CapacityType2 == poultry0
   //           ? CapacityRecorded2
   //           : CapacityType3 == poultry0
   //           ? CapacityRecorded3
   //           : 0,
   //       PoultryEfficiencyHar:
   //         CapacityType0 === poultry1
   //           ? CapacityRecorded0
   //           : CapacityType1 === poultry1
   //           ? CapacityRecorded1
   //           : CapacityType2 === poultry1
   //           ? CapacityRecorded2
   //           : CapacityType3 === poultry1
   //           ? CapacityRecorded3
   //           : 0,
   //       PoultryCUPro:
   //         CapacityType0 === poultry2
   //           ? CapacityRecorded0
   //           : CapacityType1 === poultry2
   //           ? CapacityRecorded1
   //           : CapacityType2 === poultry2
   //           ? CapacityRecorded2
   //           : CapacityType3 === poultry2
   //           ? CapacityRecorded3
   //           : 0,
   //       PoultryEfficiencyPro:
   //         CapacityType0 === poultry3
   //           ? CapacityRecorded0
   //           : CapacityType1 === poultry3
   //           ? CapacityRecorded1
   //           : CapacityType2 === poultry3
   //           ? CapacityRecorded2
   //           : CapacityType3 === poultry3
   //           ? CapacityRecorded3
   //           : 0,

   //       McdCUPro:
   //         McDonalds[0].CapacityType === "CapacityUtilisation Processing"
   //           ? McDonalds[0].CapacityRecorded
   //           : McDonalds[1].CapacityRecorded,
   //       McdEfficiencyPro:
   //         McDonalds[1].CapacityType === "Efficiency Processing"
   //           ? McDonalds[1].CapacityRecorded
   //           : McDonalds[0].CapacityRecorded,
   //     };
   //     setPlantOpsMetrics(metrics);
   //   } catch (e) {
   //     console.error(e, "Plant Ops error");
   //   }
   // };

   // const getDataPlantOps = async () => {
   //   const { setPlantOpsMetrics } = props;
   //   const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
   //   if (type === "success") {
   //     plantOpsSuccessHandler({ data });
   //   } else {
   //     setTimeout(async () => {
   //       const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
   //       if (type === "success") {
   //         plantOpsSuccessHandler({ data });
   //       } else {
   //         setPlantOpsMetrics("Error");
   //       }
   //     }, 1000);
   //   }
   // };

   const requestData = async (initial) => {
      setIsLoading(true);
      Promise.all([
         getItLatestMetrics(),
         getCloudFiscDate(),
         getCloudFiscDateinfo(),
         getItHrLatestMetrics(),
         HrAttritionData(),
         jobopeningsMetrics(),

         // getDataFinance(initial),
         // getDataEmployee(),
         // getDataEmployeeAbsenteesim(),
         // getDataOsha(),
         // getDataOshaRates(),
         // getDataPlantOps(),
      ])
         .then((values) => {
            console.log("finished loading");
            setIsLoading(false);
         })
         .catch((e) => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      const { resetApp } = props;
      resetApp();
      requestData();
      ReactGA.send({
         hitType: "pageview",
         page: window.location.pathname + window.location.search,
      });
      oktaAuth.getUser().then((userInfo) => {
         // console.log('userInfo', userInfo);
         //initGA(userInfo.AD_sAMAccountName);
         try {
            // initGA(userInfo.sub);
            ReactGA.set({ userId: userInfo.preferred_username });
            ReactGA.event({ action: "Login", category: "UserID" });
            console.log("GA", userInfo.preferred_username);
         } catch (e) {
            console.error(e);
         }
      });
   }, []);

   useEffect(() => {
      const { setUserInfo } = props;
      if (!authState.isAuthenticated) {
         // When user isn't authenticated, forget any user info
         setUserInfo(null);
      } else {
         oktaAuth.getUser().then((info) => {
            setUserInfo(info);
         });
      }
   }, [authState, oktaAuth]);

   const {
      itFinanceCloudDate,
      itFinaceLatestMetrics,
      itFiscCloudDate,
      itHrDrilldownData,
      metrics,
      fiscDateEmployee,
      fiscDateEmployeeAbsenteesim,
      quarter,
      year,
   } = props;

   const getFinanceHeaderDataChicken = () => {
      const { units } = props;
      if (!units || !Array.isArray(units)) return [];
      const header = units.find(({ entity }) => entity === "CHICKEN");
      return header || [];
   };

   const history = useHistory();
   const dispatch = useDispatch();

   const navigateAction = ({ href, section }) => {
      // resetApp();
      dispatch(setActiveSection(section));
      history.push(href);
   };

   const formatNumberString = (args) => {
      const { prependStr, number, appendStr } = formatNumber(args);

      return isNaN(number) ? 0 : `${prependStr}${number}${appendStr}`;
   };

   const formatNumberStringTeamMembers = (args) => {
      const { prependStr, number, appendStr } = formatNumber(args);

      return `${prependStr}${number}${appendStr}`;
   };

   function formatFinaceString(data, key) {
      const numForm = numberFormatter(data[key], 2).slice(0, 5);
      const reg = new RegExp("^[0-9]+$");
      if (reg.test(numForm)) {
         return `$${numberFormatter(data[key], 1)}`;
      } else {
         return `$${numberFormatter(data[key], 2)}`;
      }
   }

   function numberFormatter(num, digits) {
      const lookup = [
         { value: 1, symbol: "" },
         { value: 1e3, symbol: "k" },
         { value: 1e6, symbol: "M" },
         { value: 1e9, symbol: "B" },
         { value: 1e12, symbol: "T" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
         .slice()
         .reverse()
         .find(function (item) {
            return num >= item.value;
         });
      return item
         ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
         : "0";
   }

   function calculateDot(actual, forecast) {
      if (actual && forecast) {
         const toPercent =
            ((actual - forecast) / ((forecast + actual) / 2)) * 100;
         return toPercent > 1 || toPercent < -1 ? false : true;
      }
   }

   function formatDate(date) {
      return date ? `FY${(date + "").slice(2, 4)}` : "";
   }

   function formatfiscDate(date) {
      return date ? { date } : "";
   }

   function formatCloudDate(date) {
      return date
         ? `${date.slice(6, 7)}/${date.slice(8, 10)}/${date.slice(2, 4)}`
         : "";
   }

   return (
      <>
         <ScreenWrapper home landingPage>
            <LoadingBlocker loading={isLoading} />
            <AppLayout keepPaddings>
               <div className="flex grid gap-x-6 gap-y-3 flex-wrap mb-5">
                  {
                     //  |              |
                     //  V desktop view V
                     width > 900 ? (
                        <>
                           <div className="flex row-span-3">
                              <ItemsContainer title="FINANCE">
                                 <div>
                                    <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                                       TOTAL
                                    </h1>
                                    <ItemTriple>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.actualForecast,
                                             itFinaceLatestMetrics.actual
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_ACTUALS",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate "
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "actualForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "actualForecast"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_ACTUALS",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title={`Actual (YTD)`}
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "actual"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "actual"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.aop,
                                             itFinaceLatestMetrics.actual
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_ACTUALS",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "aop"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "aop"
                                                  )
                                          }
                                       />
                                       <div className="w-px  h-12 mr-2"></div>
                                    </ItemTriple>
                                 </div>
                                 <div>
                                    <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                                       CONTROLLABLES
                                    </h1>
                                    <ItemDouble key={1}>
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.controllableForecast,
                                             itFinaceLatestMetrics.controllable
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_CONTROLLABLES",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "controllableForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "controllableForecast"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_CONTROLLABLES",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Controllables <br/> (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "controllable"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "controllable"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.controllableAOP,
                                             itFinaceLatestMetrics.controllable
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_CONTROLLABLES",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "controllableAOP"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "controllableAOP"
                                                  )
                                          }
                                       />
                                       <div className="w-px h-12 mr-2"></div>
                                    </ItemDouble>
                                 </div>
                                 <div>
                                    <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs ">
                                       NON CONTROLLABLES
                                    </h1>
                                    <ItemDouble>
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.NoncontrollableForecast,
                                             itFinaceLatestMetrics.Noncontrollable
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_NonControllables",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "NoncontrollableForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "NoncontrollableForecast"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>

                                       <Item
                                          className="nonControl"
                                          variant="ItNonControllables"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_NonControllables",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title={`Non Controllables (YTD)`}
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "Noncontrollable"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "Noncontrollable"
                                                  )
                                          }
                                       />

                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.NoncontrollableAOP,
                                             itFinaceLatestMetrics.Noncontrollable
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_NonControllables",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "NoncontrollableAOP"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "NoncontrollableAOP"
                                                  )
                                          }
                                       />
                                       <div className="w-px h-12 mr-2"></div>
                                    </ItemDouble>
                                 </div>
                                 <div>
                                    <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs ">
                                       SALARY & BENEFITS
                                    </h1>
                                    <ItemDouble>
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.salaryAndBenefitsForecast,
                                             itFinaceLatestMetrics.salaryAndBenefits
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_COMPENSATION",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "salaryAndBenefitsForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "salaryAndBenefitsForecast"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          variant="ItNonControllables"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_COMPENSATION",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title={`Salary & Benefits (YTD)`}
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "salaryAndBenefits"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "salaryAndBenefits"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.salaryAndBenefitsAOP,
                                             itFinaceLatestMetrics.salaryAndBenefits
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_COMPENSATION",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "salaryAndBenefitsAOP"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "salaryAndBenefitsAOP"
                                                  )
                                          }
                                       />
                                       <div className="w-px h-12 mr-2"></div>
                                    </ItemDouble>
                                 </div>
                              </ItemsContainer>
                           </div>

                           <div className="container">
                              <ItemsContainer>
                                 <div>
                                    <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider  text-xs">
                                       CLOUD
                                    </h1>
                                    <ItemDouble>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.cloudCost,
                                             itFinaceLatestMetrics.cloudCostForecasts
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_CLOUD",
                                             })
                                          }
                                          date={
                                             itFiscCloudDate &&
                                             itFiscCloudDate.map(
                                                (dte) => dte.date
                                             )
                                          }
                                          title="Cloud Cost"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "cloudCost"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "cloudCost"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_CLOUD",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Forecast"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "cloudCostForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "cloudCostForecast"
                                                  )
                                          }
                                       />
                                       <div className="w-px h-12 mr-2"></div>
                                    </ItemDouble>
                                 </div>
                              </ItemsContainer>
                           </div>
                        </>
                     ) : (
                        //  |             |
                        //  V mobile view V
                        <>
                           <ItemsContainerCollapsible
                              title="FINANCE"
                              itemsCollapsible={[
                                 <div className="col-span-2 row-span-2 space-y-2">
                                    <h1 className="mx-2 font-medium">TOTAL</h1>
                                    <ItemDouble>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_ACTUALS",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "actualForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "actualForecast"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 "></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.actual,
                                             itFinaceLatestMetrics.actualForecast
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_ACTUALS",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Actual (YTD)"
                                          value={
                                             formatFinaceString(
                                                { itFinaceLatestMetrics },
                                                "actual"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "actual"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12 "></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.aop,
                                             itFinaceLatestMetrics.actual
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_ACTUALS",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "aop"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "aop"
                                                  )
                                          }
                                       />

                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                    </ItemDouble>
                                    <h1 className="m-2 mt-4 font-medium">
                                       CONTROLLABLES
                                    </h1>
                                    <ItemDouble key={1}>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_CONTROLLABLES",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "controllableForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "controllableForecast"
                                                  )
                                          }
                                       />

                                       <div className="w-px bg-gray-400 h-12 "></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.controllable,
                                             itFinaceLatestMetrics.controllableForecast
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_CONTROLLABLES",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Controllables (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "controllable"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "controllable"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.controllableAOP,
                                             itFinaceLatestMetrics.controllable
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_CONTROLLABLES",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "controllableAOP"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "controllableAOP"
                                                  )
                                          }
                                       />
                                    </ItemDouble>
                                    <h1 className="m-2 mt-4 font-medium">
                                       NON CONTROLLABLES
                                    </h1>
                                    <ItemDouble>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_NonControllables",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "NoncontrollableForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "NoncontrollableForecast"
                                                  )
                                          }
                                       />

                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.controllable,
                                             itFinaceLatestMetrics.controllableForecast
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_NonControllables",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Non Controllables(YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "Noncontrollable"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "Noncontrollable"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.controllableAOP,
                                             itFinaceLatestMetrics.controllable
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_NonControllables",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "NoncontrollableAOP"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "NoncontrollableAOP"
                                                  )
                                          }
                                       />
                                    </ItemDouble>
                                    <h1 className="m-2 mt-4 font-medium">
                                       COMPENSATION
                                    </h1>
                                    <ItemDouble>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_COMPENSATION",
                                             })
                                          }
                                          date={formatDate(
                                             itFinanceCloudDate.fiscYear
                                          )}
                                          title="Latest Estimate"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "salaryAndBenefitsForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "salaryAndBenefitsForecast"
                                                  )
                                          }
                                       />

                                       <div className="w-px bg-gray-400 h-12 mr-2"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.cloudCost,
                                             itFinaceLatestMetrics.cloudCostForecasts
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_COMPENSATION",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title={`Salary & Benefits(YTD)`}
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "salaryAndBenefits"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "salaryAndBenefits"
                                                  )
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-12"></div>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.salaryAndBenefitsAOP,
                                             itFinaceLatestMetrics.salaryAndBenefits
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section:
                                                   "IT_FINANCE_COMPENSATION",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Planned (YTD)"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "salaryAndBenefitsAOP"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "salaryAndBenefitsAOP"
                                                  )
                                          }
                                       />
                                    </ItemDouble>
                                    <h1 className="m-2 mt-4 font-medium">
                                       CLOUD
                                    </h1>
                                    <ItemDouble>
                                       <Item
                                          useDot={true}
                                          size={12}
                                          isPositiveDot={calculateDot(
                                             itFinaceLatestMetrics.cloudCost,
                                             itFinaceLatestMetrics.cloudCostForecast
                                          )}
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_CLOUD",
                                             })
                                          }
                                          date={0}
                                          title="Cloud Cost"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "cloudCost"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "cloudCost"
                                                  )
                                          }
                                       />

                                       <div className="w-px bg-gray-400 h-12"></div>
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_FINANCE_CLOUD",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Forecast"
                                          value={
                                             formatFinaceString(
                                                itFinaceLatestMetrics,
                                                "cloudCostForecast"
                                             ) === 0.0
                                                ? "Error"
                                                : formatFinaceString(
                                                     itFinaceLatestMetrics,
                                                     "cloudCostForecast"
                                                  )
                                          }
                                       />
                                    </ItemDouble>
                                 </div>,
                              ]}
                           ></ItemsContainerCollapsible>
                        </>
                     )
                  }
                  {teamFeatureFlag === true && width > 900 ? (
                     //  |              |
                     //  V desktop view V
                     <div className="container">
                        <ItemsContainer title="TEAM MEMBERS (US ONLY)">
                           <div className="flex flex-wrap gap-x-6 gap-y-3 ">
                              <div>
                                 <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                                    Total
                                 </h1>
                                 <Item
                                    className="col-span-2 row-span-2 space-y-2"
                                    onClick={() =>
                                       navigateAction({
                                          href: "/r/it-dashboard/drilldown",
                                          section: "IT_Hr_TEAM_MEMBERS",
                                       })
                                    }
                                    date={formatFiscDate({
                                       month: fiscDateEmployeeAbsenteesim.month,
                                       year: fiscDateEmployeeAbsenteesim.year,
                                    })}
                                    title="Team Members"
                                    value={team}
                                 />
                              </div>
                              <div>
                                 <h1 className="text-center mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                                    ATTRITION
                                 </h1>
                                 <ItemDouble className="row-span-2">
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_TOTAL_ATTRITION",
                                          })
                                       }
                                       date={formatFiscDate({
                                          month: fiscDateEmployeeAbsenteesim.month,
                                          year: fiscDateEmployeeAbsenteesim.year,
                                       })}
                                       title="Total Attrition"
                                       value={attr ? attr.totalAttrition : 0}
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <Item
                                       useDot={
                                          attr
                                             ? attr.turnoverRate !== "Infinity"
                                                ? true
                                                : false
                                             : false
                                       }
                                       size={12}
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_TOTAL_ATTRITION",
                                          })
                                       }
                                       date={
                                          <span className="opacity-0">0</span>
                                       }
                                       title="Turnover Rate"
                                       value={
                                          attr
                                             ? attr.turnoverRate === "Infinity"
                                                ? "NA"
                                                : `${attr.turnoverRate * 100}%`
                                             : "0"
                                       }
                                    />
                                 </ItemDouble>
                              </div>
                              <div>
                                 <div className="hr-sect ">
                                    <h1 className=" mx-2 text-center mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                                       JOB OPENINGS
                                    </h1>
                                 </div>

                                 <div className="flex">
                                    <ItemDouble className="row-span-2 ">
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_Hr_Vacancies",
                                             })
                                          }
                                          date={formatFiscDate({
                                             month: fiscDateEmployeeAbsenteesim.month,
                                             year: fiscDateEmployeeAbsenteesim.year,
                                          })}
                                          title="Vacancies"
                                          value={
                                             itHrDrilldownData
                                                ? itHrDrilldownData.vacancies
                                                : "0"
                                          }
                                       />
                                       <div className="w-px bg-gray-400 h-20"></div>
                                       <Item
                                          variant="ItNonControllables"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_Hr_Vacancies",
                                             })
                                          }
                                          date={
                                             <span className="opacity-0">
                                                0
                                             </span>
                                          }
                                          title="Frozen Positions"
                                          value={
                                             itHrDrilldownData
                                                ? itHrDrilldownData.frozenPositions
                                                : "0"
                                          }
                                       />
                                    </ItemDouble>

                                    <ItemDouble className="row-span-2 ">
                                       <Item
                                          variant="ItNonControllables"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_Hr_Positions",
                                             })
                                          }
                                          date={formatFiscDate({
                                             month: fiscDateEmployeeAbsenteesim.month,
                                             year: fiscDateEmployeeAbsenteesim.year,
                                          })}
                                          title="Positions in Offer Status"
                                          value={
                                             itHrDrilldownData
                                                ? itHrDrilldownData.PositionsInOffer
                                                : "0"
                                          }
                                       />
                                    </ItemDouble>
                                    <ItemDouble>
                                       <Item
                                          variant="ItNonControllables"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/it-dashboard/drilldown",
                                                section: "IT_Hr_AvgDaysHire",
                                             })
                                          }
                                          date={formatFiscDate({
                                             month: fiscDateEmployeeAbsenteesim.month,
                                             year: fiscDateEmployeeAbsenteesim.year,
                                          })}
                                          title="Avg Days to Hire"
                                          value={
                                             itHrDrilldownData
                                                ? numberFormatter(
                                                     itHrDrilldownData.avgDaysToHire,
                                                     0
                                                  )
                                                : "0"
                                          }
                                       />
                                    </ItemDouble>
                                 </div>
                              </div>

                              {/* <div>
                      <div className="hr-sect">
                        <h1 className="text-center mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                          TRAINING
                        </h1>
                      </div>

                      <div className="flex">
                        <Item
                          className="row-span-2 "
                          date={"YTD FY22"}
                          title="Learning Compliance"
                          value={0}
                        />
                        <ItemDouble className="row-span-2 ">
                          <Item
                            variant="double"
                            onClick={() =>
                              navigateAction({
                                href: "#",
                                section: "IT_FINANCE_DASHBOARD",
                              })
                            }
                            date={"YTD FY22"}
                            title="Six Sigma Completed"
                            value={0}
                          />
                        </ItemDouble>
                      </div>
                    </div> */}
                           </div>
                        </ItemsContainer>
                     </div>
                  ) : teamFeatureFlag ? (
                     //  |             |
                     //  V mobile view V
                     <ItemsContainerCollapsible
                        title="TEAM MEMBERS"
                        itemsCollapsible={[
                           <div className="col-span-2 row-span-2 space-y-2">
                              <div className="w-1/2">
                                 <h1 className="mx-2 mb-2 font-medium">
                                    TOTAL
                                 </h1>
                                 <Item
                                    onClick={() =>
                                       navigateAction({
                                          href: "/r/it-dashboard/drilldown",
                                          section: "IT_Hr_TEAM_MEMBERS",
                                       })
                                    }
                                    date={formatFiscDate({
                                       month: fiscDateEmployeeAbsenteesim.month,
                                       year: fiscDateEmployeeAbsenteesim.year,
                                    })}
                                    title="Team Members"
                                    value={team}
                                 />
                              </div>
                              <SectionRow>
                                 <h1 className="m-2 mt-4 font-medium">
                                    JOB OPENINGS
                                 </h1>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "#",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={formatFiscDate({
                                          month: fiscDateEmployeeAbsenteesim.month,
                                          year: fiscDateEmployeeAbsenteesim.year,
                                       })}
                                       title="Vacancies"
                                       value={
                                          itHrDrilldownData
                                             ? itHrDrilldownData.vacancies
                                             : "0"
                                       }
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>

                                    <Item
                                       variant="ItNonControllables"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_Vacancies",
                                          })
                                       }
                                       date={
                                          <span className="opacity-0">0</span>
                                       }
                                       title="Frozen Positions"
                                       value={
                                          itHrDrilldownData
                                             ? itHrDrilldownData.frozenPositions
                                             : "0"
                                       }
                                    />
                                 </ItemDouble>
                              </SectionRow>
                              <SectionRow>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_Positions",
                                          })
                                       }
                                       date={formatFiscDate({
                                          month: fiscDateEmployeeAbsenteesim.month,
                                          year: fiscDateEmployeeAbsenteesim.year,
                                       })}
                                       title="Positions in Offer Status"
                                       value={
                                          itHrDrilldownData
                                             ? itHrDrilldownData.PositionsInOffer
                                             : "0"
                                       }
                                    />
                                 </ItemDouble>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_AvgDaysHire",
                                          })
                                       }
                                       date={formatFiscDate({
                                          month: fiscDateEmployeeAbsenteesim.month,
                                          year: fiscDateEmployeeAbsenteesim.year,
                                       })}
                                       title="Avg Days to Hire"
                                       value={
                                          itHrDrilldownData
                                             ? numberFormatter(
                                                  itHrDrilldownData.avgDaysToHire,
                                                  0
                                               )
                                             : "0"
                                       }
                                    />
                                 </ItemDouble>
                              </SectionRow>
                              <SectionRow>
                                 <h1 className="m-2 mt-4 font-medium">
                                    ATTRRITION
                                 </h1>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_TOTAL_ATTRITION",
                                          })
                                       }
                                       date={formatFiscDate({
                                          month: fiscDateEmployeeAbsenteesim.month,
                                          year: fiscDateEmployeeAbsenteesim.year,
                                       })}
                                       title="Total Attrition"
                                       value={attr ? attr.totalAttrition : 0}
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <Item
                                       useDot={
                                          attr
                                             ? attr.turnoverRate !== "Infinity"
                                                ? true
                                                : false
                                             : false
                                       }
                                       size={12}
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/it-dashboard/drilldown",
                                             section: "IT_Hr_TOTAL_ATTRITION",
                                          })
                                       }
                                       date={
                                          <span className="opacity-0">0</span>
                                       }
                                       title="Turnover Rate"
                                       value={
                                          attr
                                             ? attr.turnoverRate === "Infinity"
                                                ? "NA"
                                                : `${attr.turnoverRate * 100}%`
                                             : "0"
                                       }
                                    />
                                 </ItemDouble>
                              </SectionRow>
                              {/* <div>
                      <h1 className="m-2 mt-4 font-medium">TRAINING</h1>
                      <div className="flex">
                        <Item
                          date={"YTD FY22"}
                          title="Courses Completed"
                          value={0}
                        />

                        <SectionRow>
                          <ItemDouble>
                            <Item
                              variant="double"
                              onClick={() =>
                                navigateAction({
                                  href: "/r/poultry/finance",
                                  section: "IT_FINANCE_DASHBOARD",
                                })
                              }
                              date={"YTD FY22"}
                              title="Six Sigma Completed"
                              value={0}
                            />
                          </ItemDouble>
                        </SectionRow>
                      </div>
                    </div> */}
                           </div>,
                        ]}
                     ></ItemsContainerCollapsible>
                  ) : (
                     ""
                  )}{" "}
                  {projectFeatureFlag && width > 900 ? (
                     //  |              |
                     //  V desktop view V
                     <ItemsContainer title="PROJECTS">
                        <SectionRow>
                           <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                              CAPACITY PLANNING
                           </h1>
                           <ItemDouble>
                              <Item
                                 variant="double"
                                 onClick={() =>
                                    navigateAction({
                                       href: "#",
                                       section: "IT_FINANCE_DASHBOARD",
                                    })
                                 }
                                 date={"YTD FY22"}
                                 title="Total Capacity"
                                 value={0}
                              />
                              <div className="w-px bg-gray-400 h-20"></div>
                              <Item
                                 variant="double"
                                 onClick={() =>
                                    navigateAction({
                                       href: "#",
                                       section: "IT_FINANCE_DASHBOARD",
                                    })
                                 }
                                 date={<span className="opacity-0">0</span>}
                                 title="Total Supply"
                                 value={0}
                              />
                              <div className="w-px bg-gray-400 h-20"></div>
                              <Item
                                 variant="double"
                                 onClick={() =>
                                    navigateAction({
                                       href: "#",
                                       section: "IT_FINANCE_DASHBOARD",
                                    })
                                 }
                                 date={<span className="opacity-0">0</span>}
                                 title="Total Demand"
                                 value={0}
                              />
                           </ItemDouble>
                        </SectionRow>

                        <div>
                           <h1 className="mx-2 mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                              ALLOCATION
                           </h1>
                           <ItemDouble>
                              <Item
                                 variant="double"
                                 onClick={() =>
                                    navigateAction({
                                       href: "#",
                                       section: "IT_FINANCE_DASHBOARD",
                                    })
                                 }
                                 date={"YTD FY22"}
                                 title="FTEs Over 80%"
                                 value={0}
                              />
                              <div className="w-px bg-gray-400 h-20"></div>
                              <Item
                                 variant="double"
                                 onClick={() =>
                                    navigateAction({
                                       href: "#",
                                       section: "IT_FINANCE_DASHBOARD",
                                    })
                                 }
                                 date={<span className="opacity-0">0</span>}
                                 title="FTEs Under 60%"
                                 value={0}
                              />
                              <div className="w-px bg-gray-400 h-20"></div>
                           </ItemDouble>
                        </div>

                        <SectionRow>
                           <h1 className=" mx-2 text-center mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                              ACTIVE PROJECTS
                           </h1>
                           <ItemDouble>
                              <Item
                                 variant="double"
                                 onClick={() =>
                                    navigateAction({
                                       href: "#",
                                       section: "IT_FINANCE_DASHBOARD",
                                    })
                                 }
                                 date={"YTD FY22"}
                                 title="Project Status"
                                 value={0}
                              />
                              <div className="w-px bg-gray-400 h-20"></div>
                           </ItemDouble>
                        </SectionRow>
                        <div>
                           <div className="hr-sect ">
                              <h1 className=" mx-2 text-center mb-1.5 font-sans font-extrabold tracking-wider text-xs">
                                 COMPLETED PROJECTS
                              </h1>
                           </div>

                           <div className="flex">
                              <ItemDouble className="row-span-2 ">
                                 <Item
                                    variant="double"
                                    onClick={() =>
                                       navigateAction({
                                          href: "#",
                                          section: "IT_Hr_Vacancies",
                                       })
                                    }
                                    date={"YTD FY22"}
                                    title="Total Completed"
                                    value={0}
                                 />
                                 <div className="w-px bg-gray-400 h-20"></div>
                                 <Item
                                    variant="double"
                                    onClick={() =>
                                       navigateAction({
                                          href: "#",
                                          section: "IT_Hr_Vacancies",
                                       })
                                    }
                                    date={<span className="opacity-0">0</span>}
                                    title="Total Change Requests"
                                    value={0}
                                 />
                                 <div className="w-px bg-gray-400 h-20"></div>
                                 <Item
                                    variant="double"
                                    onClick={() =>
                                       navigateAction({
                                          href: "#",
                                          section: "IT_Hr_Vacancies",
                                       })
                                    }
                                    date={<span className="opacity-0">0</span>}
                                    title="On Time"
                                    value={0}
                                 />
                                 <div className="w-px bg-gray-400 h-20"></div>
                                 <Item
                                    variant="double"
                                    onClick={() =>
                                       navigateAction({
                                          href: "#",
                                          section: "IT_Hr_Vacancies",
                                       })
                                    }
                                    date={<span className="opacity-0">0</span>}
                                    title="On Budget"
                                    value={0}
                                 />
                                 <div className="w-px bg-gray-400 h-20"></div>
                              </ItemDouble>
                           </div>
                        </div>
                     </ItemsContainer>
                  ) : projectFeatureFlag ? (
                     //  |             |
                     //  V mobile view V
                     <ItemsContainerCollapsible
                        title="PROJECTS"
                        itemsCollapsible={[
                           <div className="col-span-2 row-span-2 space-y-2">
                              <SectionRow>
                                 <h1 className="mx-2 mb-2 font-medium">
                                    CAPACITY PLANNING
                                 </h1>
                                 <ItemTriple>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          year === 0 || quarter === 0
                                             ? "Error"
                                             : formatFiscDate({ quarter, year })
                                       }
                                       title="Total Capacity"
                                       value={0}
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <div className="pt-5">
                                       <Item
                                          className="pt-5"
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/poultry/finance",
                                                section: "IT_FINANCE_DASHBOARD",
                                             })
                                          }
                                          title="Total Supply"
                                          value={0}
                                       />
                                    </div>
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <div className="pt-5">
                                       <Item
                                          variant="double"
                                          onClick={() =>
                                             navigateAction({
                                                href: "/r/poultry/finance",
                                                section: "IT_FINANCE_DASHBOARD",
                                             })
                                          }
                                          title="Total Damand"
                                          value={0}
                                       />
                                    </div>
                                 </ItemTriple>
                              </SectionRow>
                              <SectionRow>
                                 <h1 className="mx-2 mb-2 font-medium">
                                    {" "}
                                    ALLOCATION{" "}
                                 </h1>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          year === 0 || quarter === 0
                                             ? "Error"
                                             : formatFiscDate({ quarter, year })
                                       }
                                       title="FTEs Over 80%"
                                       value={0}
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          <span className="opacity-0">0</span>
                                       }
                                       title="FTEs Under 60%"
                                       value={0}
                                    />
                                 </ItemDouble>
                              </SectionRow>
                              <div className="w-2/3">
                                 <h1 className="m-2 mt-4 font-medium">
                                    IN PROGRESS
                                 </h1>
                                 <Item
                                    onClick={() =>
                                       navigateAction({
                                          href: "/r/poultry/finance",
                                          section: "IT_FINANCE_DASHBOARD",
                                       })
                                    }
                                    date={
                                       year === 0 || quarter === 0
                                          ? "Error"
                                          : formatFiscDate({ quarter, year })
                                    }
                                    title="Project Status"
                                    value={0}
                                 />
                              </div>
                              <SectionRow>
                                 <h1 className="m-2 mt-4 font-medium">
                                    COMPLETED PROJECTS
                                 </h1>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          year === 0 || quarter === 0
                                             ? "Error"
                                             : formatFiscDate({ quarter, year })
                                       }
                                       title="Total Completed"
                                       value={0}
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          <span className="opacity-0">0</span>
                                       }
                                       title="Total Change Requests"
                                       value={0}
                                    />
                                 </ItemDouble>
                              </SectionRow>
                              <SectionRow>
                                 <ItemDouble>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          year === 0 || quarter === 0
                                             ? "Error"
                                             : formatFiscDate({ quarter, year })
                                       }
                                       title="On Time"
                                       value={0}
                                    />
                                    <div className="w-px bg-gray-400 h-20"></div>
                                    <Item
                                       variant="double"
                                       onClick={() =>
                                          navigateAction({
                                             href: "/r/poultry/finance",
                                             section: "IT_FINANCE_DASHBOARD",
                                          })
                                       }
                                       date={
                                          <span className="opacity-0">0</span>
                                       }
                                       title="On Budget"
                                       value={0}
                                    />
                                 </ItemDouble>
                              </SectionRow>
                           </div>,
                        ]}
                     ></ItemsContainerCollapsible>
                  ) : (
                     ""
                  )}
               </div>
            </AppLayout>
         </ScreenWrapper>
      </>
   );
};

function mapStateToProps(state) {
   return {
      userInfo: state.userState.userInfo,
      activeQYSwitcher: state.sections.activeQYSwitcher,
      activeSection: state.sections.activeSection,
      currentDate: state.values.currentDate,
      isSingle: state.sections.isSingle,
      header: state.values.header,
      year: state.sections.year,
      quarter: state.sections.quarter,
      activeTab: state.sections.activeTab,
      activeBu: state.sections.activeBu,
      currentQuarter: state.sections.currentQuarter,
      isHeaderLoading: state.values.isHeaderLoading,
      showModal: state.app.showModal,

      userPrivilages: state.userState.userPrivilages,
      metrics: state.values.metrics,
      fiscDateEmployee: state.sections.fiscDateEmployee,
      fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
      oshaFiscDate: state.sections.oshaFiscDate,
      oshaMetrics: state.values.oshaMetrics,
      oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
      oshaRatesMetrics: state.values.oshaRatesMetrics,
      plantOpsFiscDate: state.sections.plantOpsFiscDate,
      plantOpsMetrics: state.values.plantOpsMetrics,
      units: state.values.units,
      itFinaceLatestMetrics: state.values.itFinaceLatestMetrics,
      itFinanceCloudDate: state.values.itFinanceCloudDate,
      itFiscCloudDate: state.values.itFiscCloudDate,
      itHrDrilldownData: state.values.itHrDrilldownData,
   };
}
export default connect(mapStateToProps, {
   setItFinaceLatestMetrics,
   setItFinanceCloudDate,
   setItHRDrilldownData,
   setItFiscCloudDate,
   setLatestMetrcisAbsenteesim,
   resetApp,
   setFiscDateEmployee,
   setOshaMetrics,
   setOshaRatesMetrics,
   setFiscDateEmployeeAbsenteesim,
   setUserInfo,
   setShowModal,
   setShowMenu,
   setPlantOpsMetrics,
   receiveDataHeader,
   setCurrentDate,
   setQuarter,
   setYear,
   setScrollPosition,
})(HomeScreenItDashboard);
