import React from 'react'

import Button from './Button'

const DoubleButton = ({ 
  firstText,
  firstOnClick,
  firstActive,
  firstDisabled,
  secondText,
  secondOnClick,
  secondActive,
  secondDisabled,
  thirdText,
  thirdOnClick,
  thirdActive,
  thirdDisabled,
  size='md'
}) => {
  return (
    <div className='rounded border border-gray-600 p-px whitespace-nowrap'>
      <Button
        onClick={firstOnClick}
        isActive={firstActive}
        disabled={firstDisabled}
        size={size}
      >
        { firstText }
      </Button>
      <Button
        onClick={secondOnClick}
        isActive={secondActive}
        disabled={secondDisabled}
        size={size}
      >
        { secondText }
      </Button>
      {
        thirdText && <Button
          onClick={thirdOnClick}
          isActive={thirdActive}
          disabled={thirdDisabled}
          size={size}
        >
        { thirdText }
      </Button>
      }
    </div>
  )
}

export default DoubleButton