import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setQuarter, setSingle, setYear } from '../actions/sectionsActions'
import ScrollButton from '../componentsGeneral/section/ScrollButton'

import FeatherIcon from 'feather-icons-react';


const BigGraphFinanceSlider = ({ sections, sliderRef, currentDate, activeQYSwitcher, scrollPosition, setScrollPosition }) => {
  const dispatch = useDispatch()
  const sliderScrollableElement = useRef(null)
  const [buttonsState, setButtonsState] = useState({
    showLeft: true,
    showRight: false
  })

  const setYearAndQuarter = () => {
    if(!currentDate) {
        return;
    }
    if(activeQYSwitcher === 'Q') {
        const quarter = (scrollPosition%4)+1;
        const year = Math.floor(scrollPosition/4)+(currentDate.year - 2);
        dispatch( setQuarter(quarter) );
        dispatch( setYear(year) );
    }
    else {
        const year = scrollPosition+(currentDate.year - 2);
        dispatch( setYear(year) );
    }
  }

  const manageButtonsState = () => {
    if (scrollPosition == 0) setButtonsState({
      showLeft: false,
      showRight: true
    })
    else if (scrollPosition == sections.length - 1) setButtonsState({
      showLeft: true,
      showRight: false
    })
    else setButtonsState({
      showLeft: true,
      showRight: true
    })
  }

  const getTranslateXAbs = () => {
    const element = sliderScrollableElement.current;
    return Math.abs( parseInt( element.style.transform.replace('translateX(', '').replace(')', '') ) )
  }

  function scrollTo(to, duration=200, callback) {
    const element = sliderScrollableElement.current;

    const currentScroll = getTranslateXAbs()

    var start = currentScroll,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.style.transform = `translateX(-${ val }px)`;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();

    setTimeout(() => callback(), duration + 10)
  }

  useEffect(() => {
    setYearAndQuarter()
    manageButtonsState()
    dispatch( setSingle(false) )
  }, [scrollPosition])

  return (
    <React.Fragment>
      {buttonsState.showLeft && <ScrollButton
        className='left-0'
        onClick={() => setScrollPosition(scrollPosition - 1)}
      >
        <FeatherIcon className='text-gray-600' icon={'chevron-left'} />
      </ScrollButton>}
      <div
        ref={sliderScrollableElement}
        className='flex'
        style={{
          width: '100%'
        }}
      >

        <div className='w-full'>
          { sections[scrollPosition] }
        </div>

      </div>
      {buttonsState.showRight && <ScrollButton
        className='right-0'
        onClick={() => setScrollPosition(scrollPosition + 1)}
      >
        <FeatherIcon className='text-gray-600' icon={'chevron-right'} />
      </ScrollButton>}
    </React.Fragment>

  )
}

export default BigGraphFinanceSlider
