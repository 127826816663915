import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import formatNumber from '../utils/formatNumber';


const TooltipUnitOsha = ({ payload, currentKey, className, isTwoYearTrend }) => {
  const {
    activeSection,
    year,
    activeQYSwitcher
  } = useSelector(state => ({
    activeSection: state.sections.activeSection,
    year: state.sections.year,
    activeQYSwitcher: state.sections.activeQYSwitcher
  }))

  const getTitle = () => {
    const data = payload[0].payload
    if (!data) return null
    if (activeSection === 'OSHA_RATES') return <div className='mb-1'>YTD <span className='font-bold'>{monthNames[parseInt(data.period || data.fiscMonth) - 1]}</span> FY{(data.fiscYear || year) - (2000)}</div>
    if (activeQYSwitcher === 'Y' && activeSection === 'OSHA_RECORDABLES') return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period || data.fiscMonth) - 1]}</span> FY{(data.fiscYear || year) - (2000)}</div>
    if ((activeQYSwitcher === 'Q' && activeSection === 'OSHA_RECORDABLES') && isTwoYearTrend) return <div className='mb-1'><span className='font-bold'>{monthNames[parseInt(data.period || data.fiscMonth) - 1]}</span> FY{(data.fiscYear || year) - (2000)}</div>
    else return <div className='mb-1'><span className='font-bold'>FW{parseInt(data.period || data.fiscMonth)}</span> FY{year - 2000}</div>
  }


  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${isNaN(number) ? 0 : number}${appendStr}`

  const getItems = () => {
    if (!(currentKey in payload[0].payload)) return []

    return [
      <div className='whitespace-nowrap' key={0}>{ (activeSection === 'OSHA_RECORDABLES' ? 'All Recordables' : 'Rate') + ': ' + '' + formatStr(formatNumber({ number: payload[0].payload[currentKey] }))  }</div>
    ]
    // return [
    //   <div className='whitespace-nowrap' key={0}>
    //     { (activeSection === 'OSHA_RECORDABLES' ? 'All Recordables' : 'Rate') + ': ' + '' 
    //     + formatStr(formatNumber({ number: payload[0].payload[currentKey] })) + ' '
    //     }
    //     {activeSection === 'OSHA_RECORDABLES' ? '' : 'vs '}
    //     {activeSection === 'OSHA_RECORDABLES' ? '' : 
    //     + formatStr(formatNumber({ number: payload[0].payload[currentKey+'PY'] }))
    //     }
    //     {activeSection === 'OSHA_RECORDABLES' ? '' : ' '}
    //     {activeSection === 'OSHA_RECORDABLES' ? '' : 
    //     + formatStr(formatNumber({ number: (payload[0].payload[currentKey] - payload[0].payload[currentKey+'PY'])/ payload[0].payload[currentKey+'PY']*100 }))
    //     }
    //     {activeSection === 'OSHA_RECORDABLES' ? '' : '%'}
    //     </div>
    // ]
  }

  return (
    <>
      {
        payload && payload.length ?
        <Tooltip
          className={className}
        >
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>
        :
        null
      }
      
    </>
    
  )
}

export default TooltipUnitOsha