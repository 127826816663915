import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import CurrentScreenValues from '../componentsGeneral/section/CurrentScreenValues'
import ValueWithDot from '../componentsGeneral/section/ValueWithDot'
import { getProperty } from '../utils/dataMappingTurnover'
import formatNumber from '../utils/formatNumber'


const CurrentScreenValuesTeamMembers = ({ currentKey, onlyIT_USER }) => {
  const {
    header,
    activeTabTitle,
    activeTab,
    dataGraphAdditional,
    buType,
    activeSection,
    activeBu
  } = useSelector(state => ({
    header: state.values.header,
    activeTabTitle: state.sections.activeTabTitle,
    activeTab: state.sections.activeTab,
    dataGraphAdditional: state.values.dataGraphAdditional,
    buType: state.sections.buType,
    activeSection: state.sections.activeSection,
    activeBu: state.sections.activeBu
  }))


  const getMainValue = () => {
    return header ? isNaN(header[currentKey]) ? 0 : header[currentKey] : ''
  }

  const formatBottomValue = (value) => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, employeePercents: true })

    return `${prependStr}${number}${appendStr}`
  }

  const getBottomValues = () => {
    if (activeSection === 'ABSENTEEISM') return <div className='text-sm text-black font-medium'>
      <span style={{ color: '#000' }}>{ getProperty('TotalLy', 'title') }: </span>
      <span>{ formatBottomValue(header.absCountLy) }</span>
    </div>
    return dataGraphAdditional.map(item => {
      return <div key={item.viewBy} className='text-sm text-black font-medium'>
        <span style={{ color: item.color }}>{ getProperty(item.viewBy, 'title') }: </span>
        <span>{ formatBottomValue(item[currentKey]) }</span>
      </div>
    })
  }

  return (
    <CurrentScreenValues
      value={getMainValue()}
      employeePercents={currentKey === 'turnoverRate' || activeSection === 'ABSENTEEISM'}
      defaultTitle='TYSON OVERALL'
      includeDot={activeSection === 'ABSENTEEISM'}
      dotValue={(isNaN(header.absCountLy) ? 0 : header.absCountLy) - getMainValue()}
      title={activeBu.length ? (onlyIT_USER && activeBu[activeBu.length - 1] == 'IT' ? 'IT OVERALL' :  activeBu[activeBu.length - 1]) : activeTabTitle}
      mobileHeaderChildren={`${activeTab || activeTabTitle} ${buType ? '- By ' + (buType === 'leader' ? 'Organization' : 'Location') : ''}`}
    >
      <div className='mt-2 flex flex-wrap items-center justify-center space-x-2 px-4'>
        { getBottomValues() }
      </div>
    </CurrentScreenValues>
  )
}

export default CurrentScreenValuesTeamMembers
