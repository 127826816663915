import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Osha from '../fullAppUser/Osha';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const OshaPoultry = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
      setBuType('location')
      setActiveBu(['Poultry'])
    }, [])
    
    return (
        <Osha
          onlyPoultry
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(OshaPoultry)
