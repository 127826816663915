import React from 'react'

import FeatherIcon from 'feather-icons-react';
import toClassName from '../../utils/toClassName';

const Checkbox = ({ checked, setChecked, title }) => {
  return (
    <div className='flex items-center cursor-pointer' onClick={() => setChecked(!checked)}>
      <div className={toClassName(['flex justify-center items-center w-6 h-6 rounded border border-gray-600', checked ? 'bg-gray-600' : ''])}>
        {checked && <FeatherIcon icon="check" className='text-white' />}
      </div>
      <span className='ml-2 text-sm text-black font-medium'>
        { title }
      </span>
    </div>
  )
}

export default Checkbox