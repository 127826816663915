import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';
import {initGA} from './components/Tracking/';

/**
 * initializing google analytics
 */
initGA();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);



let deferredPrompt;

const addBtn = document.querySelector('.add-button');


window.addEventListener('beforeinstallprompt', (e) => {
  //console.log('beforeinstallprompt', e);
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;

  /* addBtn.addEventListener('click', (e) => {
    // hide our user interface that shows our A2HS button
    // Show the prompt
  // Update UI notify the user they can install the PWA
  //showInstallPromotion();
  deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        deferredPrompt = null;
      });
  }); */
});
