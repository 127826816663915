import React from 'react'

const BarShapeStrapped = (props) => {
  let {height, width, y, x, fill, opacity} = props;
  const paths = []

  for(let i = height; i>0; i-= 8) {
    paths.push(
        <path
          key={i}
          opacity={opacity}
          cursor="pointer"
          fill={fill}
          radius="0"
          className="recharts-rectangle"
          d={`M ${x },${y + i}
          l ${width},${-7}
          l ${0},${5}
          l -${width},${7}
          l ${0},${-5}`}
        ></path>
    );
  }

  const yCalculated = height < 0 ? y + height : y
  // add rect to register clicks at any place
  return (
    <>
    <rect 
      width={width}
      height={Math.abs(height)}
      x={x}
      y={yCalculated}
      fill="#fff"
      cursor="pointer"
    >
    </rect>
    { paths }
    </> 
  )
}

export default BarShapeStrapped