import React from 'react'
import { connect } from 'react-redux'
import { setShowModal } from '../../actions/app'

const ScreenBlocker = ({ active, text, color, error, showModal }) => {
  return (
    <div
      style={{
        flex: 1,
        flexDirection: 'column',
        display: active ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: '#fff',
        zIndex: 1001,
        color: color ? color : ''
      }}
    >
      <div style={{display:'flex', flex:1, justifyContent: 'center', alignContent:'center', alignItems: 'center'}}>
        <div style={{padding: 16, textAlign: 'center'}}>
          <span>{text}</span>
          {error && (
            <span
              style={{
                color: '#000',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
              onClick={() => window.location.reload()}
            >
              . Reload page.
              <p className="uk-text-center uk-text-primary">
                <a
                  onClick={() =>
                    showModal({
                      active: true,
                      type: 'contact'
                    })
                  }
                >
                  Contact Support
                </a>
              </p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({})
const mapDispatchToProps = {
  showModal: setShowModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenBlocker)
