import React, { useEffect, useState }  from 'react'
import toClassName from '../../utils/toClassName'
import Portal from './Portal'

const Tooltip = ({ children, className, style={} }) => {
  const [tooltip, setTooltip] = useState(false)

  const getTooltipStyle = () => {
    const rect = tooltip.getBoundingClientRect()

    return {
      position: 'fixed',
      top: rect.y + 'px',
      left: rect.x + 'px'
    }
  }

  const getPortalTarget = () => {
    return document.body
  }

  return (
    <>
      <div ref={e => setTooltip(e)} className={toClassName(["px-3 py-2 shadow bg-gray-400 opacity-0", className])}>
        { children }
      </div>
      {tooltip  && <Portal targetNode={getPortalTarget()}>
        {/* <div className={toClassName(["px-3 py-2 shadow bg-gray-400 visible transform -translate-x-full -translate-y-full", className])} style={getTooltipStyle()}> */}
        <div className={toClassName(["px-3 py-2 shadow bg-gray-400 visible", className])} style={getTooltipStyle()}>
          { children }
        </div>
      </Portal>}
    </>
  )
}

export default Tooltip