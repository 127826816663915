import React from 'react'


import SectionTitle from './SectionTitle'

const Section = ({ title, children }) => {
  return (
    <div className='pt-4'>
      <SectionTitle text={title} />

      <div className='flex flex-wrap justify-between items-start lg:flex-row flex-col'>
        { children }
      </div>
    </div>
  )
}

export default Section