import React from 'react'


const ContentRightDesktop = ({ children }) => {
  return (
    <div className='flex-1 border-b-4 border-gray-400 lg:border-b-0 lg:overflow-hidden'>
      { children }
    </div>
  )
}

export default ContentRightDesktop