import React, {useEffect, useState} from 'react'
import {
  Bar,
  XAxis,
  ReferenceLine,
  ComposedChart,
  ResponsiveContainer,
  LabelList,
  Cell,
  Line
} from "recharts";
import formatFiscDate from "../utils/formatFiscDate";
import {useSelector} from "react-redux";
import formatNumber from "../utils/formatNumber";

const LineCustomizedLabel = (props) => {
  const { x, y, stroke, value, lastBar, index, year } = props;

  if (lastBar && lastBar.index !== index) return null

  return (
    <text x={x + 25} y={y + 5} fill={'#000'} textAnchor="start">
      <tspan fontWeight={700} fontSize={16}>{formatNumber({number: value}).prependStr + formatNumber({number: value}).number}</tspan>
      <tspan fontSize={14}>{'\u00A0'}{formatFiscDate({ year: year})} YTD</tspan>
    </text>
  )
}


const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value, lastBar } = props;

  if (payload && lastBar && lastBar.bar && payload.period !== lastBar.bar.period) return null

  return (

      <circle cx={cx + 5.5} cy={cy} r={5.5} fill='transparent' strokeWidth={3} stroke={'#002544'} />

  );
};

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const deltaY = 10 * Math.sign(value);

  return (
    <g>
      <text x={x + width / 2} y={y - (deltaY == 0 ? 7 : deltaY)} fill="#000" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    </g>
  );
};

const GraphNesFav = ({ bars, hasLine }) => {
  const { fiscDateNesFavBrand } = useSelector((state) => ({
    fiscDateNesFavBrand: state.sections.fiscDateNesFavBrand
  }));

  const [lastBar, setLastBar] = useState({})
  const [data, setData] = useState([])

  const fillMissedPeriods = (initialBars) => {
    const barsCopy = initialBars.slice(0)
    for (let i = 0; i < 4; i++) {
      if (!barsCopy[i]) barsCopy[i] = {
        "period": i + 1,
        "consumerScr": null
      }
    }

    return barsCopy
  }

  const fillXTicks = (bars) => {
    return bars.map(bar => ({
      ...bar,
      xTick: formatFiscDate({ quarter: bar.period, year: fiscDateNesFavBrand.year})
    }))
  }

  const updateComponent = () => {
    setLastBar({bar: bars[bars.length - 1], index: bars.length - 1})
    setData(fillXTicks(fillMissedPeriods(bars)))
  }

  useEffect(() => {
    updateComponent()
  }, [])

  useEffect(() => {
    updateComponent()
  }, [
    bars
  ])

  return (
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 120,
            bottom: 20,
            left: 40,
          }}
        >
          <XAxis
            tick={{fontSize: 14, fill: '#000'}}
            axisLine={false}
            tickLine={false}
            tickMargin={20}
            dataKey="xTick"
          />
          <Bar dataKey="consumerScr" minPointSize={5} fill="#413ea0" barSize={40}>
            {data.map((entry, index) => (
              <Cell fill={entry.consumerScr == 0 ? '#808080' : entry.consumerScr > 0 ? '#4CD964' : '#EB3C31'} barSize={48} key={`cell-${index}`} />
            ))}
            <LabelList dataKey="consumerScr" content={renderCustomizedLabel} />
          </Bar>
          <ReferenceLine y={0} stroke="#70707060" />
          {/* {hasLine && <Line
            dot={<CustomizedDot lastBar={lastBar} />}
            label={<LineCustomizedLabel year={fiscDateNesFavBrand.year} lastBar={lastBar} />}
            dataKey="avgLine"
            strokeDasharray={[3, 3]}
            stroke={'#002544'}
            strokeWidth={3}
          />} */}
        </ComposedChart>
      </ResponsiveContainer>
  )
}

export default GraphNesFav
