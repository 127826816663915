import roundFunction from './roundFunction';
import {
    genderColors,
    oshaColors,
    ethnicityColors,
    ageColors,
    tenureColors,
    exitReasonColors,
    absenteeismColors
} from '../constants/colors';

const formatData = (data) => {
    if (Array.isArray(data)) return data

    const result = {
        ...data,
        turnoverRate: roundFunction(data.turnoverRate*100)
    }
    return result;
}

export const makeUniqueFields = (obj, appendKey) => {
    const resultObj = {...obj};
    for(let key in obj) {
        if(key === 'viewBy' || key === 'period') {
            continue;
        }
        resultObj[key + appendKey] = obj[key];
        const divideBy = 1;
        if(key === 'turnoverRate') {
            resultObj[key + appendKey] = roundFunction( resultObj[key + appendKey]*100 )
        }
        else if (key === 'avgActiveEmployees') {
            resultObj[key + appendKey] = roundFunction( resultObj[key + appendKey]/divideBy, 'K' )
        }
        else {
            resultObj[key + appendKey] = resultObj[key + appendKey];
        }
    }
    return resultObj;
}

export const getOshaHeaderData = (data) => {
    const types = ['All Other Illnesses', 'Injury', 'All', 'SIF', 'Skin Disorders', 'Respiratory Condition', 'Hearing Loss'];
    for(let type of types) {
        if(data.find( ({ illnessType }) => illnessType == type) === undefined) data.push({ illnessType: type, countRecorded: 0 });
    }
    return data;
}

export const getHeaderData = (data) => {
    return formatData(data);
}

export const getProperty = (key, prop) => {
    if(genderColors[key]) return genderColors[key][prop];
    if(absenteeismColors[key]) return  absenteeismColors[key][prop];
    if(ethnicityColors[key]) return ethnicityColors[key][prop];
    if(ageColors[key]) return ageColors[key][prop];
    if(tenureColors[key]) return tenureColors[key][prop];
    if(exitReasonColors[key]) return exitReasonColors[key][prop];
    if(oshaColors[key]) return oshaColors[key][prop];
}


export const getGraphDataSIF = async (data) => {
    data = data.graphBarRecords;
    for(let i = 0; i < data.length; i++) {
        const prevpP = data[i - 1];
        const currentP = data[i];
        if(prevpP) currentP.countRecordedCummulative = prevpP.countRecordedCummulative + currentP.countRecorded;
        else currentP.countRecordedCummulative = currentP.countRecorded;
    }
    return data;
}

export const getGraphDataOsha = async (data, isRates) => {
    data = data.graphBarRecords;

    console.log({ isRates, data }, ' cacvqavaV')
    if(isRates) return data;

    const map = data.reduce((accumulator, currentVal) => {
        const {
            period,
            illnessType,
            countRecorded
        } = currentVal;
        let mapData = accumulator.get(period);

        const currentKey = illnessType;
        if(mapData) {
            const summedObj = {
                ...mapData,
                [currentKey]: countRecorded,
                countRecorded: mapData.countRecorded + countRecorded,
            }
            accumulator.set(period, summedObj);
        }
        else {
            accumulator.set(period, {
                period,
                [currentKey]: countRecorded,
                countRecorded: countRecorded
            });
        }
        return accumulator;
    }, new Map() );

    const result = [];
    for(let v of map) result.push(v[1]);

    console.log(result,'!!!!!!!!');
    return result;
    // for(let i = 0; i < data.length; i++) {
    //     const prevpP = data[i - 1];
    //     const currentP = data[i];
    //     if(prevpP) currentP.countRecordedCummulative = prevpP.countRecordedCummulative + currentP.countRecorded;
    //     else currentP.countRecordedCummulative = currentP.countRecorded;
    // }
    // return data;
}

export const getGraphData = async (data) => {
    const data1 = data.graphBarRecords;
    console.log({data1})

    //return data1;
    const map = data1.reduce((accumulator, currentVal) => {
        let {
            period,
            exitEmployees,
            totalEmployees,
            avgActiveEmployees,
            turnoverRate,
            viewBy,
            countRecorded
        } = currentVal;
        period = period+'';
        let resultObj = makeUniqueFields(currentVal, viewBy);
        let mapData = accumulator[period]
        if(mapData) {
            const summedObj = {
                ...mapData,
                ...resultObj,
                exitEmployees: parseInt(exitEmployees) + parseInt(mapData.exitEmployees),
                totalEmployees: parseInt(totalEmployees) + parseInt(mapData.totalEmployees),
                avgActiveEmployees: parseInt(avgActiveEmployees) + parseInt(mapData.avgActiveEmployees),
                turnoverRate: roundFunction( (parseInt(turnoverRate) + parseInt(mapData.turnoverRate))*100 ),
                countRecordedAll: viewBy === 'SIF' ? mapData.countRecordedAll !== undefined ? mapData.countRecordedAll : mapData.countRecorded
                : countRecorded + (mapData.countRecordedAll ? mapData.countRecordedAll : 0)
            }
            accumulator[period] = summedObj;
        }
        else {
            accumulator[period] = resultObj;
        }
        return accumulator;
    }, {});
    console.log(map, ' !!!s')
    let result = [];
    for(let key in map) result.push(map[key]);
    result = result.sort((a, b) => a.period - b.period);
    return result;
}


export const getSortFunction = (a, b, section, tab,dataKey) => {
    let objectSelected;
    switch(tab) {
        case 'Tenure':
            objectSelected = tenureColors;
            break;
        case 'Gender':
            objectSelected = genderColors;
            break;
        case 'Ethnicity':
            objectSelected = ethnicityColors;
            break;
        case 'AgeGroup':
            objectSelected = ageColors;
            break;
        case 'ExitReason':
            objectSelected = exitReasonColors;
            break;
        default:
            objectSelected = oshaColors;
            break;
    }
    if(section === 'teammember' || section === 'osha') return objectSelected[a.viewBy]?.index - objectSelected[b.viewBy]?.index;
    else return b[dataKey] - a[dataKey];
}

export const getGraphAdditionalData = (data1, section, tab) => {
    const data = [];

    for(let key in data1.graphSummary) {
        //if(key === 'Unknown') continue;
        if( tab === 'ExitReason' && (key !== 'Voluntary' && key !== 'Involuntary') ) {
            continue;
        };
        data.push( {
            viewBy: key,
            ...data1.graphSummary[key],
            turnoverRateVoluntary: 0,
            countRecorded: 'osha' === section ? data1.graphSummary[key] : 0
        } );
    }
    const totalValueEmpl = data.reduce((acc, { avgActiveEmployees }) => acc + avgActiveEmployees, 0);
    return data
    .sort((a, b) => getSortFunction(a,b,section, tab, 'turnoverRate'))
    .map((v) => {
        v.color = getProperty(v.viewBy ,'color');
        v.title = getProperty(v.viewBy ,'title');
        if(v.hasOwnProperty('turnoverRate')) v.turnoverRate = isNaN(roundFunction(v.turnoverRate*100)) ? 0 : roundFunction(v.turnoverRate*100);
        if(v.hasOwnProperty('avgActiveEmployees')) v.avgActiveEmployees = isNaN(roundFunction((v.avgActiveEmployees/totalValueEmpl)*100)) ? 0 : roundFunction((v.avgActiveEmployees/totalValueEmpl)*100);
        return v;
    });
}
