import React from 'react'
import { useSelector } from 'react-redux'

import CurrentScreenValues from '../componentsGeneral/section/CurrentScreenValues'
import TextTooltip from '../componentsGeneral/TextTooltip'
import { oshaColors } from '../constants/colors'
import formatNumber from '../utils/formatNumber'
import toClassName from '../utils/toClassName'


const CurrentScreenValuesOsha = ({ currentKey }) => {
  const {
    header,
    activeTabTitle,
    dataGraphAdditional,
    activeBu,
    activeTab,
    activeSection
  } = useSelector(state => ({
    header: state.values.header,
    activeTabTitle: state.sections.activeTabTitle,
    dataGraphAdditional: state.values.dataGraphAdditional,
    activeBu: state.sections.activeBu,
    activeTab: state.sections.activeTab,
    activeSection: state.sections.activeSection,
  }))


  const getMainValue = () => {
    if (!Array.isArray(header)) return 0
    
    const allIllness = activeTab === 'SIF' ?  header.find(i => i.illnessType == 'SIF') : header.find(i => i.illnessType == 'All')
    return allIllness ? allIllness[currentKey] : header[0][currentKey]
  }

  const formatBottomValue = ({value, employeePercents, needScale}) => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, employeePercents, needScale, remove$: true })
  
    return `${prependStr}${number}${appendStr}`
  }

  const getBottomValues = () => {
    if (currentKey === 'rate') {
      if (typeof dataGraphAdditional !== 'object' || Array.isArray(dataGraphAdditional)) return null
      const keysMap = {
        rate: {
          title: 'Rate', 
          employeePercents: false,
          tooltip: <div>Number of OSHA reportable cases for 100 employees.<br />Caluculated as: #cases x200,000 / #hrs worked</div>
        },
        hours: {
          title: 'Hours', 
          needScale: true,
          tooltip: 'Hours tooltip'
        },
        cases: {
          title: 'Recordables', 
          needScale: false,
          tooltip: 'Total OSHA recordable cases as of the last complete fiscal month'
        },
        // bls: {
        //   title: 'BLS', 
        //   needScale: false,
        //   tooltip: <div>Category: {`Category Name of Data`}<br />NAICS: {`NAICS`}<br /><br />BLS: Bureau of Labour Statistics<br />NAICS: North American Industry Classification System</div>
        // },
        // naics: {
        //   title: 'Naics', 
        //   needScale: false,
        //   tooltip: 'Naics tooltip'
        // },
        // catogory: {
        //   title: 'catogory', 
        //   needScale: false,
        //   tooltip: 'catogory tooltip'
        // },
      }

      // return Object.keys(dataGraphAdditional).filter(key => !key.includes('PY')).map((item, i) => {
      //   return <div key={item} className='text-sm text-black font-medium'>
      //      {keysMap[item]. title === 'Hours' ?
      //       <span className={toClassName([i % 2 === 0 ? 'text-blue-400' : '', 'mr-1'])}>{ keysMap[item].title }: </span>
      //       :
      //       keysMap[item]. title === 'Naics' || keysMap[item]. title === 'catogory'?
      //       <span></span>
      //       :
      //      <TextTooltip content={keysMap[item].tooltip}>
      //       <span className={toClassName(['text-blue-400', 'mr-1'])}>{ keysMap[item].title }: </span>
      //      </TextTooltip>
      //      }
      //     <span>
           
      //       { keysMap[item].title === 'BLS' ? formatBottomValue({value: dataGraphAdditional[item], needScale: keysMap[item].needScale}) :
      //       keysMap[item].title === 'Naics' || keysMap[item]. title === 'catogory' ? '' : 
      //       formatBottomValue({value: dataGraphAdditional[item], needScale: keysMap[item].needScale}) + ' vs' }

      //       {keysMap[item].title === 'Rate' ? ' '+ formatBottomValue({value: dataGraphAdditional[item+'PY'], needScale: keysMap[item].needScale}) + ' ' : '' } 

      //       {keysMap[item].title != 'BLS' && keysMap[item]. title != 'Naics' && keysMap[item]. title != 'catogory' && ' ' +  formatBottomValue({value: keysMap[item].title === 'Rate' ? dataGraphAdditional[item+'PY'] ? 
      //       (dataGraphAdditional[item] - dataGraphAdditional[item+'PY'])/(dataGraphAdditional[item+'PY']/100) 
      //       : 0 : dataGraphAdditional[item+'PY'], 
      //       needScale: keysMap[item].needScale, employeePercents: (activeSection === 'OSHA_RATES' && keysMap[item].title === 'Rate') ? true : keysMap[item].employeePercents}) }
      //     </span>
      //   </div>
      // })
      return Object.keys(dataGraphAdditional).filter(key => !key.includes('PY')).map((item, i) => {
        return <div key={item} className='text-sm text-black font-medium'>
           {keysMap[item]. title === 'Hours' ?
            <span className={toClassName([i % 2 === 0 ? 'text-blue-400' : '', 'mr-1'])}>{ keysMap[item].title }: </span>
            :
           <TextTooltip content={keysMap[item].tooltip}>
            <span className={toClassName([i % 2 === 0 ? 'text-blue-400' : '', 'mr-1'])}>{ keysMap[item].title }: </span>
           </TextTooltip>
           }
          <span>
           
            { formatBottomValue({value: dataGraphAdditional[item], needScale: keysMap[item].needScale}) + ' vs' }  
            {keysMap[item].title === 'Rate' ? ' '+ formatBottomValue({value: dataGraphAdditional[item+'PY'], needScale: keysMap[item].needScale}) + ' ' : '' } 

            {' ' +  formatBottomValue({value: keysMap[item].title === 'Rate' ? dataGraphAdditional[item+'PY'] ? 
            (dataGraphAdditional[item] - dataGraphAdditional[item+'PY'])/(dataGraphAdditional[item+'PY']/100) 
            : 0 : dataGraphAdditional[item+'PY'], 
            needScale: keysMap[item].needScale, employeePercents: (activeSection === 'OSHA_RATES' && keysMap[item].title === 'Rate') ? true : keysMap[item].employeePercents}) }
          </span>
        </div>
      })
    }
    else if (activeTab !== 'SIF') {
      if (!Array.isArray(dataGraphAdditional)) return null

      return dataGraphAdditional.map(item => {
        return <div key={item.viewBy} className='text-sm text-black font-medium'>
          <span>{ oshaColors[item.viewBy].title }: </span>
          <span>
            { formatBottomValue({ value: item[currentKey], needScale: false }) }
          </span>
        </div>
      })
    }
  }

  return (
    <CurrentScreenValues 
      value={getMainValue()}
      // employeePercents={currentKey === 'rate'}
      // needSign={currentKey === 'rate'}
      defaultTitle='TYSON OVERALL'
      title={activeBu.length ? activeBu[activeBu.length - 1] : activeTabTitle}
      // includeDot={currentKey === 'rate'}
      prependClasses='self-start'
    >
      {activeSection === 'OSHA_RECORDABLES' ?
        <div className='mt-2 flex flex-row flex-wrap items-start justify-center px-4 space-x-3'>
          {getBottomValues()}
        </div>
        :
        <div className='mt-2 flex flex-col flex-wrap items-start justify-start px-4'>
          {getBottomValues()}
        </div>
      
    }
    </CurrentScreenValues>
  )
}

export default CurrentScreenValuesOsha