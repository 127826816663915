import React from 'react'
import toClassName from '../../utils/toClassName'

const BusinessUnitGraphSection = ({ useGraphBorder, height, children }) => {
  return (
    <div className={toClassName([useGraphBorder ? 'border-r-2 border-l-2 border-gray-600' : false, 'bu-graph-section'])} style={{
      height
    }}>
      { children }
    </div>
  )
}

export default BusinessUnitGraphSection