import React, { useEffect, useState, useRef } from 'react'
import Dimensions from 'react-dimensions'
import { useOktaAuth } from "@okta/okta-react"
import { onError } from '../../actions/valuesActions';
import { setDataTeamMembers, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim } from '../../actions/dataActionsTeamMembers';
import { setActiveSection, setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setBuType, setYear, setSingle } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { DESKTOP_VIEW_BREAKPOINT } from '../../constants/app'
import ScreenBlocker from '../../components/ScreenBlocker/ScreenBlocker'
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import LocationIcon from '../../images/LocationIcon'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SectionSwitcherDesktop from '../../componentsGeneral/section/SectionSwticherDesktop';
import HeaderDesktop from '../../componentsGeneral/section/HeaderDesktop';
import ContentContainerDesktop from '../../componentsGeneral/section/ContentContainerDesktop';
import ContentLeftDesktop from '../../componentsGeneral/section/ContentLeftDesktop';
import ContentLeftDesktopHeader from '../../componentsGeneral/section/ContentLeftDesktopHeader';
import ContentRightDesktop from '../../componentsGeneral/section/ContentRightDesktop';
import BusinessUnitWithGraph from '../../componentsGeneral/section/BusinessUnitWithGraph';
import BigGraphOsha from '../../componentsOsha/BigGraphOsha';
import LayoutApp from '../../componentsGeneral/AppLayout';
import CurrentScreenValuesOsha from '../../componentsOsha/CurrentScreenValuesOsha';
import ValueWithDot from '../../componentsGeneral/section/ValueWithDot';
import UnitBackButton from '../../componentsGeneral/section/UnitsBackButton';
import Checkbox from '../../componentsGeneral/section/Checkbox';
import TooltipUnitOsha from '../../componentsOsha/TooltipUnitOsha';
import { Tooltip } from 'recharts';
import MobileControls from '../../componentsGeneral/section/MobileControls';

const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;

const monthNames = [
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep'
]

const TeamMembers = ({ onlyPoultry, onlyPrepared, onlyMcDonalds, onlyFreshMeats, ...props}) => {
  const { height, width } = useWindowDimensions()
  const { authState } = useOktaAuth()
  const {accessToken: { accessToken }} = authState

  const [dataKey, setDataKey] = useState('countRecorded');
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);
  const [locationUnits, setLocationUnits] = useState([]);
  const [sortUnits, setSortUnits] = useState('alph')
  const [trend2year, setTrend2year] = useState(false)
  
  useEffect(() => {
    const { setActiveSection, setActiveQYSwitcher, activeSection} = props;
    if(!activeSection || (activeSection != 'OSHA_RECORDABLES' && activeSection != 'OSHA_RATES')) {
      setActiveSection('OSHA_RATES');
      setActiveQYSwitcher('Y');
    }
  }, [])
  
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const getLevelFilters = (bus ,keyPrepend, filters) => {
    for(let i = 0; i < bus.length; i++) {
      const key = keyPrepend + (i + 1);
      filters.push({ [key]: bus[i] });
    }
  }

  const getData = () => {
    let { setDataTeamMembers, activeBu, buType, activeSection, activeTab, year, isSingle, quarter } = props;
    const additionalObject = {};
    let querySection;
    let filters = [
      props.activeSection === 'OSHA_RECORDABLES' ? { "fiscYear": year } : { "fiscYearEnd": year + '' }
    ];
    switch(activeSection) {
      case 'OSHA_RECORDABLES':
        querySection = 'osha';
        const tabFilterKey = 'IllnessType';
        if(activeTab !== 'All') filters.push({ [tabFilterKey] : activeTab  });
        if(isSingle && !quarter) filters.push({ "fiscMonth": isSingle });
        if(quarter) filters.push({ "fiscQtr": quarter });
        if(quarter && isSingle) filters.push({ "fiscWeek": isSingle });
        break;
      case 'OSHA_RATES':
        querySection = 'rates';
        filters.fiscYearEnd = year;
        delete filters.fiscYear;
        if(isSingle) filters.push({ 'fiscMonthEnd': 'FY'+(year-2000+'') + ' ' + 'P'+ (isSingle < 10 ? ('0' + isSingle + '') : isSingle + '')})
        break;
    }
    if(buType == 'location') {
      getLevelFilters(activeBu, 'Location', filters);
      if(activeBu.length == 4) additionalObject.lastHeirarchyTrendColumn = 'Location4';
    }
    setDataTeamMembers(
        {
          section:querySection,
          viewBy: activeTab,
          filters,
          ...additionalObject
        },
        accessToken,
        {buArray: activeBu, buType}
    )
    setInitialLoadingCompleted(true);
  }
  

  const selectBu = (e, name, index, type) => {
    let { setActiveBu, setNavigationStage, buType, activeBu, setBuType } = props;
    if(e.target.closest('.info_icon_container')) return;
    if(buType === 'location' && activeBu.length === 4) {
      const newStateBu = activeBu.slice(0);
      newStateBu[3] = name;
      setActiveBu(newStateBu);
      return;
    }
    setActiveBu(name);
    setNavigationStage('bu');
    setBuType(type);
  }

  const getRightValue = unit => {
    const { activeSection } = props;
    if(activeSection !== 'OSHA_RATES') return 0;
    return Number.isFinite((unit[dataKey] - unit[dataKey+'PY'])/unit[dataKey+'PY']*100) ? ((unit[dataKey] - unit[dataKey+'PY'])/unit[dataKey+'PY']*100) : 0;
  }

  const sortUnitsFunction = (unit1, unit2) => {
    if (sortUnits === 'alph') return unit1.viewBy.toLowerCase() < unit2.viewBy.toLowerCase() ? -1 : 1
    else if (sortUnits === 'third') return unit2.rightValue - unit1.rightValue
    else return unit2.value - unit1.value
  }

  const getUnits = async (nameKey, array) => {
    const { oshaFiscDate, oshaRatesFiscDate, quarter, year, activeQYSwitcher, activeBu, activeSection } = props;
    const result = [];
    let index = 0;
    if(!array) return [];

    for(let viewBy in array) {
      const name = viewBy;
      if(viewBy == 'countRecorded' || viewBy == 'trend' || name === 'Unknown' || name === 'N/A' || name === 'n/a') {
        index++;
        continue;
      }
    
      const el = array[viewBy];
      let trend = el.trend;

      if(trend) {
        let firstPeriod = trend[0].period;
        let startWeekOfPeriod = Math.floor((firstPeriod === 53 ? 39 : firstPeriod - 1)/13)*13 + 1;
        const isObservingCurrentDate = props.activeSection === 'OSHA_RECORDABLES' ? ((oshaFiscDate.year == year && activeQYSwitcher == 'Y') || (oshaFiscDate.year == year && activeQYSwitcher == 'Q' && quarter == oshaFiscDate.quarter)) : (oshaRatesFiscDate.year == year && activeQYSwitcher == 'Y');
        const lastPeriod = trend[trend.length - 1].period;
        let weeksCount = lastPeriod == 53 ? 14 : 13;
        if(props.activeQYSwitcher == 'Y') {
          weeksCount = 12;
          startWeekOfPeriod = 1;
        }
        for(let i = 0; i < weeksCount; i++) if(!trend.some(({ period }) => period == startWeekOfPeriod + i )) {
          const fillWith = (isObservingCurrentDate && startWeekOfPeriod + i > lastPeriod) ? {} : { period: startWeekOfPeriod + i, countRecorded: 0, rate: 0};
          trend.splice(i, 0, fillWith);
        }
      }

      const isActive = (activeBu.length == 4 && activeBu[activeBu.length - 1] == name);

      result.push(
        { 
          viewBy,
          rightValue: getRightValue(el),
          value: el[dataKey],
          el:<BusinessUnitWithGraph
          key={viewBy}
          value={activeSection === 'OSHA_RATES' ? getRightValue(el) : el[dataKey]}
          name={viewBy}
          graphData={trend}
          currentKey={dataKey}
          isActive={isActive}
          useDot={activeSection === 'OSHA_RATES'}
          employeePercents={activeSection === 'OSHA_RATES'}
          needScale={false}
          useGraphBorder={false}
          isLast={activeBu.length == 4}
          needSign={activeSection === 'OSHA_RATES'}
          onClick={(e) => selectBu(e, name, index, nameKey)}
          graphChildren={!isMobile && <Tooltip
              allowEscapeViewBox={{y: true, x:true}}
              offset={-3}
              isAnimationActive={false}
              content={
                <TooltipUnitOsha
                    currentKey={dataKey}
                    isTwoYearTrend={false}
                />
              }
              cursor={false}
          ></Tooltip>}
        >
          {activeSection === 'OSHA_RATES' && <ValueWithDot
            className='text-sm leading-none whitespace-nowrap text-right min-w-0 mr-1'
            value={el[dataKey]}
            employeePercents={true}
            needScale={false}
            needSign={false}
            needPercentsSign={false}
          />}
        </BusinessUnitWithGraph>}
      )
      index++;
    }
    return result.sort(sortUnitsFunction).map(({ el }) => el);
  } 

  const setUnits = async () => {
    const { locationUnits } = props;
    let u2;
    u2 = await getUnits('location', locationUnits);
    setLocationUnits(u2);
  }

  const goLevelBack = () => {
    const { setBuType, setActiveBu, activeBu } = props;
    const activeBuCopy = activeBu.slice(0);
    activeBuCopy.pop();
    if(activeBuCopy.length === 0) setBuType(false);
    setActiveBu(activeBuCopy);
  }

  const getHeaderadditionalContent = () => {
    const { activeSection, activeTab, setActiveTab } = props

    if (activeSection === 'OSHA_RECORDABLES') {
      return <Checkbox 
        checked={activeTab === 'SIF'}
        setChecked={() => setActiveTab(activeTab === 'SIF' ? false : 'SIF')}
        title='Show SIF Incidents'
      />
    }
    
    return null
  }

  const getLeftHeaderTitle = () => {
    const { activeBu, activeTab, activeSection } = props
    if ((activeBu.length > 0 && !(onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats)) || ((onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats) && props.activeBu.length >= 2)) return <UnitBackButton onClick={goLevelBack}>
      { activeBu.length == 1 ? 'Tyson Overall' : activeBu.length >= 2 ? activeBu[activeBu.length - 2] : '' }
    </UnitBackButton>
    else return <span>
      <LocationIcon />
      <span className='text-gray-600 font-bold ml-2 leading-none'>
        {activeSection === 'OSHA_RATES' ? 'Rates' : activeTab === 'SIF' ? "SIF" : 'Recordables'} by Location
      </span>
    </span>
  }

  const getGraphTitle = () => {
    const { navigationStage, activeBu, activeSection } = props
    if (activeBu.length == 0 ) return (`Tyson Overall ${activeSection === 'OSHA_RECORDABLES' ? 'Recordables' : 'Rates'} 2-YR Trend`) 
    if (activeBu.length >= 1 ) return (activeBu[activeBu.length - 1]) + ' ' + (`${activeSection === 'OSHA_RECORDABLES' ? 'Recordables' : 'Rates'} 2-YR Trend`)
    else return (`${activeSection === 'OSHA_RECORDABLES' ? 'Recordables' : 'Rates'} 2-YR Trend`) 
  
  }

  const getCurrentDate = () => {
    return dataKey === 'rate' ? props.oshaRatesFiscDate : props.oshaFiscDate
  }

  const getGraphTrend = () => {
    const { trends } = props;
    const currentDate = getCurrentDate()
    const trend = trends.bsTrend
    if (!Array.isArray(trend)) return []
    const newTrend = [];
    let j = currentDate.month;
    for(let i = currentDate.month; i <= 24 + currentDate.month; i++) {
      const yearIterate = (currentDate.year - 2) + Math.floor((i - 1)/12)
      const value = trend.find(({ fiscYear, fiscMonth }) => parseInt(fiscYear) === yearIterate && parseInt(fiscMonth) === j  );
      if(value) newTrend.push({ ...value, ratePY: value.ratePY ? value.ratePY : null  });
      else newTrend.push({
          "fiscYear": yearIterate,
          "fiscMonth": j,
          "rate": null
      });
      if(j === 12) j = 0;
      j++;
    }
    return newTrend.map(el => ({ ...el, yearMonth: `${el.fiscYear} ${el.fiscMonth}` }));
  }

  const getHeaderProps = () => {
    const { isSingle, activeQYSwitcher } = props

    if (!isSingle) return {}

    if (activeQYSwitcher == 'Y') return {
      month: isSingle 
    }
    else if (activeQYSwitcher == 'Q') return {
      week: isSingle
    }
  }

  useEffect(() => {
    setSortUnits('alph')
  }, [props.activeSection])

  useEffect(() => {
    if(!props.isGraphLoading) setTrend2year(getGraphTrend())
  }, [props.isGraphLoading])

  useEffect(() => {
      if(!props.isGraphLoading) setUnits()
  }, [
      props.isGraphLoading,
      sortUnits
  ])

  useEffect(() => {
      setUnits()
  }, [])

  useEffect(() => {
    const { activeSection } = props;

    setDataKey(activeSection === 'OSHA_RATES' ? 'rate' : 'countRecorded')
  }, [
    props.activeSection,
    props.activeTab
  ])
  
  useEffect(() => {
    const { activeSection, activeTab, year } = props;
    let timer;
    if(activeSection && year) {
      timer = setTimeout(() => {
        getData();
      }, 100)

    }
    return () => {
      clearTimeout(timer);
    }
  }, [
    props.activeSection,
    props.activeTab,
    props.isSingle,
    props.activeBu,
    props.year,
    props.quarter,
    props.activeQYSwitcher
  ])

  return (
    <ScreenWrapper osha={true} doSetYear initialLoadingCompleted={initialLoadingCompleted}>
      <ScreenBlocker
        text='Please, rotate your device.'
        active={
            isMobile &&
            ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
            (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
        }
      />
      <LayoutApp>
        <HeaderDesktop
          {...getHeaderProps()}
        >
          { getHeaderadditionalContent() }
        </HeaderDesktop>
        <SectionSwitcherDesktop
          buttons={[
            {to: 'OSHA_RATES', title: 'Rates'},
            {to: 'OSHA_RECORDABLES', title: 'Recordables'}
          ]}
        />
        <MobileControls>
          { getHeaderadditionalContent() }
        </MobileControls>
        <ContentContainerDesktop>
          <ContentLeftDesktop
            graphData={trend2year}
            graphTitle={getGraphTitle()}
            dataKey={dataKey}
            tooltip={!isMobile && <Tooltip
              allowEscapeViewBox={{y: true, x:true}}
              offset={-3}
              isAnimationActive={false}
              content={
                <TooltipUnitOsha
                    currentKey={dataKey}
                    isTwoYearTrend={true}
                />
              }
              cursor={false}
          ></Tooltip>}
          >
            <ContentLeftDesktopHeader
              includeSort
              activeSort={sortUnits}
              clickSortAlph={() => setSortUnits('alph')}
              clickSortVal={() => setSortUnits('val')}
              clickThird={props.activeSection === 'OSHA_RATES' ? () => setSortUnits('third') : false}
              number={props.activeSection == 'OSHA_RATES' || props.activeSection == 'OSHA_RECORDABLES'}
            >
              { getLeftHeaderTitle() }
            </ContentLeftDesktopHeader>
            <div className='overflow-y-scroll' style={{ maxHeight: 400 }}>
              {
                locationUnits
              }
            </div>
          </ContentLeftDesktop>
          <ContentRightDesktop>
            <CurrentScreenValuesOsha
              currentKey={dataKey}
            />
            <BigGraphOsha
              dataKey={dataKey}
              currentDate={props.activeSection === 'OSHA_RATES' ? props.oshaRatesFiscDate : props.oshaFiscDate}
            />
          </ContentRightDesktop>
        </ContentContainerDesktop>
      </LayoutApp>
    </ScreenWrapper>
  )
}



function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default Dimensions()(connect(
  state => ({
    year: state.sections.year,
    quarter: state.sections.quarter,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    activeSection: state.sections.activeSection,
    activeTab: state.sections.activeTab,
    activeTabTitle: state.sections.activeTabTitle,
    activeBu: state.sections.activeBu,
    navigationStage: state.navigationState.stage,
    header: state.values.header,
    sectionTitle: state.sections.sectionTitle,
    isGraphLoading: state.values.isGraphLoading,
    bars: state.values.bars,
    barsPY: state.values.barsPY,
    units: state.values.units,
    locationUnits: state.values.locationUnits,
    buType: state.sections.buType,
    trends: state.values.trends,
    filters: state.sections.filters,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    oshaFiscDate: state.sections.oshaFiscDate,
    oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
    isAdditionalGraphDataLoading: state.values.isAdditionalGraphDataLoading,
    userPrivilages: state.userState.userPrivilages,
  }),
  { setDataTeamMembers, setYear,setFiscDateEmployee, setFiscDateEmployeeAbsenteesim,
    onError, setActiveSection, setBuType,setNavigationStage,setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setSingle }
)(TeamMembers))

///code latest
