import React, { useState } from 'react'

import toClassName from '../../utils/toClassName';

import FeatherIcon from 'feather-icons-react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Collapsible = ({ children, title, subtitle, dashboardClickHandler }) => {
  const { height, width } = useWindowDimensions()
  const [extended, setExtended] = useState(width > 900 ? true : false)

  const toggleExtended = () => {
    setExtended(!extended)
  }

  return (
    <div className='pb-2 border-b border-gray-500 mt-3'>
      <div className='flex justify-between cursor-pointer' onClick={toggleExtended}>
        <div className='text-base2 font-bold text-left tracking-widest text-black'>{ title }</div>
        <div>
          {
            dashboardClickHandler &&
            <button onClick={dashboardClickHandler} className='border border-blue-400 rounded text-xs text-blue-400 px-2 py-1 mr-4'>
              <span className='text-xs text-blue-400 font-bold'>
                DASHBOARD
              </span>
            </button>
          }
          <FeatherIcon className={toClassName([extended ? 'rotate-180' : '', 'transform transition-all text-blue-400'])} icon={'chevron-down'} />
        </div>
      </div>
      {extended && <div className='mt-2'>
        <div className='text-left tracking-wider font-bold text-xs text-blue-400'>{ subtitle }</div>
        <div className='collapsible-dropdown-content lg:flex justify-between mt-2 flex-wrap flex-row lg:-mx-3'>
          {
            children
          }
        </div>
      </div>}
    </div>
  )
}

export default Collapsible