import React from 'react'
import Portal from './Portal'
import { useSelector } from 'react-redux'
import { sectionsFinance, sectionsEmployees, sectionsOsha, sectionsPlantOps, sectionsFillRate  } from '../../constants/app'

const GraphTicks = ({ children, targetNode }) => {
  const {
    activeSection
  } = useSelector(state => ({
    activeSection: state.sections.activeSection
  }))
  const renderContent = () => {
    return <div className='flex flex-col-reverse items-end pr-2 justify-between absolute w-16' style={{
      height : sectionsFillRate.includes(activeSection) ? 'calc(100% - 5rem)' 
      : sectionsOsha.includes(activeSection) ? 'calc(100% - 4.5rem)'
      : sectionsPlantOps.includes(activeSection) ? 'calc(100% - 5rem)'
      : 'calc(100% - 1rem)'
    }}>
      { children }
    </div>
  }

  return (
    <>
      {
        targetNode ? <Portal targetNode={targetNode}>
          { renderContent() }
        </Portal> : renderContent()
      }
    </>
  )
}

export default GraphTicks