import React from "react";

const ItemDouble = ({ children, title }) => {
  return (
    <div
      className={"bg-gray-400 px-1 lg:px-3 pt-2 pb-3.5 mt-2 w-full lg:w-max"}
    >
      <div className="text-base2 font-bold text-left tracking-widest text-black">
        {title}
      </div>
      <div className="flex justify-around mt-2 flex-wrap lg:flex-nowrap -mx-1 lg:mx-0">
        {children}
      </div>
    </div>
  );
};

export default ItemDouble;
