export const SET_FINANCE_FISC_DATE = 'SET_FINANCE_FISC_DATE';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const SET_ACTIVE_QY_SWITCHER = 'SET_ACTIVE_QY_SWITCHER';

export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';

export const SET_SINGLE = 'SET_SINGLE';

export const SET_ACTIVE_BU = 'SET_ACTIVE_BU';

export const SET_YEAR = 'SET_YEAR';

export const SET_QUARTER = 'SET_QUARTER';

export const IS_GRAPH_READY = 'IS_GRAPH_READY';

export const SET_SCROLL_POSITION = 'SET_GRAPH_POSITION';

export const SET_BU_TYPE = 'SET_BU_TYPE';

export const SET_FILTER = 'SET_FILTER';

export const SET_IS_CORE = 'SET_IS_CORE'

export const setIsCore = (isCore) => ({
    type: SET_IS_CORE,
    payload: {
        isCore
    }
})

export const setFinanceFiscDate = (financeFiscDate) => ({
    type: SET_FINANCE_FISC_DATE,
    payload: {
        financeFiscDate
    }
})

export const setFilter = ({ filter, type }) => ({
    type: SET_FILTER,
    payload: {
        filter,
        type
    }
})

export const setBuType = (buType) => ({
    type: SET_BU_TYPE,
    payload: {
        buType
    }
})

export const setScrollPosition = (scrollPosition) => ({
    type: SET_SCROLL_POSITION,
    payload: {
        scrollPosition
    }
})

export const isGraphReady = (isGraphReady) => ({
    type: IS_GRAPH_READY,
    payload: {
        isGraphReady
    }
})

export const setQuarter = (quarter) => ({
    type: SET_QUARTER,
    payload: {
        quarter
    }
})

export const setYear = (year) => ({
    type: SET_YEAR,
    payload: {
        year
    }
})

export const setActiveBu = (activeBu) => ({
    type: SET_ACTIVE_BU,
    payload: {
        activeBu: activeBu
    }
})

export const setSingle = (isSingle) => ({
    type: SET_SINGLE,
    payload: {
        isSingle: isSingle
    }
})

export const setActiveSection = (section, preselectTab) => (
    {
    type: SET_ACTIVE_SECTION,
    payload: {
        activeSection: section,
        preselectTab: preselectTab
    }
})

export const setActiveTab = (tab) => ({
    type: SET_ACTIVE_TAB,
    payload: {
        tab
    }
})

export const setActiveQYSwitcher = (activeQYSwitcher, keepSingleState) => ({
    type: SET_ACTIVE_QY_SWITCHER,
    payload: {
        activeQYSwitcher: activeQYSwitcher,
        keepSingleState
    }
})
