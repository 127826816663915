import React, { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import Icon from '../../images/icons/timeout.png'
import { darkBlue } from '../../constants/colors'
const LOGO_HEIGHT = 172

const Timeout = () => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {

  }, [])

  const goToSignIn = ()=>{
    oktaAuth.signInWithRedirect(window.location.origin)
  }

  return (
    <div
      className='uk-flex uk-height-medium uk-margin uk-text-center uk-padding-small'
      style={{ minHeight: '100vh', backgroundColor: 'lightgray' }}
    >
      <div className='uk-margin-auto uk-margin-auto-vertical uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l  uk-card uk-card-default uk-card-body'>
        <div
          style={{
            margin: 'auto'
          }}
          data-uk-grid
          className='uk-grid-medium add-h-m'
        >
          <img src={Icon} style={{height: LOGO_HEIGHT}} />
          <div className='uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l'>
            <h1
              className='uk-text-lead uk-margin-bottom uk-width-1-1@m'
              style={{ borderBottom: `3px solid ${darkBlue}`, color: darkBlue, fontSize: 46 }}
            >
              Oops, time out...
            </h1>
            <p className="uk-text-muted uk-text-left">
              Your session has expired
            </p>
            <button
              className='uk-button uk-button-text uk-align-bottom uk-width-1-1@s uk-width-auto@m'
              onClick={goToSignIn}
            >
              <span style={{fontSize: 16}}>Go To Sign In</span>
            </button>
          </div>
          {/* <div className='uk-width-expand'>
            <button
              className='uk-button uk-button-text uk-width-1-2@m uk-width-1-1@s'
              // onClick={action}
            >
              Contact Tech Support
            </button>

          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Timeout
