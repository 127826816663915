import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import FinanceGraphSectionItDash from "./FinanceGraphSectionItDash";
import BigGraphFinanceSlider from "./BigGraphFinanceSliderITDash";
import GraphInfo from "../componentsGeneral/section/GraphInfo";
import GraphXTitles from "../componentsGeneral/section/GraphXTitles";
import GraphInfoItem from "../componentsGeneral/section/GraphInfoItem";

const BigGraphFinaceItDash = (graphData) => {
  const {
    bars,
    currentDate,
    activeQYSwitcher,
    isSingle,
    year,
    quarter,
    activeSection,
    month,
    activeTab,
  } = useSelector((state) => ({
    bars: state.values.bars,
    currentDate: state.sections.financeFiscDate,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    isSingle: state.sections.isSingle,
    year: state.sections.year,
    quarter: state.sections.quarter,
    activeSection: state.sections.activeSection,
    month: state.sections.month,
    activeTab: state.sections.activeTab,
  }));

  const graphTicksRefLeft = useRef(null);
  const graphTicksRefRight = useRef(null);
  const [sliderRef, setSliderRef] = useState(null);
  const [sections, setSections] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(-1);

  const getScale = (value) => {
    if (Math.abs(value) > 1000000000) {
      return 1000000000;
    } else if (Math.abs(value) > 1000000) {
      return 1000000;
    } else {
      return 1000;
    }
  };

  const findLastIndex = (data) => {
    for (let i = data.length - 1; i > 0; i--) {
      if (data[i].isClosedWeekFlag && !data[i].isHalfClosed) {
        return data[i].index;
      }
    }
  };

  // getLines(data, 12, lastYearBars, singleSelected ? singleSelected[0] : singleSelected, lastindex);
  const getLines = (
    arr,
    totalPoints,
    lastYearBars,
    selectedBar,
    lastIndex,
    prevQuarterBars
  ) => {
    let lastActualEbit = 0;
    let lastActualVolume = 0;
    let lastActualSales = 0;
    let maxEbit = 0;
    let maxSales = 0;
    let maxVolume = 0;
    let areAllNegativeEbit = true;
    let areAllNegativeNS = true;
    let areAllNegativeAoi = true;
    let areAllNegativeVolume = true;
    for (let i = 0; i < totalPoints; i++) {
      // actual lines
      if (!arr[i]) {
        arr[i] = {};
      }
      arr[i].ebitActual = arr[i - 1]
        ? arr[i - 1].ebitActual + arr[i].ebit
        : arr[i].ebit;
      arr[i].volumeActual = arr[i - 1]
        ? arr[i - 1].volumeActual + arr[i].volume
        : arr[i].volume;
      arr[i].netSalesActual = arr[i - 1]
        ? arr[i - 1].netSalesActual + arr[i].netSales
        : arr[i].netSales;
      if (areAllNegativeEbit && arr[i].ebit > 0) {
        areAllNegativeEbit = false;
      }
      if (areAllNegativeVolume && arr[i].volume > 0) {
        areAllNegativeVolume = false;
      }
      if (areAllNegativeNS && arr[i].netSales > 0) {
        areAllNegativeNS = false;
      }
      if (areAllNegativeAoi && arr[i].aoi > 0) {
        areAllNegativeAoi = false;
      }
      if (
        lastIndex !== undefined &&
        (arr[i].index == lastIndex ||
          (i == arr.length - 1 && arr[i].index < lastIndex))
      ) {
        arr[i].isLast = true;
      }
      if (!arr[i].isClosedWeekFlag || arr[i].isHalfClosed) {
        arr[i].ebitActual = undefined;
        arr[i].netSalesActual = undefined;
        arr[i].volumeActual = undefined;
      } else {
        lastActualEbit = arr[i].ebitActual ? arr[i].ebitActual : lastActualEbit;
        lastActualSales = arr[i].netSalesActual
          ? arr[i].netSalesActual
          : lastActualSales;
        lastActualVolume = arr[i].volumeActual
          ? arr[i].volumeActual
          : lastActualVolume;
        maxEbit = Math.max(Math.abs(arr[i].ebit), maxEbit);
        maxSales = Math.max(Math.abs(arr[i].netSales), maxSales);
        maxVolume = Math.max(Math.abs(arr[i].volume), maxVolume);
      }
      // projected/planned lines

      arr[i].netSalesProjOriginal = arr[i].netSalesProj;
      arr[i].ebitProjOriginal = arr[i].ebitProj;
      arr[i].volumeProjOriginal = arr[i].volumeProj;

      arr[i].netSalesPlanOriginal = arr[i].netSalesPlan;
      arr[i].ebitPlanOriginal = arr[i].ebitPlan;
      arr[i].volumePlanOriginal = arr[i].volumePlan;
      arr[i].aoiPlanOriginal = arr[i].aoiPlan;
      //arr[i].netSalesPlanClosed = arr[i].netSalesPlanClosed;
      //arr[i].ebitPlanClosed = arr[i].ebitPlanClosed;
      arr[i].ebitProj = arr[i - 1]
        ? arr[i - 1].ebitProj + arr[i].ebitProj
        : arr[i].ebitProj;
      arr[i].volumeProj = arr[i - 1]
        ? arr[i - 1].volumeProj + arr[i].volumeProj
        : arr[i].volumeProj;
      arr[i].netSalesProj = arr[i - 1]
        ? arr[i - 1].netSalesProj + arr[i].netSalesProj
        : arr[i].netSalesProj;
      arr[i].ebitPlan = arr[i - 1]
        ? arr[i - 1].ebitPlan + arr[i].ebitPlan
        : arr[i].ebitPlan;
      arr[i].volumePlan = arr[i - 1]
        ? arr[i - 1].volumePlan + arr[i].volumePlan
        : arr[i].volumePlan;
      arr[i].netSalesPlan = arr[i - 1]
        ? arr[i - 1].netSalesPlan + arr[i].netSalesPlan
        : arr[i].netSalesPlan;
      arr[i].aoiPlan = arr[i].aoiPlan ? arr[i].aoiPlan : null;
      if (prevQuarterBars) {
        if (prevQuarterBars[i]) {
          arr[i].ebitLastYear = arr[i - 1]
            ? arr[i - 1].ebitLastYear + prevQuarterBars[i].ebit
            : prevQuarterBars[i].ebit;
          arr[i].volumeLastYear = arr[i - 1]
            ? arr[i - 1].volumeLastYear + prevQuarterBars[i].volume
            : prevQuarterBars[i].volume;
          arr[i].netSalesLastYear = arr[i - 1]
            ? arr[i - 1].netSalesLastYear + prevQuarterBars[i].netSales
            : prevQuarterBars[i].netSales;
          arr[i].aoiLastYear = prevQuarterBars[i].aoi;
          if (lastYearBars[i]) {
            arr[i].ebitLastYearOriginal = lastYearBars[i].ebit;
            arr[i].volumeLastYearOriginal = lastYearBars[i].volume;
            arr[i].netSalesLastYearOriginal = lastYearBars[i].netSales;
            arr[i].aoiLastYearOriginal = lastYearBars[i].aoi;
          }
        }
      } else if (lastYearBars && lastYearBars[i]) {
        arr[i].ebitLastYear = arr[i - 1]
          ? arr[i - 1].ebitLastYear + lastYearBars[i].ebit
          : lastYearBars[i].ebit;
        arr[i].volumeLastYear = arr[i - 1]
          ? arr[i - 1].volumeLastYear + lastYearBars[i].volume
          : lastYearBars[i].volume;
        arr[i].netSalesLastYear = arr[i - 1]
          ? arr[i - 1].netSalesLastYear + lastYearBars[i].netSales
          : lastYearBars[i].netSales;
        arr[i].aoiLastYear = lastYearBars[i].aoi;
        arr[i].ebitLastYearOriginal = lastYearBars[i].ebit;
        arr[i].volumeLastYearOriginal = lastYearBars[i].volume;
        arr[i].netSalesLastYearOriginal = lastYearBars[i].netSales;
        arr[i].aoiLastYearOriginal = lastYearBars[i].aoi;
      }
      //arr[i].aoiProj = arr[i - 1] ? arr[i - 1].aoiPlan + arr[i].aoiPlan : arr[i].aoiPlan;
      //arr[i].ebitActual = arr[i - 1] ? arr[i - 1].actual + arr[i].ebit : arr[i].ebit;
    }
    const netSalesScale = getScale(lastActualSales);
    const ebitScale = getScale(lastActualEbit);
    const volumeScale = getScale(lastActualVolume);

    if (netSalesScale * 2.5 > maxSales) {
      arr[totalPoints - 1].netSalesAllowDecimals = true;
    } else {
      arr[totalPoints - 1].netSalesAllowDecimals = false;
    }

    if (ebitScale * 2.5 > maxEbit) {
      arr[totalPoints - 1].ebitAllowDecimals = true;
    } else {
      arr[totalPoints - 1].ebitAllowDecimals = false;
    }

    if (volumeScale * 2.5 > maxVolume) {
      arr[totalPoints - 1].volumeAllowDecimals = true;
    } else {
      arr[totalPoints - 1].volumeAllowDecimals = false;
    }
    arr[totalPoints - 1].aoiAllowDecimals = true;
    arr[totalPoints - 1].totalNetSales = selectedBar
      ? selectedBar.netSales
      : lastActualSales;
    arr[totalPoints - 1].totalEbit = selectedBar
      ? selectedBar.ebit
      : lastActualEbit;
    arr[totalPoints - 1].areAllNegativeEbit = areAllNegativeEbit;
    arr[totalPoints - 1].totalVolume = selectedBar
      ? selectedBar.volume
      : lastActualVolume;
    arr[totalPoints - 1].areAllNegativeVolume = areAllNegativeVolume;
    arr[totalPoints - 1].areAllNegativeNS = areAllNegativeNS;
    arr[totalPoints - 1].areAllNegativeAoi = areAllNegativeAoi;
  };

  const filterLastYearBarsQuartal = (
    { groupByEntity, fiscYear, ...el },
    weekList,
    year,
    j
  ) => {
    if (!groupByEntity) {
      return false;
    }
    const week = groupByEntity.split("/")[1];
    return weekList.includes(week) && fiscYear == year - 3 + j;
  };

  const getSections = () => {
    const sections = [];
    const lastindex = findLastIndex(bars);
    if (activeQYSwitcher == "Y") {
      for (let i = 0; i < currentDate.year - (currentDate.year - 3); i++) {
        let data = bars.filter(
          ({ fiscYear }) => fiscYear == currentDate.year - 2 + i
        );

        for (let i = 0; i < 12; i++) {
          data[i] = { ...data[i] };
        }
        let lastYearBars = bars.filter(
          ({ fiscYear }) => fiscYear == currentDate.year - 2 + i - 1
        );
        lastYearBars = lastYearBars[0] ? lastYearBars : false;
        let singleSelected = false;
        if (isSingle) {
          singleSelected = bars.filter(
            ({ groupByEntity }) => groupByEntity == isSingle
          );
        }
        getLines(
          data,
          12,
          lastYearBars,
          singleSelected ? singleSelected[0] : singleSelected,
          lastindex
        );
        console.log("G1 Graph", sections);
        sections.push(
          <FinanceGraphSectionItDash
            key={i}
            data={graphData.graphData}
            width={sliderRef.offsetWidth}
            graphTicksRefLeft={graphTicksRefLeft}
            graphTicksRefRight={graphTicksRefRight}
            year={year}
          />
        );
      }
    } else if (activeQYSwitcher == "W") {
      for (let i = 0; i < currentDate.year - (currentDate.year - 3); i++) {
        let data = bars.filter(
          ({ fiscYear }) => fiscYear == currentDate.year - 2 + i
        );

        for (let i = 0; i < 12; i++) {
          data[i] = { ...data[i] };
        }
        let lastYearBars = bars.filter(
          ({ fiscYear }) => fiscYear == currentDate.year - 2 + i - 1
        );
        lastYearBars = lastYearBars[0] ? lastYearBars : false;
        let singleSelected = false;
        if (isSingle) {
          singleSelected = bars.filter(
            ({ groupByEntity }) => groupByEntity == isSingle
          );
        }
        getLines(
          data,
          12,
          lastYearBars,
          singleSelected ? singleSelected[0] : singleSelected,
          lastindex
        );
        console.log("G1 Graph", sections);
        sections.push(
          <FinanceGraphSectionItDash
            key={i}
            data={graphData.graphData}
            width={sliderRef.offsetWidth}
            graphTicksRefLeft={graphTicksRefLeft}
            graphTicksRefRight={graphTicksRefRight}
            year={year}
          />
        );
      }
    } else {
      for (let j = 0; j < 3; j++) {
        for (let i = 1; i <= 4; i++) {
          if (j == 2 && i > currentDate.quarter) {
            break;
          }
          let data = bars.filter(
            ({ fiscQtr, fiscYear }) =>
              fiscQtr == i && fiscYear == currentDate.year - 2 + j
          );

          const weekList = data.map(
            ({ groupByEntity }) => groupByEntity.split("/")[1]
          );
          for (let i = 0; i < data.length; i++) {
            data[i] = { ...data[i] };
          }
          let lastYearBars, prevQuarterBars;
          lastYearBars = bars.filter((el) =>
            filterLastYearBarsQuartal(el, weekList, currentDate.year, j)
          );
          prevQuarterBars = bars.filter(
            ({ fiscQtr, fiscYear }) =>
              fiscQtr == i && fiscYear == currentDate.year - 3 + j
          );
          lastYearBars = lastYearBars[0] ? lastYearBars : false;
          prevQuarterBars = prevQuarterBars[0] ? prevQuarterBars : false;
          let singleSelected = false;
          if (isSingle) {
            singleSelected = bars.filter(
              ({ groupByEntity }) => groupByEntity == isSingle
            );
          }
          const totalPointsLastYear = prevQuarterBars
            ? prevQuarterBars.length
            : 0;
          const maxTotalPoints = Math.max(data.length, totalPointsLastYear, 13);
          getLines(
            data,
            maxTotalPoints,
            lastYearBars,
            singleSelected ? singleSelected[0] : singleSelected,
            lastindex,
            prevQuarterBars
          );
          sections.push(
            <FinanceGraphSectionItDash
              data={graphData.graphData}
              key={j + "/" + i}
              width={sliderRef.offsetWidth}
              graphTicksRefLeft={graphTicksRefLeft}
              graphTicksRefRight={graphTicksRefRight}
              year={year}
              quarter={quarter}
            />
          );
        }
      }
    }
    setSections(sections);
  };

  const getGraphInfo = () => {
    const info = [
      activeSection !== "IT_FINANCE_CLOUD" && {
        title: "Planned",
        line: (
          <span
            className="inline-block mr-2 h-0 w-6"
            style={{ border: "2px #f3af00 solid" }}
          ></span>
        ),
      },
      {
        title: "Actual (LE)",
        line: (
          <span
            className="inline-block mr-2 h-0 w-6"
            style={{ border: "2px #809E9B dashed" }}
          ></span>
        ),
      },
      {
        title: "Actual (YTD)",
        line: (
          <span
            className="inline-block mr-2 h-0 w-6"
            style={{ border: "2px #002554 solid" }}
          ></span>
        ),
      },
    ];

    return info.map((v) => (
      <GraphInfoItem title={v.title}>{v.line}</GraphInfoItem>
    ));
  };

  useEffect(() => {
    console.log("G1 use", graphData);
    if (sliderRef) getSections();
  }, [bars, activeSection, sliderRef, graphData]);

  useEffect(() => {
    if (sliderRef) getSections();
  }, []);

  useEffect(() => {
    if (currentDate.year && currentDate.quarter && year) {
      const yearDiff = currentDate.year - year;
      const quarterDiff = yearDiff ? 4 - currentDate.quarter : 0;
      if (activeQYSwitcher === "Y")
        setScrollPosition(sections.length - 1 - yearDiff);
      else if (activeQYSwitcher === "Q" && activeTab === "YTD")
        setScrollPosition(sections.length - 1 - (yearDiff * 4 + quarterDiff));
      else if (activeQYSwitcher === "Q" && activeTab === "LE")
        setScrollPosition(sections.length - 1 - (yearDiff * 4 + quarterDiff));
      else setScrollPosition(sections.length - 1 - yearDiff);
    }
  }, [sections.length, currentDate, activeTab]);
  return (
    <div className="flex relative justify-center">
      <GraphInfo>{getGraphInfo()}</GraphInfo>
      <div className="flex flex-1 w-full overflow-x-scroll pt-3 pb-12 no-scrollbar">
        <div className="flex flex-1">
          <div ref={graphTicksRefLeft} className="h-full w-16 relative"></div>
          <div
            className="flex-1 relative pb-4 main-graph-container"
            ref={(ref) => setSliderRef(ref)}
          >
            {sliderRef && scrollPosition !== -1 && (
              <BigGraphFinanceSlider
                sections={sections}
                sliderRef={sliderRef}
                currentDate={currentDate}
                activeQYSwitcher={activeQYSwitcher}
                year={year}
                quarter={quarter}
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
                graphData={graphData}
                activeTab={activeTab}
              />
            )}
          </div>
          {activeSection !== "AOI" && !isSingle && (
            <div
              ref={graphTicksRefRight}
              className="h-full w-16 relative"
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BigGraphFinaceItDash;
