import React, { useEffect, useState, useRef } from 'react'
import { Tooltip } from 'recharts';
import Dimensions from 'react-dimensions'
import { useOktaAuth } from "@okta/okta-react"
import { onError } from '../../actions/valuesActions';
import { setDataTeamMembers, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim } from '../../actions/dataActionsTeamMembers';
import { setDataFillRate } from '../../actions/dataActionsFillRate';
import { setActiveSection, setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setBuType, setYear } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { DESKTOP_VIEW_BREAKPOINT, sectionsFillRate } from '../../constants/app'
import ScreenBlocker from '../../components/ScreenBlocker/ScreenBlocker'
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import LayoutApp from '../../componentsGeneral/AppLayout'
import HeaderDesktop from '../../componentsGeneral/section/HeaderDesktop'
import SectionSwitcherDesktop from '../../componentsGeneral/section/SectionSwticherDesktop'
import ContentContainerDesktop from '../../componentsGeneral/section/ContentContainerDesktop'
import ContentLeftDesktop from '../../componentsGeneral/section/ContentLeftDesktop'
import ContentRightDesktop from '../../componentsGeneral/section/ContentRightDesktop'
import ContentLeftDesktopHeader from '../../componentsGeneral/section/ContentLeftDesktopHeader'
import DoubleButton from '../../componentsGeneral/section/DoubleButton';
import OrganizationIcon from '../../images/OrganizationIcon'
import LocationIcon from '../../images/LocationIcon'
import CurrentScreenValuesFillRate from '../../componentsFillRate/CurrentScreenValuesFillRate';
import BusinessUnitWithGraph from '../../componentsGeneral/section/BusinessUnitWithGraph';
import TmSmallGraph from '../../componentsTeamMembers/TmSmallGraph'; // FILL RATE TO DO // change to fill rate components
import BigGraphFillRate from '../../componentsFillRate/BigGraphFillRate';
import UnitBackButton from '../../componentsGeneral/section/UnitsBackButton';
import TooltipUnitsFillRate from '../../componentsFillRate/TooltipUnitsFillRate';
import TooltipFillRate from "../../componentsFillRate/TooltipFillRate";
import MobileControls from '../../componentsGeneral/section/MobileControls';
import TooltipFinance from "../../componentsFinance/TooltipFinance";


const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;
const SEGMENT_KEY = 'segment'
const CHANNEL_KEY = 'channel'

const FillRatePoultry = ({ onlyPoultry, onlyPrepared, onlyMcDonalds, onlyFreshMeats, onlyIT_USER, ...props }) => {
  const { height, width } = useWindowDimensions()
  const { authState } = useOktaAuth()
  const { accessToken: { accessToken } } = authState

  const [dataKey, setDataKey] = useState('');
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);
  const [trend2year, setTrend2year] = useState(false)
  const [sortUnits, setSortUnits] = useState('alph')
  const [segmentUnits, setSegmentUnits] = useState([]);
  const [channelUnits, setChannelUnits] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [currentDate, setCurrentDate] = useState({})

  const regulateCurrentDate = () => {
    const { activeSection, fiscDateFillRate } = props
    setCurrentDate(fiscDateFillRate)
  }

  useEffect(() => {
    const { setActiveSection, setActiveTab, setActiveQYSwitcher, activeSection } = props;
    if (!activeSection || (activeSection !== 'Overall Fill Rate' && activeSection != 'Top 10 Customers' && activeSection != 'Top 10 Shorted Customers' && activeSection != 'Top 10 Shorted Categories')) {
      setActiveSection('Overall Fill Rate');
      setActiveQYSwitcher('Q');
    }
  }, [])

  useEffect(() => {
    regulateCurrentDate()
  }, [])

  useEffect(() => {
    regulateCurrentDate()
  }, [
    props.activeSection,
    props.fiscDateFillRate
  ])

  const getDataKey = () => {
    const { activeSection } = props;
    if ((activeSection !== 'Overall Fill Rate' && activeSection != 'Top 10 Customers'
    && activeSection != 'Top 10 Shorted Customers' && activeSection != 'Top 10 Shorted Categories')) setDataKey('fillRate');
  }

  useEffect(() => {
    getDataKey();
  }, [
    props.activeSection
  ])

  useEffect(() => {
    getDataKey();
  }, [])

  useEffect(() => {
    setActiveTab(0)
  }, [
    props.activeSection
  ])

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const getData = () => {
    let { setDataFillRate, activeBu, buType, activeSection, activeTab, year, isSingle, quarter, activeQYSwitcher } = props;
    const additionalObject = {};
    let filters = [
      { "fiscYear": parseInt(year) }
    ];
    if (activeBu.length) {
      const key = activeSection === 'Overall Fill Rate' ? ((buType === SEGMENT_KEY) ? 'seg_' : 'chan_') : 'seg_'

      activeBu.forEach((bu, i) => filters.push({[key + (i + 1)]: bu}))
    }
    if (quarter) filters.push({'fiscQtr': quarter})
    if (isSingle) {
      if (quarter) filters.push({ 'fiscWeek': isSingle })
      else filters.push({ 'fiscMonth': isSingle })
    }
    setDataFillRate(
      {
        filters,
        viewBy: 'Poultry',
        fillRateType: (activeSection === 'Overall Fill Rate' ? 'Overall Fill Rate'
        : activeSection === 'Top 10 Customers' ? 'Top 10 Customers'
        : activeSection === 'Top 10 Shorted Customers' ? 'Top 10 Shorted Customers'
        : activeSection === 'Top 10 Shorted Categories' ? 'Top 10 Shorted Categories'
        : activeSection),
        ...additionalObject
      },
      accessToken,
      { buArray: activeBu, buType }
    )
    setInitialLoadingCompleted(true);
  }


  let activeIndex;

  const getGraphTitle = () => {
    const { activeBu, activeSection } = props
    const segment = activeBu.length == 1 ? (activeBu[0] ? activeBu[0] : '') : activeBu.length >= 2 ? (activeBu[1] ? activeBu[1] : '') : 'Poultry'

    return `${segment} ${activeSection === 'Overall Fill Rate' ? 'Overall Fill Rate' 
    : activeSection === 'Top 10 Customers' ? 'Top 10 Customers'
    : activeSection === 'Top 10 Shorted Customers' ? 'Top 10 Shorted Customers'
    : activeSection === 'Top 10 Shorted Categories' ? 'Top 10 Shorted Categories'
    : activeSection}`
  }

  const getBackBtnText = () => {
    const { activeBu } = props

    return activeBu.length == 1 ? (activeBu[0] ? activeBu[0] : 'Tyson Overall') : activeBu.length >= 2 ? activeBu[0] : ''
  }

  const selectBu = (e, name, index, type) => {
    let { setActiveBu, setNavigationStage, setBuType, activeBu } = props;
    if (e.target.closest('.info_icon_container')) return;

    if (activeBu.length === 2) setActiveBu([activeBu[0], name])
    else setActiveBu(name);

    setNavigationStage('bu');
    setBuType(type);
  }

  const sortUnitsFunction = (unit1, unit2) => {
    if (sortUnits === 'alph') return unit1.viewBy.toLowerCase() < unit2.viewBy.toLowerCase() ? -1 : 1
    else return unit2.value - unit1.value
  }

  const fillTrendData = (trend) => {
    const { activeQYSwitcher, year, quarter } = props;
    let firstPeriod = trend[0].period;
    let startWeekOfPeriod = Math.floor((firstPeriod - 1) / 13) * 13 + 1;
    const isObservingCurrentDate = ((currentDate.year == year && activeQYSwitcher == 'Y') || (currentDate.year == year && activeQYSwitcher == 'Q' && quarter == currentDate.quarter));
    const lastPeriod = trend[trend.length - 1].period;
    let weeksCount = lastPeriod == 53 ? 14 : 13;
    if (activeQYSwitcher == 'Y') {
      weeksCount = 12;
      startWeekOfPeriod = 1;
    }
    for (let i = 0; i < weeksCount; i++) if (!trend.some(({ period }) => period == startWeekOfPeriod + i)) {
      const fillWith = (isObservingCurrentDate && startWeekOfPeriod + i > lastPeriod) ? {} : { period: startWeekOfPeriod + i, countRecorded: 0 };
      trend.splice(i, 0, fillWith);
    }
  }

  const getUnits = async (nameKey, array) => {
    const { buType, activeBu } = props;
    const result = [];
    let index = 0;

    if (!array) return [];

    for (let viewBy in array) {
      const name = viewBy;
      const el = {...array[viewBy]};
      fillTrendData(el.trend)

      let isActive = activeBu.length === 2 && activeBu[activeBu.length - 1] === name;

      result.push(
        {
          viewBy,
          value: el[dataKey],
          comp: <BusinessUnitWithGraph
            key={viewBy}
            value={el[dataKey] / 100}
            financePercents={dataKey === 'fillRate'}
            name={viewBy}
            graphData={el.trend}
            currentKey={dataKey}
            isActive={isActive}
            isLast={activeBu.length === 2}
            useDot={true}
            needScale={false}
            useGraphBorder={false}
            needSign={false}
            onClick={(e) => selectBu(e, name, index, nameKey)}
            graphChildren={!isMobile && <Tooltip
              allowEscapeViewBox={{y: true, x:true}}
              offset={-3}
              isAnimationActive={false}
              content={
                <TooltipFillRate
                  dataKey={dataKey}
                  display={true}
                />
              }
              cursor={false}
            ></Tooltip>}
          />
        }
      )
      index++;
    }
    return result.sort((a, b) => sortUnitsFunction(a, b))
      .map(u => u.comp);
  }

  const setUnits = async () => {
    let u1, u2;
    u1 = await getUnits(SEGMENT_KEY, props.allUnits[0]);
    u2 = await getUnits(CHANNEL_KEY, props.allUnits[1]);
    setChannelUnits(u1);
    setSegmentUnits(u2);
  }

  const goLevelBack = () => {
    const { setBuType, setActiveBu, activeBu } = props;
    const activeBuCopy = activeBu.slice(0);
    activeBuCopy.pop();
    if (activeBuCopy.length === 0) setBuType(false);
    setActiveBu(activeBuCopy);
  }

  useEffect(() => {
    if (!props.isGraphLoading) setUnits()
  }, [
    props.isGraphLoading,
    sortUnits,
    props.allUnits
  ])

  useEffect(() => {
    if (initialLoadingCompleted) setUnits()
  }, [initialLoadingCompleted])


  useEffect(() => {
    const { activeSection, year } = props;
    let timer;
    if (activeSection && year) {
      timer = setTimeout(() => {
        getData();
      }, 100)

    }
    return () => {
      clearTimeout(timer);
    }
  }, [
    props.activeSection,
    props.activeTab,
    props.isSingle,
    props.activeBu,
    props.year,
    props.quarter,
    props.filters.corpOps,
    props.filters.hourlySalary,
    props.filters.absenteeism,
    props.filters.absenteeismBaseLine,
    props.activeQYSwitcher
  ])

  useEffect(() => {
    if (onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats) setActiveTab(1);
  }, [])

  const clickSwitchBuType = (newValue) => {
    const { setActiveBu, setBuType, activeBu } = props
    if (newValue != activeTab && activeBu.length) {
      setActiveBu([])
      setBuType(false)
    }

    setActiveTab(newValue)
  }

  const getLeftHeaderTitle = () => {
    return <DoubleButton
      firstText={<><OrganizationIcon /> {width <= 900 ? 'Seg' : 'Segments'}</>}
      firstDisabled={onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats || props.activeSection === 'ABSENTEEISM'}
      firstOnClick={() => clickSwitchBuType(0)}
      firstActive={activeTab == 0}
      secondText={<><LocationIcon /> {width <= 900 ? 'Chan' : 'Channels'}</>}
      secondDisabled={onlyIT_USER}
      secondOnClick={() => clickSwitchBuType(1)}
      secondActive={activeTab == 1}
      size='md-'
    />
  }

  const getGraphTrend = () => {
    const { trends } = props;
    const trend = trends.bsTrend
    if (!trend || !Array.isArray(trend)) return []

    const newTrend = [];
    let j = currentDate.month;
    for (let i = currentDate.month; i <= 24 + currentDate.month; i++) {
      const yearIterate = (currentDate.year - 2) + Math.floor((i - 1) / 12)
      const value = trend.find(({ fiscYear, fiscMonth }) => parseInt(fiscYear) === yearIterate && parseInt(fiscMonth) === j);
      if (value) newTrend.push(value);
      else newTrend.push({
        "fiscYear": yearIterate,
        "fiscMonth": j,
        "fillRate": 0
      });
      if (j === 12) j = 0;
      j++;
    }
    return newTrend.map(el => ({ ...el, yearMonth: `${el.fiscYear} ${el.fiscMonth}` }));
  }

  useEffect(() => {
    if (!props.isGraphLoading) setTrend2year(getGraphTrend())
  }, [props.isGraphLoading, props.trends])

  const getAvailableSections = () => {

    let sections = [
      { to: 'Overall Fill Rate', title: 'Overall Fill Rate' },
      // { to: 'Top 10 Customers', title: 'Top 10 Customers' },
      // { to: 'Top 10 Shorted Customers', title: 'Top 10 Shorted Customers' },
      // { to: 'Top 10 Shorted Categories', title: 'Top 10 Shorted Categories' },
    ]

    return sections
  }

  const getHeaderProps = () => {
    const { isSingle, activeQYSwitcher } = props

    if (!isSingle) return {}

    if (activeQYSwitcher == 'Y') return {
      month: isSingle
    }
    else if (activeQYSwitcher == 'Q') return {
      week: isSingle
    }
  }


  return (
    <ScreenWrapper fillRate doSetQuarter doSetYear initialLoadingCompleted={initialLoadingCompleted}>
      <ScreenBlocker
        text='Please, rotate your device.'
        active={
          isMobile &&
          ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
            (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
        }
      />
      <LayoutApp>
        <HeaderDesktop
          {...getHeaderProps()}
        >
        </HeaderDesktop>
        <SectionSwitcherDesktop
          buttons={getAvailableSections()}
        />
        <MobileControls>
          {/* {props.activeSection === 'TEAM_MEMBERS' || props.activeSection === 'TURNOVER_RATE' ?
            <TabsTm
              isMobile
              options={getTabs()}
            >
            </TabsTm>
            : ''
          } */}
        </MobileControls>
        <ContentContainerDesktop>
          <ContentLeftDesktop
            graphData={trend2year}
            graphTitle={getGraphTitle() + ' 2-YR Trend'}
            dataKey={dataKey}
            tooltip={!isMobile && <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{ y: true, x: true }}
              offset={-3}
              content={<TooltipUnitsFillRate
                currentKey={dataKey}
              />}
            >
            </Tooltip>}
          >
            <ContentLeftDesktopHeader
              includeSort
              activeSort={sortUnits}
              clickSortAlph={() => setSortUnits('alph')}
              clickSortVal={() => setSortUnits('val')}
              percents={sectionsFillRate.includes(props.activeSection)}
            >
              {props.activeSection == 'Top 10 Customers' ? 'Top 10 Customers' : props.activeSection == 'Top 10 Shorted Customers' ? 'Top 10 Shorted Customers' : props.activeSection == 'Top 10 Shorted Categories' ? 'Top 10 Shorted Categories' : getLeftHeaderTitle()}
            </ContentLeftDesktopHeader>
            {
              ((props.activeBu.length > 0 && !(onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats || onlyIT_USER)) || ((onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats || onlyIT_USER) && props.activeBu.length >= 2)) && <UnitBackButton className="mb-4" onClick={goLevelBack}>
                {getBackBtnText()}
              </UnitBackButton>
            }
            <div className='overflow-y-scroll units-container' style={{ display: activeTab == 0 ? 'block' : 'none' }}>
              {
                channelUnits
              }
            </div>
            <div className='overflow-y-scroll units-container' style={{ display: activeTab == 1 ? 'block' : 'none' }}>
              {
                segmentUnits
              }
            </div>
          </ContentLeftDesktop>
          <ContentRightDesktop>
            {/* {width <= 900 && <div className='mb-5'>
              <FilterSelectTm />
            </div>} */}
            <CurrentScreenValuesFillRate
              title={getGraphTitle()}
              currentKey={dataKey}
              onlyIT_USER={onlyIT_USER}
            />
            <BigGraphFillRate
              dataKey={dataKey}
            />
          </ContentRightDesktop>
        </ContentContainerDesktop>
      </LayoutApp>
    </ScreenWrapper>
  )
}

export default connect(
  state => ({
    year: state.sections.year,
    quarter: state.sections.quarter,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    activeSection: state.sections.activeSection,
    activeTab: state.sections.activeTab,
    activeTabTitle: state.sections.activeTabTitle,
    activeBu: state.sections.activeBu,
    navigationStage: state.navigationState.stage,
    header: state.values.header,
    sectionTitle: state.sections.sectionTitle,
    isGraphLoading: state.values.isGraphLoading,
    bars: state.values.bars,
    units: state.values.units,
    locationUnits: state.values.locationUnits,
    allUnits: state.values.allUnits,
    buType: state.sections.buType,
    trends: state.values.trends,
    filters: state.sections.filters,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    fiscDateFillRate: state.sections.fiscDateFillRate,
    oshaFiscDate: state.sections.oshaFiscDate,
    isAdditionalGraphDataLoading: state.values.isAdditionalGraphDataLoading,
    userPrivilages: state.userState.userPrivilages
  }),
  {
    setDataTeamMembers, setYear, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim,
    onError, setActiveSection, setBuType, setNavigationStage,
    setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setDataFillRate
  }
)(FillRatePoultry)

///code latest
