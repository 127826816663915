import {SET_MODAL_SHOW, SET_SHOW_MENU,SET_REVENUE_DATA_REQUESTED, SET_APP_DATA, SET_LEFT_BLOCK_EXPANDED} from '../constants/app'

export function setShowModal(payload) {
  console.log('setShowModal', payload);
  return {
    type: SET_MODAL_SHOW,
    payload
  }
}
export function setShowMenu(payload) {
  return {
    type: SET_SHOW_MENU,
    payload
  }
}
export function setLeftBlockExpanded(payload) {
  return {
    type: SET_LEFT_BLOCK_EXPANDED,
    payload
  }
}
export function setRevenueDataRequested(payload){
  return {
    type: SET_REVENUE_DATA_REQUESTED,
    payload
  }
}
export function setAppData(payload){
  return {
    type: SET_APP_DATA,
    payload
  }
}
