import React, { useEffect, useRef, useState } from 'react'

import { createPopper } from '@popperjs/core';
import toClassName from '../utils/toClassName';

const Dropdown = ({ anchorEl, placement, children, className, offset=[], show, style, ...other }) => {
  const [dropdownRef, setDropdownRef] = useState(null)
  const [popper, setPopper] = useState(null)


  useEffect(() => {
    const dropdown = dropdownRef
    if (!dropdown) return

    setPopper(createPopper(anchorEl, dropdown, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset
          },
        },
      ]
    }));
  }, [dropdownRef])

  useEffect(() => {
    if (!dropdownRef) return

    if (show) dropdownRef.setAttribute('data-show', 'true')
    else dropdownRef.removeAttribute('data-show')
  }, [show, dropdownRef])

  return (
    <div className={toClassName(['z-50', className])} ref={el => setDropdownRef(el)} style={{  ...style, display: 'none' }} {...other}>
      { children } 
    </div>
  )
}

export default Dropdown