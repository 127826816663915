import React, { useEffect, useState, useRef } from 'react'
import Dimensions from 'react-dimensions'
import { useOktaAuth } from "@okta/okta-react"
import { onError } from '../../actions/valuesActions';
import { setDataTeamMembers, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim } from '../../actions/dataActionsTeamMembers';
import { setActiveSection, setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setBuType, setYear } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { DESKTOP_VIEW_BREAKPOINT } from '../../constants/app'
import ScreenBlocker from '../../components/ScreenBlocker/ScreenBlocker'
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import LayoutApp from '../../componentsGeneral/AppLayout'
import HeaderDesktop from '../../componentsGeneral/section/HeaderDesktop'
import SectionSwitcherDesktop from '../../componentsGeneral/section/SectionSwticherDesktop'
import ContentContainerDesktop from '../../componentsGeneral/section/ContentContainerDesktop'
import ContentLeftDesktop from '../../componentsGeneral/section/ContentLeftDesktop'
import ContentRightDesktop from '../../componentsGeneral/section/ContentRightDesktop'
import ContentLeftDesktopHeader from '../../componentsGeneral/section/ContentLeftDesktopHeader'
import DoubleButton from '../../componentsGeneral/section/DoubleButton';
import OrganizationIcon from '../../images/OrganizationIcon'
import LocationIcon from '../../images/LocationIcon'
import CurrentScreenValuesTeamMembers from '../../componentsTeamMembers/CurrentScreenValuesTeamMembers';
import BusinessUnitWithGraph from '../../componentsGeneral/section/BusinessUnitWithGraph';
import TmSmallGraph from '../../componentsTeamMembers/TmSmallGraph';
import BigGraphTeamMembers from '../../componentsTeamMembers/BigGraphTeamMembers';
import TabsTm from '../../componentsTeamMembers/TabsTm';
import FilterSelectTm from '../../componentsTeamMembers/FiltersSelectTm';
import UnitBackButton from '../../componentsGeneral/section/UnitsBackButton';
import TooltipTrUnitGraph from '../../componentsTeamMembers/TooltipTrUnitGraph';
import { Tooltip } from 'recharts';
import MobileControls from '../../componentsGeneral/section/MobileControls';


const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;

const TeamMembers = ({ onlyPoultry, onlyPrepared, onlyMcDonalds, onlyFreshMeats, onlyIT_USER, ...props }) => {
  const { height, width } = useWindowDimensions()
  const { authState } = useOktaAuth()
  const { accessToken: { accessToken } } = authState

  const [dataKey, setDataKey] = useState('');
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);
  const [trend2year, setTrend2year] = useState(false)
  const [sortUnits, setSortUnits] = useState('alph')
  const [locationUnits, setLocationUnits] = useState([]);
  const [leaderUnits, setLeaderUnits] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [currentDate, setCurrentDate] = useState({})

  const regulateCurrentDate = () => {
    const { activeSection, fiscDateEmployeeAbsenteesim, fiscDateEmployee } = props
    if (activeSection === 'ABSENTEEISM') setCurrentDate(fiscDateEmployeeAbsenteesim)
    else setCurrentDate(fiscDateEmployee)
  }

  useEffect(() => {
    const { setActiveSection, setActiveTab, setActiveQYSwitcher, activeSection } = props;
    if (!activeSection || (activeSection !== 'TEAM_MEMBERS' && activeSection !== 'TURNOVER_RATE' && activeSection !== 'ABSENTEEISM')) {
      setActiveSection('TEAM_MEMBERS');
      setActiveQYSwitcher('Y', 'keepSingleState');
      setActiveTab('Gender');
    }
  }, [])

  useEffect(() => {
    regulateCurrentDate()
  }, [])

  useEffect(() => {
    regulateCurrentDate()
  }, [
    props.activeSection,
    props.fiscDateEmployeeAbsenteesim,
    props.fiscDateEmployee
  ])

  const getDataKey = () => {
    if (props.activeSection == 'TURNOVER_RATE') setDataKey('turnoverRate');
    else if (props.activeSection == 'TEAM_MEMBERS') setDataKey('avgActiveEmployees');
    else if (props.activeSection == 'ABSENTEEISM') setDataKey('absCount');
  }

  useEffect(() => {
    getDataKey();
  }, [
    props.activeSection
  ])

  useEffect(() => {
    getDataKey();
  }, [])

  useEffect(() => {
    if (props.activeSection === 'ABSENTEEISM' && !onlyIT_USER) setActiveTab(1)
  }, [
    props.activeSection
  ])

  useEffect(() => {
    if (props.activeSection === 'ABSENTEEISM' && !onlyIT_USER) setActiveTab(1)
  }, [])

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const getLevelFilters = (bus, keyPrepend, filters) => {
    for (let i = 0; i < bus.length; i++) {
      const key = keyPrepend + (i + 1);
      filters.push({ [key]: bus[i] });
    }
  }

  const getData = () => {
    let { setDataTeamMembers, activeBu, buType, activeSection, activeTab, year, isSingle, quarter, activeQYSwitcher } = props;
    const additionalObject = {};
    const propsFilters = props.filters;
    let querySection;
    let filters = [
      { "fiscYear": year + '' }
    ];
    switch (activeSection) {
      case 'TURNOVER_RATE':
        querySection = 'turnoverrate';
        break;
      case 'TEAM_MEMBERS':
        querySection = 'teammember';
        break;
      case 'ABSENTEEISM':
        querySection = 'absenteeism';
        break;
    }
    if (activeSection === 'TURNOVER_RATE' || activeSection === 'TEAM_MEMBERS') additionalObject.viewBy = activeTab
    if (activeSection === 'ABSENTEEISM' && quarter && activeQYSwitcher === 'Q') filters.push({ fiscQtr: quarter + '' })
    if (isSingle) {
      if (quarter) filters.push({ 'fiscWeek': isSingle })
      else filters.push({ 'fiscMonth': isSingle })
    }
    if (buType == 'location') {
      getLevelFilters(activeBu, 'Location', filters);
      if (activeBu.length == 4) additionalObject.lastHeirarchyTrendColumn = 'Location4';
    }
    if (buType == 'leader') {
      getLevelFilters(activeBu, 'OrgLevel', filters);
      if (activeBu.length == 3) additionalObject.lastHeirarchyTrendColumn = 'OrgLevel3';
    }
    if (activeSection === 'ABSENTEEISM') {
      filters.push({ 'absenceClassification': propsFilters.absenteeism })
      filters.push({ "baseLineType": propsFilters.absenteeismBaseLine })
    }
    else {
      if (propsFilters.corpOps) filters.push({ 'EmployeeType': propsFilters.corpOps });
      if (propsFilters.hourlySalary) filters.push({ 'SalaryType': propsFilters.hourlySalary });
    }
    setDataTeamMembers(
      {
        section: querySection,
        filters,
        ...additionalObject
      },
      accessToken,
      { buArray: activeBu, buType }
    )
    setInitialLoadingCompleted(true);
  }


  let activeIndex;

  const getGraphTitle = () => {
    const { navigationStage, activeBu, activeSection } = props
    console.log("activeBu[activeBu.length - 1]", activeBu[activeBu.length - 1])
    if (activeBu.length == 0) return (`Tyson Overall ${activeSection === 'TEAM_MEMBERS' ? 'Head Count' : activeSection === 'TURNOVER_RATE' ? 'Turn Over Rate' : 'Absenteesim'} 2-YR Trend`)
    if (activeBu.length >= 1) return (activeBu[activeBu.length - 1]) + ' ' + (`${activeSection === 'TEAM_MEMBERS' ? 'Head Count' : activeSection === 'TURNOVER_RATE' ? 'Turn Over Rate' : 'Absenteesim'} 2-YR Trend`)
    else return (`${activeSection === 'TEAM_MEMBERS' ? 'Head Count' : activeSection === 'TURNOVER_RATE' ? 'Turn Over Rate' : 'Absenteesim'} 2-YR Trend`)

  }

  const getBackBtnText = () => {
    const { activeBu } = props

    return activeBu.length == 1 ? 'Tyson Overall' : activeBu.length >= 2 ? activeBu[activeBu.length - 2] : ''
  }

  const selectBu = (e, name, index, type) => {
    let { setActiveBu, setNavigationStage, buType, activeBu, setBuType, animateSwitchingBus } = props;
    if (e.target.closest('.info_icon_container')) return;
    if (!animateSwitchingBus) animateSwitchingBus = () => { };
    if (buType === 'leader' && activeBu.length === 3) {
      const newStateBu = activeBu.slice(0);
      newStateBu[2] = name;
      setActiveBu(newStateBu);
      return
    };
    if (buType === 'location' && activeBu.length === 4) {
      const newStateBu = activeBu.slice(0);
      newStateBu[3] = name;
      setActiveBu(newStateBu);
      return;
    }
    setActiveBu(name);
    setNavigationStage('bu');
    setBuType(type);
  }

  const sortUnitsFunction = (unit1, unit2) => {
    if (sortUnits === 'alph') return unit1.viewBy.toLowerCase() < unit2.viewBy.toLowerCase() ? -1 : 1
    else return unit2.value - unit1.value
  }

  const getUnits = async (nameKey, array) => {
    const { buType, quarter, year, activeQYSwitcher, activeBu, isSingle } = props;
    const result = [];
    let index = 0;

    if (!array) return [];

    for (let viewBy in array) {
      const name = viewBy;
      if (viewBy == 'countRecorded' || viewBy == 'trend' || name === 'Unknown' || name === 'N/A' || name === 'n/a') {
        continue;
      }

      const el = array[viewBy];
      if (name == props.activeBu[props.activeBu.length - 1] && props.activeBu.length >= 4) activeIndex = index
      let trend = props.activeSection != 'TEAM_MEMBERS' && props.activeSection != 'ABSENTEEISM' ? el.trend : el;
      if (props.activeSection != 'TEAM_MEMBERS' && props.activeSection != 'ABSENTEEISM' && trend) {
        let firstPeriod = trend[0].period;
        let startWeekOfPeriod = Math.floor((firstPeriod - 1) / 13) * 13 + 1;
        const isObservingCurrentDate = ((currentDate.year == year && activeQYSwitcher == 'Y') || (currentDate.year == year && activeQYSwitcher == 'Q' && quarter == currentDate.quarter));
        const lastPeriod = trend[trend.length - 1].period;
        let weeksCount = lastPeriod == 53 ? 14 : 13;
        if (props.activeQYSwitcher == 'Y') {
          weeksCount = 12;
          startWeekOfPeriod = 1;
        }
        for (let i = 0; i < weeksCount; i++) if (!trend.some(({ period }) => period == startWeekOfPeriod + i)) {
          const fillWith = (isObservingCurrentDate && startWeekOfPeriod + i > lastPeriod) ? {} : { period: startWeekOfPeriod + i, countRecorded: 0 };
          trend.splice(i, 0, fillWith);
        }
      }
      let isActive = false;
      if (buType === 'leader' && activeBu.length === 3 && activeBu[activeBu.length - 1] == name) isActive = 4;
      if (buType === 'location' && activeBu.length === 4 && activeBu[activeBu.length - 1] == name) isActive = true;

      result.push(
        {
          viewBy,
          value: el[dataKey],
          comp: <BusinessUnitWithGraph
            key={viewBy}
            value={dataKey === 'absCount' ? el[dataKey] / 100 : el[dataKey]}
            financePercents={dataKey === 'turnoverRate' || dataKey === 'absCount'}
            name={viewBy}
            graphData={trend}
            currentKey={dataKey}
            isActive={isActive}
            isLast={buType == 'leader' ? activeBu.length === 3 : activeBu.length === 4}
            useDot={false}
            needScale={false}
            useGraphBorder={false}
            needSign={false}
            onClick={(e) => selectBu(e, name, index, nameKey)}
            graph={props.activeSection === 'TURNOVER_RATE' ? false : <TmSmallGraph
              data={trend.trend}
              dataKey={dataKey}
              height={13}
              width={140}
              isSingle={isSingle}
              currentDate={currentDate}
              year={year}
              activeQYSwitcher={props.activeQYSwitcher}
            />}
            graphChildren={!isMobile && <Tooltip
              allowEscapeViewBox={{ y: true, x: true }}
              offset={-3}
              isAnimationActive={false}
              content={
                <TooltipTrUnitGraph
                  currentKey={dataKey}
                />
              }
              cursor={false}
            ></Tooltip>}
          />
        }
      )
      index++;
    }
    return result.sort((a, b) => sortUnitsFunction(a, b))
      .map(u => u.comp);
  }

  const setUnits = async () => {
    let u1, u2;
    if (props.units) u1 = await getUnits('leader', props.units);
    u2 = await getUnits('location', props.locationUnits);
    setLeaderUnits(u1);
    setLocationUnits(u2);
  }

  const goLevelBack = () => {
    const { setBuType, setActiveBu, activeBu } = props;
    const activeBuCopy = activeBu.slice(0);
    activeBuCopy.pop();
    if (activeBuCopy.length === 0) setBuType(false);
    setActiveBu(activeBuCopy);
  }

  useEffect(() => {
    if (!props.isGraphLoading) setUnits()
  }, [
    props.isGraphLoading,
    sortUnits
  ])

  useEffect(() => {
    if (initialLoadingCompleted) setUnits()
  }, [initialLoadingCompleted])


  useEffect(() => {
    const { activeSection, year } = props;
    let timer;
    if (activeSection && year) {
      timer = setTimeout(() => {
        getData();
      }, 100)

    }
    return () => {
      clearTimeout(timer);
    }
  }, [
    props.activeSection,
    props.activeTab,
    props.isSingle,
    props.activeBu,
    props.year,
    props.quarter,
    props.filters.corpOps,
    props.filters.hourlySalary,
    props.filters.absenteeism,
    props.filters.absenteeismBaseLine,
    props.activeQYSwitcher
  ])

  useEffect(() => {
    if (onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats) setActiveTab(1);
  }, [])

  const clickTab = (value) => {
    const { setActiveTab } = props
    setActiveTab(value)
  }

  const getTabs = () => {
    const { activeTab, activeSection } = props
    const result = [
      { title: 'Gender', active: activeTab === 'Gender', onClick: () => clickTab('Gender') },
      { title: 'Ethnicity', active: activeTab === 'Ethnicity', onClick: () => clickTab('Ethnicity') },
      { title: 'Age', active: activeTab === 'AgeGroup', onClick: () => clickTab('AgeGroup') },
      { title: 'Tenure', active: activeTab === 'Tenure', onClick: () => clickTab('Tenure') },
      { title: 'Exit Reason', active: activeTab === 'ExitReason', onClick: () => clickTab('ExitReason'), disabled: activeSection === 'TEAM_MEMBERS' },
    ]

    if (width <= 900 && activeSection === 'TEAM_MEMBERS' || onlyIT_USER) {
      result.pop()
    }

    return result
  }

  const clickSwitchBuType = (newValue) => {
    const { setActiveBu, setBuType, activeBu } = props
    if (newValue != activeTab && activeBu.length) {
      setActiveBu([])
      setBuType(false)
    }

    setActiveTab(newValue)
  }

  const getLeftHeaderTitle = () => {
    return <DoubleButton
      firstText={<><OrganizationIcon /> {width <= 900 ? 'Org' : 'Organization'}</>}
      firstDisabled={onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats || props.activeSection === 'ABSENTEEISM'}
      firstOnClick={() => clickSwitchBuType(0)}
      firstActive={activeTab == 0}
      secondText={<><LocationIcon /> {width <= 900 ? 'Loc' : 'Location'}</>}
      secondDisabled={onlyIT_USER}
      secondOnClick={() => clickSwitchBuType(1)}
      secondActive={activeTab == 1}
      size='md-'
    />
  }

  const getGraphTrend = () => {
    const { trends } = props;
    const trend = trends.bsTrend
    if (!trend || !Array.isArray(trend)) return []

    const newTrend = [];
    let j = currentDate.month;
    for (let i = currentDate.month; i <= 24 + currentDate.month; i++) {
      const yearIterate = (currentDate.year - 2) + Math.floor((i - 1) / 12)
      const value = trend.find(({ fiscYear, fiscMonth }) => parseInt(fiscYear) === yearIterate && parseInt(fiscMonth) === j);
      if (value) newTrend.push(value);
      else newTrend.push({
        "fiscYear": yearIterate,
        "fiscMonth": j,
        "countRecorded": 0,
        "exitEmployees": 0,
        "avgActiveEmployees": 0,
        "turnoverRate": 0
      });
      if (j === 12) j = 0;
      j++;
    }
    return newTrend.map(el => ({ ...el, yearMonth: `${el.fiscYear} ${el.fiscMonth}` }));
  }

  useEffect(() => {
    if (!props.isGraphLoading) setTrend2year(getGraphTrend())
  }, [props.isGraphLoading])

  const getHeaderProps = () => {
    const { isSingle, activeQYSwitcher } = props

    if (!isSingle) return {}

    if (activeQYSwitcher == 'Y') return {
      month: isSingle
    }
    else if (activeQYSwitcher == 'Q') return {
      week: isSingle
    }
  }


  return (
    <ScreenWrapper doSetYear initialLoadingCompleted={initialLoadingCompleted} doSetMonth>
      <ScreenBlocker
        text='Please, rotate your device.'
        active={
          isMobile &&
          ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
            (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
        }
      />
      <LayoutApp>
        <HeaderDesktop
          {...getHeaderProps()}
        >
          {props.activeSection === 'TEAM_MEMBERS' || props.activeSection === 'TURNOVER_RATE' ?
            <TabsTm
              options={getTabs()}
            >
            </TabsTm>
            : ''
          }
          {onlyIT_USER ? '' : <FilterSelectTm />}
        </HeaderDesktop>
        {onlyIT_USER ?
          ""
          :
          <SectionSwitcherDesktop
            buttons={[
              { to: 'TEAM_MEMBERS', title: 'Team Members' },
              { to: 'TURNOVER_RATE', title: 'Turnover Rate' },
              { to: 'ABSENTEEISM', title: 'Absenteeism' },
            ]}
          />
        }
        <MobileControls>
          {props.activeSection === 'TEAM_MEMBERS' || props.activeSection === 'TURNOVER_RATE' ?
            <TabsTm
              isMobile
              options={getTabs()}
            >
            </TabsTm>
            : ''
          }
        </MobileControls>
        <ContentContainerDesktop>
          <ContentLeftDesktop
            graphData={trend2year}
            graphTitle={getGraphTitle()}
            dataKey={dataKey}
            tooltip={!isMobile && <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{ y: true, x: true }}
              offset={-3}
              content={<TooltipTrUnitGraph
                currentKey={dataKey}
              />}
            >
            </Tooltip>}
          >
            <ContentLeftDesktopHeader
              includeSort
              activeSort={sortUnits}
              clickSortAlph={() => setSortUnits('alph')}
              clickSortVal={() => setSortUnits('val')}
              number={dataKey != 'turnoverRate' || dataKey != 'absCount'}
              percents={dataKey == 'turnoverRate' || dataKey == 'absCount'}
            >
              {onlyIT_USER ? 'IT Organization' : getLeftHeaderTitle()}
            </ContentLeftDesktopHeader>
            {
              ((props.activeBu.length > 0 && !(onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats || onlyIT_USER)) || ((onlyPoultry || onlyPrepared || onlyMcDonalds || onlyFreshMeats || onlyIT_USER) && props.activeBu.length >= 2)) && <UnitBackButton className="mb-4" onClick={goLevelBack}>
                {getBackBtnText()}
              </UnitBackButton>
            }
            <div className='overflow-y-scroll units-container' style={{ display: activeTab == 0 ? 'block' : 'none' }}>
              {
                leaderUnits
              }
            </div>
            <div className='overflow-y-scroll units-container' style={{ display: activeTab == 1 ? 'block' : 'none' }}>
              {
                locationUnits
              }
            </div>
          </ContentLeftDesktop>
          <ContentRightDesktop>
            {width <= 900 && <div className='mb-5'>
              <FilterSelectTm />
            </div>}
            <CurrentScreenValuesTeamMembers
              currentKey={dataKey}
              onlyIT_USER={onlyIT_USER}
            />
            <BigGraphTeamMembers
              trend2year={trend2year}
              dataKey={dataKey}
            />
          </ContentRightDesktop>
        </ContentContainerDesktop>
      </LayoutApp>
    </ScreenWrapper>
  )
}

export default connect(
  state => ({
    year: state.sections.year,
    quarter: state.sections.quarter,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    activeSection: state.sections.activeSection,
    activeTab: state.sections.activeTab,
    activeTabTitle: state.sections.activeTabTitle,
    activeBu: state.sections.activeBu,
    navigationStage: state.navigationState.stage,
    header: state.values.header,
    sectionTitle: state.sections.sectionTitle,
    isGraphLoading: state.values.isGraphLoading,
    bars: state.values.bars,
    units: state.values.units,
    locationUnits: state.values.locationUnits,
    buType: state.sections.buType,
    trends: state.values.trends,
    filters: state.sections.filters,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    oshaFiscDate: state.sections.oshaFiscDate,
    isAdditionalGraphDataLoading: state.values.isAdditionalGraphDataLoading,
    userPrivilages: state.userState.userPrivilages
  }),
  {
    setDataTeamMembers, setYear, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim,
    onError, setActiveSection, setBuType, setNavigationStage,
    setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu
  }
)(TeamMembers)

///code latest
