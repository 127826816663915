import React, { useEffect, useState, useRef } from 'react'
import Dimensions from 'react-dimensions'
import { useOktaAuth } from "@okta/okta-react"
import { onError } from '../../actions/valuesActions';
import { setDataPlantOps } from '../../actions/dataActionsPlantOps';
import { setActiveSection, setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setBuType, setYear, setSingle } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { DESKTOP_VIEW_BREAKPOINT, sectionsPlantOps } from '../../constants/app'
import ScreenBlocker from '../../components/ScreenBlocker/ScreenBlocker'
import BusinessUnitCollapsible from '../../componentsGeneral/section/BusinessUnitCollapsible'
import roundFunction from '../../utils/roundFunction';

import useWindowDimensions from '../../hooks/useWindowDimensions'
import LayoutApp from '../../componentsGeneral/AppLayout'
import HeaderDesktop from '../../componentsGeneral/section/HeaderDesktop'
import SectionSwitcherDesktop from '../../componentsGeneral/section/SectionSwticherDesktop'
import ContentContainerDesktop from '../../componentsGeneral/section/ContentContainerDesktop'
import ContentLeftDesktop from '../../componentsGeneral/section/ContentLeftDesktop'
import ContentRightDesktop from '../../componentsGeneral/section/ContentRightDesktop'
import ContentLeftDesktopHeader from '../../componentsGeneral/section/ContentLeftDesktopHeader'
import DoubleButton from '../../componentsGeneral/section/DoubleButton';
import LocationIcon from '../../images/LocationIcon'
import CurrentScreenValuesPlantOps from '../../componentsPlantOps/CurrentScreenValuesPlantOps';
import BusinessUnitWithGraph from '../../componentsGeneral/section/BusinessUnitWithGraph';
import BigGraphPlantOps from '../../componentsPlantOps/BigGraphPlantOps';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetterofEachWord';

import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'

import Poultry from '../../images/Poultry';
import Beef from '../../images/Beef';
import Pork from '../../images/Pork';
import PPI from '../../images/PPI';
import Prepared from '../../images/Prepared';
import McDonalds from '../../images/McDonalds';
import TooltipUnitsPlantOps from '../../componentsPlantOps/TooltipUnitsPlantOps';
import { Tooltip } from 'recharts';
import MobileControls from '../../componentsGeneral/section/MobileControls';

const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;

const monthNames = [
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep'
]

const PlantOps = ({showModal, revenueDataRequested, ...props}) => {
  const { height, width } = useWindowDimensions()
  const { authState } = useOktaAuth()
  const {accessToken: { accessToken }} = authState
  
  const [dataKey, setDataKey] = useState('CapacityRecorded');
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);
  const [locationUnits, setLocationUnits] = useState([]);
  const [sortUnits, setSortUnits] = useState('alph')
  const [trend2year, setTrend2year] = useState([])
  const [activeCETab, setActiveCETab] = useState('CapacityUtilisation')
  const [activePHTab, setActivePHTab] = useState('Harvesting')
  
  useEffect(() => {
    const { setActiveSection, activeSection} = props;

    if(!sectionsPlantOps.includes(activeSection)) setActiveSection('Poultry');
  }, [])
  
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const getLevelFilters = (bus ,keyPrepend, filters) => {
    filters.push({ 'location': bus[bus.length - 1] });
  }

  const getData = () => {
    let { setDataPlantOps, activeBu, buType, activeSection, activeTab, year, isSingle, quarter } = props;
    const additionalObject = {};
    let querySection;
    let filters = [
      { "fiscYear": year }
    ];

    if(quarter) {
      //filters.push({ "fiscQtr": quarter });
      filters.push({ "fiscQtr": `FY${year-2000}` + ' ' + `Q`+quarter + '' });
    }
    if(isSingle) {
      if(quarter) {
        filters.push({ "fiscWeek": isSingle });
      }
      else {
        //filters.push({ "fiscMonth": isSingle });
        filters.push({ 'fiscMonth': 'FY'+(year-2000+'') + ' ' + 'P'+ (isSingle < 10 ? ('0' + isSingle + '') : isSingle + '')});
      }
    }
    
    if(buType == 'location') {
       getLevelFilters(activeBu, 'location', filters);
      //filters.push({"location": activeBu[1]});
    }
    setDataPlantOps(
        {
          section:querySection,
          viewBy: activeSection,
          capacityType: activeSection + " " + activeTab,
          needDetails: true,
          filters,
          ...additionalObject
        },
        accessToken,
        {buArray: activeBu, buType}
    )
    setInitialLoadingCompleted(true);
  }

  const selectBu = (e, name, index, type, parentName) => {
    let { setActiveBu, setNavigationStage, buType, activeBu, setBuType } = props;
    if(e.target.closest('.info_icon_container')) return;

    const newStateBu = [name];
    setActiveBu(newStateBu);
    setNavigationStage('bu');
    setBuType(type);

    return;
  }

  const getLocationImg = (name) => {
    const { activeSection } = props

    switch(name) {
      case 'Poultry': 
        return <Poultry style={{ color: activeSection == name ? '#f3af00' : '' }} />;
      case 'Beef':
        return <Beef style={{ color: activeSection == name ? '#b02d2a' : '' }} />
      case 'Pork':
        return <Pork style={{ color: activeSection == name ? '#809e9b' : '' }} />
      case 'PPI':
        return <PPI style={{ color: activeSection == name ? '#916600' : '' }} />
      case 'Prepared': 
        return <Prepared style={{ color: activeSection == name ? '#002544' : '' }} />;
      case 'McDonalds':
        return <McDonalds style={{ color: activeSection == name ? '#f3af00' : '' }} />
    }
  }

  const getGraphTrend = () => {
    const { trends, currentDate } = props;
    const trend = trends.bsTrend
    if (!Array.isArray(trend)) return []
    const newTrend = [];
    let j = currentDate.month;
    for(let i = currentDate.month; i <= 24 + currentDate.month; i++) {
      const yearIterate = (currentDate.year - 2) + Math.floor((i - 1)/12)
      const value = trend.find(({ fiscYear, fiscMonth }) => parseInt(fiscYear) === yearIterate && parseInt(fiscMonth) === j  );
      if(value) newTrend.push(value);
      else newTrend.push({
          "fiscYear": yearIterate,
          "fiscMonth": j,
          "countRecorded": 0,
          "exitEmployees": 0,
          "avgActiveEmployees": 0,
          "turnoverRate": 0
      });
      if(j === 12) j = 0;
      j++;
    }
    return newTrend.map(el => ({ ...el, yearMonth: `${el.fiscYear} ${el.fiscMonth}` }));
  }

  const clickBuCollapsible = (newValue) => {
    const { setActiveBu, setBuType, activeBu } = props
    if (!newValue && activeBu.length) {
      setActiveBu([])
      setBuType(false)
    }
  }

  const getUnits = async (nameKey, array, lastElementRef) => {
    let result = [];
    let index = 0;
    if(!array) return [];

    for(let viewBy in array) {

      
      const name = viewBy;
      const el = array[viewBy];

        result.push(
          {
            name,
            el: <BusinessUnitCollapsible
              key={name}
              name={name}
              onCollapse={clickBuCollapsible}
              addBackButtonImage={props.activeBu.length === 1}
            >{ await getUnitsInner(nameKey, el, lastElementRef, name) }</BusinessUnitCollapsible>}
        )
    }

    result = result.sort((a, b) => a.name.localeCompare(b.name)).map((el) => el.el)

    return result;
  }

  const sortUnitsFunction = (unit1, unit2) => {
    if (sortUnits === 'alph') return unit1.name.toLowerCase() < unit2.name.toLowerCase() ? -1 : 1
    else return unit2.value - unit1.value
  }

  const getUnitsInner = async (nameKey, array, lastElementRef, parentName) => {
    const { oshaFiscDate, quarter, year, activeQYSwitcher, activeBu } = props;
    const result = [];
    let index = 0;
    if(!array) return [];
    
    for(let viewBy in array) {
      if(viewBy == 'countRecorded' || viewBy == 'trend') {
        index++;
        continue;
      }
      
      const name = viewBy;
      //if(props.activeBu.length === 0 && nameKey === 'location') img = getLocationImg(name);
      const el = array[viewBy];


      let trend = el.trend;
      if(!trend) return [];


      // trend data manipulations 
      let firstPeriod = trend[0].period;
      let startWeekOfPeriod = Math.floor((firstPeriod === 53 ? 39 : firstPeriod - 1)/13)*13 + 1;
      const isObservingCurrentDate = ((oshaFiscDate.year == year && activeQYSwitcher == 'Y') || (oshaFiscDate.year == year && activeQYSwitcher == 'Q' && quarter == oshaFiscDate.quarter));
      const lastPeriod = trend[trend.length - 1].period;
      let weeksCount = lastPeriod == 53 ? 14 : 13;


      if(props.activeQYSwitcher == 'Y') {
        weeksCount = 12;
        startWeekOfPeriod = 1;
      }


      for(let i = 0; i < weeksCount; i++) if(!trend.some(({ period }) => period == startWeekOfPeriod + i )) {
        const fillWith = (isObservingCurrentDate && startWeekOfPeriod + i > lastPeriod) ? {} : { period: startWeekOfPeriod + i, countRecorded: 0 };
        trend.splice(i, 0, fillWith);
      }

      const isActive = (activeBu[activeBu.length - 1] == name);
      result.push(
        {
          name,
          value: el.CapacityRecorded,
          gap: false,
          el: <BusinessUnitWithGraph
                key={name}
                value={el.CapacityRecorded}
                employeePercents
                name={capitalizeFirstLetter(name.toLowerCase())}
                graphData={trend}
                currentKey={dataKey}
                isActive={isActive}
                useDot={false}
                needScale={false}
                useGraphBorder={false}
                isLast={activeBu.length === 1}
                needSign={false}
                onClick={(e) => selectBu(e, name, index, nameKey)}
                graphChildren={!isMobile && <Tooltip 
                  allowEscapeViewBox={{y: true, x:true}}
                  offset={-3}
                  content={<TooltipUnitsPlantOps activeQYSwitcher={props.activeQYSwitcher} />}></Tooltip>}
              />
        }
      )
      index++;
    }
    return result.sort(sortUnitsFunction).map(({ el }) => el);
  } 

  const setUnits = async () => {
    const { activeSection, locationUnits } = props;
    let u2 = [];
    
    if(locationUnits) u2 = await getUnits('location', locationUnits);
    setLocationUnits(u2);
  }

  const getLeftHeaderTitle = () => {
    return <span>
      <LocationIcon />
      <span className='text-gray-600 font-bold ml-2 leading-none'>
        Plant Locations
      </span>
    </span>
  }

  const getGraphTitle = () => {
    const { navigationStage, activeBu, activeSection } = props
    if (activeBu.length >= 1) return `${capitalizeFirstLetter(activeBu[activeBu.length - 1].toLowerCase())} 2-YR Trend`
    else return `${activeSection} Overall 2-YR Trend`
  }

  const getAvailableSections = () => {
    const { onlyPoultry, onlyPrepared, onlyFreshMeats, onlyMcDonalds } = props

    let sections = [
      {to: 'Poultry', title: 'Poultry', icon: getLocationImg('Poultry')}, 
      {to: 'Beef', title: 'Beef', icon: getLocationImg('Beef')},
      {to: 'Pork', title: 'Pork', icon: getLocationImg('Pork')},
      // {to: 'PPI', title: 'PPI', icon: getLocationImg('PPI')},
      // {to: 'Prepared', title: 'Prepared', icon: getLocationImg('Prepared')},
      // {to: 'McDonalds', title: 'McDonalds', icon: getLocationImg('McDonalds')}
    ]

    if (onlyPoultry) sections = [
      {to: 'Poultry', title: 'Poultry', icon: getLocationImg('Poultry')}, 
      // {to: 'McDonalds', title: 'McDonalds', icon: getLocationImg('McDonalds')}
    ]
    
    if (onlyPrepared) sections = sections.filter(s => s.title === 'Prepared')

    if (onlyFreshMeats) sections = [
      {to: 'Beef', title: 'Beef', icon: getLocationImg('Beef')},
      {to: 'Pork', title: 'Pork', icon: getLocationImg('Pork')},
      // {to: 'PPI', title: 'PPI', icon: getLocationImg('PPI')},
    ]

    if (onlyMcDonalds) sections = sections.filter(s => s.title === 'McDonalds')

    return sections
  }

  const getHeaderProps = () => {
    const { isSingle, activeQYSwitcher } = props

    if (!isSingle) return {}

    if (activeQYSwitcher == 'Y') return {
      month: isSingle 
    }
    else if (activeQYSwitcher == 'Q') return {
      week: isSingle
    }
  }

  const getHeaderControls = () => {
    return (
      <>
        <DoubleButton 
          firstText={width <= 900 ? 'Cap' : 'Capacity'}
          firstOnClick={() => {
            setActiveCETab('CapacityUtilisation')
            props.setActiveTab(`CapacityUtilisation ${activePHTab}`)
          }}
          firstActive={activeCETab == 'CapacityUtilisation'}
          secondText={width <= 900 ? 'Eff' : 'Efficiency'}
          secondOnClick={() => {
            setActiveCETab('Efficiency')
            props.setActiveTab(`Efficiency ${activePHTab}`)
          }}
          secondActive={activeCETab == 'Efficiency'}
          size='md'
        />
        <DoubleButton 
          firstText={width <= 900 ? 'Proc' : 'Processing'}
          firstOnClick={() => {
            setActivePHTab('Processing')
            props.setActiveTab(`${activeCETab} Processing`)
          }}
          firstActive={activePHTab == 'Processing'}
          firstDisabled={props.activeSection == 'Beef' || props.activeSection == 'Poultry' || props.activeSection == 'Pork'}
          secondText={width <= 900 ? 'Har' : 'Harvesting'}
          secondOnClick={() => {
            setActivePHTab('Harvesting')
            props.setActiveTab(`${activeCETab} Harvesting`)
          }}
          secondActive={activePHTab == 'Harvesting'}
          secondDisabled={props.activeSection == 'PPI' || props.activeSection == 'Prepared' || props.activeSection == 'McDonalds'}
          size='md'
        />
      </>
    )
  }

  useEffect(() => {
    if(!props.isGraphLoading && props.currentDate) setTrend2year(getGraphTrend())
  }, [props.isGraphLoading, props.trends])

  useEffect(() => {
      if(!props.isGraphLoading) setUnits()
  }, [
      props.isGraphLoading
  ])

  useEffect(() => {
    if(!props.isGraphLoading) setUnits()
  }, [
      props.activeTab,
      sortUnits
  ])

  useEffect(() => {
    setUnits()
  }, [])

  useEffect(() => {
    const { activeTab } = props
    if (typeof activeTab != 'string') return

    const spl = activeTab.split(' ')

    if (spl[0] != activeCETab) setActiveCETab(spl[0])
    if (spl[1] != activePHTab) setActivePHTab(spl[1])
  }, [
    props.activeTab
  ])

  useEffect(() => {
    const { activeSection, year } = props;
    let timer;
    if(activeSection && year) {
      timer = setTimeout(() => {
        getData();
      }, 100)

    }
    return () => {
      clearTimeout(timer);
    }
  }, [
    props.activeSection,
    props.activeTab,
    props.isSingle,
    props.activeBu,
    props.year,
    props.filters.corpOps,
    props.filters.hourlySalary,
    props.quarter,
    props.activeQYSwitcher
  ])

  useEffect(() => {
    const { activeTab, activeSection } = props;

    if(!activeTab) return () => {};

    if(activeTab.includes('Efficiency')) {
      setDataKey('CapacityRecorded');
    }
    else {
      (activeTab.includes('CapacityUtilisation Processing') && activeSection !== 'PPI') ? setDataKey('actualHours') : setDataKey('actual');
    }
  }, [
    props.activeTab,
    props.activeSection
  ])

  return (
    <ScreenWrapper plantops doSetQuarter doSetYear initialLoadingCompleted={initialLoadingCompleted}>
      <ScreenBlocker
        text='Please, rotate your device.'
        active={
            isMobile &&
            ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
            (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
        }
      />
      <LayoutApp>
        <HeaderDesktop
          {...getHeaderProps()}
        >
          { getHeaderControls() } 
        </HeaderDesktop>
        <SectionSwitcherDesktop 
          buttons={getAvailableSections()}
        />
        <MobileControls>
          { getHeaderControls() }
        </MobileControls>
        <ContentContainerDesktop>
          <ContentLeftDesktop
            graphData={trend2year}
            graphTitle={getGraphTitle()}
            dataKey={dataKey}
            tooltip={!isMobile && <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{y: true, x:true}}
              offset={-3}
              content={<TooltipUnitsPlantOps activeQYSwitcher={'Y'} />}
            />}
          >
            <ContentLeftDesktopHeader 
              includeSort
              activeSort={sortUnits}
              clickSortAlph={() => setSortUnits('alph')}
              clickSortVal={() => setSortUnits('val')}
              percents={props.activeSection == 'Poultry' || props.activeSection == 'Beef' || props.activeSection == 'Pork' || props.activeSection == 'PPI' || props.activeSection == 'Prepared' || props.activeSection == 'McDonalds'}
            >
              { getLeftHeaderTitle() }
            </ContentLeftDesktopHeader>
            <div className='overflow-y-scroll' style={{ maxHeight: 400 }}>
              {
                locationUnits
              }
            </div>
          </ContentLeftDesktop>
          <ContentRightDesktop>
            <CurrentScreenValuesPlantOps
              currentKey={dataKey}
            />
            <BigGraphPlantOps
              dataKey={dataKey}
            />
          </ContentRightDesktop>
        </ContentContainerDesktop>
      </LayoutApp>
    </ScreenWrapper>
  )
}



function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default Dimensions()(connect(
  state => ({
    year: state.sections.year,
    quarter: state.sections.quarter,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    currentDate: state.sections.plantOpsFiscDate,
    activeSection: state.sections.activeSection,
    activeTab: state.sections.activeTab,
    activeTabTitle: state.sections.activeTabTitle,
    activeBu: state.sections.activeBu,
    navigationStage: state.navigationState.stage,
    header: state.values.header,
    sectionTitle: state.sections.sectionTitle,
    isGraphLoading: state.values.isGraphLoading,
    bars: state.values.bars,
    barsPY: state.values.barsPY,
    units: state.values.units,
    locationUnits: state.values.locationUnits,
    buType: state.sections.buType,
    trends: state.values.trends,
    filters: state.sections.filters,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    oshaFiscDate: state.sections.oshaFiscDate,
    oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
    plantOpsFiscDate: state.sections.plantOpsFiscDate,
    dataGraphAdditional: state.values.dataGraphAdditional,
    isAdditionalGraphDataLoading: state.values.isAdditionalGraphDataLoading
  }),
  { setDataPlantOps, setYear,onError, setActiveSection, setBuType,setNavigationStage,setActiveTab, setActiveQYSwitcher, setQuarter, setActiveBu, setSingle }
)(PlantOps))

///code latest
