import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setShowModal } from '../../actions/app'
import SadIcon from '../../images/icons/sad.png'
import { deepBlue } from '../../constants/colors'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from "@okta/okta-react"
import { onError as onerr } from '../../actions/valuesActions'
import './styles.css'

const ScreenBlocker = ({ active, title, text, color, error, showModal, action, isModalShown, ...props}) => {
  const blocker = useRef(null)
  const {oktaAuth} = useOktaAuth()
  const [state, setState] = useState({
    isOpen: false
  });

  useEffect(() => {
    console.log('state.isOpen',state.isOpen, 'active:',active);

    if (blocker) {
      if (!state.isOpen && active && !isModalShown) {
        window.UIkit.modal(blocker.current).show()
      } else if(state.isOpen && !active){
        window.UIkit.modal(blocker.current).hide()
      }
      setState({
        ...state,
        isOpen: active
      })
    }
  }, [blocker, active])

  const onReload = e => {
    window.location.reload()
  }
  const goToSignIn = e => {
    oktaAuth.signOut()
  }

  return (
    <div
      ref={blocker}
      className='uk-flex-top uk-width-expand error-modal'
      data-uk-modal
      style={{
        backgroundColor: 'gray',
        display: active ? 'block':'none'
      }}
    >
      <div
        className='uk-modal-dialog uk-modal-body uk-margin-auto-vertical background-desk-error'
        style={{ width: 800 }}
      >
        <div style={{
          backgroundColor: '#EAEAEA',
          margin: 'auto'
        }} data-uk-grid className='uk-grid-medium add-h-m'>
          <div className='uk-width-1-2@s uk-width-1-3@m'>
            <img src={SadIcon} className='uk-align-center' />
          </div>
          <div className='uk-width-1-1@s uk-width-2-3@m'>
            <h1
              className='uk-text-lead uk-margin-bottom uk-width-1-3@m uk-text-center'
              style={{ borderBottom: `3px solid ${deepBlue}`, color: deepBlue }}
            >
              {title}
            </h1>
            <p>
              {text}<br/> Please try&nbsp;
              <a style={{
                color: '#002554',
                textDecoration: 'underline'
              }} onClick={onReload}>refreshing</a>
              &nbsp;the page or choose an option below.
            </p>
          </div>
          <div className='uk-width-expand'>
            <button
              className='uk-button uk-button-text uk-width-1-2@m uk-width-1-1@s uk-margin-bottom'
              onClick={action}
            >
              Contact Tech Support
            </button>
            <button
              className='uk-button uk-button-text uk-width-1-2@m uk-width-1-1@s uk-margin-bottom'
              onClick={goToSignIn}
            >
              Go To Sign In
            </button>
          </div>
        </div>
      </div>
      {/* <div
      style={{
        flex: 1,
        flexDirection: 'column',
        display: active ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: '#fff',
        zIndex: 1001,
        color: color ? color : ''
      }}
    >

    </div> */}
    </div>
  )
}
const mapStateToProps = state => ({
  active: state.values.err ? true : false,
  error: state.values.err,
  isModalShown: state.app.showModal
})

const mapDispatchToProps = {
  showModal: setShowModal,
  onError: onerr
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenBlocker)
