import React from 'react'

const SectionTitle = ({ text }) => {
  return (
    <div className='border-t border-gray-500'>
      <div className='text-base2 text-black tracking-widest font-bold text-center py-2 m-0'>
        { text }
      </div>
    </div>
  )
}

export default SectionTitle