
import { TOKEN_SUCCESS } from '../actions/authAction';

const INITIAL_STATE = {
    accessToken: null
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOKEN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.accessToken
            };

        default:
            return state;
    }
};
