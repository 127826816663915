import React from 'react'

const ExpandButton = ({ cb, isActive }) => {
  return (
    <button
      onClick={cb}
      className={"rounded-full shadow text-blue-400 w-6 h-6 flex justify-center items-center bg-white"}>
      <span className={"transition transform text-sm " + (isActive ? '' : ' rotate-180')}>
        {"<"}
      </span>
    </button>
  )
}

export default ExpandButton
