const monthNames = [
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep'
]

export default ({quarter, year, month, week}) => {
  return `${month ? `${monthNames[month-1]} ` : week ? `FW${week} ` : quarter ? `Q${quarter} ` : ''}FY${Number.parseInt(year) - 2000}`
}