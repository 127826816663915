import React from 'react'

const GraphInfo = ({ children }) => {
  // top-8 to top-12
  return (
    <div className='bg-gray-200 bg-opacity-80 flex 
    absolute bottom-4 lg:bottom-auto lg:-top-8 lg:left-16 py-0.5 
    lg:py-1 px-2 space-x-2 lg:px-4 lg:space-x-4 flex-wrap justify-center'>
      { children }
    </div>
  )
}

export default GraphInfo