import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TeamMembers from '../fullAppUser/TeamMembers';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const TeamMembersFreshMeats = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
        setActiveBu(['Fresh Meats'])
        setBuType('location')
    }, [])
    
    return (
        <TeamMembers
          onlyFreshMeats
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(TeamMembersFreshMeats)
