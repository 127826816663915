import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { publicSynchronizerevenue } from "../../services/dataService";
import {
   setData,
   setTrendData,
   onError,
   resetApp,
} from "../../actions/valuesActions";
import {
   getTeamMembersData,
   getHrAttritionData,
   getVacanciesData,
   getAvgDaysHire,
} from "../../services/dataService";
import {
   getGraphData,
   getGraphAdditionalData,
   getAttrGraphData,
} from "../../utils/dataMappingItdashboard";
import {
   receiveDataGraph,
   setTrend,
   receiveDataGraphAdditional,
} from "../../actions/valuesActions";
import {
   setQuarter,
   setYear,
   setActiveBu,
   setActiveTab,
   setIsCore,
   setSingle,
   setActiveSection,
   setActiveQYSwitcher,
} from "../../actions/sectionsActions";
import { setNavigationStage } from "../../actions/navigationActions";
import DoubleButton from "../../componentsGeneral/section/DoubleButton";
import { setRevenueDataRequested } from "../../actions/app";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { DESKTOP_VIEW_BREAKPOINT } from "../../constants/app";
import ScreenBlocker from "../../components/ScreenBlocker/ScreenBlocker";
import { setShowModal } from "../../actions/app";
import ScreenWrapper from "../../components/ScreenWrapper/ScreenWrapper";
import ReactGA from "react-ga4";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LayoutApp from "../../componentsGeneral/AppLayout";
import ContentContainerDesktop from "../../componentsGeneral/section/ContentContainerDesktop";
import ContentLeftDesktop from "../../componentsGeneral/section/ContentLeftDesktop";
import ContentRightDesktop from "../../componentsGeneral/section/ContentRightDesktop";
import CurrentScreenValueItDashboard from "../../componentsItDashboard/CurrentScreenValueItDashboard";
import ContentLeftDesktopHeader from "../../componentsGeneral/section/ContentLeftDesktopHeader";
import BigGraphFinanceItDash from "../../componentsItDashboard/BigGraphFinaceItDash";
import SectionSwitcherDesktop from "../../componentsGeneral/section/SectionSwticherDesktop";
import TabsTm from "../../componentsTeamMembers/TabsTm";
import { Tooltip } from "recharts";
import TooltipFinance from "../../componentsItDashboard/TooltipFinanceITDash";
import BusinessUnitInfo from "../../componentsItDashboard/BusinessUnitInfo";
import BusinessUnitWithGraph from "../../componentsGeneral/section/BusinessUnitWithGraph";
import ItSmallGraph from "../../componentsItDashboard/ItSmallGraph";
import LoadingBlocker from "../../components/LoadingBlocker/LoadingBlocker";
import { createBrowserHistory } from "history";
import MobileControls from "../../componentsGeneral/section/MobileControls";
import HeaderDesktop from "../../componentsGeneral/section/HeaderDesktop";
import qs from "qs";
import {
   getFinanceActualsData,
   getTeamData,
   getFinanceControllables,
   getFinanceSalaryAndBenefits,
   getLatestEmployeeAbsenteesimDate,
   getFinanceCloud,
   getFinanceCloudDateInfo,
   getFinanceFiscDateInfo,
   getFinanceNonControllables,
   getHrAttritionData2,
} from "../../services/dataService";
import {
   setItFinanceDrilldownData,
   setItFinanceCloudDate,
   setItHRDrilldownData,
} from "../../actions/itDashFinaceActions";
import BigGraphItTeamMembers from "../../componentsItDashboard/BigGraphItTeamMembers";

import TooltipTrUnitGraph from "../../componentsTeamMembers/TooltipTrUnitGraph";
import TooltipTm from "../../componentsItDashboard/ToolTipTm";
import Tooltipunitgraph from "../../componentsItDashboard/Tooltipunitgraph";
import TooltipGraph from "../../componentsItDashboard/TooltipGraph";
import Button from "../../componentsGeneral/section/Button";

const USER_EMAIL_IGNORING_IDLE = process.env.REACT_APP_USER_EMAIL_IGNORING_IDLE;
let drilldownTitle = "";
let dateTitle = "";

const Main = ({ showModal, revenueDataRequested, year, quarter, ...props }) => {
   const { height, width } = useWindowDimensions();

   const history = createBrowserHistory();
   const graphTicksRefLeft = useRef(null);
   const graphTicksRefRight = useRef(null);
   const [sliderRef, setSliderRef] = useState(null);
   const dispatch = useDispatch();
   const [trend2year, setTrend2year] = useState(false);
   const [currentKey, setCurrentKey] = useState("OI");
   const [dataKey, setDataKey] = useState();
   const [sortUnits, setSortUnits] = useState("alph");
   const [mounted, setMounted] = useState(false);
   const [units, setUnits] = useState([]);
   const [headerDesktopYear, setHeaderDesktopYear] = useState("");
   const [headerDesktopQtr, setHeaderDesktopQtr] = useState("");
   const [lefttabs, setLefttabs] = useState(1);
   const { authState, oktaAuth } = useOktaAuth();
   const section = [
      "IT_FINANCE_ACTUALS",
      "IT_FINANCE_CONTROLLABLES",
      "IT_FINANCE_COMPENSATION",
      "IT_FINANCE_NonControllables",
   ];
   const Jobsection = [
      "IT_Hr_Vacancies",
      "IT_Hr_Positions",
      "IT_Hr_AvgDaysHire",
   ];
   const hrSections = [
      "IT_Hr_Vacancies",
      "IT_Hr_Positions",
      "IT_Hr_AvgDaysHire",
      "IT_Hr_TEAM_MEMBERS",
      "IT_Hr_TOTAL_ATTRITION",
   ];
   const {
      accessToken: { accessToken },
   } = authState;

   const twoyertrend2 = [];

   const [isLoading, setIsLoading] = useState(false);

   const initRevenueData = async (accessToken) => {
      const { isCore } = props;
      try {
         const rdata = await publicSynchronizerevenue(accessToken, isCore);
         props.setTrendData(rdata);
      } catch (err) {
         console.log("publicSynchronizerevenue err", err);
      }
   };

   useEffect(() => {
      const { setActiveSection, activeSection, setActiveTab } = props;
      setActiveSection(activeSection);
      {
         props.activeSection !== "IT_Hr_TEAM_MEMBERS"
            ? setActiveTab("YTD")
            : setActiveTab("Gender");
      }
      props.activeSection === "IT_Hr_Vacancies" && setActiveTab("All");
      {
         props.activeSection === "IT_Hr_TEAM_MEMBERS"
            ? setDataKey("avgActiveEmployees")
            : props.activeSection === "IT_Hr_Vacancies"
            ? setDataKey("vacancies")
            : setDataKey("attrition");
      }
   }, [props.activeSection]);

   useEffect(() => {
      const { activeSection } = props;
      if (activeSection) {
         history.push(`?section=${activeSection}`);
         ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
         });
      }
   }, []);

   const getCurrentScreenData = async (year, qtr, date, activeSection) => {
      // const storedSection = history.location.search.substr(1).slice(8, 999);
      const storedSection = hrSections.includes(activeSection)
         ? activeSection
         : history.location.search.substr(1).slice(8, 999);
      setHeaderDesktopQtr(qtr || itFinanceCloudDate.fiscQtr);
      setHeaderDesktopYear(year || itFinanceCloudDate.fiscYear);
      if (storedSection === "IT_FINANCE_ACTUALS") {
         {
            props.activeTab === "YTD"
               ? (drilldownTitle = "ACTUAL YTD ")
               : (drilldownTitle = "ACTUAL LE");
         }
         dateTitle = "Actual vs Forecast";
         await getItFinanceActuals(year, qtr, date);
      } else if (storedSection === "IT_FINANCE_COMPENSATION") {
         {
            props.activeTab === "YTD"
               ? (drilldownTitle = "COMPENSATION YTD")
               : (drilldownTitle = "COMPENSATION LE");
         }
         // drilldownTitle = "COMPENSATION";
         dateTitle = "Salary/Benefits vs Forecast";
         await getItFinanceSalary(year, qtr, date);
      } else if (storedSection === "IT_FINANCE_CONTROLLABLES") {
         {
            props.activeTab === "YTD"
               ? (drilldownTitle = "CONTROLLABLES YTD")
               : (drilldownTitle = "CONTROLLABLES LE");
         }
         // drilldownTitle = "CONTROLLABLES";
         dateTitle = "Controllables vs Forecast";
         await getItFinanceControllables(year, qtr, date);
      } else if (storedSection === "IT_FINANCE_CLOUD") {
         drilldownTitle = "CLOUD COST";
         dateTitle = "Cloud Cost vs Forecast";
         await getItFinanceCloud(year, qtr, date);
      } else if (storedSection === "IT_FINANCE_NonControllables") {
         {
            props.activeTab === "YTD"
               ? (drilldownTitle = " NON CONTROLLABLES YTD")
               : (drilldownTitle = "NON CONTROLLABLES LE");
         }
         // drilldownTitle = "NON CONTROLLABLES";
         dateTitle = "NON Controllables vs Forecast";
         await getItFinanceNonControllables(year, qtr, date);
      } else if (storedSection === "IT_Hr_TEAM_MEMBERS") {
         drilldownTitle = "";
         dateTitle = "TEAM MEMBERS";
         setActiveTab("Gender");
         await getTeamData1();
      } else if (storedSection === "IT_Hr_TOTAL_ATTRITION") {
         drilldownTitle = "Attrition";
         dateTitle = "Attrition";
         await getAttritionData();
      } else if (storedSection === "IT_Hr_Vacancies") {
         drilldownTitle = "";
         dateTitle = "Vacancies";
         await getVacancies();
      } else if (storedSection === "IT_Hr_Positions") {
         drilldownTitle = "";
         dateTitle = "Positions in Offer status";
         await getVacancies();
      } else if (storedSection === "IT_Hr_AvgDaysHire") {
         drilldownTitle = "";
         dateTitle = "Average Days to Hire";
         await getAvgDays();
      }
   };

   const requestData = async () => {
      setIsLoading(true);
      getCloudFiscDate();
      // Promise.all([getCloudFiscDate(), getCurrentScreenData()])
      //   .then(() => {
      //     console.log("finished loading", itFinanceDrilldownData);
      //     setIsLoading(false);
      //   })
      //   .catch((e) => {
      //     console.log("data collection error", e);
      //     setIsLoading(false);
      //   });
   };

   useEffect(() => {
      const { resetApp } = props;
      //resetApp();
      requestData();
   }, []);

   useEffect(() => {
      const { resetApp } = props;
      //resetApp();
      requestData();
   }, [props.activeSection]);

   useEffect(() => {
      showBusiniessUnit();
      showHrBusinessUnit();
   }, [sortUnits]);

   useEffect(() => {
      console.log("accessToken changed:", accessToken);
      if (accessToken && !revenueDataRequested) {
         oktaAuth.getUser().then((userInfo) => {
            // console.log('userInfo', userInfo);
            //initGA(userInfo.AD_sAMAccountName);
            try {
               // initGA(userInfo.sub);
               ReactGA.set({ userId: userInfo.preferred_username });
               ReactGA.event({ action: "Login", category: "UserID" });
            } catch (e) {
               console.error(e);
            }
         });

         initRevenueData(accessToken);
         props.setRevenueDataRequested(true);
      }
      return () => console.log("###\nMAIN COMPONENT UNMOUNTED\n###");
   }, [accessToken]);

   const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

   let refresher;
   useEffect(() => {
      oktaAuth.getUser().then((info) => {
         // console.log(info);
         refresher = setInterval(() => {
            try {
               oktaAuth.tokenManager.renew("idToken");
            } catch (e) {
               window.location.href = "/logout";
            }
         }, 1000 * 600);
      });

      return () => {
         clearInterval(refresher);
      };
   }, [authState]);

   oktaAuth.tokenManager.on("renewed", function (key, newToken, oldToken) {
      console.log("renewed: ", { key, newToken, oldToken });
   });

   const getQueryInfo = (includeGraph = 0) => {
      let { isSingle, year, quarter, activeBu, activeTab, isCore } = props;
      return {
         year: year,
         quartal: quarter ?? 1,
         month: quarter ? false : isSingle,
         week: quarter ? isSingle : false,
         segment: activeTab ? activeTab.toUpperCase() : activeTab,
         unit: activeBu,
         type: includeGraph,
         isCore,
      };
   };

   const prevQYState =
      usePrevious({
         activeQYSwitcher: props.activeQYSwitcher,
         isSingle: props.isSingle,
      }) || {};

   const requestOnlyHeaderData = () => {
      const {
         setData,
         isSingle,
         activeQYSwitcher,
         activeSection,
         isGraphLoading,
      } = props;
      if (isGraphLoading) return;
      {
         setData({ ...getQueryInfo(0) }, 0, accessToken);
      }
   };

   useEffect(() => {
      if (accessToken) {
         requestOnlyHeaderData();
         setTrend2year(getTeamMember2yr());
      }
   }, [props.year, props.quarter, props.isSingle]);

   const requestHeaderAndGraphData = () => {
      if (accessToken) {
         const { setData } = props;
         setData({ ...getQueryInfo(1) }, 1, accessToken);
      }
   };

   useEffect(() => {
      requestData();
      requestHeaderAndGraphData();
   }, [props.activeTab, props.activeBu, props.activeQYSwitcher, props.isCore]);

   useEffect(() => {
      initRevenueData(accessToken);
   }, []);

   useEffect(() => {
      initRevenueData(accessToken);
   }, [props.isCore]);

   useEffect(() => {
      const { setYear, setQuarter, currentDate } = props;

      if (!currentDate.year) return;

      setYear(currentDate.year);
      setQuarter(currentDate.quarter);
      setMounted(true);
      setSingle(itFinanceCloudDate.fiscMonthNum);
   }, [props.currentDate]);

   useEffect(() => {
      if (mounted) requestHeaderAndGraphData();
   }, [mounted]);

   useEffect(() => {
      const { activeSection } = props;

      const keys = {
         OI: "ebit",
         NET_SALES: "netSales",
         AOI: "aoi",
         VOLUME: "volume",
      };

      setCurrentKey(keys[activeSection]);
      {
         activeSection !== "IT_Hr_TEAM_MEMBERS"
            ? setActiveTab("YTD")
            : setActiveTab("Gender");
      }

      if (activeSection === "IT_Hr_TEAM_MEMBERS") {
         setDataKey("avgActiveEmployees");
      } else if (activeSection === "IT_Hr_Vacancies") {
         setDataKey("vacancies");
         setActiveTab("All");
      } else if (activeSection === "IT_Hr_AvgDaysHire") {
         setDataKey("avgDaysToHire");
      } else if (activeSection === "IT_Hr_TOTAL_ATTRITION") {
         setDataKey("attrition");
      } else if (activeSection === "IT_Hr_Positions") {
         setDataKey("PositionsInOffer");
      }
   }, [props.activeSection]);

   const getWeekMonth = (type) => {
      const { isSingle, activeQYSwitcher, activeSection } = props;
      if (activeSection === "IT_Hr_TEAM_MEMBERS") {
         setItFinanceCloudDate({ ...itFinanceCloudDate, year: year });
         return;
      }
      if (!isSingle) return false;

      if (activeQYSwitcher == "Y" && type == "month")
         return isSingle.split("/")[1];
      if (activeQYSwitcher == "Q" && type == "week")
         return isSingle.split("/")[1];
   };

   const itCloudFiscDateSuccessHandler = ({ data }) => {
      const { setItFinanceCloudDate } = props;
      try {
         setItFinanceCloudDate(data[0]);
      } catch (err) {
         console.error(err, "Latest Metrics. Error");
      }
   };

   const getCloudFiscDate = async () => {
      const { setItFinanceCloudDate, activeSection, year } = props;
      let data, type;
      if (
         activeSection === "IT_FINANCE_CLOUD" ||
         activeSection.startsWith("IT_Hr_")
      ) {
         const resp = await getFinanceCloudDateInfo({ accessToken });
         data = resp.data;
         type = resp.type;
      } else {
         const resp = await getFinanceFiscDateInfo({ accessToken });
         data = resp.data;
         type = resp.type;
      }
      if (type === "success") {
         itCloudFiscDateSuccessHandler({ data });
         getCurrentScreenData(
            data[0].fiscYear,
            data[0].fiscQtr,
            data,
            activeSection
         );
      } else {
         setTimeout(async () => {
            if (
               activeSection === "IT_FINANCE_CLOUD" ||
               activeSection === "IT_Hr_TEAM_MEMBERS"
            ) {
               const resp = await getFinanceCloudDateInfo({ accessToken });
               data = resp.data;
               type = resp.type;
            } else {
               const resp = await getFinanceFiscDateInfo({ accessToken });
               data = resp.data;
               type = resp.type;
            }
            if (type === "success") {
               setItFinanceCloudDate({ data });
               getCurrentScreenData(
                  data[0].fiscYear,
                  data[0].fiscQtr,
                  data,
                  activeSection
               );
            } else {
               setItFinanceCloudDate("error");
            }
         }, 1000);
      }
   };

   const getItFinanceNonControllables = async (year, qtr, date) => {
      setIsLoading(true);
      const { setItFinanceDrilldownData } = props;
      const { activeTab, activeSection } = props;
      const { data, type } = await getFinanceNonControllables({
         accessToken: accessToken,
         year: year || itFinanceCloudDate.fiscYear,
         qtr:
            props.activeQYSwitcher === "Q"
               ? qtr || itFinanceCloudDate.fiscQtr
               : undefined,
         tab: activeTab,
      });
      if (type === "success") {
         itFinanceDrilldownDataSuccessHandler({ data });
      } else {
         setTimeout(async () => {
            const { data, type } = await getFinanceNonControllables({
               accessToken: accessToken,
               year: year || itFinanceCloudDate.fiscYear,
               qtr:
                  props.activeQYSwitcher === "Q"
                     ? qtr || itFinanceCloudDate.fiscQtr
                     : undefined,
            });
            if (type === "success") {
               setItFinanceDrilldownData({ data });
            } else {
               setItFinanceDrilldownData("error");
            }
         }, 1000);
      }
      setIsLoading(false);
   };

   const itFinanceDrilldownDataSuccessHandler = async ({ data }) => {
      const { setItFinanceDrilldownData } = props;
      try {
         await setItFinanceDrilldownData(data);
      } catch (err) {
         console.error(err, "Latest Metrics. Error");
      }
   };

   const itHrDrilldownDataSuccessHandler = async ({ data }) => {
      const { setItHrDrilldownData } = props;
      try {
         await setItHrDrilldownData(data);
      } catch (err) {
         console.error(err, "Latest Metrics. Error");
      }
   };

   const getVacancies = async () => {
      let {
         setItHRDrilldownData,
         activeSection,
         activeTab,
         setItFinanceDrilldownData,
         isSingle,
         quarter,
         activeQYSwitcher,
         fiscDateEmployee,
         itFinanceCloudDate,
      } = props;
      setIsLoading(true);
      let filters = { fiscYear: itFinanceCloudDate.fiscYear };
      filters = qs.stringify(filters);
      const { data, type } = await getVacanciesData(filters, accessToken);
      if (type === "success") {
         itHrDrilldownDataSuccessHandler(data);

         const dGraph = await getGraphData(data);
         const graphData = {
            graphBarRecords: dGraph,
            graphBarRecordsPY: data,
         };
         if (graphData.graphBarRecords === undefined) {
            return;
         } else {
            dispatch(receiveDataGraph(graphData));
            dispatch(receiveDataGraphAdditional(getGraphAdditionalData(data)));
            setTrend({ bsTrend: data.twoYearTrendRecord });
            // setItHRDrilldownData(data);
            setItFinanceDrilldownData(data);
         }
      }
      setIsLoading(false);
   };

   const getAvgDays = async () => {
      let {
         setItHRDrilldownData,
         activeSection,
         activeTab,
         setItFinanceDrilldownData,
         isSingle,
         quarter,
         activeQYSwitcher,
         fiscDateEmployee,
         itFinanceCloudDate,
      } = props;
      setIsLoading(true);
      let filters = { fiscYear: itFinanceCloudDate.fiscYear };
      filters = qs.stringify(filters);
      const { data, type } = await getAvgDaysHire(filters, accessToken);
      if (type === "success") {
         itHrDrilldownDataSuccessHandler(data);
         const dGraph = await getGraphData(data);
         const graphData = {
            graphBarRecords: dGraph,
            graphBarRecordsPY: data,
         };
         if (graphData.graphBarRecords === undefined) {
            return;
         } else {
            dispatch(receiveDataGraph(graphData));
            dispatch(receiveDataGraphAdditional(getGraphAdditionalData(data)));
            setTrend({ bsTrend: data.twoYearTrendRecord });
            // setItHRDrilldownData(data);
            setItFinanceDrilldownData(data);
         }
      }
      setIsLoading(false);
   };

   const getTeamData1 = async () => {
      setIsLoading(true);
      let {
         setItHRDrilldownData,
         activeSection,
         activeTab,
         setItFinanceDrilldownData,
         isSingle,
         quarter,
         activeQYSwitcher,
         fiscDateEmployee,
         itFinanceCloudDate,
      } = props;
      const viewBy = activeTab || "Gender";
      // (year+1 === 2023 && dispatch(setSingle(itFinanceCloudDate.fiscMonthNum)))
      let date = await getLatestEmployeeAbsenteesimDate({ accessToken });
      let querySection = "teammember";
      let filters = [
         { fiscYear: (year && year) || itFinanceCloudDate.fiscYear },
      ];
      if (activeSection === "IT_Hr_TEAM_MEMBERS") {
         filters.push({
            fiscMonth: isSingle || date.data.fiscMonth,
         });
         filters.push({
            EmployeeType: ["Operations", "Corporate", "Support"],
         });
         filters.push({ OrgLevel1: "IT" });
      }

      const { data, type } = await getTeamMembersData(
         {
            section: querySection,
            viewBy,
            filters,
         },
         accessToken
      );
      if (type === "success") {
         itHrDrilldownDataSuccessHandler(data);

         const dGraph = await getGraphData(data);
         const graphData = {
            graphBarRecords: dGraph,
            graphBarRecordsPY: data,
         };
         if (graphData.graphBarRecords === undefined) {
            return;
         } else {
            dispatch(receiveDataGraph(graphData));
            dispatch(
               receiveDataGraphAdditional(
                  getGraphAdditionalData(data, querySection, viewBy)
               )
            );
            setTrend({ bsTrend: data.twoYearTrendRecord });
            // setItHRDrilldownData(data);
            setItFinanceDrilldownData(data);
         }
      }
      setIsLoading(false);
   };

   const getAttritionData = async (year, qtr, date) => {
      setIsLoading(true);
      let {
         setItFinanceDrilldownData,
         activeSection,
         activeTab,
         isSingle,
         quarter,
         activeQYSwitcher,
      } = props;
      let filters = [
         { fiscYear: itFinanceCloudDate.fiscYear },
         {
            OrgLevel1: "IT",
         },
      ];
      let viewBy = "ExitReason";
      const { data, type } = await getHrAttritionData(
         {
            filters,
            viewBy,
         },
         accessToken
      );

      const dGraph = await getAttrGraphData(data, itFinanceCloudDate.fiscYear);
      const graphData = {
         graphBarRecords: dGraph,
         graphBarRecordsPY: data,
      };

      if (graphData.graphBarRecords === undefined) {
         return;
      } else {
         dispatch(receiveDataGraph(graphData));
      }
      if (type === "success") {
         itFinanceDrilldownDataSuccessHandler({ data });
      } else {
         const { data, type } = await getHrAttritionData(
            {
               filters,
               viewBy,
            },
            accessToken
         );
         const dGraph = await getGraphData(data);
         const graphData = {
            graphBarRecords: dGraph,
            graphBarRecordsPY: data,
         };

         if (graphData.graphBarRecords === undefined) {
            return;
         } else {
            dispatch(receiveDataGraph(graphData));
         }

         if (type === "success") {
            setItFinanceDrilldownData({ data });
         } else {
            setItFinanceDrilldownData("error");
         }
      }
      setIsLoading(false);
   };

   const getItFinanceActuals = async (year, qtr, date) => {
      setIsLoading(true);
      const { setItFinanceDrilldownData } = props;
      const { activeTab, activeSection } = props;
      const { data, type } = await getFinanceActualsData({
         accessToken: accessToken,
         year: year || itFinanceCloudDate.fiscYear,
         qtr:
            props.activeQYSwitcher === "Q"
               ? qtr || itFinanceCloudDate.fiscQtr
               : undefined,
         tab: activeTab,
      });
      if (type === "success") {
         itFinanceDrilldownDataSuccessHandler({ data });
      } else {
         setTimeout(async () => {
            const { data, type } = await getFinanceActualsData({
               accessToken: accessToken,
               year: year || itFinanceCloudDate.fiscYear,
               qtr:
                  props.activeQYSwitcher === "Q"
                     ? qtr || itFinanceCloudDate.fiscQtr
                     : undefined,
               tab: activeTab,
            });

            if (type === "success") {
               setItFinanceDrilldownData({ data });
            } else {
               setItFinanceDrilldownData("error");
            }
         }, 1000);
      }
      setIsLoading(false);
   };

   const getItFinanceSalary = async (year, qtr, date) => {
      setIsLoading(true);
      const { setItFinanceDrilldownData } = props;
      const { activeTab, activeSection } = props;
      const { data, type } = await getFinanceSalaryAndBenefits({
         accessToken: accessToken,
         year: year || itFinanceCloudDate.fiscYear,
         qtr:
            props.activeQYSwitcher === "Q"
               ? qtr || itFinanceCloudDate.fiscQtr
               : undefined,
         tab: activeTab,
      });
      if (type === "success") {
         itFinanceDrilldownDataSuccessHandler({ data });
      } else {
         setTimeout(async () => {
            const { data, type } = await getFinanceSalaryAndBenefits({
               accessToken: accessToken,
               year: year || itFinanceCloudDate.fiscYear,
               qtr:
                  props.activeQYSwitcher === "Q"
                     ? qtr || itFinanceCloudDate.fiscQtr
                     : undefined,
               tab: activeTab,
            });
            if (type === "success") {
               setItFinanceDrilldownData({ data });
            } else {
               setItFinanceDrilldownData("error");
            }
         }, 1000);
      }
      setIsLoading(false);
   };

   const getItFinanceControllables = async (year, qtr, date) => {
      const { activeTab, activeSection } = props;
      setIsLoading(true);
      const { setItFinanceDrilldownData } = props;
      const { data, type } = await getFinanceControllables({
         accessToken: accessToken,
         year: year || itFinanceCloudDate.fiscYear,
         qtr:
            props.activeQYSwitcher === "Q"
               ? qtr || itFinanceCloudDate.fiscQtr
               : undefined,
         tab: activeTab,
      });
      if (type === "success") {
         itFinanceDrilldownDataSuccessHandler({ data });
      } else {
         setTimeout(async () => {
            const { data, type } = await getFinanceControllables({
               accessToken: accessToken,
               year: year || itFinanceCloudDate.fiscYear,
               qtr:
                  props.activeQYSwitcher === "Q"
                     ? qtr || itFinanceCloudDate.fiscQtr
                     : undefined,
               tab: activeTab,
            });
            if (type === "success") {
               setItFinanceDrilldownData({ data });
            } else {
               setItFinanceDrilldownData("error");
            }
         }, 1000);
      }
      setIsLoading(false);
   };

   const getItFinanceCloud = async (year, qtr, date) => {
      setIsLoading(true);
      const requestBody = {};
      const { setItFinanceDrilldownData } = props;
      if (props.activeQYSwitcher === "Q") {
         //
         const { data, type } = await getFinanceCloud({
            accessToken: accessToken,
            year: year || itFinanceCloudDate.fiscYear,
            qtr: qtr || itFinanceCloudDate.fiscQtr,
         });
         if (type === "success") {
            itFinanceDrilldownDataSuccessHandler({ data });
         } else {
            setTimeout(async () => {
               const { data, type } = await getFinanceCloud({ accessToken });
               if (type === "success") {
                  setItFinanceDrilldownData({ data });
               } else {
                  setItFinanceDrilldownData("error");
               }
            }, 1000);
         }
      } else if (props.activeQYSwitcher === "Y") {
         //
         const { data, type } = await getFinanceCloud({
            accessToken: accessToken,
            year: year || itFinanceCloudDate.fiscYear,
         });
         if (type === "success") {
            itFinanceDrilldownDataSuccessHandler({ data });
         } else {
            setTimeout(async () => {
               const { data, type } = await getFinanceCloud({ accessToken });
               if (type === "success") {
                  setItFinanceDrilldownData({ data });
               } else {
                  setItFinanceDrilldownData("error");
               }
            }, 1000);
         }
      } else if (props.activeQYSwitcher === "W") {
         //
         const { data, type } = await getFinanceCloud({
            accessToken: accessToken,
            year: year || itFinanceCloudDate.fiscYear,
            date: date || itFinanceCloudDate.date,
         });
         if (type === "success") {
            itFinanceDrilldownDataSuccessHandler({ data });
         } else {
            setTimeout(async () => {
               const { data, type } = await getFinanceCloud({ accessToken });
               if (type === "success") {
                  setItFinanceDrilldownData({ data });
               } else {
                  setItFinanceDrilldownData("error");
               }
            }, 1000);
         }
      }
      setIsLoading(false);
   };

   function calculateDot(forecast, current) {
      if (forecast && current) {
         const toPercent =
            ((current - forecast) / ((forecast + current) / 2)) * 100;
         return toPercent > 1 || toPercent < -1 ? false : true;
      }
   }

   useEffect(() => {
      props.activeSection === "IT_Hr_TEAM_MEMBERS" && getTeamData1();
   }, [year]);

   useEffect(() => {
      props.activeSection === "IT_Hr_TOTAL_ATTRITION" && getAttritionData();
      // .activeSection === "IT_Hr_Vacancies" &&
      //   getVacancies()
   }, [props.itFinanceCloudDate.fiscYear]);

   const showHrBusinessUnit = () => {
      let sorted = {};
      let attrsorted = [];
      let keydata = dataKey;
      let vacanciesSorted = {};
      let keysa = [];
      let minigraph = {};
      let data = {};
      let obj = [];
      if (
         itFinanceDrilldownData.orgData &&
         props.activeSection !== "IT_Hr_TOTAL_ATTRITION"
      ) {
         Object.keys(itFinanceDrilldownData.orgData)
            .sort(function (a, b) {
               if (sortUnits === "val") {
                  return (
                     itFinanceDrilldownData.orgData[b].avgActiveEmployees -
                     itFinanceDrilldownData.orgData[a].avgActiveEmployees
                  );
               }
            })
            .forEach(function (key) {
               sorted[key] = itFinanceDrilldownData.orgData[key];
            });
         return (
            <div>
               {Object.keys(sorted).map((key) => (
                  <BusinessUnitWithGraph
                     name={key}
                     value={sorted[key].avgActiveEmployees}
                     useDot={false}
                     needScale={false}
                     useGraphBorder={false}
                     needSign={false}
                     graph={
                        <ItSmallGraph
                           data={sorted[key].trend}
                           dataKey={dataKey}
                           currentDate={itFinanceCloudDate}
                           month={12}
                           height={13}
                           width={140}
                           year={year}
                           isSingle={props.isSingle}
                           activeQYSwitcher={props.activeQYSwitcher}
                        />
                     }
                  />
               ))}
            </div>
         );
      } else if (
         itFinanceDrilldownData.gradeAndReason &&
         props.activeSection === "IT_Hr_TOTAL_ATTRITION"
      ) {
         Object.keys(itFinanceDrilldownData.gradeAndReason).forEach(function (
            key
         ) {
            sorted[key] = itFinanceDrilldownData.gradeAndReason[key];
         });
         sortUnits === "val" &&
            Object.keys(sorted.gradeAttrition)
               .sort(function (a, b) {
                  if (sortUnits === "val") {
                     return (
                        sorted.gradeAttrition[b].attrition -
                        sorted.gradeAttrition[a].attrition
                     );
                  }
               })
               .forEach(function (key) {
                  attrsorted.push(sorted.gradeAttrition[key]);
               });

         Object.keys(itFinanceDrilldownData.miniGraphs).forEach(function (key) {
            minigraph[key] = itFinanceDrilldownData.miniGraphs[key];
         });
         Object.keys(minigraph.jobGradesLineRecords)
            .sort(function (a, b) {
               if (sortUnits === "val") {
                  return (
                     minigraph.jobGradesLineRecords[b].exitEmployees -
                     minigraph.jobGradesLineRecords[a].exitEmployees
                  );
               }
            })
            .forEach(function (key) {
               minigraph[key] = minigraph.jobGradesLineRecords[key];
            });

         sorted.gradeAttrition.map((item, i) => {
            obj = [];
            minigraph.jobGradesLineRecords.map((jobs, j) =>
               jobs.viewBy === item.grade ? obj.push(jobs) : ""
            );
            data[item.grade] = {
               attrition: item.attrition,
               data: obj,
            };
         });
         Object.keys(data)
            .sort(function (a, b) {
               if (sortUnits === "val") {
                  return data[b].attrition - data[a].attrition;
               }
            })
            .forEach(function (key) {
               data[key] = data[key];
               keysa.push(key);
            });

         return (
            <div>
               {lefttabs === 1
                  ? Object.values(sorted.reasonAttrition).map((key) => (
                       <>
                          <BusinessUnitInfo
                             firstNum={key.reason}
                             secondNum={key.attrition}
                             section={props.activeSection}
                             tabs={lefttabs}
                          />
                       </>
                    ))
                  : sortUnits !== "val"
                  ? Object.keys(data).map((key) => (
                       <BusinessUnitWithGraph
                          name={key}
                          value={data[key].attrition}
                          useDot={false}
                          needScale={false}
                          useGraphBorder={false}
                          needSign={false}
                          section={props.activeSection}
                          tabs={lefttabs}
                          graphData={data[key].data}
                          currentKey={"exitEmployees"}
                          graphChildren={
                             !isMobile && (
                                <Tooltip
                                   allowEscapeViewBox={{ y: true, x: true }}
                                   offset={-3}
                                   isAnimationActive={false}
                                   content={
                                      <TooltipGraph
                                         dataKey={"exitEmployees"}
                                         display={true}
                                      />
                                   }
                                   cursor={false}
                                ></Tooltip>
                             )
                          }
                       />
                    ))
                  : keysa.map((key) => (
                       <BusinessUnitWithGraph
                          name={key}
                          value={data[key]["attrition"]}
                          useDot={false}
                          needScale={false}
                          useGraphBorder={false}
                          needSign={false}
                          section={props.activeSection}
                          tabs={lefttabs}
                          graphData={data[key]["data"]}
                          currentKey={"exitEmployees"}
                          graphChildren={
                             !isMobile && (
                                <Tooltip
                                   allowEscapeViewBox={{ y: true, x: true }}
                                   offset={-3}
                                   isAnimationActive={false}
                                   content={
                                      <TooltipGraph
                                         dataKey={"exitEmployees"}
                                         display={true}
                                      />
                                   }
                                   cursor={false}
                                ></Tooltip>
                             )
                          }
                       />
                    ))}
            </div>
         );
      } else if (
         itFinanceDrilldownData.itOrgData &&
         props.activeSection.startsWith("IT_Hr_")
      ) {
         {
            lefttabs === 1
               ? Object.keys(itFinanceDrilldownData.itOrgData)
                    .sort(function (a, b) {
                       if (sortUnits === "alph") {
                          return (
                             itFinanceDrilldownData.itOrgData[b] -
                             itFinanceDrilldownData.itOrgData[a]
                          );
                       } else if (sortUnits === "val") {
                          return props.activeSection === "IT_Hr_Vacancies" &&
                             props.activeTab !== "Frozen"
                             ? itFinanceDrilldownData.itOrgData[b].vacancies -
                                  itFinanceDrilldownData.itOrgData[a].vacancies
                             : props.activeSection === "IT_Hr_Vacancies" &&
                               props.activeTab === "Frozen"
                             ? itFinanceDrilldownData.itOrgData[b]
                                  .frozenPositions -
                               itFinanceDrilldownData.itOrgData[a]
                                  .frozenPositions
                             : props.activeSection === "IT_Hr_Positions"
                             ? itFinanceDrilldownData.itOrgData[b]
                                  .PositionsInOffer -
                               itFinanceDrilldownData.itOrgData[a]
                                  .PositionsInOffer
                             : itFinanceDrilldownData.itOrgData[b]
                                  .avgDaysToHire -
                               itFinanceDrilldownData.itOrgData[a]
                                  .avgDaysToHire;
                       }
                    })
                    .forEach(function (key) {
                       sorted[key] = itFinanceDrilldownData.itOrgData[key];
                    })
               : Object.keys(itFinanceDrilldownData.jobGradeData)
                    .sort(function (a, b) {
                       if (sortUnits === "alph") {
                          return (
                             itFinanceDrilldownData.jobGradeData[b] -
                             itFinanceDrilldownData.jobGradeData[a]
                          );
                       } else if (sortUnits === "val") {
                          return props.activeSection === "IT_Hr_Vacancies" &&
                             props.activeTab === "Active"
                             ? itFinanceDrilldownData.jobGradeData[b]
                                  .vacancies -
                                  itFinanceDrilldownData.jobGradeData[a]
                                     .vacancies
                             : props.activeSection === "IT_Hr_Vacancies" &&
                               props.activeTab === "Frozen"
                             ? itFinanceDrilldownData.jobGradeData[b]
                                  .frozenPositions -
                               itFinanceDrilldownData.jobGradeData[a]
                                  .frozenPositions
                             : props.activeSection === "IT_Hr_Vacancies" &&
                               props.activeTab === "All"
                             ? itFinanceDrilldownData.jobGradeData[b]
                                  .frozenPositions +
                               itFinanceDrilldownData.jobGradeData[b]
                                  .vacancies -
                               (itFinanceDrilldownData.jobGradeData[a]
                                  .frozenPositions +
                                  itFinanceDrilldownData.jobGradeData[a]
                                     .vacancies)
                             : props.activeSection === "IT_Hr_Positions"
                             ? itFinanceDrilldownData.jobGradeData[b]
                                  .PositionsInOffer -
                               itFinanceDrilldownData.jobGradeData[a]
                                  .PositionsInOffer
                             : itFinanceDrilldownData.jobGradeData[b]
                                  .avgDaysToHire -
                               itFinanceDrilldownData.jobGradeData[a]
                                  .avgDaysToHire;
                       }
                    })
                    .forEach(function (key) {
                       keysa.push(key);
                       sorted[key] = itFinanceDrilldownData.jobGradeData[key];
                    });
         }
         return (
            <div>
               {lefttabs === 1 &&
                  Object.keys(sorted).map((key) => (
                     <BusinessUnitWithGraph
                        name={key
                           .replace(new RegExp("\\d", "g"), "")
                           .replace(/[()-]+/g, "")}
                        value={
                           props.activeSection === "IT_Hr_AvgDaysHire"
                              ? Math.round(sorted[key][dataKey])
                              : props.activeTab === "Frozen"
                              ? sorted[key]["frozenPositions"]
                              : props.activeTab === "All"
                              ? sorted[key][dataKey] +
                                sorted[key]["frozenPositions"]
                              : sorted[key][dataKey]
                        }
                        useDot={false}
                        needScale={false}
                        useGraphBorder={false}
                        needSign={false}
                        section={props.activeSection}
                        tabs={lefttabs}
                        currentKey={
                           props.activeTab === "Frozen"
                              ? "frozenPositions"
                              : dataKey
                        }
                        graphData={sorted[key].itOrgGraph}
                        graphChildren={
                           !isMobile && (
                              <Tooltip
                                 allowEscapeViewBox={{ y: true, x: true }}
                                 offset={-3}
                                 isAnimationActive={false}
                                 content={
                                    <TooltipGraph
                                       dataKey={dataKey}
                                       activeTab={props.activeTab}
                                       display={true}
                                    />
                                 }
                                 cursor={false}
                              ></Tooltip>
                           )
                        }
                     />
                  ))}
               {lefttabs === 0 &&
                  keysa.map((key) => (
                     <BusinessUnitWithGraph
                        name={key}
                        value={
                           props.activeSection === "IT_Hr_AvgDaysHire"
                              ? Math.round(sorted[key][dataKey])
                              : props.activeTab === "Frozen"
                              ? sorted[key]["frozenPositions"]
                              : props.activeTab === "All"
                              ? sorted[key][dataKey] +
                                sorted[key]["frozenPositions"]
                              : sorted[key][dataKey]
                        }
                        useDot={false}
                        needScale={false}
                        useGraphBorder={false}
                        needSign={false}
                        section={props.activeSection}
                        tabs={lefttabs}
                        currentKey={
                           props.activeTab === "Frozen"
                              ? "frozenPositions"
                              : dataKey
                        }
                        graphData={sorted[key].jobGradeGraph}
                        graphChildren={
                           !isMobile && (
                              <Tooltip
                                 allowEscapeViewBox={{ y: true, x: true }}
                                 offset={-3}
                                 isAnimationActive={false}
                                 content={
                                    <TooltipGraph
                                       dataKey={dataKey}
                                       display={true}
                                    />
                                 }
                                 cursor={false}
                              ></Tooltip>
                           )
                        }
                     />
                  ))}
            </div>
         );
      }
   };

   const showBusiniessUnit = () => {
      let sorted = {};
      if (itFinanceDrilldownData.itOrgData) {
         Object.keys(itFinanceDrilldownData.itOrgData)
            .sort(function (a, b) {
               if (sortUnits === "alph") {
                  return (
                     itFinanceDrilldownData.itOrgData[b] -
                     itFinanceDrilldownData.itOrgData[a]
                  );
               } else if (sortUnits === "val") {
                  return (
                     itFinanceDrilldownData.itOrgData[b].current -
                     itFinanceDrilldownData.itOrgData[a].current
                  );
               }
            })
            .forEach(function (key) {
               sorted[key] = itFinanceDrilldownData.itOrgData[key];
            });
         return (
            <div>
               {Object.keys(sorted).map((key) => (
                  <BusinessUnitInfo
                     firstNum={key}
                     secondNum={numberFormatter(sorted[key].current)}
                     thirdNum={
                        sorted[key].forecast
                           ? calculateDiff(
                                sorted[key].current,
                                props.activeSection !== "IT_FINANCE_CLOUD"
                                   ? sorted[key].aop
                                   : sorted[key].forecast
                             ) + "%"
                           : "N/A"
                     }
                     isPosDot={calculateDot(
                        props.activeSection !== "IT_FINANCE_CLOUD"
                           ? sorted[key].aop
                           : sorted[key].forecast,
                        sorted[key].current
                     )}
                  ></BusinessUnitInfo>
               ))}
            </div>
         );
      }
   };

   function calculateDiff(current, aop) {
      const difference = (current - aop) / aop;
      const val = (difference * 100).toFixed();
      return val === "-0" ? "0" : val;
   }

   function numberFormatter(num, digits) {
      const lookup = [
         { value: 1, symbol: "" },
         { value: 1e3, symbol: "k" },
         { value: 1e6, symbol: "M" },
         { value: 1e9, symbol: "B" },
         { value: 1e12, symbol: "T" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
         .slice()
         .reverse()
         .find(function (item) {
            return num >= item.value;
         });
      return item
         ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
         : "0";
   }

   const clickSwitchTabs = (value) => {
      setLefttabs(value);
   };

   const getLeftHeaderTitle = () => {
      return (
         <>
            {props.activeSection === "IT_Hr_TOTAL_ATTRITION" ? (
               <>
                  <Button
                     // firstText={<>{width <= 900 ? "Job Grade" : "Job Grade"}</>}
                     // firstOnClick={() => clickSwitchTabs(0)}
                     // firstDisabled={false}
                     // firstActive={lefttabs === 0}
                     secondText={<>{width <= 900 ? "Reasons" : "Reasons"}</>}
                     secondOnClick={() => clickSwitchTabs(1)}
                     secondActive={lefttabs === 1}
                     secondDisabled={false}
                     size="md-"
                     children={"Reasons"}
                     type={"secondary"}
                     isActive={true}
                  />
               </>
            ) : props.activeSection === "IT_Hr_TEAM_MEMBERS" ? (
               ""
            ) : (
               <>
                  <Button
                     firstText={<>{width <= 900 ? "IT" : "IT Org"}</>}
                     firstOnClick={() => clickSwitchTabs(1)}
                     firstDisabled={false}
                     firstActive={lefttabs === 1}
                     children={"IT Org"}
                     type={"secondary"}
                     isActive={true}
                     // secondText={<>{width <= 900 ? "Job" : "Job Grade"}</>}
                     // secondOnClick={() => clickSwitchTabs(0)}
                     // secondActive={lefttabs === 0}
                     // secondDisabled={false}
                     size="md-"
                  />
               </>
            )}
         </>
      );
   };

   const displayGraph = async () => {
      if (itFinanceDrilldownData) {
         return (
            <BigGraphFinanceItDash
               graphData={
                  itFinanceDrilldownData
                     ? itFinanceDrilldownData.graphBarRecords
                     : ""
               }
               month={getWeekMonth("month")}
               currentKey={currentKey}
            />
         );
      } else {
         const data = await itFinanceDrilldownData;
         setTimeout(async () => {
            return (
               <BigGraphFinanceItDash
                  graphData={data ? data.graphBarRecords : ""}
                  month={getWeekMonth("month")}
                  currentKey={currentKey}
               />
            );
         }, 1000);
      }
   };

   useEffect(() => {}, [
      props.isGraphLoading,
      props.year,
      props.activeBu,
      sortUnits,
      props.units,
      props.trends,
      currentKey,
      props.quarter,
      props.isCore,
   ]);

   const { itFinanceDrilldownData, itFinanceCloudDate, itHrDrilldownData } =
      props;

   const clickTab = (value) => {
      const { setActiveTab } = props;
      setActiveTab(value);
   };

   const getTeamMember2yr = () => {
      let { currentDate } = props;
      let twoyertrend = itFinanceDrilldownData.twoYearTrendRecord;
      const newTrend = [];
      let j = itFinanceCloudDate.fiscMonthNum;
      for (
         let i = itFinanceCloudDate.fiscMonthNum;
         i <= 24 + itFinanceCloudDate.fiscMonthNum;
         i++
      ) {
         const yearIterate = currentDate.year - 2 + Math.floor((i - 1) / 12);
         const value =
            twoyertrend &&
            twoyertrend.find(
               ({ fiscYear, fiscMonth }) =>
                  parseInt(fiscYear) === yearIterate &&
                  parseInt(fiscMonth) === j
            );
         if (value) newTrend.push(value);
         else
            newTrend.push({
               fiscYear: yearIterate,
               fiscMonth: j,
               countRecorded: 0,
               exitEmployees: 0,
               avgActiveEmployees: 0,
               turnoverRate: 0,
            });
         if (j === 12) j = 0;
         j++;
      }
      return newTrend;
   };

   const getTabs = () => {
      const { activeTab, activeSection } = props;
      const result = [
         {
            title: "YTD",
            active: activeTab === "YTD",
            onClick: () => clickTab("YTD"),
         },
         {
            title: "LE",
            active: activeTab === "LE",
            onClick: () => clickTab("LE"),
         },
      ];

      return result;
   };

   const getHrTabs = () => {
      const { activeTab, activeSection } = props;
      const result = [
         {
            title: "Gender",
            active: activeTab === "Gender",
            onClick: () => clickTab("Gender"),
         },
         {
            title: "Ethnicity",
            active: activeTab === "Ethnicity",
            onClick: () => clickTab("Ethnicity"),
         },
         {
            title: "Age",
            active: activeTab === "AgeGroup",
            onClick: () => clickTab("AgeGroup"),
         },
         {
            title: "Tenure",
            active: activeTab === "Tenure",
            onClick: () => clickTab("Tenure"),
         },
      ];

      if (width <= 900 && activeSection === "IT_FINANCE_TEAM_MEMBERS") {
         result.pop();
      }

      return result;
   };

   const getFrozenTabs = () => {
      const { activeTab, activeSection } = props;
      const result = [
         {
            title: "All",
            active: activeTab === "All",
            onClick: () => clickTab("All"),
         },
         {
            title: "Frozen",
            active: activeTab === "Frozen",
            onClick: () => clickTab("Frozen"),
         },
         {
            title: "Active",
            active: activeTab === "Active",
            onClick: () => clickTab("Active"),
         },
      ];

      if (width <= 900 && activeSection === "IT_FINANCE_TEAM_MEMBERS") {
         result.pop();
      }

      return result;
   };

   return (
      <ScreenWrapper doSetYearFinance doSetQuarterFinance>
         <LoadingBlocker loading={isLoading} />
         <ScreenBlocker
            text="Please, rotate your device."
            active={
               isMobile &&
               ((width > DESKTOP_VIEW_BREAKPOINT && isPortrait) ||
                  (width < DESKTOP_VIEW_BREAKPOINT && !isPortrait))
            }
         />
         <LayoutApp>
            <HeaderDesktop
               week={getWeekMonth("week")}
               month={getWeekMonth("month")}
               quarter={headerDesktopQtr}
               year={
                  props.activeSection === "IT_Hr_TOTAL_ATTRITION" ||
                  props.activeSection === "IT_Hr_Vacancies"
                     ? itFinanceCloudDate.fiscYear
                     : props.activeSection === "IT_Hr_TEAM_MEMBERS"
                     ? year
                     : headerDesktopYear
               }
               activeQYSwitcher={props.activeQYSwitcher}
               activeSections={props.activeSection}
            >
               {section.includes(`${props.activeSection}`) ? (
                  <TabsTm options={getTabs()}></TabsTm>
               ) : props.activeSection === "IT_Hr_TEAM_MEMBERS" ? (
                  <TabsTm options={getHrTabs()}></TabsTm>
               ) : (
                  props.activeSection === "IT_Hr_Vacancies" && (
                     <TabsTm options={getFrozenTabs()}></TabsTm>
                  )
               )}
            </HeaderDesktop>

            {Jobsection.includes(`${props.activeSection}`) ? (
               <SectionSwitcherDesktop
                  buttons={[
                     { to: "IT_Hr_Vacancies", title: "Vacancies" },
                     { to: "IT_Hr_Positions", title: "Positions in offer" },
                     { to: "IT_Hr_AvgDaysHire", title: "Avg days to hire" },
                  ]}
               />
            ) : (
               (props.activeSection === "IT_Hr_TEAM_MEMBERS" ||
                  props.activeSection === "IT_Hr_TOTAL_ATTRITION") && (
                  <SectionSwitcherDesktop
                     buttons={[
                        { to: "IT_Hr_TEAM_MEMBERS", title: "Team Members" },
                        { to: "IT_Hr_TOTAL_ATTRITION", title: "Attrition" },
                     ]}
                  />
               )
            )}

            <MobileControls>
               {section.includes(`${props.activeSection}`) ? (
                  <TabsTm options={getTabs()}></TabsTm>
               ) : props.activeSection === "IT_Hr_TEAM_MEMBERS" ? (
                  <TabsTm options={getHrTabs()}></TabsTm>
               ) : (
                  props.activeSection === "IT_Hr_Vacancies" && (
                     <TabsTm options={getFrozenTabs()}></TabsTm>
                  )
               )}
            </MobileControls>
            <ContentContainerDesktop>
               <ContentLeftDesktop
                  percents={true}
                  graphData={
                     props.activeSection === "IT_Hr_TEAM_MEMBERS"
                        ? trend2year
                           ? getTeamMember2yr()
                           : null
                        : props.activeSection === "IT_Hr_TOTAL_ATTRITION"
                        ? itFinanceDrilldownData.twoYearTrendRecord != null
                           ? [...itFinanceDrilldownData.twoYearTrendRecord]
                           : null
                        : itFinanceDrilldownData.twoYearTrendRecord != null
                        ? [
                             ...itFinanceDrilldownData.twoYearTrendRecord,
                          ].reverse()
                        : null
                  }
                  graphTitle={"IT Overall 2-YR Trend"}
                  dataKey={
                     props.activeSection.startsWith("IT_Hr_")
                        ? dataKey
                        : "current"
                  }
                  dataKey2={
                     props.activeSection === "IT_FINANCE_CLOUD"
                        ? "forecast"
                        : "aop"
                  }
                  tooltip={
                     props.activeSection.startsWith("IT_Hr_")
                        ? !isMobile && (
                             <Tooltip
                                isAnimationActive={false}
                                allowEscapeViewBox={{ y: true, x: true }}
                                offset={-3}
                                content={
                                   <Tooltipunitgraph
                                      currentKey={`${dataKey}`}
                                   />
                                }
                             ></Tooltip>
                          )
                        : !isMobile && (
                             <Tooltip
                                isAnimationActive={false}
                                allowEscapeViewBox={{ y: true, x: true }}
                                offset={-3}
                                content={
                                   <>
                                      <TooltipFinance
                                         currentKey={currentKey}
                                         groupKey={"yearMonth"}
                                         activeQYSwitcher={"Y"}
                                         splitWithValue=" "
                                         display={true}
                                         isNumberlessthan1000={true}
                                      />
                                   </>
                                }
                             ></Tooltip>
                          )
                  }
               >
                  <div className="object-tops">
                     {props.activeSection === "IT_Hr_TOTAL_ATTRITION" ? (
                        <ContentLeftDesktopHeader
                           includeSort={lefttabs === 1 ? false : true}
                           activeSort={sortUnits}
                           clickSortAlph={() => setSortUnits("alph")}
                           clickSortVal={() => setSortUnits("val")}
                           thirdText={true}
                           number={true}
                        >
                           {props.activeSection.startsWith("IT_Hr")
                              ? getLeftHeaderTitle()
                              : ""}
                        </ContentLeftDesktopHeader>
                     ) : (
                        <ContentLeftDesktopHeader
                           includeSort
                           activeSort={sortUnits}
                           clickSortAlph={() => setSortUnits("alph")}
                           clickSortVal={() => setSortUnits("val")}
                           thirdText={true}
                           number={
                              props.activeSection.startsWith("IT_Hr")
                                 ? true
                                 : false
                           }
                        >
                           {props.activeSection.startsWith("IT_Hr")
                              ? getLeftHeaderTitle()
                              : ""}
                        </ContentLeftDesktopHeader>
                     )}
                  </div>
                  <div
                     className="overflow-y-auto hover:overflow-y-hidden overflow-x-hidden units-container"
                     style={{ display: "block" }}
                  >
                     {props.activeSection.startsWith("IT_Hr")
                        ? showHrBusinessUnit()
                        : showBusiniessUnit()}
                  </div>
               </ContentLeftDesktop>
               <ContentRightDesktop>
                  <CurrentScreenValueItDashboard
                     data={itFinanceDrilldownData.headerRecord}
                     title={drilldownTitle}
                     currentKey={currentKey}
                     week={getWeekMonth("week")}
                     month={getWeekMonth("month")}
                     navigationStage={props.navigationStage}
                     activeQYSwitcher={props.activeQYSwitcher}
                     dataKey={dataKey}
                     includeDot={true}
                     activeSection={props.activeSection}
                     activeTab={props.activeTab}
                  />
                  {props.activeSection.startsWith("IT_Hr") ? (
                     <>
                        <BigGraphItTeamMembers
                           trend2year={
                              itFinanceDrilldownData
                                 ? itFinanceDrilldownData.graphBarRecords
                                 : ""
                           }
                           reasonline={
                              itFinanceDrilldownData
                                 ? itFinanceDrilldownData.reasonsLineRecords
                                 : ""
                           }
                           dataKey={dataKey}
                           attritiontabs={lefttabs}
                           activetab={props.activeTab}
                           date={itFinanceCloudDate}
                           getCurrentScreenData={(year, qtr, date) =>
                              getCurrentScreenData(year, qtr, date)
                           }
                        />
                     </>
                  ) : (
                     <>
                        {mounted && itFinanceDrilldownData && (
                           <BigGraphFinanceItDash
                              graphData={
                                 itFinanceDrilldownData
                                    ? itFinanceDrilldownData.graphBarRecords
                                    : ""
                              }
                              month={getWeekMonth("month")}
                              currentKey={currentKey}
                              activeTab={props.activeTab}
                              getCurrentScreenData={(year, qtr, date) =>
                                 getCurrentScreenData(year, qtr, date)
                              }
                              itFinanceCloudDate={itFinanceCloudDate}
                           />
                        )}
                     </>
                  )}
               </ContentRightDesktop>
            </ContentContainerDesktop>
         </LayoutApp>
      </ScreenWrapper>
   );
};

function usePrevious(value) {
   const ref = useRef();
   useEffect(() => {
      ref.current = value;
   });
   return ref.current;
}

function mapStateToProps(state) {
   return {
      color: state.sections.color,
      scale: state.values.scale,
      sign: state.values.sign,
      navigationStage: state.navigationState.stage,
      units: state.values.units,
      year: state.sections.year,
      quarter: state.sections.quarter,
      activeTab: state.sections.activeTab,
      activeBu: state.sections.activeBu,
      isSingle: state.sections.isSingle,
      activeQYSwitcher: state.sections.activeQYSwitcher,
      activeSection: state.sections.activeSection,
      header: state.values.header,
      isGraphLoading: state.values.isGraphLoading,
      bars: state.values.bars,
      isHeaderLoading: state.values.isHeaderLoading,
      scaleNumber: state.values.scaleNumber,
      activeTabTitle: state.sections.activeTabTitle,
      trends: state.values.trends,
      planScaleNumber: state.values.planScaleNumber,
      projScaleNubmer: state.values.projScaleNubmer,
      planScale: state.values.planScale,
      projScale: state.values.projScale,
      projDelta: state.values.projDelta,
      planDelta: state.values.planDelta,
      stateResetApp: state.values.resetApp,
      mainValue: state.values.mainValue,
      isGraphReady: state.sections.isGraphReady,
      isTrendReady: state.values.isTrendReady,
      err: state.values.err,
      revenueDataRequested: state.app.revenueDataRequested,
      appValues: state.app,
      showModalValue: state.app.showModal,
      currentDate: state.sections.financeFiscDate,
      isFirstDataFetched: state.values.isFirstDataFetched,
      scrollPosition: state.sections.scrollPosition,
      isCore: state.sections.isCore,
      itFinanceDrilldownData: state.values.itFinanceDrilldownData,
      itHrDrilldownData: state.values.itHrDrilldownData,
      itFinanceSalaryData: state.values.itFinanceSalaryData,
      itFinanceControllablesData: state.values.itFinanceControllablesData,
      itFinanceCloudData: state.values.itFinanceCloudData,
      itFinanceCloudDate: state.values.itFinanceCloudDate,
      fiscDateEmployee: state.sections.fiscDateEmployee,
   };
}
export default connect(mapStateToProps, {
   setItFinanceDrilldownData,
   setItHRDrilldownData,
   setData,
   setIsCore,
   setTrendData,
   resetApp,
   setActiveBu,
   showModal: setShowModal,
   setNavigationStage,
   onError,
   setRevenueDataRequested,
   setQuarter,
   setYear,
   setActiveTab,
   setItFinanceCloudDate,
   setActiveSection,
   setActiveQYSwitcher,
})(Main);

///code latest
