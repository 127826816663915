
import { SET_USER_INFO, SET_USER_PRIVILAGES } from '../actions/userActions';

const INITIAL_STATE = {
    userInfo: {},
    userPrivilages: {}
};

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_PRIVILAGES:
            const { userPrivilages } = action.payload;
            window.localStorage.setItem('view', userPrivilages.view);
            return {
                ...state,
                userPrivilages
            }
        case SET_USER_INFO:
            const { userInfo } = action.payload;
            return {
                ...state,
                userInfo
            };

        default:
            return state;
    }
};
