import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { setActiveQYSwitcher, setQuarter } from '../../actions/sectionsActions'

import DoubleButton from './DoubleButton'

import {
  sectionsEmployees,
  sectionsFinance,
  sectionsOsha,
  disableQuarter,
  sectionsPlantOps,
  sectionsFillRate
} from '../../constants/app'

const PeriodSwitcher = () => {
  const dispatch = useDispatch()

  const {
    year,
    activeSection,
    activeQYSwitcher,
    fiscDateEmployee,
    fiscDateEmployeeAbsenteesim,
    oshaFiscDate,
    oshaRatesFiscDate,
    plantOpsFiscDate,
    financeFiscDate,
    fiscDateNesFavBrand,
    fiscDateFillRate,
  } = useSelector(state => ({
    sectionTitle: state.sections.sectionTitle,
    quarter: state.sections.quarter,
    year: state.sections.year,
    fiscDateEmployee: state.sections.fiscDateEmployee,
    fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
    oshaFiscDate: state.sections.oshaFiscDate,
    oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
    plantOpsFiscDate: state.sections.plantOpsFiscDate,
    financeFiscDate: state.sections.financeFiscDate,
    fiscDateNesFavBrand: state.sections.fiscDateNesFavBrand,
    fiscDateFillRate: state.sections.fiscDateFillRate,
    activeSection: state.sections.activeSection,
    activeQYSwitcher: state.sections.activeQYSwitcher
  }))

  const getCurrentDate = () => {
    if (activeSection === sectionsEmployees[0] || activeSection === sectionsEmployees[1]) return fiscDateEmployee
    if (activeSection === sectionsEmployees[2]) return fiscDateEmployeeAbsenteesim
    if (sectionsFinance.includes(activeSection)) return financeFiscDate
    if (activeSection === sectionsOsha[0]) return oshaFiscDate
    if (activeSection === sectionsOsha[1]) return oshaRatesFiscDate
    if (sectionsPlantOps.includes(activeSection)) return plantOpsFiscDate
    if (sectionsFillRate.includes(activeSection)) return fiscDateFillRate
    // return fiscDateFillRate
  }


  const switchPeriod = (e, value) => {
    const currentDate = getCurrentDate()

    if(value != activeQYSwitcher) {
      if(value == 'Q'){ 
          if(year == currentDate.year) dispatch( setQuarter(currentDate.quartal ) );
          else dispatch( setQuarter(4) );
      }
      else dispatch( setQuarter(false) );

      dispatch( setActiveQYSwitcher(value) );
    }
  }
  
  const isButtonActive = (v) => {
    return v === activeQYSwitcher
  }

  if(activeSection === 'IT_FINANCE_CLOUD'){
    return (
      <DoubleButton 
      firstText='Q'
      firstOnClick={(e) => switchPeriod(e, 'Q')}
      firstActive={isButtonActive('Q')}
      firstDisabled={disableQuarter.includes(activeSection)}
      secondText='Y'
      secondOnClick={(e) => switchPeriod(e, 'Y')}
      secondActive={isButtonActive('Y')}
      thirdText={'W'}
      thirdOnClick={(e) => switchPeriod(e, 'W')}
      thirdActive={isButtonActive('W')}
      size='sm'
    />
    )
  }else{
    return(
      <DoubleButton 
        firstText='Q'
        firstOnClick={(e) => switchPeriod(e, 'Q')}
        firstActive={isButtonActive('Q')}
        firstDisabled={disableQuarter.includes(activeSection)}
        secondText='Y'
        secondOnClick={(e) => switchPeriod(e, 'Y')}
        secondActive={isButtonActive('Y')}
      />
    )
  }
}

export default PeriodSwitcher