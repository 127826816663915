import React, { useEffect, useState } from 'react';
import { setUserInfo } from '../../actions/userActions';
import { getLatestDataHeader, getLatestEmployeeMetrics, getLatestEmployeeAbsenteesimMetrics, getOshaLatesMetrics, getOshaRatesLatesMetrics, getLatestPlantOpsMetrics } from '../../services/dataService';
import { receiveDataHeader, setCurrentDate, resetApp } from '../../actions/valuesActions'
import { setQuarter, setYear, setScrollPosition, setActiveQYSwitcher, setActiveSection } from '../../actions/sectionsActions';
import { setLatestMetrcis, setLatestMetrcisAbsenteesim, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setOshaMetrics, setOshaRatesMetrics } from '../../actions/dataActionsTeamMembers';
import { setPlantOpsMetrics, setPlantOpsFiscDate } from '../../actions/dataActionsPlantOps';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setShowModal, setShowMenu } from '../../actions/app';
import roundFunction from '../../utils/roundFunction';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import { financeErrorData } from '../../constants/app'

import AppLayout from '../../componentsGeneral/AppLayout'
import ItemsContainer from '../../homeScreenComponents/general/ItemsContainer'
import ItemsContainerCollapsible from '../../homeScreenComponents/general/ItemsContainerCollapsible'
//import ItemDouble from '../homeScreenComponents/general/ItemDouble'
import Item from '../../homeScreenComponents/general/Item'
import Collapsible from '../../homeScreenComponents/general/Collapsible'
import Section from '../../homeScreenComponents/general/Section'
import SectionRow from '../../homeScreenComponents/general/SectionRow';

import formatFiscDate from '../../utils/formatFiscDate'
import formatNumber from '../../utils/formatNumber'

import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import toClassName from '../../utils/toClassName';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LoadingBlocker from '../../components/LoadingBlocker/LoadingBlocker';


const HomeScreen = (props) => {
    const { height, width } = useWindowDimensions()

    const { authState, oktaAuth } = useOktaAuth()
    const { accessToken: { accessToken } } = authState
    const [isLoading, setIsLoading] = useState(false)

    const getDataFinance = async (initial) => {
        const { receiveDataHeader, setQuarter, setCurrentDate, setYear, setScrollPosition } = props;

        let { data, type } = await getLatestDataHeader('', accessToken);

        if (type === 'success') {
            const date = data.date[0];
            data = data.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
        else {
            // setTimeout(async () => {
            //     let { data, type } = await getLatestDataHeader('', accessToken);
            //     if (type === 'success') {
            //         const date = data.date[0];
            //         data = data.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     } else {
            //         const date = financeErrorData.date[0];
            //         data = financeErrorData.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     }
            // }, 1000)
            const date = financeErrorData.date[0];
            data = financeErrorData.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
    }


    const employeeSuccessHandler = ({ data }) => {
        const { setLatestMetrcis, onlyChicken, onlyPrepared } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            if (onlyChicken) {
                const { employeeInfo } = data;
                metrcis = employeeInfo;
            }
            else if (onlyPrepared) {
                const { employeeInfo } = data;
                metrcis = employeeInfo;
            }
            else {
                metrcis = {
                    exitEmployees: employeeInfo.exitEmployees,
                    avgActiveEmployees: roundFunction(employeeInfo.avgActiveEmployees, 'B'),
                    turnoverRate: employeeInfo.turnoverRate,
                }
            }
            setLatestMetrcis(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployee = async () => {
        const { setLatestMetrcis, onlyChicken, onlyPrepared } = props;
        const { data, type } = await getLatestEmployeeMetrics({ segment: onlyChicken ? '?segment=Poultry,McDonalds' : onlyPrepared ? '?segment=Prepared Foods' : '', accessToken });
        if (type === 'success') {
            employeeSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeMetrics({ segment: onlyChicken ? '?segment=Poultry,McDonalds' : onlyPrepared ? '?segment=Prepared Foods' : '', accessToken });
            //     if (type === 'success') {
            //         employeeSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcis('Error');
            //     }
            // }, 1000)
            setLatestMetrcis('Error');
        }
    }

    const employeeAbsenteesimSuccessHandler = ({ data }) => {
        const { setLatestMetrcisAbsenteesim } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                absenteeism: employeeInfo.absenteeism,
            }
            setLatestMetrcisAbsenteesim(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployeeAbsenteesim = async () => {
        const { setLatestMetrcisAbsenteesim } = props;
        const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '', accessToken });
        if (type === 'success') {
            employeeAbsenteesimSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         employeeAbsenteesimSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcisAbsenteesim('Error');
            //     }
            // }, 1000)
            setLatestMetrcisAbsenteesim('Error');
        }
    }

    const oshaSuccessHandler = ({ data }) => {
        const { setOshaMetrics, onlyChicken, onlyPrepared } = props;
        try {
            let metrics = {};
            if (onlyChicken) {
                const { poultryMcdCounts } = data;
                metrics = {
                    "countRecorded": poultryMcdCounts[0].businessFunction === "Poultry" ? poultryMcdCounts[0].countRecorded : poultryMcdCounts[1].countRecorded,
                    'countRecordedMCD': poultryMcdCounts[1].businessFunction === "McDonalds" ? poultryMcdCounts[1].countRecorded : poultryMcdCounts[0].countRecorded,
                }
            }
            else if (onlyPrepared) {
                const { poultryMcdCounts } = data;
                metrics = {
                    "countRecorded": poultryMcdCounts[0].countRecorded ? poultryMcdCounts[0].countRecorded : 0,
                }
            }
            else {
                const { employeeInfo } = data;
                metrics = {
                    "countRecorded": employeeInfo.countRecorded,
                }
            }
            setOshaMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Record. error');
        }
    }

    const getDataOsha = async () => {
        const { setOshaMetrics, onlyChicken, onlyPrepared } = props;
        const { data, type } = await getOshaLatesMetrics({ segment: onlyChicken ? '?segment=Poultry,McDonalds' : onlyPrepared ? '?segment=Prepared Foods' : '', accessToken });
        if (type === 'success') {
            oshaSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaLatesMetrics({ segment: onlyChicken ? '?segment=Poultry,McDonalds' : onlyPrepared ? '?segment=Prepared Foods' : '', accessToken });
            //     if (type === 'success') {
            //         oshaSuccessHandler({ data });
            //     } else {
            //         setOshaMetrics('Error');
            //     }
            // }, 1000)
            setOshaMetrics('Error');
        }
    }

    const oshaRatesSuccessHandler = ({ data }) => {
        const { setOshaRatesMetrics, onlyChicken, onlyPrepared } = props;
        try {
            let metrics = {};
            if (onlyChicken) {
                const { poultryMcdCounts } = data;
                metrics = {
                    "rate": poultryMcdCounts[0].businessFunction === "Poultry" ? (poultryMcdCounts[0].rate) : (poultryMcdCounts[1].rate),
                    "ratePY": poultryMcdCounts[0].businessFunction === "Poultry" ? (poultryMcdCounts[0].ratePY) : (poultryMcdCounts[1].ratePY),
                    'rateMCD': poultryMcdCounts[1].businessFunction === "McDonalds" ? (poultryMcdCounts[1].rate) : (poultryMcdCounts[0].rate),
                    'ratePYMCD': poultryMcdCounts[1].businessFunction === "McDonalds" ? (poultryMcdCounts[1].ratePY) : (poultryMcdCounts[0].ratePY)
                }
            }
            else if (onlyPrepared) {
                const { poultryMcdCounts } = data;
                metrics = {
                    "rate": poultryMcdCounts[0].businessFunction === "Prepared Foods" ? (poultryMcdCounts[0].rate) : 0,
                    "ratePY": poultryMcdCounts[0].businessFunction === "Prepared Foods" ? (poultryMcdCounts[0].ratePY) : 0,
                }
            }
            else {
                const { employeeInfo } = data;
                metrics = {
                    "rate": (employeeInfo.rate) ? (employeeInfo.rate) : 0,
                    "ratePY": (employeeInfo.rate) ? (employeeInfo.ratePY) : 0
                }
            }
            setOshaRatesMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Rates error');
        }
    }

    const getDataOshaRates = async () => {
        const { setOshaRatesMetrics, onlyChicken, onlyPrepared } = props;
        const { data, type } = await getOshaRatesLatesMetrics({ segment: onlyChicken ? '?segment=Poultry,McDonalds' : onlyPrepared ? '?segment=Prepared Foods' : '', accessToken });
        if (type === 'success') {
            oshaRatesSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaRatesLatesMetrics({ segment: onlyChicken ? '?segment=Poultry,McDonalds' : onlyPrepared ? '?segment=Prepared Foods' : '', accessToken });
            //     if (type === 'success') {
            //         oshaRatesSuccessHandler({ data });
            //     } else {
            //         setOshaRatesMetrics('Error');
            //     }
            // }, 1000)
            setOshaRatesMetrics('Error');
        }
    }

    const plantOpsSuccessHandler = ({ data }) => {
        const { setPlantOpsMetrics, onlyChicken } = props;
        try {
            let metrics = {};
            const { Poultry, Beef, Pork, PPI, Prepared } = data;
            const poultry0 = "CapacityUtilisation Harvesting";
            const poultry1 = "Efficiency Harvesting";
            const poultry2 = "CapacityUtilisation Processing";
            const poultry3 = "Efficiency Processing";
            const CapacityRecorded0 = Poultry[0].CapacityRecorded;
            const CapacityRecorded1 = Poultry[1].CapacityRecorded;
            const CapacityRecorded2 = Poultry[2].CapacityRecorded;
            const CapacityRecorded3 = Poultry[3].CapacityRecorded;
            const CapacityType0 = Poultry[0].CapacityType;
            const CapacityType1 = Poultry[1].CapacityType;
            const CapacityType2 = Poultry[2].CapacityType;
            const CapacityType3 = Poultry[3].CapacityType;

            metrics = {
                "PoultryCUHar": CapacityType0 == poultry0 ? CapacityRecorded0
                    : CapacityType1 == poultry0 ? CapacityRecorded1
                        : CapacityType2 == poultry0 ? CapacityRecorded2
                            : CapacityType3 == poultry0 ? CapacityRecorded3 : 0,
                "PoultryEfficiencyHar": CapacityType0 === poultry1 ? CapacityRecorded0
                    : CapacityType1 === poultry1 ? CapacityRecorded1
                        : CapacityType2 === poultry1 ? CapacityRecorded2
                            : CapacityType3 === poultry1 ? CapacityRecorded3 : 0,
                "PoultryCUPro": CapacityType0 === poultry2 ? CapacityRecorded0
                    : CapacityType1 === poultry2 ? CapacityRecorded1
                        : CapacityType2 === poultry2 ? CapacityRecorded2
                            : CapacityType3 === poultry2 ? CapacityRecorded3 : 0,
                "PoultryEfficiencyPro": CapacityType0 === poultry3 ? CapacityRecorded0
                    : CapacityType1 === poultry3 ? CapacityRecorded1
                        : CapacityType2 === poultry3 ? CapacityRecorded2
                            : CapacityType3 === poultry3 ? CapacityRecorded3 : 0,

                "BeefCUHar": Beef[0].CapacityType === "CapacityUtilisation Harvesting" ? (Beef[0].CapacityRecorded) : (Beef[1].CapacityRecorded),
                "BeefEfficiencyHar": Beef[1].CapacityType === "Efficiency Harvesting" ? (Beef[1].CapacityRecorded) : (Beef[0].CapacityRecorded),

                "PorkCUHar": Pork[0].CapacityType === "CapacityUtilisation Harvesting" ? (Pork[0].CapacityRecorded) : (Pork[1].CapacityRecorded),
                "PorkEfficiencyHar": Pork[1].CapacityType === "Efficiency Harvesting" ? (Pork[1].CapacityRecorded) : (Pork[0].CapacityRecorded),

                "PPICUPro": PPI[0].CapacityType === "CapacityUtilisation Processing" ? (PPI[0].CapacityRecorded) : (PPI[1].CapacityRecorded),
                "PPIEfficiencyPro": PPI[1].CapacityType === "Efficiency Processing" ? (PPI[1].CapacityRecorded) : (PPI[0].CapacityRecorded),

                "PreparedCUPro": Prepared[0].CapacityType === "CapacityUtilisation Processing" ? (Prepared[0].CapacityRecorded) : (Prepared[1].CapacityRecorded),
                "PreparedEfficiencyPro": Prepared[1].CapacityType === "Efficiency Processing" ? (Prepared[1].CapacityRecorded) : (Prepared[0].CapacityRecorded),
            }
            setPlantOpsMetrics(metrics);
        } catch (e) {
            console.error(e, 'Plant Ops error');
        }
    }

    const getDataPlantOps = async () => {
        const { setPlantOpsMetrics, onlyChicken } = props;
        const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
        if (type === 'success') {
            plantOpsSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
            //     if (type === 'success') {
            //         plantOpsSuccessHandler({ data });
            //     } else {
            //         setPlantOpsMetrics('Error');
            //     }
            // }, 1000)
            setPlantOpsMetrics('Error');
        }
    }

    const requestData = async (initial) => {
        setIsLoading(true)
        Promise.all([
            getDataFinance(initial),
            getDataEmployee(),
            getDataEmployeeAbsenteesim(),
            getDataOsha(),
            getDataOshaRates(),
            getDataPlantOps()
        ]).then((values) => {
            setIsLoading(false)
        })
            .catch(e => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const { resetApp } = props;
        resetApp();
        requestData();
    }, []);

    useEffect(() => {
        const { setUserInfo } = props;
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null)
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info)
            })
        }
    },
        [
            authState,
            oktaAuth
        ]);

    const getDot = (value) => {
        if (value > 0) return <Circle />
        else if (value == 0) return null
        else return <Diamond />
    }

    const getDotForOshaRates = (value) => {
        if (value < 0) return <Circle />
        else return <Diamond />
    }

    const generateDot = (title, value, classes = []) => {
        return <span className={toClassName(['inline-flex items-center leading-0', ...classes])}>
            <span className='text-xs font-medium mr-1 leading-tight'>{title}</span>{getDot(value)}
        </span>
    }

    const {
        metrics,
        absenteesimMetrics,
        plantOpsMetrics,
        fiscDateEmployee,
        fiscDateEmployeeAbsenteesim,
        oshaRatesMetrics,
        oshaMetrics,
        oshaRatesFiscDate,
        oshaFiscDate,
        plantOpsFiscDate,
        quarter,
        year,
        header,
        userPrivilages
    } = props

    const history = useHistory()
    const dispatch = useDispatch()

    const switchView = (view) => {
        const links = {
            'CHICKEN': '/poultry',
            'PREPARED FOODS': '/prepared',
            'FRESH MEATS': '/freshMeats',
            'MCDONALDS': '/McDonalds',
            'HROSHA': '/hrosha',
            'OPERATIONS': '/operations',
            'OPERATIONS_POULTRY': '/operations/poultry',
            'OPERATIONS_PREPARED': '/operations/prepared',
            'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
            'OPERATIONS_MCDONALDS': '/operations/McDonalds',
            'IT_USER': '/information-technology',
            'false': '/'
        }

        dispatch({
            type: 'SET_USER_PRIVILAGES',
            payload: {
                userPrivilages: {
                    ...userPrivilages,
                    view
                }
            }
        })

        history.push(links[view.toString()])
    }

    const navigateAction = ({ href, section, tab }) => {
        // resetApp();
        dispatch(setActiveSection(section, tab))

        history.push(href)
    }

    const formatNumberString = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    const formatNumberStringTeamMembers = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    return (
        <ScreenWrapper home landingPage>
            <LoadingBlocker loading={isLoading} />
            <AppLayout keepPaddings>
                <div className='flex gap-x-6 flex-wrap'>

                    <ItemsContainer title='TEAM MEMBERS'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/team-members', section: 'TEAM_MEMBERS' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ month: fiscDateEmployee.month, year: fiscDateEmployee.year })}
                            title='Team Members'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.avgActiveEmployees) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.avgActiveEmployees) ? 0 : metrics.avgActiveEmployees })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/team-members', section: 'TURNOVER_RATE' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ year: fiscDateEmployee.year })}
                            title='Turnover Rate'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.turnoverRate * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.turnoverRate * 100) ? 0 : metrics.turnoverRate * 100, employeePercents: true })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/team-members', section: 'ABSENTEEISM' })}
                            date={!fiscDateEmployeeAbsenteesim.year ? 'Error' : formatFiscDate({ year: fiscDateEmployeeAbsenteesim.year })}
                            title='Absenteeism'
                            value={absenteesimMetrics === 'Error' ? 'Error' : isNaN(absenteesimMetrics.absenteeism * 100) ? 0 : formatNumberStringTeamMembers({ number: absenteesimMetrics.absenteeism, employeePercents: true })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title='OSHA'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/osha', section: 'OSHA_RATES' })}
                            date={!oshaRatesFiscDate.year ? 'Error' : formatFiscDate({ year: oshaRatesFiscDate.year }) + ` YTD`}
                            title='Rates'
                            value={<>{oshaRatesMetrics === 'Error' ? 'Error' : isNaN(oshaRatesMetrics.rate) ? 0 : formatNumberString({ number: oshaRatesMetrics.rate })} {oshaRatesMetrics === 'Error' ? '' : getDotForOshaRates(oshaRatesMetrics.rate - oshaRatesMetrics.ratePY)}</>}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/osha', section: 'OSHA_RECORDABLES' })}
                            date={!oshaFiscDate.year ? 'Error' : formatFiscDate({ quarter: oshaFiscDate.quarter, year: oshaFiscDate.year })}
                            title='Recordables'
                            value={oshaMetrics === 'Error' ? 'Error' : isNaN(oshaMetrics.countRecorded) ? 0 : formatNumberString({ number: oshaMetrics.countRecorded })}
                        />
                    </ItemsContainer>
                </div>

            </AppLayout>
        </ScreenWrapper>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.userState.userInfo,
        activeQYSwitcher: state.sections.activeQYSwitcher,
        activeSection: state.sections.activeSection,
        currentDate: state.values.currentDate,
        isSingle: state.sections.isSingle,
        header: state.values.header,
        year: state.sections.year,
        quarter: state.sections.quarter,
        activeTab: state.sections.activeTab,
        activeBu: state.sections.activeBu,
        currentQuarter: state.sections.currentQuarter,
        isHeaderLoading: state.values.isHeaderLoading,
        showModal: state.app.showModal,

        userPrivilages: state.userState.userPrivilages,
        metrics: state.values.metrics,
        absenteesimMetrics: state.values.absenteesimMetrics,
        fiscDateEmployee: state.sections.fiscDateEmployee,
        fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
        oshaFiscDate: state.sections.oshaFiscDate,
        oshaMetrics: state.values.oshaMetrics,
        oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
        oshaRatesMetrics: state.values.oshaRatesMetrics,
        plantOpsFiscDate: state.sections.plantOpsFiscDate,
        plantOpsMetrics: state.values.plantOpsMetrics,
        units: state.values.units
    }
}
export default connect(
    mapStateToProps,
    {
        setLatestMetrcis, setLatestMetrcisAbsenteesim, resetApp, setFiscDateEmployeeAbsenteesim,
        setFiscDateEmployee, setOshaMetrics, setOshaRatesMetrics,
        setUserInfo, setShowModal, setShowMenu, setPlantOpsMetrics,
        receiveDataHeader, setCurrentDate, setQuarter, setYear, setScrollPosition, setActiveQYSwitcher
    }
)(HomeScreen)
