
const INITIAL_STATE = {
    observingValue: "Q3 FY20"
}

export const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        default:
            return state;
    }
}
