import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Osha from '../fullAppUser/Osha';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const OshaFreshMeats = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
      setBuType('location')
      setActiveBu(['Fresh Meats'])
    }, [])
    
    return (
        <Osha
          onlyFreshMeats
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(OshaFreshMeats)
