import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TeamMembers from '../fullAppUser/TeamMembers';
import { setActiveBu, setBuType } from '../../actions/sectionsActions';


const TeamMembersMcDonalds = ({ setBuType, setActiveBu }) => {
    useEffect(() => {
        setActiveBu(['McDonalds'])
        setBuType('location')
    }, [])
    
    return (
        <TeamMembers
          onlyMcDonalds
        />
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        userPrivilages: state.userState.userPrivilages
    }),
    { setActiveBu, setBuType }
  )(TeamMembersMcDonalds)
