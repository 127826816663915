
import { SET_VALUES } from '../actions/graphDimensionValuesActions';

const INITIAL_STATE = {
    values: {

    }
}

export const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_VALUES:
            const { values, indentifier } = action.payload;
            return {
                ...state,
                values: {
                    ...state.values,
                    [indentifier]: values
                }
            }
        default:
            return state;
    }
}
