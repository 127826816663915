import React from 'react'

import FeatherIcon from 'feather-icons-react';
import toClassName from '../../utils/toClassName';

const UnitBackButton = ({ onClick, children, className }) => {
  return (
    <span className={toClassName(['cursor-pointer', className])} onClick={onClick}>
      <FeatherIcon icon='chevron-left' className='text-blue-400' />
      <span className='text-gray-600 font-bold ml-1 leading-none'>
        { children }
      </span>
    </span>
  )
}

export default UnitBackButton