import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Bar, Cell, Line, Tooltip, YAxis } from 'recharts'

import formatNumber from '../utils/formatNumber'
import BigGraph from '../componentsGeneral/section/BigGraph'
import GraphTicks from '../componentsGeneral/section/GraphTicks'
import GraphXTitles from '../componentsGeneral/section/GraphXTitles'
import { setSingle } from '../actions/sectionsActions'
import BarShapeFinance from './BarShapeFinance'
import TooltipFinance from './TooltipFinance'
import useTooltipControl from '../hooks/useTooltipControl'
import { isMobile } from 'react-device-detect'


const FinanceGraphSection = ({ data, height=60, width, graphTicksRefLeft, graphTicksRefRight }) => {
  const [graphRef, setGraphRef] = useState(null)
  const [currentKey, setCurrentKey] = useState('ebit')
  const [leftTicks, setLeftTicks] = useState([])
  const [rightTicks, setRightTicks] = useState([])

  const graphHeight = 310

  const dispatch = useDispatch()
  const { year, quarter, activeSection, activeBu, activeTab, isSingle, activeQYSwitcher } = useSelector(state => ({ 
    year: state.sections.year,
    quarter: state.sections.quarter,
    activeSection: state.sections.activeSection,
    activeBu: state.sections.activeBu,
    activeTab: state.sections.activeTab,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
  }))

  const {
    onMouseOverBar,
    onMouseLeaveBar,
    isTooltipActive
  } = useTooltipControl()

  const showTicks = () => {
    return year == data[0].fiscYear && (!quarter || quarter == data[0].fiscQtr)
  }

  const clickBar = (v, s, e) => {
    e.stopPropagation()
    dispatch( setSingle(isSingle === v.groupByEntity ? false : v.groupByEntity ) )
  }

  const clickChart = (e) => {
    if(isSingle) dispatch( setSingle(false) );
  }

  const formatTickValue = (value) => {
    const {
      prependStr,
      appendStr,
      number
    } = formatNumber({ number: isNaN(value) ? 0 : value, 
      needScale: activeSection !== 'AOI', 
      financePercents: activeSection === 'AOI',
      remove$: activeSection === 'VOLUME' ? true : false  
    })

    return `${prependStr}${number}${appendStr}`
  }

  const renderTicks = (yAxis) => {
    if (!yAxis) return null
    
    return yAxis.niceTicks.map((tickValue, i) => <span className='text-sm text-black leading-0' key={i}>
      { isFinite(tickValue) ? formatTickValue(tickValue) : '' }
    </span>)
  }

  useEffect(() => {
    const keys = {
      'OI': 'ebit',
      'NET_SALES': 'netSales',
      'AOI': 'aoi',
      'VOLUME': 'volume'
    } 

    setCurrentKey(keys[activeSection])
  }, [activeSection])

  useEffect(() => {
    if (graphRef && graphRef.state.yAxisMap) setLeftTicks( renderTicks(graphRef.state.yAxisMap.left) )
    else setLeftTicks([])

    if (graphRef && graphRef.state.yAxisMap) setRightTicks( renderTicks(graphRef.state.yAxisMap.right) )
    else setRightTicks([])
  }, [graphRef, currentKey, activeBu, activeTab, data, activeSection])

  return (
    <>
      {showTicks() && <GraphTicks
        targetNode={graphTicksRefLeft.current}
      >
        { leftTicks }
      </GraphTicks>}
      <BigGraph
        height={graphHeight}
        data={data}
        setRef={setGraphRef}
        onClick={clickChart}
        width={width}
        titles={<GraphXTitles 
          data={data}
          getMonth={(element, prevMonth) => element.groupByEntity ? Number.parseInt( element.groupByEntity.split('/')[1] ) : prevMonth}
          monthKey='groupByEntity'
        />}
      >
        {!isMobile && <Tooltip
          cursor={false}
          content={<TooltipFinance 
            currentKey={currentKey}
            groupKey={'groupByEntity'}
            display={isTooltipActive}
            activeQYSwitcher={activeQYSwitcher}
            isNumberlessthan1000={true}
          ></TooltipFinance>}
        >
        </Tooltip>}
        <YAxis 
          yAxisId='left'
          hide
        />
        <Bar 
          dataKey={currentKey} 
          yAxisId='left'
          onClick={clickBar}
          barSize={20}
          minPointSize={25}     
          shape={<BarShapeFinance />}
          onMouseOver={onMouseOverBar}
          onMouseLeave={onMouseLeaveBar}
        >
          {
            data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill='#002554'
                opacity={(isSingle && isSingle !== entry.groupByEntity) ? 0.5 : 1}
              />
            ))
          }
        </Bar>
        <Line 
          dataKey={currentKey + 'Plan'} 
          hide={!!isSingle}
          yAxisId={activeSection === 'AOI' ? 'left' : 'right'} 
          strokeWidth={4}
          stroke='#809E9B'
          strokeDasharray={[12, 8]}
          dot={false}
        />
        <Line 
          dataKey={currentKey + 'Actual'} 
          yAxisId='right'
          hide={!!isSingle}
          strokeWidth={5}
          stroke='#002554'
          dot={false}
        />
        {activeSection !== 'AOI' && <Line 
          dataKey={currentKey + 'LastYear'}
          hide={!!isSingle}
          yAxisId='right' 
          stroke='#F3AF00'
          strokeWidth={4}
          dot={false}
        />}
        {activeSection !== 'AOI' && <Line 
          dataKey={currentKey + 'Proj'}
          hide={!!isSingle} 
          yAxisId='right' 
          stroke='#2699FB'
          strokeWidth={4}
          strokeDasharray={[4, 4]}
          dot={false}
          key="proj"
          isAnimationActive={false}
        />}
        <YAxis 
          yAxisId='right'
          hide
        />
      </BigGraph>
      {(showTicks() && activeSection !== 'AOI' && !isSingle) && <GraphTicks
        targetNode={graphTicksRefRight.current}
      >
        { rightTicks }
      </GraphTicks>}
    </>
  )
}

export default FinanceGraphSection