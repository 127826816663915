import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Main from '../fullAppUser/Main';
import { setActiveBu, setActiveTab, setActiveSection } from '../../actions/sectionsActions';
import { setNavigationStage } from '../../actions/navigationActions';


const MainOperationsFreshMeatsBeef = ({ activeTab, setActiveTab, setActiveSection, activeSection, setNavigationStage, setActiveBu, activeBu }) => {

    useEffect(() => {
        setActiveTab('beef');
        if (!activeSection) setActiveSection('VOLUME')
        setNavigationStage(1);
    }, [])

    if (activeTab !== 'beef' || !activeSection) return <></>

    return (
        <Main
        onlyFreshMeats_Operations
        />
    )
}

export default connect(
    state => ({
      activeBu: state.sections.activeBu,
      activeTab: state.sections.activeTab,
      activeSection: state.sections.activeSection,
      userPrivilages: state.userState.userPrivilages
    }),
    { setActiveTab, setNavigationStage, setActiveBu, setActiveSection }
  )(MainOperationsFreshMeatsBeef)
