import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Bar, Cell, Line, Tooltip, YAxis, XAxis } from "recharts";

import formatNumber from "../utils/formatNumber";
import BigGraph from "../componentsGeneral/section/BigGraph";
import GraphTicks from "../componentsGeneral/section/GraphTicks";
import { setSingle } from "../actions/sectionsActions";
import TooltipFinance from "../componentsItDashboard/TooltipFinanceITDash";
import useTooltipControl from "../hooks/useTooltipControl";
import BarShapeFinance from "../componentsFinance/BarShapeFinance";
import { isMobile } from "react-device-detect";

const FinanceGraphSection = ({
  data,
  height = 60,
  width,
  graphTicksRefLeft,
  graphTicksRefRight,
}) => {
  const [graphRef, setGraphRef] = useState(null);
  const [currentKey, setCurrentKey] = useState("current");
  const [leftTicks, setLeftTicks] = useState([]);
  const [rightTicks, setRightTicks] = useState([]);

  const graphHeight = 310;

  const dispatch = useDispatch();
  const {
    year,
    quarter,
    activeSection,
    activeBu,
    activeTab,
    isSingle,
    activeQYSwitcher,
  } = useSelector((state) => ({
    year: state.sections.year,
    quarter: state.sections.quarter,
    activeSection: state.sections.activeSection,
    activeBu: state.sections.activeBu,
    activeTab: state.sections.activeTab,
    isSingle: state.sections.isSingle,
    activeQYSwitcher: state.sections.activeQYSwitcher,
  }));

  const { onMouseOverBar, onMouseLeaveBar, isTooltipActive } =
    useTooltipControl();

  const showTicks = () => {
    console.log("G1 showTicks", data);
    // return data[0].fiscYear
    return "2021";
  };

  const clickBar = (v, s, e) => {
    e.stopPropagation();
    dispatch(setSingle(isSingle === v.groupByEntity ? false : v.groupByEntity));
  };

  const clickChart = (e) => {
    if (isSingle) dispatch(setSingle(false));
  };

  const formatTickValue = (value) => {
    const { prependStr, appendStr, number } = formatNumber({
      number: isNaN(value) ? 0 : value,
      needScale: activeSection !== "AOI",
      financePercents: activeSection === "AOI",
      remove$: activeSection === "VOLUME" ? true : false,
    });

    return `${prependStr}${number}${appendStr}`;
  };

  const renderTicks = (yAxis) => {
    if (!yAxis) return null;

    return yAxis.niceTicks.map((tickValue, i) => (
      <span className="text-sm text-black leading-0" key={i}>
        {isFinite(tickValue) ? formatTickValue(tickValue) : ""}
      </span>
    ));
  };

  useEffect(() => {
    console.log("G1 USE", activeSection, data);
    const keys = {
      IT_FINANCE_ACTUALS: "current",
      IT_FINANCE_CONTROLLABLES: "current",
      IT_FINANCE_COMPENSATION: "current",
      IT_FINANCE_NonControllables:"current",
    };

    setCurrentKey(keys[activeSection]);
  }, [activeSection]);

  useEffect(() => {
    if (graphRef && graphRef.state.yAxisMap)
      setLeftTicks(renderTicks(graphRef.state.yAxisMap.left));
    else setLeftTicks([]);

    if (graphRef && graphRef.state.yAxisMap)
      setRightTicks(renderTicks(graphRef.state.yAxisMap.right));
    else setRightTicks([]);
  }, [graphRef, currentKey, activeBu, activeTab, data, activeSection]);

  const monthNames = {
    4: "Jan",
    5: "Feb",
    6: "Mar",
    7: "Apr",
    8: "May",
    9: "Jun",
    10: "Jul",
    11: "Aug",
    12: "Sep",
    1: "Oct",
    2: "Nov",
    3: "Dec",
  };

  const mapData = () => {
    console.log("G1 mapData", data);
    if (data) {
      if (activeQYSwitcher !== "W") {
        const periodToMonth = data.map((p) =>
          p.period ? { ...p, period: monthNames[p.period] } : p
        );
        return periodToMonth;
      } else if (activeQYSwitcher === "W") {
        const formatDate = data.map((p) =>
          p.date ? { ...p, date: p.date.slice(5, 11) } : p
        );
        return formatDate;
      }
    }
  };

  const mapPeriod = () => {
    if (activeQYSwitcher === "W") {
      return "date";
    } else {
      return "period";
    }
  };

  return (
    <>
      {showTicks() && (
        <GraphTicks targetNode={graphTicksRefLeft.current}>
          {leftTicks}
        </GraphTicks>
      )}
      <BigGraph
        height={graphHeight}
        data={mapData()}
        setRef={setGraphRef}
        onClick={clickChart}
        width={width}
      >
        {!isMobile && (
          <Tooltip
            cursor={false}
            content={
              <TooltipFinance
                currentKey={"current"}
                groupKey={"groupByEntity"}
                display={isTooltipActive}
                activeQYSwitcher={activeQYSwitcher}
                isNumberlessthan1000={true}
              ></TooltipFinance>
            }
          ></Tooltip>
        )}
        <XAxis dataKey={mapPeriod()} />
        <Bar
          dataKey={"current"}
          yAxisId="left"
          onClick={clickBar}
          barSize={20}
          minPointSize={25}
          shape={<BarShapeFinance />}
          onMouseOver={onMouseOverBar}
          onMouseLeave={onMouseLeaveBar}
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill="#002554"
                opacity={isSingle && isSingle !== entry.current ? 0.5 : 1}
              />
            ))}
        </Bar>
        <Line
          dataKey={"forecastTotal"}
          activeDot={true}
          hide={!!isSingle}
          yAxisId={"right"}
          strokeWidth={4}
          stroke="#809E9B"
          strokeDasharray={[3, 3]}
          dot={false}
        />
        <Line
          dataKey={"sumCurrent"}
          activeDot={true}
          yAxisId={"right"}
          hide={!!isSingle}
          strokeWidth={5}
          stroke="#002554"
          dot={false}
          activeDot={{ r: 6 }}
        />
        <Line
          dataKey={"aopTotal"}
          activeDot={true}
          yAxisId={"right"}
          hide={!!isSingle}
          strokeWidth={5}
          stroke="#f3af00"
          dot={false}
          activeDot={{ r: 6 }}
        />
      </BigGraph>
      {showTicks() && activeSection !== "AOI" && !isSingle && (
        <GraphTicks targetNode={graphTicksRefRight.current}>
          {rightTicks}
        </GraphTicks>
      )}
    </>
  );
};

export default FinanceGraphSection;
