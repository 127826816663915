import React from 'react';

export default ({cx, cy, r=8, fill='#65686B', noBorder}) => {
    return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} stroke-width={0.1}>
        <circle stroke-width={noBorder ? 0 : 2} stroke="#fff" fill={fill} cx="10" cy="10" r={r}/>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
    )
}
