export default (data, startWeekOfPeriod, totalIteratios, fillWith, currentDate, activeQYSwitcher, year) => {
  for(let i = 0; i < totalIteratios; i++) {
    const currentPeriod = startWeekOfPeriod + i;
    let currentPeriodObj = data.find(({ period }) => parseInt(period) === currentPeriod );
    if(!currentPeriodObj) {
      currentPeriodObj = {...fillWith, period: currentPeriod };
      data.splice(i, 0, currentPeriodObj)
    }
    else {
      data[i] = { ...currentPeriodObj };
    }

    if (currentDate.year == year && data[i].period >= currentDate.month && activeQYSwitcher == 'Y') data[i].isHalfClosed = true
  }
}
