import React, { useEffect, useState } from 'react';
import { setUserInfo } from '../../actions/userActions';
import { getLatestDataHeader, getLatestEmployeeMetrics, getLatestEmployeeAbsenteesimMetrics, getOshaLatesMetrics, getOshaRatesLatesMetrics, getLatestPlantOpsMetrics, getLatestFillRateMetrics } from '../../services/dataService';
import { receiveDataHeader, setCurrentDate, resetApp } from '../../actions/valuesActions'
import { setQuarter, setYear, setScrollPosition, setActiveQYSwitcher, setActiveSection } from '../../actions/sectionsActions';
import { setLatestMetrcis, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setLatestMetrcisAbsenteesim, setOshaMetrics, setOshaRatesMetrics } from '../../actions/dataActionsTeamMembers';
import { setPlantOpsMetrics, setPlantOpsFiscDate } from '../../actions/dataActionsPlantOps';
import { setFillRateMetrics, setFiscDateFillRate } from '../../actions/dataActionsFillRate';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setShowModal, setShowMenu } from '../../actions/app';
import roundFunction from '../../utils/roundFunction';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import { financeErrorData, fillRateTarget } from '../../constants/app'

import AppLayout from '../../componentsGeneral/AppLayout'
import ItemsContainer from '../../homeScreenComponents/general/ItemsContainer'
import ItemsContainerCollapsible from '../../homeScreenComponents/general/ItemsContainerCollapsible'
import ItemDouble from '../../homeScreenComponents/general/ItemDouble'
import Item from '../../homeScreenComponents/general/Item'
import Collapsible from '../../homeScreenComponents/general/Collapsible'
import Section from '../../homeScreenComponents/general/Section'
import SectionRow from '../../homeScreenComponents/general/SectionRow';

import formatFiscDate from '../../utils/formatFiscDate'
import formatFiscDateLastYear from '../../utils/formatFiscDateLastYear'
import formatNumber from '../../utils/formatNumber'

import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import toClassName from '../../utils/toClassName';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LoadingBlocker from '../../components/LoadingBlocker/LoadingBlocker';

const HomeScreenChicken = (props) => {
    const { height, width } = useWindowDimensions()

    const { authState, oktaAuth } = useOktaAuth()
    const { accessToken: { accessToken } } = authState
    const [isLoading, setIsLoading] = useState(false)

    const poultryMcDonalds = '?segment=Poultry,McDonalds';
    const financePoultryRoute = '/r/poultry/finance';
    const poultryTMRoute = '/r/poultry/team-members?segment=Poultry';
    const mcDTMRoute = '/r/McDonalds/team-members?segment=McDonalds';

    const getDataFinance = async (initial) => {
        const { receiveDataHeader, setQuarter, setCurrentDate, setYear, setScrollPosition } = props;

        let { data, type } = await getLatestDataHeader('', accessToken);

        if (type === 'success') {
            const date = data.date[0];
            data = data.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
        else {
            // setTimeout(async () => {
            //     let { data, type } = await getLatestDataHeader('', accessToken);
            //     if (type === 'success') {
            //         const date = data.date[0];
            //         data = data.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     } else {
            //         const date = financeErrorData.date[0];
            //         data = financeErrorData.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     }
            // }, 1000)
            const date = financeErrorData.date[0];
            data = financeErrorData.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
    }


    const employeeSuccessHandler = ({ data }) => {
        const { setLatestMetrcis } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                exitEmployeesPoultry: employeeInfo['Poultry'].exitEmployees,
                avgActiveEmployeesPoultry: roundFunction(employeeInfo['Poultry'].avgActiveEmployees, 'B'),
                turnoverRatePoultry: employeeInfo['Poultry'].turnoverRate,
                exitEmployeesMcDonalds: employeeInfo['McDonalds'].exitEmployees,
                avgActiveEmployeesMcDonalds: roundFunction(employeeInfo['McDonalds'].avgActiveEmployees, 'B'),
                turnoverRateMcDonalds: employeeInfo['McDonalds'].turnoverRate,
            }
            setLatestMetrcis(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployee = async () => {
        const { setLatestMetrcis } = props;
        const { data, type } = await getLatestEmployeeMetrics({ segment: poultryMcDonalds, accessToken });
        if (type === 'success') {
            employeeSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeMetrics({ segment: poultryMcDonalds, accessToken });
            //     if (type === 'success') {
            //         employeeSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcis('Error');
            //     }
            // }, 1000)
            setLatestMetrcis('Error');
        }
    }

    const employeeAbsenteesimSuccessHandler = ({ data }) => {
        const { setLatestMetrcisAbsenteesim } = props;
        try {
            const { employeeInfo } = data;
            console.log(employeeInfo, ' @@@')
            const metrcis = {
                absenteeismPoultry: employeeInfo['Poultry'].absenteeism,
                absenteeismMcDonalds: employeeInfo['McDonalds'].absenteeism,
            }
            setLatestMetrcisAbsenteesim(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployeeAbsenteesim = async () => {
        const { setLatestMetrcisAbsenteesim } = props;
        const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: poultryMcDonalds, accessToken });
        console.log(type, ' @@@@')
        if (type === 'success') {
            employeeAbsenteesimSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: poultryMcDonalds, accessToken });
            //     if (type === 'success') {
            //         employeeAbsenteesimSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcisAbsenteesim('Error');
            //     }
            // }, 1000)
            setLatestMetrcisAbsenteesim('Error');
        }
    }

    const oshaSuccessHandler = ({ data }) => {
        const { setOshaMetrics } = props;
        try {
            let metrics = {};

            const { poultryMcdCounts } = data;

            poultryMcdCounts.length === 1 ?
                metrics = { "countRecorded": poultryMcdCounts[0].countRecorded ? poultryMcdCounts[0].countRecorded : 0 }
                :
                metrics = {
                    "countRecorded": poultryMcdCounts[0].businessFunction === "Poultry" ? poultryMcdCounts[0].countRecorded : poultryMcdCounts[1].countRecorded,
                    'countRecordedMCD': poultryMcdCounts[1].businessFunction === "McDonalds" ? poultryMcdCounts[1].countRecorded : poultryMcdCounts[0].countRecorded,
                }

            setOshaMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Record. error');
        }
    }

    const getDataOsha = async () => {
        const { setOshaMetrics } = props;
        const { data, type } = await getOshaLatesMetrics({ segment: poultryMcDonalds, accessToken });
        if (type === 'success') {
            oshaSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaLatesMetrics({ segment: poultryMcDonalds, accessToken });
            //     if (type === 'success') {
            //         oshaSuccessHandler({ data });
            //     } else {
            //         setOshaMetrics('Error');
            //     }
            // }, 1000)
            setOshaMetrics('Error');
        }
    }

    const oshaRatesSuccessHandler = ({ data }) => {
        const { setOshaRatesMetrics } = props;
        try {
            let metrics = {};
            const { poultryMcdCounts } = data;
            metrics = {
                "rate": poultryMcdCounts[0].businessFunction === "Poultry" ? (poultryMcdCounts[0].rate) : (poultryMcdCounts[1].rate),
                "ratePY": poultryMcdCounts[0].businessFunction === "Poultry" ? (poultryMcdCounts[0].ratePY) : (poultryMcdCounts[1].ratePY),
                'rateMCD': poultryMcdCounts[1].businessFunction === "McDonalds" ? (poultryMcdCounts[1].rate) : (poultryMcdCounts[0].rate),
                'ratePYMCD': poultryMcdCounts[1].businessFunction === "McDonalds" ? (poultryMcdCounts[1].ratePY) : (poultryMcdCounts[0].ratePY)
            }
            setOshaRatesMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Rates error');
        }
    }

    const getDataOshaRates = async () => {
        const { setOshaRatesMetrics } = props;
        const { data, type } = await getOshaRatesLatesMetrics({ segment: poultryMcDonalds, accessToken });
        if (type === 'success') {
            oshaRatesSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaRatesLatesMetrics({ segment: poultryMcDonalds, accessToken });
            //     if (type === 'success') {
            //         oshaRatesSuccessHandler({ data });
            //     } else {
            //         setOshaRatesMetrics('Error');
            //     }
            // }, 1000)
            setOshaRatesMetrics('Error');
        }
    }

    const plantOpsSuccessHandler = ({ data }) => {
        const { setPlantOpsMetrics } = props;
        try {
            let metrics = {};
            const { Poultry, McDonalds } = data;
            const poultry0 = "CapacityUtilisation Harvesting";
            const poultry1 = "Efficiency Harvesting";
            const poultry2 = "CapacityUtilisation Processing";
            const poultry3 = "Efficiency Processing";
            const CapacityRecorded0 = Poultry[0].CapacityRecorded;
            const CapacityRecorded1 = Poultry[1].CapacityRecorded;
            const CapacityRecorded2 = Poultry[2].CapacityRecorded;
            const CapacityRecorded3 = Poultry[3].CapacityRecorded;
            const CapacityType0 = Poultry[0].CapacityType;
            const CapacityType1 = Poultry[1].CapacityType;
            const CapacityType2 = Poultry[2].CapacityType;
            const CapacityType3 = Poultry[3].CapacityType;

            metrics = {
                "PoultryCUHar": CapacityType0 == poultry0 ? CapacityRecorded0
                    : CapacityType1 == poultry0 ? CapacityRecorded1
                        : CapacityType2 == poultry0 ? CapacityRecorded2
                            : CapacityType3 == poultry0 ? CapacityRecorded3 : 0,
                "PoultryEfficiencyHar": CapacityType0 === poultry1 ? CapacityRecorded0
                    : CapacityType1 === poultry1 ? CapacityRecorded1
                        : CapacityType2 === poultry1 ? CapacityRecorded2
                            : CapacityType3 === poultry1 ? CapacityRecorded3 : 0,
                "PoultryCUPro": CapacityType0 === poultry2 ? CapacityRecorded0
                    : CapacityType1 === poultry2 ? CapacityRecorded1
                        : CapacityType2 === poultry2 ? CapacityRecorded2
                            : CapacityType3 === poultry2 ? CapacityRecorded3 : 0,
                "PoultryEfficiencyPro": CapacityType0 === poultry3 ? CapacityRecorded0
                    : CapacityType1 === poultry3 ? CapacityRecorded1
                        : CapacityType2 === poultry3 ? CapacityRecorded2
                            : CapacityType3 === poultry3 ? CapacityRecorded3 : 0,

                "McdCUPro": McDonalds[0].CapacityType === "CapacityUtilisation Processing" ? (McDonalds[0].CapacityRecorded) : (McDonalds[1].CapacityRecorded),
                "McdEfficiencyPro": McDonalds[1].CapacityType === "Efficiency Processing" ? (McDonalds[1].CapacityRecorded) : (McDonalds[0].CapacityRecorded),

            }
            setPlantOpsMetrics(metrics);
        } catch (e) {
            console.error(e, 'Plant Ops error');
        }
    }

    const getDataPlantOps = async () => {
        const { setPlantOpsMetrics } = props;
        const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
        if (type === 'success') {
            plantOpsSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
            //     if (type === 'success') {
            //         plantOpsSuccessHandler({ data });
            //     } else {
            //         setPlantOpsMetrics('Error');
            //     }
            // }, 1000)
            setPlantOpsMetrics('Error');
        }
    }

    const fillRateSuccessHandler = ({ data }) => {
        const { setFillRateMetrics } = props;
        try {
            const { consumerFillRateInfo } = data;
            let metrcis = {};
            metrcis = {
                fillRate: consumerFillRateInfo.poultryFillRate,
            }
            setFillRateMetrics(metrcis);
        } catch (e) {
            console.error(e, 'NES & Fav Brand error');
        }
    }

    const getDataFillRate = async () => {
        const { setFillRateMetrics } = props;
        const { data, type } = await getLatestFillRateMetrics({ segment: '', accessToken });
        if (type === 'success') {
            fillRateSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestFillRateMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         fillRateSuccessHandler({ data });
            //     } else {
            //         setFillRateMetrics('Error');
            //     }
            // }, 1000)
            setFillRateMetrics('Error');
        }
    }

    const requestData = async (initial) => {
        setIsLoading(true)
        Promise.all([
            getDataFinance(initial),
            getDataEmployee(),
            getDataEmployeeAbsenteesim(),
            getDataOsha(),
            getDataOshaRates(),
            getDataPlantOps(),
            getDataFillRate(),
        ]).then((values) => {
            setIsLoading(false)
        })
            .catch(e => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const { resetApp } = props;
        resetApp();
        requestData();
    }, []);

    useEffect(() => {
        const { setUserInfo } = props;
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null)
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info)
            })
        }
    },
        [
            authState,
            oktaAuth
        ]);

    const getDot = (value) => {
        if (value > 0) return <Circle />
        else if (value == 0) return null
        else return <Diamond />
    }

    const getDotForOshaRates = (value) => {
        if (value < 0) return <Circle />
        else return <Diamond />
    }

    const getDotBasedOnValue = (value) => {
        if (value >= fillRateTarget) return <Circle />
        else return <Diamond />
    }

    const generateDot = (title, value, classes = []) => {
        return <span className={toClassName(['inline-flex items-center leading-0', ...classes])}>
            <span className='text-xs font-medium mr-1 leading-tight'>{title}</span>{getDot(value)}
        </span>
    }

    const {
        metrics,
        absenteesimMetrics,
        plantOpsMetrics,
        fiscDateEmployee,
        fiscDateEmployeeAbsenteesim,
        oshaRatesMetrics,
        oshaMetrics,
        fillRateMetrics,
        oshaRatesFiscDate,
        oshaFiscDate,
        plantOpsFiscDate,
        fiscDateFillRate,
        quarter,
        year,
        header,
        userPrivilages,
        units
    } = props

    const getFinanceHeaderDataChicken = () => {
        const { units } = props;
        if (!units || !Array.isArray(units)) return [];
        const header = units.find(({ entity }) => entity === 'CHICKEN');
        return header || [];
    }

    const history = useHistory()
    const dispatch = useDispatch()

    const switchView = (view) => {
        const links = {
            'CHICKEN': '/poultry',
            'PREPARED FOODS': '/prepared',
            'FRESH MEATS': '/freshMeats',
            'MCDONALDS': '/McDonalds',
            'HROSHA': '/hrosha',
            'OPERATIONS': '/operations',
            'OPERATIONS_POULTRY': '/operations/poultry',
            'OPERATIONS_PREPARED': '/operations/prepared',
            'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
            'OPERATIONS_MCDONALDS': '/operations/McDonalds',
            'IT_USER': '/information-technology',
            'false': '/'
        }

        dispatch({
            type: 'SET_USER_PRIVILAGES',
            payload: {
                userPrivilages: {
                    ...userPrivilages,
                    view
                }
            }
        })

        history.push(links[view.toString()])
    }

    const navigateAction = ({ href, section, tab }) => {
        // resetApp();
        dispatch(setActiveSection(section, tab))

        history.push(href)
    }

    const formatNumberString = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    const formatNumberStringTeamMembers = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    return (
        <ScreenWrapper home landingPage>
            <LoadingBlocker loading={isLoading} />
            <AppLayout keepPaddings>
                <div className='flex gap-x-6 gap-y-3 flex-wrap'>
                    {
                        width > 900 ? <ItemsContainer title='CHICKEN FINANCE'>
                            <Item
                                onClick={() => navigateAction({ href: financePoultryRoute, section: 'OI' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Operating Income'
                                value={getFinanceHeaderDataChicken().ebit === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().ebit) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().ebit, needScale: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', getFinanceHeaderDataChicken().ebit - getFinanceHeaderDataChicken().ebitProj),
                                        generateDot('AOP ', getFinanceHeaderDataChicken().ebit - getFinanceHeaderDataChicken().ebitPlan, ['ml-2'])
                                    ]
                                }
                            />
                            <Item
                                onClick={() => navigateAction({ href: financePoultryRoute, section: 'AOI' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='AOI%'
                                value={getFinanceHeaderDataChicken().aoi === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().aoi) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().aoi, financePercents: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('AOP ', getFinanceHeaderDataChicken().aoi - getFinanceHeaderDataChicken().aoiPlan)
                                    ]
                                }
                            />
                            <Item
                                onClick={() => navigateAction({ href: financePoultryRoute, section: 'NET_SALES' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Net Sales'
                                value={getFinanceHeaderDataChicken().netSales === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().netSales) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().netSales, needScale: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', getFinanceHeaderDataChicken().netSales - getFinanceHeaderDataChicken().netSalesProj),
                                        generateDot('AOP ', getFinanceHeaderDataChicken().netSales - getFinanceHeaderDataChicken().netSalesPlan, ['ml-2'])
                                    ]
                                }
                            />
                            <ItemDouble>
                                <Item
                                    variant='double'
                                    onClick={() => navigateAction({ href: financePoultryRoute, section: 'VOLUME' })}
                                    date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                    title='Volume'
                                    value={getFinanceHeaderDataChicken().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().volume) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().volume, needScale: true, remove$: true, needLBS: true })}
                                    indicators={(year === 0 || quarter === 0) ? '' :
                                        [
                                            generateDot('Proj. ', getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumeProj),
                                            generateDot('AOP ', getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumePlan, ['ml-2'])
                                        ]
                                    }
                                />
                                <div className='w-px bg-gray-400 h-20'></div>
                                <Item
                                    variant='double'
                                    onClick={() => navigateAction({ href: financePoultryRoute, section: 'VOLUME' })}
                                    date={<span className='opacity-0'>0</span>}
                                    title='Growth'
                                    value={getFinanceHeaderDataChicken().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().volume) ? 0 : formatNumberString({ number: (getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumePrevious) / getFinanceHeaderDataChicken().volumePrevious, financePercents: true })}
                                    indicators={(year === 0 || quarter === 0) ? '' :
                                        [
                                            generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumePrevious) / getFinanceHeaderDataChicken().volumePrevious)
                                        ]
                                    }
                                />
                            </ItemDouble>
                        </ItemsContainer>
                            :
                            <ItemsContainerCollapsible
                                title='FINANCE'
                                itemsCollapsible={[
                                    <Item
                                        key={1}
                                        onClick={() => navigateAction({ href: financePoultryRoute, section: 'NET_SALES' })}
                                        date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                        title='Net Sales'
                                        value={getFinanceHeaderDataChicken().netSales === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().netSales) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().netSales, needScale: true })}
                                        indicators={(year === 0 || quarter === 0) ? '' :
                                            [
                                                generateDot('Proj. ', getFinanceHeaderDataChicken().netSales - getFinanceHeaderDataChicken().netSalesProj),
                                                generateDot('AOP ', getFinanceHeaderDataChicken().netSales - getFinanceHeaderDataChicken().netSalesPlan, ['ml-2'])
                                            ]
                                        }
                                    />,
                                    <ItemDouble key={2}>
                                        <Item
                                            variant='double'
                                            onClick={() => navigateAction({ href: financePoultryRoute, section: 'VOLUME' })}
                                            date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                            title='Volume'
                                            value={getFinanceHeaderDataChicken().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().volume) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().volume, needScale: true, remove$: true, needLBS: true })}
                                            indicators={(year === 0 || quarter === 0) ? '' :
                                                [
                                                    generateDot('Proj. ', getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumeProj),
                                                    generateDot('AOP ', getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumePlan, ['ml-2'])
                                                ]
                                            }
                                        />
                                        <div className='w-px bg-gray-400 h-12'></div>
                                        <Item
                                            variant='double'
                                            onClick={() => navigateAction({ href: financePoultryRoute, section: 'VOLUME' })}
                                            date={<span className='opacity-0'>0</span>}
                                            title='Growth'
                                            value={getFinanceHeaderDataChicken().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().volume) ? 0 : formatNumberString({ number: (getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumePrevious) / getFinanceHeaderDataChicken().volumePrevious, financePercents: true })}
                                            indicators={(year === 0 || quarter === 0) ? '' :
                                                [
                                                    generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (getFinanceHeaderDataChicken().volume - getFinanceHeaderDataChicken().volumePrevious) / getFinanceHeaderDataChicken().volumePrevious)
                                                ]
                                            }
                                        />
                                    </ItemDouble>
                                ]}
                            >
                                <Item
                                    onClick={() => navigateAction({ href: financePoultryRoute, section: 'OI' })}
                                    date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                    title='Operating Income'
                                    value={getFinanceHeaderDataChicken().ebit === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().ebit) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().ebit, needScale: true })}
                                    indicators={(year === 0 || quarter === 0) ? '' :
                                        [
                                            generateDot('Proj. ', getFinanceHeaderDataChicken().ebit - getFinanceHeaderDataChicken().ebitProj),
                                            generateDot('AOP ', getFinanceHeaderDataChicken().ebit - getFinanceHeaderDataChicken().ebitPlan, ['ml-2'])
                                        ]
                                    }
                                />
                                <Item
                                    onClick={() => navigateAction({ href: financePoultryRoute, section: 'AOI' })}
                                    date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                    title='AOI%'
                                    value={getFinanceHeaderDataChicken().aoi === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataChicken().aoi) ? 0 : formatNumberString({ number: getFinanceHeaderDataChicken().aoi, financePercents: true })}
                                    indicators={(year === 0 || quarter === 0) ? '' :
                                        [
                                            generateDot('AOP ', getFinanceHeaderDataChicken().aoi - getFinanceHeaderDataChicken().aoiPlan)
                                        ]
                                    }
                                />
                            </ItemsContainerCollapsible>
                    }

                    <ItemsContainer title='CUSTOMER/CONSUMER'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/poultry-fill-rate', section: 'OVERALL_FILL_RATE' })}
                            date={!fiscDateFillRate.year ? 'Error' : formatFiscDate({ quarter: fiscDateFillRate.quarter, year: fiscDateFillRate.year })}
                            title='Fill Rate'
                            value={<>{fillRateMetrics === 'Error' ? 'Error' : isNaN(fillRateMetrics.fillRate * 100) ? 0+'%' : formatNumberStringTeamMembers({ number: fillRateMetrics.fillRate, employeePercents: true })} {fillRateMetrics === 'Error' ? '' : getDotBasedOnValue(fillRateMetrics.fillRate)}</>}
                            />
                    </ItemsContainer>

                    <ItemsContainer title='POULTRY TEAM MEMBERS'>
                        <Item
                            onClick={() => navigateAction({ href: poultryTMRoute, section: 'TEAM_MEMBERS' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ month: fiscDateEmployee.month, year: fiscDateEmployee.year })}
                            title='Team Members'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.avgActiveEmployeesPoultry) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.avgActiveEmployeesPoultry) ? 0 : metrics.avgActiveEmployeesPoultry })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: poultryTMRoute, section: 'TURNOVER_RATE' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ year: fiscDateEmployee.year })}
                            title='Turnover Rate'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.turnoverRatePoultry * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.turnoverRatePoultry * 100) ? 0 : metrics.turnoverRatePoultry * 100, employeePercents: true })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: poultryTMRoute, section: 'ABSENTEEISM' })}
                            date={!fiscDateEmployeeAbsenteesim.year ? 'Error' : formatFiscDate({ year: fiscDateEmployeeAbsenteesim.year })}
                            title='Absenteeism'
                            value={absenteesimMetrics === 'Error' ? 'Error' : isNaN(absenteesimMetrics.absenteeismPoultry * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(absenteesimMetrics.absenteeismPoultry) ? 0 : absenteesimMetrics.absenteeismPoultry, employeePercents: true })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title='POULTRY OSHA'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/poultry/osha?segment=Poultry', section: 'OSHA_RATES' })}
                            date={!oshaRatesFiscDate.year ? 'Error' : formatFiscDate({ year: oshaRatesFiscDate.year }) + ` YTD`}
                            title='Rates'
                            value={<>{oshaRatesMetrics === 'Error' ? 'Error' : isNaN(oshaRatesMetrics.rate) ? 0 : formatNumberString({ number: oshaRatesMetrics.rate })} {oshaRatesMetrics === 'Error' ? '' : getDotForOshaRates(oshaRatesMetrics.rate - oshaRatesMetrics.ratePY)}</>}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/poultry/osha?segment=Poultry', section: 'OSHA_RECORDABLES' })}
                            date={!oshaFiscDate.year ? 'Error' : formatFiscDate({ quarter: oshaFiscDate.quarter, year: oshaFiscDate.year })}
                            title='Recordables'
                            value={oshaMetrics === 'Error' ? 'Error' : isNaN(oshaMetrics.countRecorded) ? 0 : formatNumberString({ number: oshaMetrics.countRecorded })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title="MCDONALD'S OSHA">
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/osha?segment=McDonalds', section: 'OSHA_RATES' })}
                            date={!oshaRatesFiscDate.year ? 'Error' : formatFiscDate({ year: oshaRatesFiscDate.year }) + ` YTD`}
                            title='Rates'
                            value={<>{oshaRatesMetrics === 'Error' ? 'Error' : isNaN(oshaRatesMetrics.rateMCD) ? 0 : formatNumberString({ number: oshaRatesMetrics.rateMCD })} {oshaRatesMetrics === 'Error' ? '' : getDotForOshaRates(oshaRatesMetrics.rateMCD - oshaRatesMetrics.ratePYMCD)}</>}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/McDonalds/osha?segment=McDonalds', section: 'OSHA_RECORDABLES' })}
                            date={!oshaFiscDate.year ? 'Error' : formatFiscDate({ quarter: oshaFiscDate.quarter, year: oshaFiscDate.year })}
                            title='Recordables'
                            value={oshaMetrics === 'Error' ? 'Error' : isNaN(oshaMetrics.countRecordedMCD) ? 0 : formatNumberString({ number: oshaMetrics.countRecordedMCD })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title="MCDONALD'S TEAM MEMBERS">
                        <Item
                            onClick={() => navigateAction({ href: mcDTMRoute, section: 'TEAM_MEMBERS' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ month: fiscDateEmployee.month, year: fiscDateEmployee.year })}
                            title='Team Members'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.avgActiveEmployeesMcDonalds) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.avgActiveEmployeesMcDonalds) ? 0 : metrics.avgActiveEmployeesMcDonalds })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: mcDTMRoute, section: 'TURNOVER_RATE' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ year: fiscDateEmployee.year })}
                            title='Turnover Rate'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.turnoverRateMcDonalds * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.turnoverRateMcDonalds * 100) ? 0 : metrics.turnoverRateMcDonalds * 100, employeePercents: true })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: mcDTMRoute, section: 'ABSENTEEISM' })}
                            date={!fiscDateEmployeeAbsenteesim.year ? 'Error' : formatFiscDate({ year: fiscDateEmployeeAbsenteesim.year })}
                            title='Absenteeism'
                            value={absenteesimMetrics === 'Error' ? 'Error' : isNaN(absenteesimMetrics.absenteeismMcDonalds * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(absenteesimMetrics.absenteeismMcDonalds) ? 0 : absenteesimMetrics.absenteeismMcDonalds, employeePercents: true })}
                        />
                    </ItemsContainer>

                </div>
                <Section title='OPERATIONS'>
                    <SectionRow>
                        <Collapsible
                            title='POULTRY'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/poultry/plant-ops', section: 'Poultry', tab: 'CapacityUtilisation Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryCUHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryCUHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/poultry/plant-ops', section: 'Poultry', tab: 'Efficiency Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryEfficiencyHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryEfficiencyHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            {/* <Item
                                onClick={() => navigateAction({ href: '/r/poultry/plant-ops', section: 'Poultry', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/poultry/plant-ops', section: 'Poultry', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PoultryEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PoultryEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            /> */}
                        </Collapsible>

                    </SectionRow>

                    {/* <SectionRow>
                        <Collapsible
                            title='MCDONALDS'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                        >

                            <Item
                                onClick={() => navigateAction({ href: '/r/poultry/plant-ops', section: 'McDonalds', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.McdCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.McdCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/poultry/plant-ops', section: 'McDonalds', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.McdEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.McdEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />
                        </Collapsible>

                    </SectionRow> */}

                </Section>
            </AppLayout>
        </ScreenWrapper>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.userState.userInfo,
        activeQYSwitcher: state.sections.activeQYSwitcher,
        activeSection: state.sections.activeSection,
        currentDate: state.values.currentDate,
        isSingle: state.sections.isSingle,
        header: state.values.header,
        year: state.sections.year,
        quarter: state.sections.quarter,
        activeTab: state.sections.activeTab,
        activeBu: state.sections.activeBu,
        currentQuarter: state.sections.currentQuarter,
        isHeaderLoading: state.values.isHeaderLoading,
        showModal: state.app.showModal,

        userPrivilages: state.userState.userPrivilages,
        metrics: state.values.metrics,
        absenteesimMetrics: state.values.absenteesimMetrics,
        fiscDateEmployee: state.sections.fiscDateEmployee,
        fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
        oshaFiscDate: state.sections.oshaFiscDate,
        oshaMetrics: state.values.oshaMetrics,
        oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
        oshaRatesMetrics: state.values.oshaRatesMetrics,
        plantOpsFiscDate: state.sections.plantOpsFiscDate,
        plantOpsMetrics: state.values.plantOpsMetrics,
        fillRateMetrics: state.values.fillRateMetrics,
        fiscDateFillRate: state.sections.fiscDateFillRate,
        units: state.values.units
    }
}
export default connect(
    mapStateToProps,
    {
        setLatestMetrcis, setLatestMetrcisAbsenteesim, resetApp, setFiscDateEmployee,
        setOshaMetrics, setOshaRatesMetrics, setFiscDateEmployeeAbsenteesim, setFillRateMetrics,
        setUserInfo, setShowModal, setShowMenu, setPlantOpsMetrics,
        receiveDataHeader, setCurrentDate, setQuarter, setYear, setScrollPosition, setActiveQYSwitcher
    }
)(HomeScreenChicken)
