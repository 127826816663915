

export default ({ year, quartal, month, week, segment, unit, type, isCore }) => {
    year = year ? `fiscYear=${year}` : '';
    quartal = quartal ? `&fiscQtr=${quartal}` : '';
    month = month ? `&fiscMonth=${month}` : '';
    week = week ? `&fiscWeek=${week}` : '';
    segment = segment ? `&businessSeg=${segment}` : '';
    unit = unit ? `&businessFamily=${unit.split('&').join('%26')}` : '';
    type = type ? `&includeGraphData=${type}` : '';
    isCore = isCore ? `&isCore=1` : '';
    return year + quartal + month + week + segment + unit + type + isCore;
}
