import axios from "./api";
import { Event } from "../components/Tracking";

const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_URL_FIN = process.env.REACT_APP_API_URL;
const BASE_URL_TEAMMEMBERS = process.env.REACT_APP_API_URL;
const BASE_URL_OSHA = process.env.REACT_APP_API_URL;
const BASE_URL_PLANT_OPS = process.env.REACT_APP_API_URL;
const BASE_URL_NES_FAV_BRAND = process.env.REACT_APP_API_URL;
// const BASE_URL_FILL_RATE = "http://localhost:3002";
const BASE_URL_FILL_RATE = process.env.REACT_APP_API_URL;

//const BASE_URL_IT_DASHBOARD = "http://localhost:4000";
const BASE_URL_IT_DASHBOARD = process.env.REACT_APP_API_URL;

const APPLICATION_JSON = "application/json";
const API_EXCEPTION = "API Exception";

// Determines what access user has
export const getUserPermissions = async ({ accessToken }) => {
  const url = `${BASE_URL}/finance/user-permissions`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

/* IT DASHBOARD ROUTES STARTS */

// IT Dashboard Finance routes
export const getFinanceCloud = async ({ accessToken, year, qtr, date }) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/cloud`;
  const body = {
    filters: [{ fiscYear: year }, { fiscQtr: qtr }, { date: date }],
  };
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/plain",
      },
      data: body,
    });
    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceSalaryAndBenefits = async ({
  accessToken,
  year,
  qtr,
  tab,
}) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/salary-benefits`;
  const body = {
    filters: [{ fiscYear: year }, { fiscQtr: qtr }, { toggle: tab }],
  };
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/plain",
      },
      data: body,
    });
    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceControllables = async ({
  accessToken,
  year,
  qtr,
  tab,
}) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/controllables`;
  const body = {
    filters: [{ fiscYear: year }, { fiscQtr: qtr }, { toggle: tab }],
  };
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/plain",
      },
      data: body,
    });
    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceNonControllables = async ({
  accessToken,
  year,
  qtr,
  tab,
}) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/non-controllables`;
  const body = {
    filters: [{ fiscYear: year, fiscQtr: qtr, toggle: tab }],
  };
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/plain",
      },
      data: body,
    });
    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getHrAttritionData = async ({ filters, viewBy }, accessToken) => {
  const url = `${BASE_URL_IT_DASHBOARD}/hrmetrics/attrition`;
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
      data: {
        viewBy,
        needDetails: true,
        filters,
      },
    });

    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Hr-Metrics routes
export const getVacanciesData = async (filters,accessToken) => {
  const url = `${BASE_URL_IT_DASHBOARD}/hrmetrics/v2/vacancies`;

  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: filters
        
      ,
    });

    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Hr-Metrics routes
export const getAvgDaysHire = async (filters,accessToken) => {
  const url = `${BASE_URL_IT_DASHBOARD}/hrmetrics/v2/avg-days-hire`;
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: filters
    });
    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};


export const getJbLatestMetrics = async (filters,accessToken) => {
  const url = `${BASE_URL_IT_DASHBOARD}/hrmetrics/v2/latest-metrics`;
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: filters
      ,
    });

    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceActualsData = async ({
  accessToken,
  year,
  qtr,
  tab,
}) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/actuals`;

  const body = {
    filters: [{ fiscYear: year, fiscQtr: qtr, toggle: tab }],
  };
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/plain",
      },
      data: body,
    });
    return { type: "success", data: data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceFiscDateInfo = async ({ accessToken }) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/fisc-date-info`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceCloudDateInfo = async ({ accessToken }) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/cloud-fisc-date-info`;
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// IT Dashboard Finance routes
export const getFinanceLatestMetrics = async ({ accessToken }) => {
  const url = `${BASE_URL_IT_DASHBOARD}/finance/v2/latest-metrics`;
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

export const getTeamData = async ({ accessToken }) => {
  const url = `http://localhost:8000/data`;
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

/* IT DASHBOARD ROUTES ENDS */

// ***********************************************************************************

/* MEATBALL ROUTES STARTS */

// meatball Fill rate routes
export const getFillRateFiscDate = async ({ accessToken }) => {
  const url = `${BASE_URL_FILL_RATE}/consumer/fillrate-latest-date-info`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error-fill-rate-fisc-date", data: err.message };
  }
};

// meatball Fill rate routes
export const getLatestFillRateMetrics = async ({ accessToken }) => {
  const url = `${BASE_URL_FILL_RATE}/consumer/fillrate-latest-metrics`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error-fill-rate-landing", data: err.message };
  }
};

// meatball Fill rate routes
export const getFillRateData = async (
  { viewBy, filters, fillRateType },
  accessToken
) => {
  const url = `${BASE_URL_FILL_RATE}/consumer/fillrate`;

  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
      data: {
        viewBy,
        needDetails: true,
        fillRateType,
        filters,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error-fill-rate-post", data: err.message };
  }
};

// meatball Net Exp. Score, and Fav. brand routes
export const getLatestNesFavBrandMetrics = async ({ accessToken }) => {
  const url = `${BASE_URL_NES_FAV_BRAND}/consumer/nes-latest-metrics`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball Net Exp. Score, and Fav. brand routes
export const getNesFavBrandFiscDate = async ({ accessToken }) => {
  const url = `${BASE_URL_NES_FAV_BRAND}/consumer/nes-fisc-date-info`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball Net Exp. Score, and Fav. brand routes
export const getNesFavBrandData = async ({ filters }, accessToken) => {
  const url = `${BASE_URL_NES_FAV_BRAND}/consumer/nes-fav-brand`;

  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
      data: {
        filters,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball plantOps routes
export const getPlantOpsFiscDate = async ({ accessToken }) => {
  const url = `${BASE_URL_PLANT_OPS}/plant-ops/fisc-date-info`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball plantOps routes
export const getLatestPlantOpsMetrics = async ({ accessToken }) => {
  const url = `${BASE_URL_PLANT_OPS}/plant-ops/latest-metrics`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball plantOps routes
export const getPlantOpsData = async (
  { viewBy, capacityType, lastHeirarchyTrendColumn, filters },
  accessToken
) => {
  const url = `${BASE_URL_PLANT_OPS}/plant-ops`;

  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
      data: {
        viewBy,
        capacityType,
        needDetails: true,
        lastHeirarchyTrendColumn,
        filters,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball osha routes
export const getOshaLatesMetrics = async ({
  segment = `?segment=Poultry,McDonalds`,
  accessToken,
}) => {
  const url = `${BASE_URL_OSHA}/osha/osha-latest-metrics${segment}`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball osha routes
export const getOshaFiscDate = async ({ accessToken }) => {
  const url = `${BASE_URL_OSHA}/osha/osha-fisc-date-info`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball osha routes
export const getOshaData = async (
  { filters, lastHeirarchyTrendColumn },
  accessToken,
  appendLink = ""
) => {
  const url = `${BASE_URL_OSHA}/osha${appendLink}`;
  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
      data: {
        lastHeirarchyTrendColumn,
        filters,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball osha routes
export const getOshaRatesLatesMetrics = async ({
  segment = `?segment=Poultry,McDonalds`,
  accessToken,
}) => {
  const url = `${BASE_URL_OSHA}/osha/rates-latest-metrics${segment}`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball osha routes
export const getOshaRatesFiscDate = async ({ accessToken }) => {
  const url = `${BASE_URL_OSHA}/osha/rates-fisc-date-info`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball team member routes
export const getLatestEmployeeDate = async ({ accessToken }) => {
  const url = `${BASE_URL_TEAMMEMBERS}/hrmetrics/fisc-date-info`;
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball team member routes
export const getLatestEmployeeMetrics = async ({
  segment = `?segment=Poultry,McDonalds`,
  accessToken,
}) => {
  const url = `${BASE_URL_TEAMMEMBERS}/hrmetrics/latest-metrics${segment}`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball team member routes
export const getLatestEmployeeAbsenteesimMetrics = async ({
  segment = `?segment=Poultry,McDonalds`,
  accessToken,
}) => {
  //const url = `${BASE_URL}/hrmetrics/latest-metrics${segment}`;
  const url = `${BASE_URL_TEAMMEMBERS}/hrmetrics/absenteeism-latest-metrics${segment}`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball team member routes
export const getLatestEmployeeAbsenteesimDate = async ({ accessToken }) => {
  //const url = `${BASE_URL}/hrmetrics/fisc-date-info`;
  const url = `${BASE_URL_TEAMMEMBERS}/hrmetrics/absenteeism-fisc-date-info`;
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball team member routes
export const getTeamMembersData = async (
  { section, viewBy, lastHeirarchyTrendColumn, filters },
  accessToken
) => {
  const url = `${BASE_URL_TEAMMEMBERS}/hrmetrics/` + section;

  try {
    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": APPLICATION_JSON,
      },
      data: {
        viewBy,
        needDetails: true,
        lastHeirarchyTrendColumn,
        filters,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball finance routes
// meatball finance routes
export const getLatestDataHeader = async (
  url = `isYearlyView=0`,
  accessToken
) => {
  url = `${BASE_URL_FIN}/latest-header-data?` + url;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball finance routes
export const publicSynchronize = async (
  url = `fiscYear=2020&businessSeg=PORK`,
  accessToken
) => {
  url = `${BASE_URL_FIN}/rsummary?` + url;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};

// meatball finance routes
export const publicSynchronizerevenue = async (accessToken, isCore) => {
  const url = `${BASE_URL_FIN}/revenue-trend?businessSeg=CHICKEN,BEEF,PORK,PREPARED FOODS,INTERSEGMENT,INTERNATIONAL${
    isCore ? "&isCore=1" : ""
  }`;

  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return { type: "success", data };
  } catch (err) {
    console.error("error:!!!", err);
    Event(API_EXCEPTION, url, err);
    return { type: "error", data: err.message };
  }
};
const dataService = {
  publicSynchronize,
};

export default dataService;
