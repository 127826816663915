import * as React from "react"

const SvgComponent = ({ size=36, color='#2699FB', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props}>
    <path data-name="Rectangle 19699" fill="none" d="M0 0h36v36H0z" />
    <path
      data-name="Union 7"
      d="M16.846 29.242c-1.166-.824-2.225-1.785-3.318-2.7-.717-.6-1.381-1.221-2.08-1.832a8.117 8.117 0 0 0-5.094-2.039 24.833 24.833 0 0 1-2.691-.225 4.3 4.3 0 0 1-3.641-3.384A1.961 1.961 0 0 1 0 18.741c0-1.475.055-2.95 0-4.42a5.742 5.742 0 0 1 1.242-3.774 14.749 14.749 0 0 1 5.541-4.362 21.167 21.167 0 0 1 6.51-1.854 33.823 33.823 0 0 1 8.233-.148 17.079 17.079 0 0 1 11.693 5.766 10.262 10.262 0 0 1 2.619 5.462c.162 1.238.125 2.5.162 3.455-.055 1.375-.037 2.448-.145 3.5a8.77 8.77 0 0 1-2.312 4.783 12.636 12.636 0 0 1-9.381 4.4h-.25a11.954 11.954 0 0 1-7.066-2.307Zm-2.1-23.959a22.034 22.034 0 0 0-5.99 1.263 15.225 15.225 0 0 0-6.205 4.16 6.353 6.353 0 0 0-1.309 2.365A3.536 3.536 0 0 0 3.9 17.492c.879.094 1.758.231 2.637.272A8.557 8.557 0 0 1 12 19.875c.324.266.646.526.934.8 1.166 1 2.242 2.057 3.443 3a11.619 11.619 0 0 0 15.369-.451c3.713-3.43 4.107-8.058 1.023-12.051A14.857 14.857 0 0 0 25.9 6.262a22.458 22.458 0 0 0-7.211-1.185c-1.306.065-2.632.082-3.943.206Zm3.66 17.683a34.05 34.05 0 0 1-2.8-2.2c-.879-.746-1.723-1.547-2.619-2.312a10.211 10.211 0 0 0-6.367-2.536 24.2 24.2 0 0 1-2.436-.218 1.922 1.922 0 0 1-1.418-.9 1.939 1.939 0 0 1-.125-1.665 5.574 5.574 0 0 1 1.65-2.366 15.3 15.3 0 0 1 7.424-3.715 33.276 33.276 0 0 1 6.4-.652 22.1 22.1 0 0 1 7.555 1.164 12.967 12.967 0 0 1 6.53 5.125 6.445 6.445 0 0 1 .8 6.092 8.5 8.5 0 0 1-1.291 2.181 9.98 9.98 0 0 1-6.4 3.608 9.4 9.4 0 0 1-1.418.105 9.67 9.67 0 0 1-5.484-1.711Zm3.694-6.654a1.756 1.756 0 0 0 .072 2.661 3.932 3.932 0 0 0 4.77.042 1.764 1.764 0 0 0-.035-2.892 3.538 3.538 0 0 0-2.152-.742c-.072 0-.143-.005-.215-.005a3.62 3.62 0 0 0-2.44.936Z"
      className="fill-current"
    />
  </svg>
)

export default SvgComponent
