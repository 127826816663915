import React from 'react'

import BarShapeRegular from '../componentsGeneral/section/BarShapeRegular'
import BarShapeStrapped from '../componentsGeneral/section/BarShapeStrapped'

const BarShapeFinance = (props) => {
  const {
    isHalfClosed
  } = props

  if (isHalfClosed) return <BarShapeStrapped {...props} />
  else return <BarShapeRegular {...props} />
}

export default BarShapeFinance