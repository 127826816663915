import React, { useEffect, useState } from 'react';
import { setUserInfo } from '../../actions/userActions';
import { getLatestDataHeader, getLatestEmployeeMetrics, getLatestEmployeeAbsenteesimMetrics, getOshaLatesMetrics, getOshaRatesLatesMetrics, getLatestPlantOpsMetrics, getLatestFillRateMetrics } from '../../services/dataService';
import { receiveDataHeader, setCurrentDate, resetApp } from '../../actions/valuesActions'
import { setQuarter, setYear, setScrollPosition, setActiveQYSwitcher, setActiveSection } from '../../actions/sectionsActions';
import { setLatestMetrcis, setLatestMetrcisAbsenteesim, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setOshaMetrics, setOshaRatesMetrics } from '../../actions/dataActionsTeamMembers';
import { setPlantOpsMetrics, setPlantOpsFiscDate } from '../../actions/dataActionsPlantOps';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setShowModal, setShowMenu } from '../../actions/app';
import roundFunction from '../../utils/roundFunction';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper'
import { financeErrorData, fillRateTarget } from '../../constants/app'
import AppLayout from '../../componentsGeneral/AppLayout'
import ItemsContainer from '../../homeScreenComponents/general/ItemsContainer'
import ItemsContainerCollapsible from '../../homeScreenComponents/general/ItemsContainerCollapsible'
import ItemDouble from '../../homeScreenComponents/general/ItemDouble'
import Item from '../../homeScreenComponents/general/Item'
import Collapsible from '../../homeScreenComponents/general/Collapsible'
import Section from '../../homeScreenComponents/general/Section'
import SectionRow from '../../homeScreenComponents/general/SectionRow';

import formatFiscDate from '../../utils/formatFiscDate'
import formatFiscDateLastYear from '../../utils/formatFiscDateLastYear'
import formatNumber from '../../utils/formatNumber'

import Circle from '../../images/Circle'
import Diamond from '../../images/Diamond'
import toClassName from '../../utils/toClassName';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LoadingBlocker from '../../components/LoadingBlocker/LoadingBlocker';

const HomeOperationsFreshMeats = (props) => {
    const { height, width } = useWindowDimensions()

    const { authState, oktaAuth } = useOktaAuth()
    const { accessToken: { accessToken } } = authState
    const [isLoading, setIsLoading] = useState(false)

    const getDataFinance = async (initial) => {
        const { receiveDataHeader, setQuarter, setCurrentDate, setYear, setScrollPosition } = props;

        let { data, type } = await getLatestDataHeader('', accessToken);

        if (type === 'success') {
            const date = data.date[0];
            data = data.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
        else {
            // setTimeout(async () => {
            //     let { data, type } = await getLatestDataHeader('', accessToken);
            //     if (type === 'success') {
            //         const date = data.date[0];
            //         data = data.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     } else {
            //         const date = financeErrorData.date[0];
            //         data = financeErrorData.data;
            //         setQuarter(parseInt(date.qtr));
            //         setYear(parseInt(date.year));
            //         setScrollPosition(undefined);
            //         receiveDataHeader(data);
            //         setCurrentDate(date);
            //     }
            // }, 1000)
            const date = financeErrorData.date[0];
            data = financeErrorData.data;
            setQuarter(parseInt(date.qtr));
            setYear(parseInt(date.year));
            setScrollPosition(undefined);
            receiveDataHeader(data);
            setCurrentDate(date);
        }
    }


    const employeeSuccessHandler = ({ data }) => {
        const { setLatestMetrcis } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                exitEmployeesFreshMeats: employeeInfo['Fresh Meats'].exitEmployees,
                avgActiveEmployeesFreshMeats: roundFunction(employeeInfo['Fresh Meats'].avgActiveEmployees, 'B'),
                turnoverRateFreshMeats: employeeInfo['Fresh Meats'].turnoverRate,
            }
            setLatestMetrcis(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployee = async () => {
        const { setLatestMetrcis } = props;
        const { data, type } = await getLatestEmployeeMetrics({ segment: '?segment=Fresh Meats', accessToken });
        if (type === 'success') {
            employeeSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeMetrics({ segment: '?segment=Fresh Meats', accessToken });
            //     if (type === 'success') {
            //         employeeSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcis('Error');
            //     }
            // }, 1000)
            setLatestMetrcis('Error');
        }
    }

    const employeeAbsenteesimSuccessHandler = ({ data }) => {
        const { setLatestMetrcisAbsenteesim } = props;
        try {
            const { employeeInfo } = data;
            let metrcis = {};
            metrcis = {
                absenteesimFreshMeats: employeeInfo['Fresh Meats'].absenteeism,
            }
            setLatestMetrcisAbsenteesim(metrcis);
        } catch (e) {
            console.error(e, 'Team members error');
        }
    }

    const getDataEmployeeAbsenteesim = async () => {
        const { setLatestMetrcisAbsenteesim } = props;
        const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '?segment=Fresh Meats', accessToken });
        if (type === 'success') {
            employeeAbsenteesimSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestEmployeeAbsenteesimMetrics({ segment: '?segment=Fresh Meats', accessToken });
            //     if (type === 'success') {
            //         employeeAbsenteesimSuccessHandler({ data });
            //     } else {
            //         setLatestMetrcisAbsenteesim('Error');
            //     }
            // }, 1000)
            setLatestMetrcisAbsenteesim('Error');
        }
    }

    const oshaSuccessHandler = ({ data }) => {
        const { setOshaMetrics } = props;
        try {
            let metrics = {};

            const { poultryMcdCounts } = data;
            metrics = {
                "countRecorded": poultryMcdCounts[0].countRecorded ? poultryMcdCounts[0].countRecorded : 0,
            }
            setOshaMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Record. error');
        }
    }

    const getDataOsha = async () => {
        const { setOshaMetrics } = props;
        const { data, type } = await getOshaLatesMetrics({ segment: '?segment=Fresh Meats', accessToken });
        if (type === 'success') {
            oshaSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaLatesMetrics({ segment: '?segment=Fresh Meats', accessToken });
            //     if (type === 'success') {
            //         oshaSuccessHandler({ data });
            //     } else {
            //         setOshaMetrics('Error');
            //     }
            // }, 1000)
            setOshaMetrics('Error');
        }
    }

    const oshaRatesSuccessHandler = ({ data }) => {
        const { setOshaRatesMetrics } = props;
        try {
            let metrics = {};
            const { poultryMcdCounts } = data;
            metrics = {
                "rate": poultryMcdCounts[0].businessFunction === "Fresh Meats" ? (poultryMcdCounts[0].rate) : 0,
                "ratePY": poultryMcdCounts[0].businessFunction === "Fresh Meats" ? (poultryMcdCounts[0].ratePY) : 0,
            }
            setOshaRatesMetrics(metrics);
        } catch (e) {
            console.error(e, 'osha Rates error');
        }
    }

    const getDataOshaRates = async () => {
        const { setOshaRatesMetrics } = props;
        const { data, type } = await getOshaRatesLatesMetrics({ segment: '?segment=Fresh Meats', accessToken });
        if (type === 'success') {
            oshaRatesSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getOshaRatesLatesMetrics({ segment: '?segment=Fresh Meats', accessToken });
            //     if (type === 'success') {
            //         oshaRatesSuccessHandler({ data });
            //     } else {
            //         setOshaRatesMetrics('Error');
            //     }
            // }, 1000)
            setOshaRatesMetrics('Error');
        }
    }

    const plantOpsSuccessHandler = ({ data }) => {
        const { setPlantOpsMetrics } = props;
        try {
            let metrics = {};
            const { Beef, Pork, PPI } = data;

            const pork0 = "CapacityUtilisation Harvesting";
            const pork1 = "Efficiency Harvesting";
            const pork2 = "CapacityUtilisation Processing";
            const pork3 = "Efficiency Processing";
            const CapacityRecordedPork0 = Pork[0].CapacityRecorded;
            const CapacityRecordedPork1 = Pork[1].CapacityRecorded;
            const CapacityRecordedPork2 = Pork[2].CapacityRecorded;
            const CapacityRecordedPork3 = Pork[3].CapacityRecorded;
            const CapacityTypePork0 = Pork[0].CapacityType;
            const CapacityTypePork1 = Pork[1].CapacityType;
            const CapacityTypePork2 = Pork[2].CapacityType;
            const CapacityTypePork3 = Pork[3].CapacityType;

            metrics = {
                "BeefCUHar": Beef[0].CapacityType === "CapacityUtilisation Harvesting" ? (Beef[0].CapacityRecorded) : (Beef[1].CapacityRecorded),
                "BeefEfficiencyHar": Beef[1].CapacityType === "Efficiency Harvesting" ? (Beef[1].CapacityRecorded) : (Beef[0].CapacityRecorded),

                "PorkCUHar": CapacityTypePork0 == pork0 ? CapacityRecordedPork0
                    : CapacityTypePork1 == pork0 ? CapacityRecordedPork1
                        : CapacityTypePork2 == pork0 ? CapacityRecordedPork2
                            : CapacityTypePork3 == pork0 ? CapacityRecordedPork3 : 0,
                "PorkEfficiencyHar": CapacityTypePork0 === pork1 ? CapacityRecordedPork0
                    : CapacityTypePork1 === pork1 ? CapacityRecordedPork1
                        : CapacityTypePork2 === pork1 ? CapacityRecordedPork2
                            : CapacityTypePork3 === pork1 ? CapacityRecordedPork3 : 0,
                "PorkCUPro": CapacityTypePork0 === pork2 ? CapacityRecordedPork0
                    : CapacityTypePork1 === pork2 ? CapacityRecordedPork1
                        : CapacityTypePork2 === pork2 ? CapacityRecordedPork2
                            : CapacityTypePork3 === pork2 ? CapacityRecordedPork3 : 0,
                "PorkEfficiencyPro": CapacityTypePork0 === pork3 ? CapacityRecordedPork0
                    : CapacityTypePork1 === pork3 ? CapacityRecordedPork1
                        : CapacityTypePork2 === pork3 ? CapacityRecordedPork2
                            : CapacityTypePork3 === pork3 ? CapacityRecordedPork3 : 0,

                "PPICUPro": PPI[0].CapacityType === "CapacityUtilisation Processing" ? (PPI[0].CapacityRecorded) : (PPI[1].CapacityRecorded),
                "PPIEfficiencyPro": PPI[1].CapacityType === "Efficiency Processing" ? (PPI[1].CapacityRecorded) : (PPI[0].CapacityRecorded),

            }
            setPlantOpsMetrics(metrics);
        } catch (e) {
            console.error(e, 'Plant Ops error');
        }
    }

    const getDataPlantOps = async () => {
        const { setPlantOpsMetrics } = props;
        const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
        if (type === 'success') {
            plantOpsSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestPlantOpsMetrics({ accessToken });
            //     if (type === 'success') {
            //         plantOpsSuccessHandler({ data });
            //     } else {
            //         setPlantOpsMetrics('Error');
            //     }
            // }, 1000)
            setPlantOpsMetrics('Error');
        }
    }

    const fillRateSuccessHandler = ({ data }) => {
        const { setFillRateMetrics } = props;
        try {
            const { consumerFillRateInfo } = data;
            let metrcis = {};
            metrcis = {
                fillRate: consumerFillRateInfo.freshMeatsFillRate ? consumerFillRateInfo.freshMeatsFillRate : 0,
            }
            setFillRateMetrics(metrcis);
        } catch (e) {
            console.error(e, 'NES & Fav Brand error');
        }
    }

    const getDataFillRate = async () => {
        const { setFillRateMetrics } = props;
        const { data, type } = await getLatestFillRateMetrics({ segment: '', accessToken });
        if (type === 'success') {
            fillRateSuccessHandler({ data });
        }
        else {
            // setTimeout(async () => {
            //     const { data, type } = await getLatestFillRateMetrics({ segment: '', accessToken });
            //     if (type === 'success') {
            //         fillRateSuccessHandler({ data });
            //     } else {
            //         setFillRateMetrics('Error');
            //     }
            // }, 1000)
            setFillRateMetrics('Error');
        }
    }

    const requestData = async (initial) => {
        setIsLoading(true)
        Promise.all([
            getDataFinance(initial),
            getDataEmployee(),
            getDataEmployeeAbsenteesim(),
            getDataOsha(),
            getDataOshaRates(),
            getDataPlantOps(),
            getDataFillRate(),
        ]).then((values) => {
            setIsLoading(false)
        })
            .catch(e => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const { resetApp } = props;
        resetApp();
        requestData();
    }, []);

    useEffect(() => {
        const { setUserInfo } = props;
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null)
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info)
            })
        }
    },
        [
            authState,
            oktaAuth
        ]);

    const getDot = (value) => {
        if (value > 0) return <Circle />
        else if (value == 0) return null
        else return <Diamond />
    }

    const getDotForOshaRates = (value) => {
        if (value < 0) return <Circle />
        else return <Diamond />
    }

    const getDotBasedOnValue = (value) => {
        if (value >= fillRateTarget) return <Circle />
        else return <Diamond />
    }

    const generateDot = (title, value, classes = []) => {
        return <span className={toClassName(['inline-flex items-center leading-0', ...classes])}>
            <span className='text-xs font-medium mr-1 leading-tight'>{title}</span>{getDot(value)}
        </span>
    }

    const {
        metrics,
        absenteesimMetrics,
        plantOpsMetrics,
        fiscDateEmployee,
        fiscDateEmployeeAbsenteesim,
        oshaRatesMetrics,
        oshaMetrics,
        fillRateMetrics,
        oshaRatesFiscDate,
        oshaFiscDate,
        plantOpsFiscDate,
        fiscDateFillRate,
        quarter,
        year,
        header,
        userPrivilages,
        units
    } = props

    const getFinanceHeaderDataBeef = () => {
        const { units } = props;
        if (!units || !Array.isArray(units)) return [];
        const header = units.find(({ entity }) => entity === 'BEEF');
        return header || [];
    }

    const getFinanceHeaderDataPork = () => {
        const { units } = props;
        if (!units || !Array.isArray(units)) return [];
        const header = units.find(({ entity }) => entity === 'PORK');
        return header || [];
    }

    const getFinanceHeaderDataIntersegment = () => {
        const { units } = props;
        if (!units || !Array.isArray(units)) return [];
        const header = units.find(({ entity }) => entity === 'INTERSEGMENT');
        return header || [];
    }

    const history = useHistory()
    const dispatch = useDispatch()

    const switchView = (view) => {
        const links = {
            'CHICKEN': '/poultry',
            'PREPARED FOODS': '/prepared',
            'FRESH MEATS': '/freshMeats',
            'MCDONALDS': '/McDonalds',
            'HROSHA': '/hrosha',
            'OPERATIONS': '/operations',
            'OPERATIONS_POULTRY': '/operations/poultry',
            'OPERATIONS_PREPARED': '/operations/prepared',
            'OPERATIONS_FRESH_MEATS': '/operations/freshMeats',
            'OPERATIONS_MCDONALDS': '/operations/McDonalds',
            'IT_USER': '/information-technology',
            'false': '/'
        }

        dispatch({
            type: 'SET_USER_PRIVILAGES',
            payload: {
                userPrivilages: {
                    ...userPrivilages,
                    view
                }
            }
        })

        history.push(links[view.toString()])
    }

    const navigateAction = ({ href, section, tab }) => {
        // resetApp();
        dispatch(setActiveSection(section, tab))

        history.push(href)
    }

    const formatNumberString = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    const formatNumberStringTeamMembers = (args) => {
        const {
            prependStr,
            number,
            appendStr
        } = formatNumber(args)

        return `${prependStr}${number}${appendStr}`
    }

    return (
        <ScreenWrapper home landingPage>
            <LoadingBlocker loading={isLoading} />
            <AppLayout keepPaddings>
                <div className='flex gap-x-6 gap-y-3 flex-wrap'>
                    <ItemsContainer title='BEEF FINANCE'>
                        <ItemDouble>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/freshMeatsBeef/finance', section: 'VOLUME' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Volume'
                                value={getFinanceHeaderDataBeef().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataBeef().volume) ? 0 : formatNumberString({ number: getFinanceHeaderDataBeef().volume, needScale: true, remove$: true, needLBS: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', getFinanceHeaderDataBeef().volume - getFinanceHeaderDataBeef().volumeProj),
                                        generateDot('AOP ', getFinanceHeaderDataBeef().volume - getFinanceHeaderDataBeef().volumePlan, ['ml-2'])
                                    ]
                                }
                            />
                            <div className='w-px bg-gray-400 h-20'></div>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/freshMeatsBeef/finance', section: 'VOLUME' })}
                                date={<span className='opacity-0'>0</span>}
                                title='Growth'
                                value={getFinanceHeaderDataBeef().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataBeef().volume) ? 0 : formatNumberString({ number: (getFinanceHeaderDataBeef().volume - getFinanceHeaderDataBeef().volumePrevious) / getFinanceHeaderDataBeef().volumePrevious, financePercents: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (getFinanceHeaderDataBeef().volume - getFinanceHeaderDataBeef().volumePrevious) / getFinanceHeaderDataBeef().volumePrevious)
                                    ]
                                }
                            />
                        </ItemDouble>
                    </ItemsContainer>

                    <ItemsContainer title='PORK FINANCE'>
                        <ItemDouble>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/freshMeatsPork/finance', section: 'VOLUME' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Volume'
                                value={getFinanceHeaderDataPork().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataPork().volume) ? 0 : formatNumberString({ number: getFinanceHeaderDataPork().volume, needScale: true, remove$: true, needLBS: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', getFinanceHeaderDataPork().volume - getFinanceHeaderDataPork().volumeProj),
                                        generateDot('AOP ', getFinanceHeaderDataPork().volume - getFinanceHeaderDataPork().volumePlan, ['ml-2'])
                                    ]
                                }
                            />
                            <div className='w-px bg-gray-400 h-20'></div>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/freshMeatsPork/finance', section: 'VOLUME' })}
                                date={<span className='opacity-0'>0</span>}
                                title='Growth'
                                value={getFinanceHeaderDataPork().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataPork().volume) ? 0 : formatNumberString({ number: (getFinanceHeaderDataPork().volume - getFinanceHeaderDataPork().volumePrevious) / getFinanceHeaderDataPork().volumePrevious, financePercents: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (getFinanceHeaderDataPork().volume - getFinanceHeaderDataPork().volumePrevious) / getFinanceHeaderDataPork().volumePrevious)
                                    ]
                                }
                            />
                        </ItemDouble>
                    </ItemsContainer>

                    {/* <ItemsContainer title='INTERSEGMENT FINANCE'>
                        <ItemDouble>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/freshMeatsIntersegment/finance', section: 'VOLUME' })}
                                date={(year === 0 || quarter === 0) ? 'Error' : formatFiscDate({ quarter, year })}
                                title='Volume'
                                value={getFinanceHeaderDataIntersegment().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataIntersegment().volume) ? 0 : formatNumberString({ number: getFinanceHeaderDataIntersegment().volume, needScale: true, remove$: true, needLBS: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot('Proj. ', getFinanceHeaderDataIntersegment().volume - getFinanceHeaderDataIntersegment().volumeProj),
                                        generateDot('AOP ', getFinanceHeaderDataIntersegment().volume - getFinanceHeaderDataIntersegment().volumePlan, ['ml-2'])
                                    ]
                                }
                            />
                            <div className='w-px bg-gray-400 h-20'></div>
                            <Item
                                variant='double'
                                onClick={() => navigateAction({ href: '/r/operations/freshMeatsIntersegment/finance', section: 'VOLUME' })}
                                date={<span className='opacity-0'>0</span>}
                                title='Growth'
                                value={getFinanceHeaderDataIntersegment().volume === 1112223334445556 ? 'Error' : isNaN(getFinanceHeaderDataIntersegment().volume) ? 0 : formatNumberString({ number: (getFinanceHeaderDataIntersegment().volume - getFinanceHeaderDataIntersegment().volumePrevious) / getFinanceHeaderDataIntersegment().volumePrevious, financePercents: true })}
                                indicators={(year === 0 || quarter === 0) ? '' :
                                    [
                                        generateDot(`vs ${formatFiscDateLastYear({ quarter, year })}`, (getFinanceHeaderDataIntersegment().volume - getFinanceHeaderDataIntersegment().volumePrevious) / getFinanceHeaderDataIntersegment().volumePrevious)
                                    ]
                                }
                            />
                        </ItemDouble>
                    </ItemsContainer> */}

                    <ItemsContainer title='FRESH MEATS TEAM MEMBERS'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/freshMeats/team-members?segment=Fresh Meats', section: 'TEAM_MEMBERS' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ month: fiscDateEmployee.month, year: fiscDateEmployee.year })}
                            title='Team Members'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.avgActiveEmployeesFreshMeats) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.avgActiveEmployeesFreshMeats) ? 0 : metrics.avgActiveEmployeesFreshMeats })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/freshMeats/team-members?segment=Fresh Meats', section: 'TURNOVER_RATE' })}
                            date={!fiscDateEmployee.year ? 'Error' : formatFiscDate({ year: fiscDateEmployee.year })}
                            title='Turnover Rate'
                            value={metrics === 'Error' ? 'Error' : isNaN(metrics.turnoverRateFreshMeats * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(metrics.turnoverRateFreshMeats * 100) ? 0 : metrics.turnoverRateFreshMeats * 100, employeePercents: true })}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/freshMeats/team-members?segment=Fresh Meats', section: 'ABSENTEEISM' })}
                            date={!fiscDateEmployeeAbsenteesim.year ? 'Error' : formatFiscDate({ year: fiscDateEmployeeAbsenteesim.year })}
                            title='Absenteeism'
                            value={absenteesimMetrics === 'Error' ? 'Error' : isNaN(absenteesimMetrics.absenteesimFreshMeats * 100) ? 0 : formatNumberStringTeamMembers({ number: isNaN(absenteesimMetrics.absenteesimFreshMeats * 100) ? 0 : absenteesimMetrics.absenteesimFreshMeats, employeePercents: true })}
                        />
                    </ItemsContainer>

                    <ItemsContainer title='FRESH MEATS OSHA'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/freshMeats/osha?segment=Fresh Meats', section: 'OSHA_RATES' })}
                            date={!oshaRatesFiscDate.year ? 'Error' : formatFiscDate({ year: oshaRatesFiscDate.year }) + ` YTD`}
                            title='Rates'
                            value={<>{oshaRatesMetrics === 'Error' ? 'Error' : isNaN(oshaRatesMetrics.rate) ? 0 : formatNumberString({ number: oshaRatesMetrics.rate })} {oshaRatesMetrics === 'Error' ? '' : getDotForOshaRates(oshaRatesMetrics.rate - oshaRatesMetrics.ratePY)}</>}
                        />
                        <Item
                            onClick={() => navigateAction({ href: '/r/freshMeats/osha?segment=Fresh Meats', section: 'OSHA_RECORDABLES' })}
                            date={!oshaFiscDate.year ? 'Error' : formatFiscDate({ quarter: oshaFiscDate.quarter, year: oshaFiscDate.year })}
                            title='Recordables'
                            value={oshaMetrics === 'Error' ? 'Error' : isNaN(oshaMetrics.countRecorded) ? 0 : formatNumberString({ number: oshaMetrics.countRecorded })}
                        />
                    </ItemsContainer>

                    {/* <ItemsContainer title='CUSTOMER/CONSUMER'>
                        <Item
                            onClick={() => navigateAction({ href: '/r/freshMeats-fill-rate', section: 'OVERALL_FILL_RATE' })}
                            date={!fiscDateFillRate.year ? 'Error' : formatFiscDate({ quarter: fiscDateFillRate.quarter, year: fiscDateFillRate.year })}
                            title='Fill Rate'
                            value={<>{fillRateMetrics === 'Error' ? 'Error' : isNaN(fillRateMetrics.fillRate * 100) ? 0+'%' : formatNumberStringTeamMembers({ number: fillRateMetrics.fillRate, employeePercents: true })} {fillRateMetrics === 'Error' ? '' : getDotBasedOnValue(fillRateMetrics.fillRate)}</>}
                            />
                    </ItemsContainer> */}

                </div>
                <Section title='OPERATIONS'>
                    <SectionRow>
                        <Collapsible
                            title='PORK'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'Pork', tab: 'CapacityUtilisation Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkCUHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkCUHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'Pork', tab: 'Efficiency Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkEfficiencyHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkEfficiencyHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            {/* <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'Pork', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkCUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkCUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'Pork', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PorkEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PorkEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            /> */}
                        </Collapsible>
                    </SectionRow>

                    <SectionRow>

                        <Collapsible
                            title='BEEF'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'Beef', tab: 'CapacityUtilisation Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.BeefCUHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.BeefCUHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'Beef', tab: 'Efficiency Harvesting' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.BeefEfficiencyHar * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.BeefEfficiencyHar * 100, percents: true })}
                                indicators={[
                                    generateDot('Harvesting', 0)
                                ]}
                            />
                        </Collapsible>


                        {/* <Collapsible
                            title='PPI'
                            subtitle='CAPACITY UTILIZATION / EFFICIENCY'
                        >
                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'PPI', tab: 'CapacityUtilisation Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Capacity Utilization'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PPICUPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PPICUPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />

                            <Item
                                onClick={() => navigateAction({ href: '/r/freshMeats/plant-ops', section: 'PPI', tab: 'Efficiency Processing' })}
                                variant='horizontal'
                                date={!plantOpsFiscDate.year ? 'Error' : formatFiscDate({ quarter: plantOpsFiscDate.quarter, year: plantOpsFiscDate.year })}
                                title='Efficiency'
                                value={plantOpsMetrics === 'Error' ? 'Error' : isNaN(plantOpsMetrics.PPIEfficiencyPro * 100) ? 0 : formatNumberString({ number: plantOpsMetrics.PPIEfficiencyPro * 100, percents: true })}
                                indicators={[
                                    generateDot('Processing', 0)
                                ]}
                            />
                        </Collapsible> */}
                    </SectionRow>
                </Section>
            </AppLayout>
        </ScreenWrapper>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.userState.userInfo,
        activeQYSwitcher: state.sections.activeQYSwitcher,
        activeSection: state.sections.activeSection,
        currentDate: state.values.currentDate,
        isSingle: state.sections.isSingle,
        header: state.values.header,
        year: state.sections.year,
        quarter: state.sections.quarter,
        activeTab: state.sections.activeTab,
        activeBu: state.sections.activeBu,
        currentQuarter: state.sections.currentQuarter,
        isHeaderLoading: state.values.isHeaderLoading,
        showModal: state.app.showModal,

        userPrivilages: state.userState.userPrivilages,
        metrics: state.values.metrics,
        absenteesimMetrics: state.values.absenteesimMetrics,
        fiscDateEmployee: state.sections.fiscDateEmployee,
        fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
        oshaFiscDate: state.sections.oshaFiscDate,
        oshaMetrics: state.values.oshaMetrics,
        oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
        oshaRatesMetrics: state.values.oshaRatesMetrics,
        plantOpsFiscDate: state.sections.plantOpsFiscDate,
        plantOpsMetrics: state.values.plantOpsMetrics,
        fillRateMetrics: state.values.fillRateMetrics,
        fiscDateFillRate: state.sections.fiscDateFillRate,
        units: state.values.units
    }
}
export default connect(
    mapStateToProps,
    {
        setLatestMetrcis, setLatestMetrcisAbsenteesim, resetApp, setFiscDateEmployee, setFiscDateEmployeeAbsenteesim,
        setOshaMetrics, setOshaRatesMetrics,
        setUserInfo, setShowModal, setShowMenu, setPlantOpsMetrics,
        receiveDataHeader, setCurrentDate, setQuarter, setYear, setScrollPosition, setActiveQYSwitcher
    }
)(HomeOperationsFreshMeats)
