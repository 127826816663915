import React, { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import classes from './styles.module.css'

const Logout = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const { accessToken } = authState

  useEffect(() => {
    //console.log('Logout oktaAuth', oktaAuth)
    if (accessToken) {
      oktaAuth.logout(window.location.origin+'/login')
    }
  }, [accessToken])

  const onLoginClick = e => {
    window.location.href = '/login'
  }

  return (
    <div className={classes.rowContainerCenter} style={{minHeight: '100vh'}}>
      <div className={classes.colContainerCenter}>
        <div className={classes.rowContainerCenter} style={{marginBottom: 16}}>
          <p>Logged Out</p>
        </div>
        <button onClick={onLoginClick} className={classes.btn}>
          Login
        </button>
      </div>
    </div>
  )
}

export default Logout
