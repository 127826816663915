import React from 'react'
import toClassName from '../../utils/toClassName'

const ScrollButton = ({ children, onClick, className }) => {
  return (
    <button 
      onClick={onClick}
      className={toClassName(['bg-gray-700 flex justify-center items-center absolute transform -translate-y-1/2 top-1/2 z-10', className])} style={{
        height: 130
      }}
    >
      { children }
    </button>
  )
}

export default ScrollButton