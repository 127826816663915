import { SET_MODAL_SHOW, SET_SHOW_MENU, SET_REVENUE_DATA_REQUESTED, SET_APP_DATA, SET_LEFT_BLOCK_EXPANDED } from '../constants/app'

const INITIAL_STATE = {
  showModal: false,
  revenueDataRequested: false,
  leftBlockExpanded: true,
  showMenu: {
    active: true,
    first: true
  }
}

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MODAL_SHOW:
      return {
        ...state,
        showModal: action.payload
      }
    case SET_SHOW_MENU:
      return {
        ...state,
        showMenu: action.payload
      }
    case SET_REVENUE_DATA_REQUESTED:
      return {
        ...state,
        revenueDataRequested: action.payload
      }
    case SET_LEFT_BLOCK_EXPANDED:
      return {
        ...state,
        leftBlockExpanded: action.payload
      }
    case SET_APP_DATA:
        return {
          ...state,
          ...action.payload
        }
    default:
      return state
  }
}
