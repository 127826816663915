import { useState, useEffect } from 'react';
import throttle from '../utils/throttle';

function isElementInViewport (el) {
  const rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default function useMobileHeader() {
  const [headerVisible, setHeaderVisible] = useState(false)
  const [breakpointEl, setBreakpointEl] = useState(null)

  useEffect(() => {
    const handleScroll = throttle(function() {
      if (breakpointEl && isElementInViewport(breakpointEl)) setHeaderVisible(false)
      else setHeaderVisible(true)
    }, 200)

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [breakpointEl]);

  return { headerVisible, setBreakpointEl };
}