import React from "react";
import TabsTm from "../../componentsTeamMembers/TabsTm";

import PeriodSwitcher from "./PeriodSwitcher";

const MobileControls = ({ children, isItDash }) => {
  if (isItDash === true) {
    return (
      <div className="flex justify-around gap-2 flex-wrap lg:hidden mt-4 px-5">
        <PeriodSwitcher />
        {children}
      </div>
    );
  } else {
    return (
      <div className="flex justify-around gap-2 flex-wrap lg:hidden mt-4 px-5">
        <PeriodSwitcher />
        {children}
      </div>
    );
  }
};

export default MobileControls;
