import React from 'react'

const ParagraphNesFav = ({title, text}) => {
  return (
    <p className='text-sm mt-8 text-black'>
      <span className='font-bold'>
        { title }
      </span>
      { text }
    </p>
  )
}

export default ParagraphNesFav
