import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from "../componentsGeneral/section/Tooltip"
import { monthNames } from '../constants/app';
import { getProperty } from '../utils/dataMappingTurnover';
import formatNumber from '../utils/formatNumber';


const TooltipTmUnitGraph = ({ isSingle, data, dataKey, }) => {
  const {
    year,
    activeQYSwitcher,
    quarter
  } = useSelector(state => ({
    year: state.sections.year,
    activeQYSwitcher: state.sections.activeQYSwitcher,
    quarter: state.sections.quarter
  }))

  const getTitle = () => {
    const firstText = isSingle ? activeQYSwitcher === 'Y' ? monthNames[parseInt(isSingle) - 1] : `FW${ parseInt(isSingle) }` : activeQYSwitcher === 'Q' ? `Q${ quarter }` : ''
    return <div className='mb-1'><span className='font-bold'>{ firstText }</span> FY{year - 2000}</div>
  }

  const formatStr = ({ prependStr, number, appendStr }) => `${prependStr}${number}${appendStr}`

  const getItems = () => {
    const key = dataKey === 'absCount' ? 'viewBy' : 'period'
    const r = data
    .sort((a, b) => getProperty(a[key], 'index') - getProperty(b[key], 'index'))
    .map(itm => <div key={itm[key]} style={{ color: getProperty(itm[key], 'color') }}>{itm[dataKey] != 0 && getProperty(itm[key], 'title')} {itm[dataKey] != 0 && `:`} { itm[dataKey] != 0 && formatStr( formatNumber({ number: itm[dataKey] }) )  }</div>)

    return r
  }

  return (
        <Tooltip>
          <div className='text-xs text-black'>
            { getTitle() }
            { getItems() }
          </div>
        </Tooltip>

  )
}

export default TooltipTmUnitGraph
