import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getLatestDataHeader, getLatestEmployeeDate, getLatestEmployeeAbsenteesimDate, getOshaFiscDate,getOshaRatesFiscDate, getPlantOpsFiscDate ,getUserPermissions, getNesFavBrandFiscDate, getFillRateFiscDate } from '../../services/dataService';
import { setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setOshaFiscDate, setOshaRatesFiscDate } from '../../actions/dataActionsTeamMembers';
import { setPlantOpsFiscDate } from '../../actions/dataActionsPlantOps';
import { setFiscDateNesFavBrand } from '../../actions/dataActionsNesFavBrand';
import { setFiscDateFillRate } from '../../actions/dataActionsFillRate';

import ErrorModal from '../ErrorModal'
import { setShowModal } from '../../actions/app'
import { useOktaAuth } from '@okta/okta-react';
import { onError } from '../../actions/valuesActions';
import { setUserPrivilages, setUserInfo } from '../../actions/userActions';
import { setYear, setSingle, setQuarter, setActiveBu, setFinanceFiscDate } from '../../actions/sectionsActions';
import LoadingBlocker from '../LoadingBlocker/LoadingBlocker';
import Timeout from "../Timeout/Timeout";
import { useHistory } from "react-router-dom";
import { financeUrl } from '../../constants/urls';

const ScreenWrapper = (props) => {
    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth()
    const {accessToken: { accessToken, claims }} = authState;

    const { setFiscDateEmployee, setFiscDateEmployeeAbsenteesim, setSingle, setQuarter, setYear, osha, plantops, nesFavBrand, fillRate } = props;

    const [isOshaLoading, setIsOshaLoading] = useState(false);
    const [isOshaRatesLoading, setIsOshaRatesLoading] = useState(false);
    const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);
    const [isEmployeeAbsenteesimLoading, setIsEmployeeAbsenteesimLoading] = useState(false);
    const [isFinanceLoading, setIsFinanceLoading] = useState(false);
    const [isPlantOpsLoading, setIsPlantOpsLoading] = useState(false);
    const [isNesFavBrandLoading, setIsNesFavBrandLoading] = useState(false);
    const [isFillRateLoading, setIsFillRateLoading] = useState(false);
    const [permissonGranted, setPermissonGranted] = useState(false);
    const [datesFetched, setDatesFetched] = useState(false)

    const fetchUserInfo = async () => {
        const { onError } = props;
        const r = await getUserPermissions({ accessToken });
        if(r.type === 'success') {
            const storageItm = window.localStorage.getItem('view');
            let view = storageItm === "false" ? false : storageItm ?? false;

            if(!r.data.isSuperUser) {
                //have access only specific to Prepared Foods metrics
                if(r.data.businessSegments?.length === 1 && r.data.businessSegments[0] === 'PREPARED FOODS') 
                view = 'PREPARED FOODS';
                //have access only specific to Chicken metrics
                if(r.data.businessSegments?.length === 1 && r.data.businessSegments[0] === 'CHICKEN') 
                view = 'CHICKEN';
                //have access only specific to Fresh meats metrics
                if(r.data.businessSegments?.length === 2 && (r.data.businessSegments[0] === 'BEEF' || r.data.businessSegments[0] === 'PORK'))
                 view = 'FRESH MEATS';
                //have access only specific to Mcdonald's metrics
                if(r.data.allowedMetrics?.length === (r.data.allowedMetrics.includes('IT')) ? 3 : 2 
                && r.data.allowedMetrics.includes('MCDONALDS_USER') && r.data.allowedMetrics.includes('HR_METRICS')) 
                view = 'MCDONALDS';
                //have access only specific to HR user
                if(r.data.allowedMetrics?.length === (r.data.allowedMetrics.includes('IT')) ? 3 : 2 
                    && r.data.allowedMetrics.includes('HR_METRICS') && r.data.allowedMetrics.includes('OSHA_METRICS')) view = 'HROSHA';
                //have access for evrything OI, AOI, NS in Finance
                if(r.data.allowedMetrics?.length === (r.data.allowedMetrics.includes('IT')) ? 4 : 3 
                    && r.data.allowedMetrics.includes('FINANCE') && r.data.allowedMetrics.includes('PLANT_OPS') && r.data.allowedMetrics.includes('HR_METRICS')) view = 'OPERATIONS';
                //have access only specific to Technology(IT) metrics
                if(r.data.allowedMetrics?.length === 1 && r.data.allowedMetrics.includes('IT')) view = 'IT_USER';
                // if(r.data.allowedMetrics?.length === 2 && r.data.allowedMetrics.includes('IT') && r.data.allowedMetrics.includes('HR_METRICS')) view = 'IT_USER';
            }

            props.setUserPrivilages(
                {...r.data, view}
            );
            setTimeout(() => {
                controlURL({...r.data, view})
            }, 0)

        } else {
            // props.setUserPrivilages(
            //     {
            //         "userEmail": "dsgsdglfk",
            //         "businessSegments": [
            //            "CHICKEN"
            //         ],
            //         "lastRefreshTime": "2021-03-24T15:24:18.335Z",
            //         "isSuperUser": true
            //     },

            // );
            onError(r.data);
        }
    }

    const fetchOshaDate = async () => {
        if(isOshaLoading) return;
        const { setOshaFiscDate } = props;

        setIsOshaLoading(true)
        let {data} = await getOshaFiscDate({ accessToken });
        console.log({data},'!@#!')
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            week: data.fiscWeek,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
          }
        setOshaFiscDate(date);
        if(props.doSetYear && osha) setYear(date.year);
        if(props.doSetMonth && osha) setSingle(date.month);
        if(props.doSetQuarter && osha) setQuarter(date.quarter);
        setIsOshaLoading(false);
    }

    const fetchOshaRatesDate = async () => {
        if(isOshaRatesLoading) return;
        const { setOshaRatesFiscDate } = props;

        setIsOshaRatesLoading(true)
        let {data} = await getOshaRatesFiscDate({ accessToken });
        console.log({data},'!@#!')
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            week: data.fiscWeek,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
          }
        setOshaRatesFiscDate(date);
        if(props.doSetYear && osha) setYear(date.year);
        if(props.doSetMonth && osha) setSingle(date.month);
        if(props.doSetQuarter && osha) setQuarter(date.quarter);
        setIsOshaRatesLoading(false);
    }

    const fetchPlantOpsDate = async () => {
        if(isPlantOpsLoading) return;
        const { setPlantOpsFiscDate } = props;

        setIsPlantOpsLoading(true)
        let {data} = await getPlantOpsFiscDate({ accessToken });
        console.log({data},'!@#!')
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            week: data.fiscWeek,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
          }
        setPlantOpsFiscDate(date);
        if(props.doSetYear && plantops) setYear(date.year);
        if(props.doSetMonth && plantops) setSingle(date.month);
        if(props.doSetQuarter && plantops) setQuarter(date.quarter);
        setIsPlantOpsLoading(false);
    }

    const fetchFinanceDate = async () => {
        const { setFinanceFiscDate, setYear, setQuarter, doSetYearFinance, doSetQuarterFinance } = props
        const errorData = {
            "date": [
                {
                    "year": "0",
                    "quarter": "0"
                }
            ],
        }
        const { home } = props
        if(isFinanceLoading || home) return;
        setIsFinanceLoading(true);
        let {data, type} = await getLatestDataHeader('', accessToken);
        if(type === 'success') {
              const date = data.date[0];
              console.log({ date })
              setFinanceFiscDate({
                  year: parseInt(date.year),
                  quarter: parseInt(date.qtr),
                  month: parseInt(date.fiscMonth)
              });
              if(doSetYearFinance) setYear(parseInt(date.year))
              if(doSetQuarterFinance) setQuarter(parseInt(date.qtr))
              setIsFinanceLoading(false)
        } else {
                console.error('An error happened fetchFinanceDate')
              const date = errorData.date[0];
              setFinanceFiscDate({date});
              setIsFinanceLoading(true)
            }
    }

    const fetchEmployeeDate = async () => {
        if(isEmployeeLoading) return;
        setIsEmployeeLoading(true)
        const { data, type } = await getLatestEmployeeDate({ accessToken });
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
        }
        if(type === 'success') setFiscDateEmployee(date);
        if(props.doSetYear && !osha && !plantops && !nesFavBrand && !fillRate) setYear(date.year);
        if(props.doSetMonth && !osha && !plantops && !nesFavBrand && !fillRate) setSingle(date.month);
        if(props.doSetQuarter && !osha && !plantops && !nesFavBrand && !fillRate) setQuarter(date.quarter)
        setIsEmployeeLoading(false);

    }

    const fetchEmployeeAbsenteesimDate = async () => {
        if(isEmployeeAbsenteesimLoading) return;
        setIsEmployeeAbsenteesimLoading(true)
        const { data, type } = await getLatestEmployeeAbsenteesimDate({ accessToken });
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
            week: data.fiscWeek,
        }
        if(type === 'success') setFiscDateEmployeeAbsenteesim(date);
        if(props.doSetYear && !osha && !plantops && !nesFavBrand && !fillRate) setYear(date.year);
        if(props.doSetMonth && !osha && !plantops && !nesFavBrand && !fillRate) setSingle(date.month);
        if(props.doSetQuarter && !osha && !plantops && !nesFavBrand && !fillRate) setQuarter(date.quarter)
        setIsEmployeeAbsenteesimLoading(false);

    }

    const fetchNesFavBrandDate = async () => {
        if(isNesFavBrandLoading) return;
        const { setFiscDateNesFavBrand } = props
        setIsNesFavBrandLoading(true)
        const { data, type } = await getNesFavBrandFiscDate({ accessToken });
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
            // year: 2022,
            // month: 5,
            // quarter: 2,
            // quartal: 2,
        }
        if(type === 'success') setFiscDateNesFavBrand(date);
        if(props.doSetYear && nesFavBrand) setYear(date.year);
        if(props.doSetMonth && nesFavBrand) setSingle(date.month);
        if(props.doSetQuarter && nesFavBrand) setQuarter(date.quarter)
        setIsNesFavBrandLoading(false);

    }

    const fetchFillRateDate = async () => {
        if(isFillRateLoading) return;
        const { setFiscDateFillRate } = props
        setIsFillRateLoading(true)
        const { data, type } = await getFillRateFiscDate({ accessToken });
        const date = {
            year: data.fiscYear,
            month: data.fiscMonth,
            quarter: data.fiscQtr,
            quartal: data.fiscQtr,
            week: data.fiscWeek,
            // year: 2022,
            // month: 5,
            // quarter: 2,
            // quartal: 2,
        }
        if(type === 'success') setFiscDateFillRate(date);
        if(props.doSetYear && fillRate) setYear(date.year);
        if(props.doSetMonth && fillRate) setSingle(date.month);
        if(props.doSetQuarter && fillRate) setQuarter(date.quarter)
        setIsFillRateLoading(false);

    }

    // make it async
    const getLatestDates = async () => {
        const promises = []

        if(!props.fiscDateEmployee.year) {
            promises.push(fetchEmployeeDate())
        }
        if(!props.fiscDateEmployeeAbsenteesim.year) {
            promises.push(fetchEmployeeAbsenteesimDate())
        }
        if(!props.financeFiscDate.year) {
            promises.push(fetchFinanceDate())
        }
        if(!props.oshaFiscDate.year) {
            promises.push(fetchOshaDate())
        }
        if(!props.oshaRatesFiscDate.year) {
            promises.push(fetchOshaRatesDate())
        }
        if(!props.plantOpsFiscDate.year) {
            promises.push(fetchPlantOpsDate())
        }
        if(!props.fiscDateNesFavBrand.year) {
            promises.push(fetchNesFavBrandDate())
        }
        if(!props.fiscDateFillRate.year) {
            promises.push(fetchFillRateDate())
        }

        await Promise.all(promises)
        setDatesFetched(true)
    }

    useEffect(() => {
        getLatestDates();
        fetchUserInfo();
    }, []);

    useEffect(() => {
        const { setUserInfo } = props;
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null)
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info)
            })
        }
    }, [
        authState,
        oktaAuth
    ]);

    useEffect(() => {
        const { userPrivilages, setUserPrivilages } = props;

        if((!window.location.pathname || window.location.pathname.length < 2) && userPrivilages.view) {
            setUserPrivilages({
                ...userPrivilages,
                view: false
            })

            return;
        }
    }, [])

    const controlHrOshaUser = (userPrivilages) => {
        const { isSuperUser, view, isITUser } = userPrivilages;

        if (!isSuperUser && view === 'HROSHA' && (isITUser|| claims.MeatballAppUserRole?.includes('Meatball IT App User'))) {
            if(
                ((!window.location.pathname || window.location.pathname.length < 2)) ||
                ((!window.location.href.includes('team-members') && !window.location.href.includes('osha')) && !window.location.href.includes('hrosha') && !window.location.href.includes ('it-dashboard') &&
                !window.location.href.includes ('information-technology'))
            ) history.push('/hrosha')
        }
        if (!isSuperUser && view === 'HROSHA' && (!isITUser|| !claims.MeatballAppUserRole?.includes('Meatball IT App User')) ) {
            if(
                ((!window.location.pathname || window.location.pathname.length < 2)) ||
                ((!window.location.href.includes('team-members') && !window.location.href.includes('osha')) && !window.location.href.includes('hrosha') )
            ) history.push('/hrosha')
        }
    }

    const controlURL = (userPrivilages) => {
        const { setUserPrivilages } = props;
        if(!userPrivilages.userEmail) return;
        setPermissonGranted(false)

        if((!window.location.pathname || window.location.pathname.length < 2) && userPrivilages.view && userPrivilages.isSuperUser) {
            setUserPrivilages({
                ...userPrivilages,
                view: false
            })

            setPermissonGranted(true)
            return;
        }

        controlHrOshaUser(userPrivilages)

        if (userPrivilages.isSuperUser) {
            if ((window.location.href.includes('hrosha'))) {
                history.push('/')
            }
            if (userPrivilages.view === 'CHICKEN' && (window.location.href.includes('prepared') || window.location.href.includes('freshMeats') ) ) {
                history.push('/');
            }
            if (userPrivilages.view === 'PREPARED FOODS' && (window.location.href.includes('poultry') || window.location.href.includes('freshMeats') || window.location.href.includes('McDonalds')) ) {
                history.push('/');
            }
            if (userPrivilages.view === 'FRESH MEATS' && (window.location.href.includes('poultry') || window.location.href.includes('prepared') || window.location.href.includes('McDonalds')) ) {
                history.push('/');
            }
            if (userPrivilages.view === 'MCDONALDS' && ( window.location.href.includes('prepared')|| window.location.href.includes('freshMeats')) ) {
                history.push('/');
            }
            if (userPrivilages.view === 'IT_USER' && ( window.location.href.includes('poultry') || window.location.href.includes('prepared')|| window.location.href.includes('freshMeats') || window.location.href.includes('McDonalds')) ) {
                history.push('/');
            }
            if (userPrivilages.view === 'OPERATIONS' && ( window.location.href.includes('poultry') || window.location.href.includes('prepared') || window.location.href.includes('freshMeats') || window.location.href.includes('information-technology') || window.location.href.includes('McDonalds')) ) {
                history.push('/');
            }
        }

        if (!userPrivilages.isSuperUser) {
            if (userPrivilages.view === 'CHICKEN' && (window.location.href.includes('hrosha') )) {
                history.push('/poultry')
            }
            if (userPrivilages.view === 'PREPARED FOODS' && (window.location.href.includes('hrosha') )) {
                history.push('/prepared')
            }
            if (userPrivilages.view === 'FRESH MEATS' && (window.location.href.includes('hrosha') )) {
                history.push('/freshMeats')
            }
            if (userPrivilages.view === 'MCDONALDS' && (window.location.href.includes('hrosha') )) {
                history.push('/McDonalds')
            }
            if (userPrivilages.view === 'IT_USER' && (window.location.href.includes('hrosha') )) {
                history.push('/information-technology')
            }
            if (userPrivilages.view === 'OPERATIONS' && (window.location.href.includes('hrosha') )) {
                history.push('/operations')
            }
        }

        if(!userPrivilages.view) {
            if(userPrivilages.isSuperUser && window.location.href.includes('poultry')) {
                history.push( '/');
            }
            if(userPrivilages.isSuperUser && window.location.href.includes('prepared')) {
                history.push( '/');
            }
            if(userPrivilages.isSuperUser && window.location.href.includes('freshMeats')) {
                history.push( '/');
            }
            if(userPrivilages.isSuperUser && window.location.href.includes('McDonalds')) {
                history.push( '/');
            }
            if(userPrivilages.isSuperUser && window.location.href.includes('information-technology')) {
                history.push( '/');
            }
            if(userPrivilages.isSuperUser && window.location.href.includes('operations')) {
                history.push( '/');
            }
        }

        if((!userPrivilages.isSuperUser && userPrivilages.businessSegments?.includes('CHICKEN') && userPrivilages.businessSegments.length === 1 && (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('poultry') &&
            !window.location.href.includes ('it-dashboard') &&
            !window.location.href.includes ('information-technology') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('osha') &&
            !window.location.href.includes('fill-rate')
        )) {
            history.push( '/poultry');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.businessSegments?.includes('CHICKEN') && userPrivilages.businessSegments.length === 1 && (!userPrivilages?.isITUser || !claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('poultry') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('osha') &&
            !window.location.href.includes('fill-rate')
        )) {
            history.push( '/poultry');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.businessSegments?.includes('PREPARED FOODS') && userPrivilages.businessSegments.length === 1 && (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('prepared') &&
            !window.location.href.includes ('it-dashboard') &&
            !window.location.href.includes ('information-technology') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('osha') &&
            !window.location.href.includes('fill-rate')
        )) {
            history.push( '/prepared');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.businessSegments?.includes('PREPARED FOODS') && userPrivilages.businessSegments.length === 1 && (!userPrivilages?.isITUser || !claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('prepared') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('osha') &&
            !window.location.href.includes('fill-rate')
        )) {
            history.push( '/prepared');
        }

        if((!userPrivilages.isSuperUser && (userPrivilages.businessSegments?.includes('BEEF') || userPrivilages.businessSegments?.includes('PORK')) && userPrivilages.businessSegments.length === 2 && (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('freshMeats') &&
            !window.location.href.includes ('it-dashboard') &&
            !window.location.href.includes ('information-technology') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('osha')
        )) {
            history.push( '/freshMeats');
        }

        if((!userPrivilages.isSuperUser && (userPrivilages.businessSegments?.includes('BEEF') || userPrivilages.businessSegments?.includes('PORK')) && userPrivilages.businessSegments.length === 2 && (!userPrivilages?.isITUser || !claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('freshMeats') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('osha')
        )) {
            history.push( '/freshMeats');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('MCDONALDS_USER') 
        && userPrivilages.allowedMetrics?.includes('HR_METRICS') && userPrivilages.allowedMetrics.length === (userPrivilages.allowedMetrics.includes('IT') ? 3 : 2)
        && (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('McDonalds') &&
            !window.location.href.includes ('it-dashboard') &&
            !window.location.href.includes ('information-technology') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('osha')
        )) {
            history.push( '/McDonalds');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('MCDONALDS_USER') && userPrivilages.allowedMetrics?.includes('HR_METRICS') && userPrivilages.allowedMetrics.length === 2 && (!userPrivilages?.isITUser || !claims.MeatballAppUserRole?.includes('Meatball IT App User'))) && (
            !window.location.href.includes ('McDonalds') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('osha')
        )) {
            history.push( '/McDonalds');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('IT') && userPrivilages.allowedMetrics.length === 1) && (
            !window.location.href.includes ('information-technology') &&
            !window.location.href.includes ('it-dashboard')
        )) {
            history.push( '/information-technology');
        }

        // if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('IT') && userPrivilages.allowedMetrics?.includes('HR_METRICS') && userPrivilages.allowedMetrics.length === 2) && (
        //     !window.location.href.includes ('information-technology') &&
        //     !window.location.href.includes ('it-dashboard')
        // )) {
        //     history.push( '/information-technology');
        // }

        // if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('IT') && userPrivilages.allowedMetrics.length === 1 && userPrivilages?.isITUser) && (
        //     !window.location.href.includes ('information-technology') &&
        //     !window.location.href.includes ('it-dashboard')
        // )) {
        //     history.push( '/information-technology');
        // }

        if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('FINANCE') && userPrivilages.allowedMetrics?.includes('PLANT_OPS') 
        && userPrivilages.allowedMetrics?.includes('HR_METRICS') && (userPrivilages?.isITUser || claims.MeatballAppUserRole?.includes('Meatball IT App User'))
        && userPrivilages.allowedMetrics.length == (userPrivilages.allowedMetrics.includes('IT') ? 4 : 3)) && (
            !window.location.href.includes ('operations') &&
            !window.location.href.includes ('it-dashboard') &&
            !window.location.href.includes ('information-technology') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('osha') &&
            !window.location.href.includes('nes-fav-brand') &&
            !window.location.href.includes('fill-rate')
        )) {
            history.push( '/operations');
        }

        if((!userPrivilages.isSuperUser && userPrivilages.allowedMetrics?.includes('FINANCE') && userPrivilages.allowedMetrics?.includes('PLANT_OPS') && userPrivilages.allowedMetrics?.includes('HR_METRICS') && (!userPrivilages?.isITUser || !claims.MeatballAppUserRole?.includes('Meatball IT App User'))
        && userPrivilages.allowedMetrics.length == 3) && (
            !window.location.href.includes ('operations') &&
            !window.location.href.includes('team-members') &&
            !window.location.href.includes('plant-ops') &&
            !window.location.href.includes('osha') &&
            !window.location.href.includes('nes-fav-brand') &&
            !window.location.href.includes('fill-rate')
        )) {
            history.push( '/operations');
        }

        setPermissonGranted(true)
    }

    useEffect(() => {
        console.log(history.location.pathname, ' 2$$!')
        const { userPrivilages } = props
        controlURL(userPrivilages)
    }, [
        history.location.pathname
    ])

    if(!((props.initialLoadingCompleted === undefined || props.initialLoadingCompleted))) {
        return <LoadingBlocker loading={true} />
    }

    const doRenderChildren = () => {
        if (window.location.pathname.includes(financeUrl) && !props.financeFiscDate.year) return false

        return true
    }

    return (
        <>
            <ErrorModal
                title={'Oh Snap!'}
                text={`Looks like we're experiencing some technical issues.`}
                action={() => {
                    props.showModal({
                        active: true,
                        type: 'contact'
                    })
                    props.onError(null)

                }}
            />
            { (permissonGranted && datesFetched && doRenderChildren()) ? props.children : null }
            <LoadingBlocker loading={props.isGraphLoading || !datesFetched} />
            <Timeout />
        </>
    )
}

export default connect(
    state => ({
        activeSection: state.sections.activeSection,
        year: state.sections.year,
        fiscDateEmployee: state.sections.fiscDateEmployee,
        fiscDateEmployeeAbsenteesim: state.sections.fiscDateEmployeeAbsenteesim,
        oshaFiscDate: state.sections.oshaFiscDate,
        oshaRatesFiscDate: state.sections.oshaRatesFiscDate,
        plantOpsFiscDate: state.sections.plantOpsFiscDate,
        financeFiscDate: state.sections.financeFiscDate,
        fiscDateNesFavBrand: state.sections.fiscDateNesFavBrand,
        fiscDateFillRate: state.sections.fiscDateFillRate,
        isGraphLoading: state.values.isGraphLoading,
        showModalValue: state.app.showModal,
        userPrivilages: state.userState.userPrivilages
    }),
    { setFiscDateEmployee, setFiscDateEmployeeAbsenteesim,
        setUserInfo, showModal: setShowModal, setFiscDateNesFavBrand, setFiscDateFillRate,
        onError, setYear, setOshaFiscDate, setOshaRatesFiscDate, setPlantOpsFiscDate,
        setQuarter, setSingle, setUserPrivilages, setActiveBu, setFinanceFiscDate }
)(ScreenWrapper)
