
import {
  oiColor,
  aoiColor,
  salesColor
} from '../constants/colors';
import {
  tabsFinance,
  mutualTabsTeamMembers,
  PoultryTabsPlantOps,
  BeefTabsPlantOps,
  PorkTabsPlantOps,
  PPITabsPlantOps,
  PreparedTabsPlantOps,
  McdTabsPlantOps,
  sectionsFinance,
  sectionsPlantOps,
  sectionsOsha,
  sectionsEmployees,
  sectionsFillRate,
} from '../constants/app';
import {
  SET_ACTIVE_TAB,
  SET_ACTIVE_QY_SWITCHER,
  SET_ACTIVE_SECTION,
  SET_SINGLE,
  SET_ACTIVE_BU,
  SET_YEAR,
  SET_QUARTER,
  IS_GRAPH_READY,
  SET_SCROLL_POSITION,
  SET_BU_TYPE,
  SET_FILTER,
  SET_IS_CORE,
  SET_FINANCE_FISC_DATE
} from '../actions/sectionsActions';
import {
  SET_FISC_DATE_EMPLOYEE,
  SET_FISC_DATE_EMPLOYEE_ABSENTEEISM,
  SET_OSHA_FISC_DATE,
  SET_OSHA_RATES_FISC_DATE,
} from '../actions/dataActionsTeamMembers';
import {
  SET_PLANT_OPS_FISC_DATE
} from '../actions/dataActionsPlantOps';
import {
  SET_NES_FAV_BRAND_FISC_DATE
} from '../actions/dataActionsNesFavBrand';
import {
  SET_FILL_RATE_FISC_DATE
} from '../actions/dataActionsFillRate';
import getCurrentQY from '../utils/getCurrentQY';

const { year, quartal } = getCurrentQY();

const filtersInitialState = ['Operations', 'Corporate', 'Support'];

const absFilter = {
  absenteeismFiltersInitialState: ['Planned', 'Unplanned', 'Leave of Absence'],
  absenteeismBaseLineFiltersInitialState: ['Y', 'N']
}
const filtersInitialsState = {
  corpOps: filtersInitialState.slice(0),
  absenteeism: absFilter.absenteeismFiltersInitialState.slice(0),
  absenteeismBaseLine: absFilter.absenteeismBaseLineFiltersInitialState.slice(0, 1),
  hourlySalary: false
}

const CapacityUtilisationHarvesting = 'CapacityUtilisation Harvesting';
const OpsPerformance = 'Ops Performance';
const CapacityUtilisationProcessing = 'CapacityUtilisation Processing';


export const INITIAL_STATE = {
  activeSection: '',
  color: oiColor,
  activeQYSwitcher: 'Q',
  activeTab: false,
  activeTabTitle: '',
  isSingle: false,
  activeBu: false,
  year: 0,
  quarter: 0,
  isGraphReady: false,
  currentYear: year,
  currentQuarter: quartal,
  isCore: false,
  buType: '',
  sectionTitle: '',
  filters: {
      ...filtersInitialsState
  },
  financeFiscDate: {},
  fiscDateEmployee: {},
  fiscDateEmployeeAbsenteesim: {},
  fiscDateNesFavBrand: {},
  fiscDateFillRate: {},
  oshaFiscDate: {},
  oshaRatesFiscDate: {},
  plantOpsFiscDate: {},
}

const getSegment = () => {
  const params = new URLSearchParams(window.location.search);
  const segment = params.get('segment');
  console.log({ segment }, ' dddd')
  return segment;
}

export const reducer = (state = INITIAL_STATE, action) => {

  switch(action.type) {
      case SET_FINANCE_FISC_DATE:
          const { financeFiscDate } = action.payload;
          return {
              ...state,
              financeFiscDate
          }
      case SET_PLANT_OPS_FISC_DATE:
          let { plantOpsFiscDate } = action.payload;
          return {
              ...state,
              plantOpsFiscDate
          }
      case SET_OSHA_FISC_DATE:
          let { oshaFiscDate } = action.payload;
          return {
              ...state,
              oshaFiscDate
          }
      case SET_OSHA_RATES_FISC_DATE:
          let { oshaRatesFiscDate } = action.payload;
          return {
              ...state,
              oshaRatesFiscDate
          }
      case SET_FILTER:
          let {
              filter,
              type
          } = action.payload;
          if(type === 'corpOps') {
              let corpOpsCopy = state.filters.corpOps.slice(0);
              corpOpsCopy.push(filter);
              filter = corpOpsCopy;
          }
          else if(type === 'corpOpsRemove') {
              type = 'corpOps';
              let corpOpsCopy = state.filters.corpOps.filter(filterV => filterV !== filter);
              if( corpOpsCopy.length === 0 ) corpOpsCopy = filtersInitialState.filter(filterV => filterV !== filter);

              filter = corpOpsCopy;
          }
          else if(type === 'hourlySalary') {
              if(filter !== state.filters.hourlySalary) {

              }
              else if (state.filters.hourlySalary) {
                  filter = false;
              }
          }
          else if(type === 'absenteeism' || type === 'absenteeismBaseLine') {
              const absFilters = state.filters[type]
              if (absFilters.includes(filter)) {
                  if (absFilters.length === 1) {
                      filter = absFilter[type + 'FiltersInitialState'].filter(f => f !== filter)
                  } else {
                      filter = absFilters.filter(f => f !== filter)
                  }
              }
              else filter = [...absFilters, filter]
          }
          return {
              ...state,
              filters: {
                  ...state.filters,
                  [type]: filter
               }
          }
      case SET_BU_TYPE:
          const { buType } = action.payload;
          return {
              ...state,
              buType
          }
      case SET_IS_CORE:
          const { isCore } = action.payload;
          return {
              ...state,
              isCore
          }
      case IS_GRAPH_READY:
          const { isGraphReady } = action.payload;
          return {
              ...state,
              isGraphReady
          }
      case SET_YEAR:
          const { year } = action.payload;
          return {
              ...state,
              year
          }
      case SET_QUARTER:
          const v = action.payload.quarter;
          return {
              ...state,
              quarter: v
          }
      case SET_SCROLL_POSITION:
          const { scrollPosition } = action.payload;
          return {
              ...state,
              scrollPosition
          }
      case SET_ACTIVE_TAB:
          const { tab } = action.payload;
          let activeTabTitle = false;
          switch(tab) {
              case 'chicken':
                  activeTabTitle = 'Poultry';
                  break;
              case 'prepared foods':
                  activeTabTitle = 'Prepared';
                  break;
              case 'beef':
                  activeTabTitle = 'Beef';
                  break;
              case 'pork':
                  activeTabTitle = 'Pork';
                  break;
              case 'intersegment':
                  activeTabTitle = 'Intersegment';
                  break;
              case 'international':
                  activeTabTitle = 'International'
                  break;
              default:
                  activeTabTitle = false;
                  break;

          }
          return {
              ...state,
              activeTab: tab,
              activeTabTitle,
              //isSingle: (tabsTeamMembers.includes(tab) || oshaTabs.includes(tab)) ? false : state.isSingle
          }
      case SET_ACTIVE_QY_SWITCHER:
          const { activeQYSwitcher, keepSingleState } = action.payload;
          let isSingleVal = keepSingleState ? state.isSingle : false;
          return {
              ...state,
              activeQYSwitcher: activeQYSwitcher,
              isSingle: isSingleVal
          }
      case SET_FISC_DATE_EMPLOYEE:
          return {
              ...state,
              fiscDateEmployee: action.payload.date
          }
      case SET_FISC_DATE_EMPLOYEE_ABSENTEEISM:
          return {
              ...state,
              fiscDateEmployeeAbsenteesim: action.payload.date
          }
      case SET_NES_FAV_BRAND_FISC_DATE:
          return {
              ...state,
              fiscDateNesFavBrand: action.payload.date
          }
      case SET_FILL_RATE_FISC_DATE:
          console.log(action.payload.date, ' hiuiuhiuihu')
          return {
              ...state,
              fiscDateFillRate: action.payload.date
          }
      case SET_ACTIVE_SECTION:
          const { activeSection, preselectTab } = action.payload;
          let color, isSingle, activeTabTitleN,yearNew, newBuType, newActiveBu, newActiveTab, sectionTitle, newActiveQYSwitcher, newQuarter, filters;
          const seg = getSegment();
          switch (activeSection) {
              case 'IT_FINANCE_ACTUALS':
                  isSingle = false;
                  newActiveQYSwitcher = 'Y';
                  sectionTitle = "Actual vs Planned";
                  break;
              case 'IT_FINANCE_COMPENSATION':
                  isSingle = false;
                  newActiveQYSwitcher = 'Y';
                  sectionTitle = "Salary & Benefits vs Planned";
                  break;
              case 'IT_FINANCE_CONTROLLABLES':
                  isSingle = false;
                  newActiveQYSwitcher = 'Y';
                  sectionTitle = "Controllables vs Planned";
                  break;
              case "IT_FINANCE_NonControllables":
                  isSingle = false;
                  newActiveQYSwitcher = "Y";
                  sectionTitle = "NonControllables vs Planned";
                  break;
              case 'IT_FINANCE_CLOUD':
                  isSingle = false;
                  newActiveQYSwitcher = 'Y';
                  sectionTitle = "Cloud Cost vs Planned";
                  break;
              case "IT_Hr_TEAM_MEMBERS":
                  isSingle = false;
                  newActiveQYSwitcher = "Y";
                  sectionTitle = "Team Members";
                  newActiveTab = "Gender";
                  break;
              case "IT_Hr_Vacancies":
                    isSingle = false;
                    newActiveQYSwitcher = "Y";
                    sectionTitle = "Vacancies";
                    newActiveTab = "All"
                    break;
              case "IT_Hr_Positions":
                    isSingle = false;
                    newActiveQYSwitcher = "Y";
                    sectionTitle = "Position In Offer Status";
                    break;
              case "IT_Hr_AvgDaysHire":
                    isSingle = false;
                    newActiveQYSwitcher = "Y";
                    sectionTitle = "Average Days to Hire";
                    break;
              case "IT_Hr_TOTAL_ATTRITION":
                    isSingle = false;
                    newActiveQYSwitcher = "Y";
                    sectionTitle = "Attrition";
                    break;
              case 'OI':
                  color = oiColor;
                  isSingle = sectionsFinance.includes(state.activeSection) ? undefined : false;
                  sectionTitle = 'Operating Income'
                  if( Array.isArray(state.activeBu)) newActiveBu = false;
                  if(seg && !state.activeBu) newActiveBu = seg;
                  if(preselectTab) newActiveBu = preselectTab;
                  if(!tabsFinance.includes(state.activeTab)) newActiveTab = false;
                  break;
              case 'AOI':
                  color = aoiColor;
                  isSingle = sectionsFinance.includes(state.activeSection) ? undefined : false;
                  sectionTitle = 'AOI%'
                  if( Array.isArray(state.activeBu)) newActiveBu = false;
                  if(seg && !state.activeBu) newActiveBu = seg;
                  if(preselectTab) newActiveBu = preselectTab;
                  if(!tabsFinance.includes(state.activeTab)) newActiveTab = false;
                  break;
              case 'NET_SALES':
                  color = salesColor;
                  isSingle = sectionsFinance.includes(state.activeSection) ? undefined :false;
                  sectionTitle = 'Net Sales'
                  if( Array.isArray(state.activeBu)) newActiveBu = false;
                  if(seg && !state.activeBu) newActiveBu = seg;
                  if(preselectTab) newActiveBu = preselectTab;
                  if(!tabsFinance.includes(state.activeTab)) newActiveTab = false;
                  break;
              case 'VOLUME':
                  isSingle = sectionsFinance.includes(state.activeSection) ? undefined :false;
                  sectionTitle = 'Volume & Growth'
                  if( Array.isArray(state.activeBu)) newActiveBu = false;
                  if(seg && !state.activeBu) newActiveBu = seg;
                  if(preselectTab) newActiveBu = preselectTab;
                  if(!tabsFinance.includes(state.activeTab)) newActiveTab = false;
                  break;
              case 'TEAM_MEMBERS':
                  isSingle = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) && state.year != state.fiscDateEmployee.year ? 12 : state.fiscDateEmployee.month;
                  yearNew = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.year : state.fiscDateEmployee.year;
                  newBuType = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.buType : seg ? 'location' : false;
                  newActiveBu = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.activeBu : seg ? [seg] : [];
                  newActiveTab = mutualTabsTeamMembers.includes(state.activeTab) ? state.activeTab : 'Gender';
                  sectionTitle = 'Team Members'
                  newActiveQYSwitcher = 'Y';
                  newQuarter = false;
                  if (activeSection !== 'TEAM_MEMBERS' && activeSection !== 'TURNOVER_RATE') filters = { ...filtersInitialsState }
                  break;
              case 'TURNOVER_RATE':
                  isSingle = false;
                  yearNew = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.year : state.fiscDateEmployee.year;
                  newBuType = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.buType : seg ? 'location' : false;
                  newActiveBu = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.activeBu : seg ? [seg] : [];
                  newActiveTab = mutualTabsTeamMembers.includes(state.activeTab) ? state.activeTab : 'Gender';
                  sectionTitle = 'Turnover Rate';
                  newActiveQYSwitcher = 'Y';
                  newQuarter = false;
                  if (activeSection !== 'TEAM_MEMBERS' && activeSection !== 'TURNOVER_RATE') filters = { ...filtersInitialsState }
                  break;
              case 'ABSENTEEISM':
                  isSingle = false;
                  yearNew = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.year > (state.fiscDateEmployeeAbsenteesim.year ? state.fiscDateEmployeeAbsenteesim.year : state.year) ? state.fiscDateEmployeeAbsenteesim.year : state.year : state.fiscDateEmployeeAbsenteesim.year;
                  newQuarter = (!(sectionsEmployees.includes(state.activeSection)) || (state.fiscDateEmployeeAbsenteesim.year == state.year)) ? state.fiscDateEmployeeAbsenteesim.quarter : 4;
                  newBuType = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.buType : seg ? 'location' : false;
                  newActiveBu = ((sectionsEmployees.includes(state.activeSection)) && !preselectTab) ? state.activeBu : seg ? [seg] : [];
                  newActiveTab = false;
                  sectionTitle = 'Absenteesim'
                  newActiveQYSwitcher = 'Y';
                  filters = { ...filtersInitialsState }
                  break;
              case 'OSHA_RATES':
                  isSingle = false;
                  yearNew = ((sectionsOsha.includes(state.activeSection)) && !preselectTab) ? state.year > state.oshaRatesFiscDate.year ? state.oshaRatesFiscDate.year : state.year : state.oshaRatesFiscDate.year;
                  newBuType = ((sectionsOsha.includes(state.activeSection)) && !preselectTab) ? state.buType : seg ? 'location' : false;
                  newActiveBu = ((sectionsOsha.includes(state.activeSection)) && !preselectTab) ? state.activeBu : seg ? [seg] : [];
                  newActiveTab = false;
                  newActiveQYSwitcher = 'Y';
                  sectionTitle = 'OSHA Rates'
                  newQuarter = false;
                  activeTabTitleN = false;
                  break;
              case 'OSHA_RECORDABLES':
                  isSingle = false;
                  yearNew = ((sectionsOsha.includes(state.activeSection)) && !preselectTab) ? state.year > state.oshaFiscDate.year ? state.oshaFiscDate.year : state.year  : state.oshaFiscDate.year;
                  newQuarter = (!(sectionsOsha.includes(state.activeSection)) || (state.oshaFiscDate.year == state.year)) ? state.oshaFiscDate.quarter : 4;
                  newBuType = ((sectionsOsha.includes(state.activeSection)) && !preselectTab) ? state.buType : seg ? 'location' : false;
                  newActiveBu = ((sectionsOsha.includes(state.activeSection)) && !preselectTab) ? state.activeBu : seg ? [seg] : [];
                  newActiveTab = 'All';
                  sectionTitle = 'OSHA Recordables'
                  newActiveQYSwitcher = 'Q';
                  activeTabTitleN = false;
                  break;
              case 'Poultry':
                  isSingle = false;
                  yearNew = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.year : state.plantOpsFiscDate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = preselectTab ? preselectTab : PoultryTabsPlantOps.includes(state.activeTab) ? state.activeTab : CapacityUtilisationHarvesting;
                  newActiveQYSwitcher = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q' ;
                  newQuarter = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.plantOpsFiscDate.quarter;
                  activeTabTitleN = false;
                  sectionTitle = OpsPerformance
                  break;
              case 'Beef':
                  isSingle = false;
                  yearNew = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.year : state.plantOpsFiscDate.year;
                  newBuType = seg ? 'location' : false;
                   newActiveBu = seg ? [seg] : [];
                  newActiveTab = preselectTab ? preselectTab : BeefTabsPlantOps.includes(state.activeTab) ? state.activeTab : CapacityUtilisationHarvesting;
                  newActiveQYSwitcher = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q' ;
                  newQuarter = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.plantOpsFiscDate.quarter;
                  activeTabTitleN = false;
                  sectionTitle = OpsPerformance
                  break;
              case 'Pork':
                  isSingle = false;
                  yearNew = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.year : state.plantOpsFiscDate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = preselectTab ? preselectTab : PorkTabsPlantOps.includes(state.activeTab) ? state.activeTab : CapacityUtilisationHarvesting;
                  newActiveQYSwitcher = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q' ;
                  newQuarter = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.plantOpsFiscDate.quarter;
                  activeTabTitleN = false;
                  sectionTitle = OpsPerformance
                  break;
              case 'PPI':
                  isSingle = false;
                  yearNew = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.year : state.plantOpsFiscDate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = preselectTab ? preselectTab : PPITabsPlantOps.includes(state.activeTab) ? state.activeTab : CapacityUtilisationProcessing;
                  newActiveQYSwitcher = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q' ;
                  newQuarter = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.plantOpsFiscDate.quarter;
                  activeTabTitleN = false;
                  sectionTitle = OpsPerformance
                  break;
              case 'Prepared':
                  isSingle = false;
                  yearNew = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.year : state.plantOpsFiscDate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = preselectTab ? preselectTab : PreparedTabsPlantOps.includes(state.activeTab) ? state.activeTab : CapacityUtilisationProcessing;
                  newActiveQYSwitcher = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q' ;
                  newQuarter = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.plantOpsFiscDate.quarter;
                  activeTabTitleN = false;
                  sectionTitle = OpsPerformance
                  break;
              case 'McDonalds':
                  isSingle = false;
                  yearNew = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.year : state.plantOpsFiscDate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = preselectTab ? preselectTab : McdTabsPlantOps.includes(state.activeTab) ? state.activeTab : CapacityUtilisationProcessing;
                  newActiveQYSwitcher = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q' ;
                  newQuarter = ((sectionsPlantOps.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.plantOpsFiscDate.quarter;
                  activeTabTitleN = false;
                  sectionTitle = OpsPerformance
                  break;
              case 'NES_FAVORITE_BRAND':
                  isSingle = false;
                  yearNew = state.fiscDateNesFavBrand.year;
                  newActiveQYSwitcher = 'Q' ;
                  newQuarter = state.fiscDateNesFavBrand.quarter;
                  activeTabTitleN = false;
                  sectionTitle = 'NES & Favorite Brand'
                  break;
              case 'Overall Fill Rate':
                  isSingle = false;
                  yearNew = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.year : state.fiscDateFillRate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = false;
                  sectionTitle = 'Fill Rate';
                  newActiveQYSwitcher = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q';
                  newQuarter = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.fiscDateFillRate.quarter;
                  break;
              case 'Top 10 Customers':
                  isSingle = false;
                  yearNew = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.year : state.fiscDateFillRate.year;
                //   newBuType = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.buType : seg ? 'location' : false;
                //   newActiveBu = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.activeBu : seg ? [seg] : [];
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = false;
                  sectionTitle = 'Fill Rate';
                  newActiveQYSwitcher = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q';
                  newQuarter = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.fiscDateFillRate.quarter;
                  break;
              case 'Top 10 Shorted Customers':
                  isSingle = false;
                  yearNew = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.year : state.fiscDateFillRate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = false;
                  sectionTitle = 'Fill Rate';
                  newActiveQYSwitcher = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q';
                  newQuarter = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.fiscDateFillRate.quarter;
                  break;
              case 'Top 10 Shorted Categories':
                  isSingle = false;
                  yearNew = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.year : state.fiscDateFillRate.year;
                  newBuType = seg ? 'location' : false;
                  newActiveBu = seg ? [seg] : [];
                  newActiveTab = false;
                  sectionTitle = 'Fill Rate';
                  newActiveQYSwitcher = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.activeQYSwitcher : 'Q';
                  newQuarter = ((sectionsFillRate.includes(state.activeSection)) && !preselectTab) ? state.quarter : state.fiscDateFillRate.quarter;
                  break;
              default:
                  break;
          }
          return {
              ...state,
              activeSection,
              color,
              isSingle: isSingle !== undefined ? isSingle : state.isSingle,
              year: yearNew !== undefined ? yearNew : state.year,
              buType: newBuType !== undefined ? newBuType : state.buType,
              activeBu: newActiveBu !== undefined ? newActiveBu : state.activeBu,
              activeTab: newActiveTab !== undefined ? newActiveTab : state.activeTab,
              sectionTitle: sectionTitle !== undefined ? sectionTitle : state.sectionTitle,
              activeQYSwitcher: newActiveQYSwitcher !== undefined ? newActiveQYSwitcher : state.activeQYSwitcher,
              quarter: newQuarter !== undefined ? newQuarter : state.quarter,
              filters: filters ? filters : state.filters,
              isCore: false,
              // filters: {
              //     corpOps: filtersInitialState,
              //     hourlySalary: false
              // },
              activeTabTitle: activeTabTitleN !== undefined ? activeTabTitleN : state.activeTabTitle
          }
      case SET_SINGLE:
          if(action.payload.isSingle === false && state.activeSection === 'TEAM_MEMBERS') return {
              ...state
          }
          return {
              ...state,
              isSingle: action.payload.isSingle
          }
      case SET_ACTIVE_BU:
          const { activeBu } = action.payload;
          if(sectionsFinance.includes(state.activeSection) ) return {
              ...state,
              activeBu
          }
          if(Array.isArray(activeBu)) return {
              ...state,
              activeBu
          }
          const activeBuArray = state.activeBu.slice(0);
          if(activeBuArray.length < 4) activeBuArray.push(activeBu);
          else activeBuArray[activeBuArray.length - 1] = activeBu;
          return {
              ...state,
              activeBu: activeBuArray
          }
      default:
          return state;
  }
}
